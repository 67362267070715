import { useEffect, useState } from "react"
import { db } from "../../../../firebase"
import TwitterIcon from "../../../../assets/media/userProfile/twitter.svg"
import TwitterIconSolid from "../../../../assets/media/userProfile/twitterSolid.svg"
import { Tooltip } from "react-tooltip"
import { actions as Mixpanel } from "../../../../util/Mixpanel"

interface TwitterProviderDocument {
	handle: string
}

export const TwitterIntegration: React.FC<{ walletAddress: string }> = ({
	walletAddress,
}) => {
	const [twitterHandle, setTwitterHandle] = useState<string | null>(null)

	const [hovered, setHovered] = useState(false)

	const handleMouseEnter = () => {
		setHovered(true)
	}

	const handleMouseLeave = () => {
		setHovered(false)
	}

	useEffect(() => {
		if (walletAddress) {
			db.doc(`/accounts/${walletAddress}/providers/twitter`)
				.get()
				.then(twitterDoc => {
					if (twitterDoc.exists) {
						const twitterData = twitterDoc.data() as TwitterProviderDocument
						setTwitterHandle(twitterData.handle ?? null)
					}
				})
				.catch(e => {
					Mixpanel.track("ERROR_FIREBASE_FETCH_TWITTER", {
						e,
					})
				})
		}
	}, [walletAddress])

	if (twitterHandle) {
		return (
			<div className='mr-[0.5rem]'>
				<Tooltip
					id={`view-twitter-profile-tooltip`}
					style={{
						backgroundColor: "white",
						color: "#031021",
						fontSize: "0.75rem",
					}}
				/>
				<a
					data-tooltip-id={`view-twitter-profile-tooltip`}
					data-tooltip-content={`View Twitter Profile`}
					data-tooltip-place='right'
					href={`https://twitter.com/${twitterHandle}`}
					target='_blank'
					rel='noreferrer'
				>
					<img
						src={hovered ? TwitterIconSolid : TwitterIcon}
						alt='Twitter Profile'
						className='w-[24px] h-[24px]'
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					/>
				</a>
			</div>
		)
	}

	return <></>
}
