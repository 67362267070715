import { inject, observer } from "mobx-react"
import { useEffect, useMemo, useState } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { AuthStoreProp } from "../stores/AuthStore"
import Layout from "./Layout"
import { auth } from "../firebase"
import { apiURL } from "../util/settings"
// I dont like this eslint rule; having this line lets us as props as they're necessary
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DiscordOauthProp extends AuthStoreProp {}

const DiscordOauth: React.FC<DiscordOauthProp> = ({ authStore }) => {
	const { search } = useLocation()
	const query = useMemo(() => new URLSearchParams(search), [search])
	const code = useMemo(() => query?.get("code"), [query])
	const [hasSaved, setHasSaved] = useState(false)

	const PageBody = useMemo(() => {
		if (!authStore?.loggedUser?.addr) {
			return (
				<div className='flex flex-1 mt-4 flex-row items-center justify-center w-full'>
					<p className='text-2xl'>
						{" "}
						Please log-in to finish linking your Discord account.
					</p>
				</div>
			)
		}

		return <></>
	}, [authStore?.loggedUser])

	useEffect(() => {
		if (authStore?.loggedUser?.addr && code) {
			;(async () => {
				const authToken = await auth?.currentUser?.getIdToken(true)
				if (authToken) {
					const headers = new Headers()
					headers.set("Authorization", `${authToken}`)
					fetch(`${apiURL}/discord?code=${code}`, {
						headers,
					}).then(() => {
						setHasSaved(true)
					})
				}
			})()
		}
	}, [code, authStore?.loggedUser])

	// if there is a code, hit a backend endpoint which saves the code
	// to the user's Firebase account

	// Go home if no oauth code
	if (!code) {
		return <Navigate to={"/"} replace />
	}

	if (hasSaved) {
		return <Navigate to={"/settings"} replace />
	}

	return <Layout>{PageBody}</Layout>
}

export const DiscordOauthScreen = inject("authStore")(observer(DiscordOauth))
