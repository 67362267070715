/* eslint-disable @typescript-eslint/no-use-before-define */
import { formatAddress, TokenMetadata } from "flowty-common"
import { Config } from "../../types"
import { FlowNFTData } from "../../common/CommonTypes"

export const getStorefrontListingTxn = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData,
	isNftCatalog: boolean
): string =>
	config.crescendo
		? storefrontListingTxnCrescendo(config, token, nftData, isNftCatalog)
		: storefrontListingTxn(config, token, nftData, isNftCatalog)

export const getBulkStorefrontListingTxn = (
	config: Config,
	token: TokenMetadata
): string => {
	return config.crescendo
		? bulkListingTxnCrescendo(config, token)
		: bulkListingTxn(config, token)
}

export const getDelistStorefrontListingTxn = (
	config: Config,
	listingType: string
): string => {
	const listingTypeAddress = formatAddress(listingType.split(".")[1])
	if (listingTypeAddress === config.contractAddresses.NFTStorefrontV2) {
		return config.crescendo ? delistItemCrescendo(config) : delistItem(config)
	}

	if (listingTypeAddress === config.contractAddresses.NFTStorefrontV2_Shared) {
		return config.crescendo
			? delistItemSharedStorefrontItemCrescendo(config)
			: delistSharedStorefrontItem(config)
	}

	throw new Error("unknown listing type")
}

export const getBulkDelistStorefrontListingTxn = (config: Config): string => {
	return config.crescendo
		? bulkDelistTxnCrescendo(config)
		: bulkDelistTxn(config)
}

const bulkListingTxn = (config: Config, token: TokenMetadata): string => {
	if (token.symbol === "DUC") {
		return bulkListingTxnDapperBalance(config)
	}

	if (token.symbol === "FUT") {
		return bulkListingTxnDapperFlow(config)
	}

	return bulkListingTxnNonCustodial(config)
}

const bulkListingTxnCrescendo = (
	config: Config,
	token: TokenMetadata
): string => {
	if (token.symbol === "DUC") {
		return bulkListingTxnDapperBalanceCrescendo(config)
	}

	if (token.symbol === "FUT") {
		return bulkListingTxnDapperFlowCrescendo(config)
	}

	return bulkListingTxnNonCustodialCrescendo(config)
}

const bulkListingTxnDapperBalance = (
	config: Config
): string => `// Flowty - Sell item without the use of the NFT Catalog
// This transaction will list an item for sale on Flowty's Marketplace
// using the contract's NFTCollectionData metdata view.
//
// Importantly, if there is a conflicting link present on an account,
// this transaction will NOT override anything already present.

import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import ViewResolver from ${config.contractAddresses.ViewResolver}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}
import FlowtyUtils from ${config.contractAddresses.FlowtyUtils}
import TransactionTypes from ${config.contractAddresses.TransactionTypes}
import DapperUtilityCoin from ${config.contractAddresses.DapperUtilityCoin}
import TokenForwarding from ${config.contractAddresses.TokenForwarding}

transaction(saleRequests: [TransactionTypes.StorefrontListingRequest]) {
    prepare(seller: AuthAccount) {
        if seller.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            // Create a new empty Storefront
            let storefront <- NFTStorefrontV2.createStorefront() as! @NFTStorefrontV2.Storefront
            // save it to the account
            seller.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
            // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present
            seller.unlink(NFTStorefrontV2.StorefrontPublicPath)
            seller.link<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontPublicPath, target: NFTStorefrontV2.StorefrontStoragePath)
        }

        let paymentReceiver = seller.getCapability<&{FungibleToken.Receiver}>(/public/dapperUtilityCoinReceiver)
        assert(paymentReceiver.check() != nil, message: "Missing or mis-typed DapperUtilityCoin receiver")

        let nftCache: {String: Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>} = {}
        let nftRef = &nftCache as! &{String: Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>}

        let typeCache: {String: Type} = {}
        let typeRef = &typeCache as! &{String: Type}

        let storefront = seller.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath)!
        for request in saleRequests {
          createListing(seller, storefront, request, paymentReceiver, nftRef, typeRef)
        }
    }
}

pub fun createListing(
  _ seller: AuthAccount,
  _ storefront: &NFTStorefrontV2.Storefront,
  _ r: TransactionTypes.StorefrontListingRequest,
  _ paymentReceiver: Capability<&{FungibleToken.Receiver}>,
  _ nftCache: &{String: Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>},
  _ typeCache: &{String: Type}
) {
  if typeCache[r.nftTypeIdentifier] == nil {
    typeCache[r.nftTypeIdentifier] = CompositeType(r.nftTypeIdentifier) ?? panic("invalid nft type identifier")
  }

  let collectionCap = getCollectionCap(seller, r, nftCache, typeCache[r.nftTypeIdentifier]!)

  // check for existing listings of the NFT
  var existingListingIDs = storefront.getExistingListingIDs(
      nftType: typeCache[r.nftTypeIdentifier]!,
      nftID: r.nftID
  )
  // remove existing listings
  for listingID in existingListingIDs {
      storefront.removeListing(listingResourceID: listingID)
  }

  // Create listing
  storefront.createListing(
    nftProviderCapability: collectionCap,
    paymentReceiver: paymentReceiver,
    nftType: typeCache[r.nftTypeIdentifier]!,
    nftID: r.nftID,
    salePaymentVaultType: Type<@DapperUtilityCoin.Vault>(),
    price: r.price,
    customID: r.customID,
    expiry: UInt64(getCurrentBlock().timestamp) + r.expiry,
    buyer: r.buyerAddress
  )
}

pub fun getCollectionCap(
  _ seller: AuthAccount,
  _ r: TransactionTypes.StorefrontListingRequest,
  _ nftCache: &{String: Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>},
  _ nftType: Type
): Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}> {
  let key = r.nftProviderAddress.toString().concat(r.nftTypeIdentifier)
  if nftCache[key] != nil {
    return nftCache[key]!
  }

  let cap = seller.getCapability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(r.nftProviderPath)
  nftCache[key] = cap

  if !cap.check() {
    if r.catalogCollection {
      seller.unlink(r.nftProviderPath)
    }
    seller.link<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(r.nftProviderPath, target: r.nftStoragePath)
  }

  return cap
}`

const bulkListingTxnDapperFlow = (
	config: Config
): string => `// Flowty - Sell item without the use of the NFT Catalog
// This transaction will list an item for sale on Flowty's Marketplace
// using the contract's NFTCollectionData metdata view.
//
// Importantly, if there is a conflicting link present on an account,
// this transaction will NOT override anything already present.

import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import ViewResolver from ${config.contractAddresses.ViewResolver}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}
import FlowtyUtils from ${config.contractAddresses.FlowtyUtils}
import TransactionTypes from ${config.contractAddresses.TransactionTypes}
import FlowUtilityToken from ${config.contractAddresses.FlowUtilityToken}
import TokenForwarding from ${config.contractAddresses.TokenForwarding}

transaction(saleRequests: [TransactionTypes.StorefrontListingRequest]) {
    prepare(seller: AuthAccount) {
        if seller.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            // Create a new empty Storefront
            let storefront <- NFTStorefrontV2.createStorefront() as! @NFTStorefrontV2.Storefront
            // save it to the account
            seller.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
            // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present
            seller.unlink(NFTStorefrontV2.StorefrontPublicPath)
            seller.link<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontPublicPath, target: NFTStorefrontV2.StorefrontStoragePath)
        }

        if seller.borrow<&{FungibleToken.Receiver}>(from: /storage/flowUtilityTokenReceiver) == nil {
            let dapper = getAccount(${config.contractAddresses.FlowUtilityToken})
            let dapperFUTReceiver = dapper.getCapability<&{FungibleToken.Receiver}>(/public/flowUtilityTokenReceiver)!

            // Create a new Forwarder resource for FUT and store it in the new account's storage
            let futForwarder <- TokenForwarding.createNewForwarder(recipient: dapperFUTReceiver)
            seller.save(<-futForwarder, to: /storage/flowUtilityTokenReceiver)

            // Publish a Receiver capability for the new account, which is linked to the FUT Forwarder
            seller.link<&FlowUtilityToken.Vault{FungibleToken.Receiver}>(
                /public/flowUtilityTokenReceiver,
                target: /storage/flowUtilityTokenReceiver
            )
        }

        let paymentReceiver = seller.getCapability<&{FungibleToken.Receiver}>(/public/flowUtilityTokenReceiver)
        assert(paymentReceiver.check() != nil, message: "Missing or mis-typed FlowUtilityToken receiver")

        let nftCache: {String: Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>} = {}
        let nftRef = &nftCache as! &{String: Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>}

        let typeCache: {String: Type} = {}
        let typeRef = &typeCache as! &{String: Type}

        let storefront = seller.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath)!
        for request in saleRequests {
          createListing(seller, storefront, request, paymentReceiver, nftRef, typeRef)
        }
    }
}

pub fun createListing(
  _ seller: AuthAccount,
  _ storefront: &NFTStorefrontV2.Storefront,
  _ r: TransactionTypes.StorefrontListingRequest,
  _ paymentReceiver: Capability<&{FungibleToken.Receiver}>,
  _ nftCache: &{String: Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>},
  _ typeCache: &{String: Type}
) {
  if typeCache[r.nftTypeIdentifier] == nil {
    typeCache[r.nftTypeIdentifier] = CompositeType(r.nftTypeIdentifier) ?? panic("invalid nft type identifier")
  }

  let collectionCap = getCollectionCap(seller, r, nftCache, typeCache[r.nftTypeIdentifier]!)

  // check for existing listings of the NFT
  var existingListingIDs = storefront.getExistingListingIDs(
      nftType: typeCache[r.nftTypeIdentifier]!,
      nftID: r.nftID
  )
  // remove existing listings
  for listingID in existingListingIDs {
      storefront.removeListing(listingResourceID: listingID)
  }

  // Create listing
  storefront.createListing(
    nftProviderCapability: collectionCap,
    paymentReceiver: paymentReceiver,
    nftType: typeCache[r.nftTypeIdentifier]!,
    nftID: r.nftID,
    salePaymentVaultType: Type<@FlowUtilityToken.Vault>(),
    price: r.price,
    customID: r.customID,
    expiry: UInt64(getCurrentBlock().timestamp) + r.expiry,
    buyer: r.buyerAddress
  )
}

pub fun getCollectionCap(
  _ seller: AuthAccount,
  _ r: TransactionTypes.StorefrontListingRequest,
  _ nftCache: &{String: Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>},
  _ nftType: Type
): Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}> {
  let key = r.nftProviderAddress.toString().concat(r.nftTypeIdentifier)
  if nftCache[key] != nil {
    return nftCache[key]!
  }

  let cap = seller.getCapability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(r.nftProviderPath)
  nftCache[key] = cap

  if !cap.check() {
    if r.catalogCollection {
      seller.unlink(r.nftProviderPath)
    }
    seller.link<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(r.nftProviderPath, target: r.nftStoragePath)
  }

  return cap
}`

const bulkListingTxnNonCustodial = (
	config: Config
): string => `// Flowty - Sell item without the use of the NFT Catalog
// This transaction will list an item for sale on Flowty's Marketplace
// using the contract's NFTCollectionData metdata view.
//
// Importantly, if there is a conflicting link present on an account,
// this transaction will NOT override anything already present.

import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import ViewResolver from ${config.contractAddresses.ViewResolver}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}
import FlowtyUtils from ${config.contractAddresses.FlowtyUtils}
import TransactionTypes from ${config.contractAddresses.TransactionTypes}

import HybridCustody from ${config.contractAddresses.HybridCustody}

transaction(
  saleRequests: [TransactionTypes.StorefrontListingRequest],
  paymentReceiverAddress: Address,
  paymentReceiverPath: PublicPath,
  salePaymentVaultTypeIdentifier: String
) {
    prepare(seller: AuthAccount) {
        if seller.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            // Create a new empty Storefront
            let storefront <- NFTStorefrontV2.createStorefront() as! @NFTStorefrontV2.Storefront
            // save it to the account
            seller.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
            // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present
            seller.unlink(NFTStorefrontV2.StorefrontPublicPath)
            seller.link<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontPublicPath, target: NFTStorefrontV2.StorefrontStoragePath)
        }

        let paymentType = CompositeType(salePaymentVaultTypeIdentifier) ?? panic("invalid payment type identifier")
        let paymentReceiver = getPaymentReceiver(seller, paymentReceiverAddress, paymentReceiverPath, paymentType)

        let nftCache: {String: Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>} = {}
        let nftRef = &nftCache as! &{String: Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>}

        let typeCache: {String: Type} = {}
        let typeRef = &typeCache as! &{String: Type}

        let storefront = seller.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath)!
        for request in saleRequests {
          createListing(seller, storefront, request, paymentReceiver, paymentType, nftRef, typeRef)
        }
    }
}

pub fun createListing(
  _ seller: AuthAccount,
  _ storefront: &NFTStorefrontV2.Storefront,
  _ r: TransactionTypes.StorefrontListingRequest,
  _ paymentReceiver: Capability<&{FungibleToken.Receiver}>,
  _ paymentType: Type,
  _ nftCache: &{String: Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>},
  _ typeCache: &{String: Type}
) {
  if typeCache[r.nftTypeIdentifier] == nil {
    typeCache[r.nftTypeIdentifier] = CompositeType(r.nftTypeIdentifier) ?? panic("invalid nft type identifier")
  }

  let collectionCap = getCollectionCap(seller, r, nftCache, typeCache[r.nftTypeIdentifier]!)

  // check for existing listings of the NFT
  var existingListingIDs = storefront.getExistingListingIDs(
      nftType: typeCache[r.nftTypeIdentifier]!,
      nftID: r.nftID
  )
  // remove existing listings
  for listingID in existingListingIDs {
      storefront.removeListing(listingResourceID: listingID)
  }

  // Create listing
  storefront.createListing(
    nftProviderCapability: collectionCap,
    paymentReceiver: paymentReceiver,
    nftType: typeCache[r.nftTypeIdentifier]!,
    nftID: r.nftID,
    salePaymentVaultType: paymentType,
    price: r.price,
    customID: r.customID,
    expiry: UInt64(getCurrentBlock().timestamp) + r.expiry,
    buyer: r.buyerAddress
  )
}

pub fun getPaymentReceiver(
  _ seller: AuthAccount,
  _ paymentReceiverAddress: Address,
  _ paymentPath: PublicPath,
  _ paymentType: Type
): Capability<&{FungibleToken.Receiver}> {
  if paymentReceiverAddress == seller.address {
    let cap = seller.getCapability<&{FungibleToken.Receiver}>(paymentPath)
    if !cap.check() {
      let tokenInfo = FlowtyUtils.getTokenInfo(paymentType) ?? panic("token info not found")
      seller.link<&{FungibleToken.Receiver}>(paymentPath, target: tokenInfo.storagePath)
    }

    return cap
  }

  let manager = seller.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
    ?? panic("Missing or mis-typed HybridCustody Manager")

  let child = manager.borrowAccount(addr: paymentReceiverAddress) ?? panic("no child account with that address")
  let cap = getAccount(paymentReceiverAddress).getCapability<&{FungibleToken.Receiver}>(paymentPath)

  return cap
}

pub fun getCollectionCap(
  _ seller: AuthAccount,
  _ r: TransactionTypes.StorefrontListingRequest,
  _ nftCache: &{String: Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>},
  _ nftType: Type
): Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}> {
  let key = r.nftProviderAddress.toString().concat(r.nftTypeIdentifier)
  if nftCache[key] != nil {
    return nftCache[key]!
  }

  if r.nftProviderAddress == seller.address {
    let cap = seller.getCapability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(r.nftProviderPath)
    nftCache[key] = cap

    if !cap.check() {
      if r.catalogCollection {
        seller.unlink(r.nftProviderPath)
      }
      seller.link<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(r.nftProviderPath, target: r.nftStoragePath)
    }

    return cap
  }

  let manager = seller.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
    ?? panic("Missing or mis-typed HybridCustody Manager")

  let child = manager.borrowAccount(addr: r.nftProviderAddress) ?? panic("no child account with that address")
  let providerCap = child.getCapability(path: r.nftProviderPath, type: Type<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>())
    ?? panic("no nft provider found")
  let cap = providerCap as! Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
  nftCache[key] = cap

  return cap
}`

const bulkListingTxnDapperBalanceCrescendo = (config: Config): string => ``

const bulkListingTxnDapperFlowCrescendo = (config: Config): string => ``

const bulkListingTxnNonCustodialCrescendo = (
	config: Config
): string => `// Flowty - Sell item without the use of the NFT Catalog
// This transaction will list an item for sale on Flowty's Marketplace
// using the contract's NFTCollectionData metdata view.
//
// Importantly, if there is a conflicting link present on an account,
// this transaction will NOT override anything already present.

import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import ViewResolver from ${config.contractAddresses.ViewResolver}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}
import FlowtyUtils from ${config.contractAddresses.FlowtyUtils}
import TransactionTypes from ${config.contractAddresses.TransactionTypes}

import HybridCustody from ${config.contractAddresses.HybridCustody}

transaction(
  saleRequests: [TransactionTypes.StorefrontListingRequest],
  paymentReceiverAddress: Address,
  paymentReceiverPath: PublicPath,
  salePaymentVaultTypeIdentifier: String
) {
    prepare(seller: auth(Capabilities, Storage) &Account) {
        if seller.storage.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            // Create a new empty Storefront
            let storefront <- NFTStorefrontV2.createStorefront()
            // save it to the account
            seller.storage.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
            // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present
            seller.capabilities.unpublish(NFTStorefrontV2.StorefrontPublicPath)
            seller.capabilities.publish(
                seller.capabilities.storage.issue<&{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontStoragePath),
                at: NFTStorefrontV2.StorefrontPublicPath
            )
        }

        let paymentType = CompositeType(salePaymentVaultTypeIdentifier) ?? panic("invalid payment type identifier")
        let paymentReceiver = getPaymentReceiver(seller, paymentReceiverAddress, paymentReceiverPath, paymentType)

        let nftCache: {String: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>} = {}
        let nftRef: auth(Mutate) &{String: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>} = &nftCache

        let typeCache: {String: Type} = {}
        let typeRef: auth(Mutate) &{String: Type} = &typeCache

        let storefront = seller.storage.borrow<auth(NFTStorefrontV2.List, NFTStorefrontV2.Cancel) &NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath)!
        for request in saleRequests {
          createListing(seller, storefront, request, paymentReceiver, paymentType, nftRef, typeRef)
        }
    }
}

access(all) fun createListing(
  _ seller: auth(Capabilities, Storage) &Account,
  _ storefront: auth(NFTStorefrontV2.List, NFTStorefrontV2.Cancel) &NFTStorefrontV2.Storefront,
  _ r: TransactionTypes.StorefrontListingRequest,
  _ paymentReceiver: Capability<&{FungibleToken.Receiver}>,
  _ paymentType: Type,
  _ nftCache: auth(Mutate) &{String: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>},
  _ typeCache: auth(Mutate) &{String: Type}
) {
  if typeCache[r.nftTypeIdentifier] == nil {
    typeCache[r.nftTypeIdentifier] = CompositeType(r.nftTypeIdentifier) ?? panic("invalid nft type identifier")
  }

  let collectionCap = getCollectionCap(seller, r, nftCache, typeCache[r.nftTypeIdentifier]!)

  // check for existing listings of the NFT
  var existingListingIDs = storefront.getExistingListingIDs(
      nftType: typeCache[r.nftTypeIdentifier]!,
      nftID: r.nftID
  )
  // remove existing listings
  for listingID in existingListingIDs {
      storefront.removeListing(listingResourceID: listingID)
  }

  // Create listing
  storefront.createListing(
    nftProviderCapability: collectionCap,
    paymentReceiver: paymentReceiver,
    nftType: typeCache[r.nftTypeIdentifier]!,
    nftID: r.nftID,
    salePaymentVaultType: paymentType,
    price: r.price,
    customID: r.customID,
    expiry: UInt64(getCurrentBlock().timestamp) + r.expiry,
    buyer: r.buyerAddress
  )
}

access(all) fun getPaymentReceiver(
  _ seller: auth(Capabilities, Storage) &Account,
  _ paymentReceiverAddress: Address,
  _ paymentPath: PublicPath,
  _ paymentType: Type
): Capability<&{FungibleToken.Receiver}> {
  if paymentReceiverAddress == seller.address {
    let cap = seller.capabilities.get<&{FungibleToken.Receiver}>(paymentPath)
    if !cap.check() {
      let tokenInfo = FlowtyUtils.getTokenInfo(paymentType) ?? panic("token info not found")
      seller.capabilities.publish(seller.capabilities.storage.issue<&{FungibleToken.Receiver}>(tokenInfo.storagePath), at: paymentPath)
    }

    return cap
  }

  let manager = seller.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
    ?? panic("Missing or mis-typed HybridCustody Manager")

  let child = manager.borrowAccount(addr: paymentReceiverAddress) ?? panic("no child account with that address")
  let cap = getAccount(paymentReceiverAddress).capabilities.get<&{FungibleToken.Receiver}>(paymentPath)

  return cap
}

access(all) fun getCollectionCap(
  _ seller: auth(Capabilities, Storage) &Account,
  _ r: TransactionTypes.StorefrontListingRequest,
  _ nftCache: auth(Mutate) &{String: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>},
  _ nftType: Type
): Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}> {
  let key = r.nftProviderAddress.toString().concat(r.nftTypeIdentifier)
  if nftCache[key] != nil {
    return nftCache[key]!
  }

  if r.nftProviderAddress == seller.address {
    let cap = seller.capabilities.storage.issue<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(r.nftStoragePath)
    nftCache[key] = cap
    return cap
  }

  let manager = seller.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
    ?? panic("Missing or mis-typed HybridCustody Manager")

  let child = manager.borrowAccount(addr: r.nftProviderAddress) ?? panic("no child account with that address")
  let providerCap = child.getCapability(controllerID: r.nftProviderControllerID, type: Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>())
    ?? panic("no nft provider found")
  let cap = providerCap as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
  nftCache[key] = cap

  return cap
}`

const storefrontListingTxn = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData,
	isNftCatalog: boolean
): string => {
	const isDapper = ["DUC", "FUT"].includes(token.symbol)
	return sellItem(config, token, nftData, isNftCatalog, isDapper)
}

const sellItem = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData,
	isNftCatalog: boolean,
	isDapper: boolean
): string => {
	if (isDapper) {
		return token.symbol === "DUC"
			? sellItemDapperBalance(config)
			: sellItemDapperFlow(config)
	}

	return isNftCatalog
		? sellItemHybridCustodyCatalog(config, token, nftData)
		: sellItemHybridCustodyNoCatalog(config, token, nftData)
}

const storefrontListingTxnCrescendo = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData,
	isNftCatalog: boolean
): string => {
	const isDapper = ["DUC", "FUT"].includes(token.symbol)

	if (isDapper && nftData.contractName !== "TopShot") {
		return "" // TODO: implement
	}
	if (isDapper && nftData.contractName === "TopShot") {
		return "" // TODO: implement
	}

	return isNftCatalog
		? sellItemHybridCustodyCatalogCrescendo(config, token)
		: sellItemHybridCustodyNoCatalogCrescendo(config, token)
}

const sellItemHybridCustodyCatalogCrescendo = (
	config: Config,
	token: TokenMetadata
): string => `import ${token.contractName} from ${token.contractAddress}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}
import TokenForwarding from ${config.contractAddresses.TokenForwarding}

import HybridCustody from ${config.contractAddresses.HybridCustody}

/// Transaction used to facilitate the creation of the listing under the signer's owned storefront resource.
/// It accepts the certain details from the signer,i.e. -
///
/// \`saleItemID\` - ID of the NFT that is put on sale by the seller.
/// \`saleItemPrice\` - Amount of tokens (FT) buyer needs to pay for the purchase of listed NFT.
/// \`customID\` - Optional string to represent identifier of the dapp.
/// \`buyer\` - Optional address for the only address that is permitted to fill this listing
/// \`expiry\` - Unix timestamp at which created listing become expired.
/// \`nftProviderAddress\` - The address the nft being sold should come from
/// \`providerPathIdentifier\` - The path to the provider capability for the nft being sold
/// \`publicPathIdentifier\` - The path to the public capability for the nft being sold
/// \`ftReceiverAddress\` - The address that should receive purchase payment
/// If the given nft has a support of the RoyaltyView then royalties will added as the sale cut.

transaction(
  collectionIdentifier: String,
  saleItemID: UInt64,
  saleItemPrice: UFix64,
  customID: String?,
  buyer: Address?,
  expiry: UInt64,
  nftProviderControllerID: UInt64,
  nftProviderAddress: Address,
  ftReceiverAddress: Address
) {
    let paymentReceiver: Capability<&{FungibleToken.Receiver}>
    let nftProvider: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
    let storefront: auth(NFTStorefrontV2.List, NFTStorefrontV2.Cancel) &NFTStorefrontV2.Storefront
    let nftType: Type

    prepare(seller: auth(Capabilities, Storage) &Account) {
        if seller.storage.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            // Create a new empty Storefront
            let storefront <- NFTStorefrontV2.createStorefront()
            // save it to the account
            seller.storage.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
            // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present

            seller.capabilities.unpublish(NFTStorefrontV2.StorefrontPublicPath)
            seller.capabilities.publish(
              seller.capabilities.storage.issue<&{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontStoragePath),
              at: NFTStorefrontV2.StorefrontPublicPath
            )
        }

        let value = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

        if ftReceiverAddress == seller.address {
            self.paymentReceiver = seller.capabilities.get<&{FungibleToken.Receiver}>(${token.receiverPath})!
        } else {
            let manager = seller.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: ftReceiverAddress) ?? panic("no child account with that address")
            self.paymentReceiver = getAccount(ftReceiverAddress).capabilities.get<&{FungibleToken.Receiver}>(${token.receiverPath})!
        }

        assert(self.paymentReceiver.check(), message: "Missing or mis-typed ${token.contractName} receiver")

        if nftProviderAddress == seller.address {
            self.nftProvider = seller.capabilities.storage.issue<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(value.collectionData.storagePath)
        } else {
            let manager = seller.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: nftProviderAddress) ?? panic("no child account with that address")
            let providerCap = child.getCapability(controllerID: nftProviderControllerID, type: Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()) ?? panic("no nft provider found")
            self.nftProvider = providerCap as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
        }

        assert(self.nftProvider.borrow() != nil, message: "Missing or mis-typed NFT provider")

        let collection = self.nftProvider.borrow()
            ?? panic("Could not borrow a reference to the collection")
        let nft = collection.borrowNFT(saleItemID) ?? panic("nft could not be borrowed")
        self.nftType = nft.getType()

        self.storefront = seller.storage.borrow<auth(NFTStorefrontV2.List, NFTStorefrontV2.Cancel) &NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath)
            ?? panic("Missing or mis-typed NFTStorefront Storefront")
    }

    execute {
        // check for existing listings of the NFT
        var existingListingIDs = self.storefront.getExistingListingIDs(
            nftType: self.nftType,
            nftID: saleItemID
        )
        // remove existing listings
        for listingID in existingListingIDs {
            self.storefront.removeListing(listingResourceID: listingID)
        }

        // Create listing
        self.storefront.createListing(
            nftProviderCapability: self.nftProvider,
            paymentReceiver: self.paymentReceiver,
            nftType: self.nftType,
            nftID: saleItemID,
            salePaymentVaultType: Type<@${token.contractName}.Vault>(),
            price: saleItemPrice,
            customID: customID,
            expiry: UInt64(getCurrentBlock().timestamp) + expiry,
            buyer: buyer
        )
    }
}`

const sellItemHybridCustodyCatalog = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData
): string => `import ${token.contractName} from ${token.contractAddress}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}
import TokenForwarding from ${config.contractAddresses.TokenForwarding}

import HybridCustody from ${config.contractAddresses.HybridCustody}

/// Transaction used to facilitate the creation of the listing under the signer's owned storefront resource.
/// It accepts the certain details from the signer,i.e. -
///
/// \`saleItemID\` - ID of the NFT that is put on sale by the seller.
/// \`saleItemPrice\` - Amount of tokens (FT) buyer needs to pay for the purchase of listed NFT.
/// \`customID\` - Optional string to represent identifier of the dapp.
/// \`buyer\` - Optional address for the only address that is permitted to fill this listing
/// \`expiry\` - Unix timestamp at which created listing become expired.
/// \`nftProviderAddress\` - The address the nft being sold should come from
/// \`providerPathIdentifier\` - The path to the provider capability for the nft being sold
/// \`publicPathIdentifier\` - The path to the public capability for the nft being sold
/// \`ftReceiverAddress\` - The address that should receive purchase payment
/// If the given nft has a support of the RoyaltyView then royalties will added as the sale cut.

transaction(collectionIdentifier: String, saleItemID: UInt64, saleItemPrice: UFix64, customID: String?, buyer: Address?, expiry: UInt64, nftProviderPathIdentifier: String, nftProviderAddress: Address, ftReceiverAddress: Address) {
    let paymentReceiver: Capability<&${token.contractName}.Vault{FungibleToken.Receiver}>
    let nftProvider: Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
    let storefront: &NFTStorefrontV2.Storefront
    let nftType: Type

    prepare(seller: AuthAccount) {
        if seller.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            // Create a new empty Storefront
            let storefront <- NFTStorefrontV2.createStorefront() as! @NFTStorefrontV2.Storefront
            // save it to the account
            seller.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
            // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present
            seller.unlink(NFTStorefrontV2.StorefrontPublicPath)
            seller.link<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontPublicPath, target: NFTStorefrontV2.StorefrontStoragePath)
        }

        let value = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

        if ftReceiverAddress == seller.address {
            self.paymentReceiver = seller.getCapability<&${token.contractName}.Vault{FungibleToken.Receiver}>(${token.receiverPath})
        } else {
            let manager = seller.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: ftReceiverAddress) ?? panic("no child account with that address")
            self.paymentReceiver = getAccount(ftReceiverAddress).getCapability<&${token.contractName}.Vault{FungibleToken.Receiver}>(${token.receiverPath})
        }

        assert(self.paymentReceiver.check(), message: "Missing or mis-typed ${token.contractName} receiver")

        if nftProviderAddress == seller.address {
            let flowtyNftCollectionProviderPath = /private/${nftData.contractName}${nftData.contractAddress}CollectionProviderForFlowty

            if !seller.getCapability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(flowtyNftCollectionProviderPath).check() {
                seller.unlink(flowtyNftCollectionProviderPath)
                seller.link<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(flowtyNftCollectionProviderPath, target: value.collectionData.storagePath)
            }

            if !seller.getCapability<&{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath).check() {
                seller.unlink(value.collectionData.publicPath)
                seller.link<&{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath, target: value.collectionData.storagePath)
            }

            self.nftProvider = seller.getCapability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(flowtyNftCollectionProviderPath)
        } else {
            let collectionProviderPrivatePath = PrivatePath(identifier: nftProviderPathIdentifier) ?? panic("invalid provider path identifier")

            let manager = seller.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: nftProviderAddress) ?? panic("no child account with that address")
            let providerCap = child.getCapability(path: collectionProviderPrivatePath, type: Type<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()) ?? panic("no nft provider found")
            self.nftProvider = providerCap as! Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
        }

        assert(self.nftProvider.borrow() != nil, message: "Missing or mis-typed NFT provider")

        let collection = self.nftProvider.borrow()
            ?? panic("Could not borrow a reference to the collection")
        let nft = collection.borrowNFT(id: saleItemID)
        self.nftType = nft.getType()

        self.storefront = seller.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath)
            ?? panic("Missing or mis-typed NFTStorefront Storefront")
    }

    execute {
        // check for existing listings of the NFT
        var existingListingIDs = self.storefront.getExistingListingIDs(
            nftType: self.nftType,
            nftID: saleItemID
        )
        // remove existing listings
        for listingID in existingListingIDs {
            self.storefront.removeListing(listingResourceID: listingID)
        }

        // Create listing
        self.storefront.createListing(
            nftProviderCapability: self.nftProvider,
            paymentReceiver: self.paymentReceiver,
            nftType: self.nftType,
            nftID: saleItemID,
            salePaymentVaultType: Type<@${token.contractName}.Vault>(),
            price: saleItemPrice,
            customID: customID,
            expiry: UInt64(getCurrentBlock().timestamp) + expiry,
            buyer: buyer
        )
    }
}`

const sellItemHybridCustodyNoCatalog = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData
): string => `// Flowty - Sell item without the use of the NFT Catalog
// This transaction will list an item for sale on Flowty's Marketplace
// using the contract's NFTCollectionData metdata view.
//
// Importantly, if there is a conflicting link present on an account,
// this transaction will NOT override anything already present.

import ${token.contractName} from ${token.contractAddress}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import ViewResolver from ${config.contractAddresses.ViewResolver}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}

import HybridCustody from ${config.contractAddresses.HybridCustody}

/// \`contractAddress\` - Address of the NFT contract.
/// \`contractName\` - Name of the NFT contract.
/// \`saleItemID\` - ID of the NFT that is put on sale by the seller.
/// \`saleItemPrice\` - Amount of tokens (FT) buyer needs to pay for the purchase of listed NFT.
/// \`customID\` - Optional string to represent identifier of the dapp.
/// \`buyer\` - Optional address for the only address that is permitted to fill this listing
/// \`expiry\` - Unix timestamp at which created listing become expired.
/// \`nftProviderAddress\` - Address of the account that will provide the NFT.
/// \`ftReceiverAddress\` - Address of the account that will receive payment if the listing is purchased.

/// If the given nft has a support of the RoyaltyView then royalties will added as the sale cut.
transaction(
    contractAddress: Address,
    contractName: String,
    saleItemID: UInt64,
    saleItemPrice: UFix64,
    customID: String?,
    buyer: Address?,
    expiry: UInt64,
    nftProviderPathIdentifier: String,
    nftProviderAddress: Address,
    ftReceiverAddress: Address
) {
    let paymentReceiver: Capability<&AnyResource{FungibleToken.Receiver}>
    let collectionCap: Capability<&AnyResource{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
    let storefront: &NFTStorefrontV2.Storefront
    let nftType: Type

    prepare(seller: AuthAccount) {

        if seller.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            // Create a new empty Storefront
            let storefront <- NFTStorefrontV2.createStorefront() as! @NFTStorefrontV2.Storefront
            // save it to the account
            seller.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
            // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present
            seller.unlink(NFTStorefrontV2.StorefrontPublicPath)
            seller.link<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontPublicPath, target: NFTStorefrontV2.StorefrontStoragePath)
        }

        let contract = getAccount(contractAddress).contracts.borrow<&ViewResolver>(name: contractName) ?? panic ("Specified contract address and name is not found or does not implement ViewResolver contract.")
        let md = contract.resolveView(Type<MetadataViews.NFTCollectionData>()) ?? panic("NFTCollectionData view not found on the contract.")
        let collectionData = md as! MetadataViews.NFTCollectionData

        if ftReceiverAddress == seller.address {

            self.paymentReceiver = seller.getCapability<&${token.contractName}.Vault{FungibleToken.Receiver}>(${token.receiverPath})
        } else {
            let manager = seller.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: ftReceiverAddress) ?? panic("no child account with that address")
            self.paymentReceiver = getAccount(ftReceiverAddress).getCapability<&${token.contractName}.Vault{FungibleToken.Receiver}>(${token.receiverPath})
        }

        assert(self.paymentReceiver.check(), message: "Missing or mis-typed ${token.contractName} receiver")

        if nftProviderAddress == seller.address {
            let flowtyNftCollectionProviderPath = /private/${nftData.contractName}${nftData.contractAddress}CollectionProviderForFlowty

            if !seller.getCapability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(flowtyNftCollectionProviderPath).check() {
                seller.unlink(flowtyNftCollectionProviderPath)
                seller.link<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(flowtyNftCollectionProviderPath, target: collectionData.storagePath)
            }

            if !seller.getCapability<&{NonFungibleToken.CollectionPublic}>(collectionData.publicPath).check() {
                seller.unlink(collectionData.publicPath)
                seller.link<&{NonFungibleToken.CollectionPublic}>(collectionData.publicPath, target: collectionData.storagePath)
            }

            self.collectionCap = seller.getCapability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(flowtyNftCollectionProviderPath)
        } else {
            let collectionProviderPrivatePath = PrivatePath(identifier: nftProviderPathIdentifier) ?? panic("invalid provider path identifier")

            let manager = seller.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: nftProviderAddress) ?? panic("no child account with that address")
            let providerCap = child.getCapability(path: collectionProviderPrivatePath, type: Type<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()) ?? panic("no nft provider found")
            self.collectionCap = providerCap as! Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
        }

        let collection = self.collectionCap.borrow()
            ?? panic("Could not borrow a reference to the collection")
        let nft = collection.borrowNFT(id: saleItemID)
        self.nftType = nft.getType()

        self.storefront = seller.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath)
            ?? panic("Missing or mis-typed NFTStorefront Storefront")
    }

    execute {
        // check for existing listings of the NFT
        var existingListingIDs = self.storefront.getExistingListingIDs(
            nftType: self.nftType,
            nftID: saleItemID
        )
        // remove existing listings
        for listingID in existingListingIDs {
            self.storefront.removeListing(listingResourceID: listingID)
        }

        // Create listing
        self.storefront.createListing(
            nftProviderCapability: self.collectionCap,
            paymentReceiver: self.paymentReceiver,
            nftType: self.nftType,
            nftID: saleItemID,
            salePaymentVaultType: Type<@${token.contractName}.Vault>(),
            price: saleItemPrice,
            customID: customID,
            expiry: UInt64(getCurrentBlock().timestamp) + expiry,
            buyer: buyer
        )
    }
}`

const sellItemHybridCustodyNoCatalogCrescendo = (
	config: Config,
	token: TokenMetadata
): string => `// Flowty - Sell item without the use of the NFT Catalog
// This transaction will list an item for sale on Flowty's Marketplace
// using the contract's NFTCollectionData metdata view.
//
// Importantly, if there is a conflicting link present on an account,
// this transaction will NOT override anything already present.

import ${token.contractName} from ${token.contractAddress}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import ViewResolver from ${config.contractAddresses.ViewResolver}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}
import TokenForwarding from ${config.contractAddresses.TokenForwarding}

import HybridCustody from ${config.contractAddresses.HybridCustody}

/// \`contractAddress\` - Address of the NFT contract.
/// \`contractName\` - Name of the NFT contract.
/// \`saleItemID\` - ID of the NFT that is put on sale by the seller.
/// \`saleItemPrice\` - Amount of tokens (FT) buyer needs to pay for the purchase of listed NFT.
/// \`customID\` - Optional string to represent identifier of the dapp.
/// \`buyer\` - Optional address for the only address that is permitted to fill this listing
/// \`expiry\` - Unix timestamp at which created listing become expired.
/// \`nftProviderAddress\` - Address of the account that will provide the NFT.
/// \`ftReceiverAddress\` - Address of the account that will receive payment if the listing is purchased.

/// If the given nft has a support of the RoyaltyView then royalties will added as the sale cut.
transaction(
    contractAddress: Address,
    contractName: String,
    saleItemID: UInt64,
    saleItemPrice: UFix64,
    customID: String?,
    buyer: Address?,
    expiry: UInt64,
    nftProviderControllerID: UInt64,
    nftProviderAddress: Address,
    ftReceiverAddress: Address
) {
    let paymentReceiver: Capability<&{FungibleToken.Receiver}>
    let collectionCap: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
    let storefront: auth(NFTStorefrontV2.List, NFTStorefrontV2.Cancel) &NFTStorefrontV2.Storefront
    let nftType: Type

    prepare(seller: auth(Capabilities, Storage) &Account) {
        if seller.storage.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            // Create a new empty Storefront
            let storefront <- NFTStorefrontV2.createStorefront()
            // save it to the account
            seller.storage.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
            // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present

            seller.capabilities.unpublish(NFTStorefrontV2.StorefrontPublicPath)
            seller.capabilities.publish(
              seller.capabilities.storage.issue<&{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontStoragePath),
              at: NFTStorefrontV2.StorefrontPublicPath
            )
        }

        let c = getAccount(contractAddress).contracts.borrow<&{ViewResolver}>(name: contractName) ?? panic ("Specified contract address and name is not found or does not implement ViewResolver contract.")
        let md = c.resolveContractView(resourceType: nil, viewType: Type<MetadataViews.NFTCollectionData>()) ?? panic("NFTCollectionData view not found on the contract.")
        let collectionData = md as! MetadataViews.NFTCollectionData

        if ftReceiverAddress == seller.address {
            self.paymentReceiver = seller.capabilities.get<&{FungibleToken.Receiver}>(/public/flowTokenReceiver)!
        } else {
            let manager = seller.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: ftReceiverAddress) ?? panic("no child account with that address")
            self.paymentReceiver = getAccount(ftReceiverAddress).capabilities.get<&{FungibleToken.Receiver}>(/public/flowTokenReceiver)!
        }

        assert(self.paymentReceiver.check(), message: "Missing or mis-typed ${token.contractName} receiver")

        if nftProviderAddress == seller.address {
            self.collectionCap = seller.capabilities.storage.issue<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(collectionData.storagePath)
        } else {
            let manager = seller.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: nftProviderAddress) ?? panic("no child account with that address")
            let providerCap = child.getCapability(controllerID: nftProviderControllerID, type: Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()) ?? panic("no nft provider found")
            self.collectionCap = providerCap as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
        }

        let collection = self.collectionCap.borrow()
            ?? panic("Could not borrow a reference to the collection")
        let nft = collection.borrowNFT(saleItemID) ?? panic("nft not found")
        self.nftType = nft.getType()

        self.storefront = seller.storage.borrow<auth(NFTStorefrontV2.List, NFTStorefrontV2.Cancel) &NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath)
            ?? panic("Missing or mis-typed NFTStorefront Storefront")
    }

    execute {
        // check for existing listings of the NFT
        var existingListingIDs = self.storefront.getExistingListingIDs(
            nftType: self.nftType,
            nftID: saleItemID
        )
        // remove existing listings
        for listingID in existingListingIDs {
            self.storefront.removeListing(listingResourceID: listingID)
        }

        // Create listing
        self.storefront.createListing(
            nftProviderCapability: self.collectionCap,
            paymentReceiver: self.paymentReceiver,
            nftType: self.nftType,
            nftID: saleItemID,
            salePaymentVaultType: Type<@${token.contractName}.Vault>(),
            price: saleItemPrice,
            customID: customID,
            expiry: UInt64(getCurrentBlock().timestamp) + expiry,
            buyer: buyer
        )
    }
}`

const sellItemDapperBalance = (
	config: Config
): string => `import DapperUtilityCoin from ${config.contractAddresses.DapperUtilityCoin}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}
import TokenForwarding from ${config.contractAddresses.TokenForwarding}

/// Transaction used to facilitate the creation of the listing under the signer's owned storefront resource.
/// It accepts the certain details from the signer,i.e. -
///
/// \`saleItemID\` - ID of the NFT that is put on sale by the seller.
/// \`saleItemPrice\` - Amount of tokens (FT) buyer needs to pay for the purchase of listed NFT.
/// \`customID\` - Optional string to represent identifier of the dapp.
/// \`buyer\` - Optional address for the only address that is permitted to fill this listing
/// \`expiry\` - Unix timestamp at which created listing become expired.

/// If the given nft has a support of the RoyaltyView then royalties will added as the sale cut.

transaction(collectionIdentifier: String, saleItemID: UInt64, saleItemPrice: UFix64, customID: String?, buyer: Address?, expiry: UInt64) {
    let paymentReceiver: Capability<&AnyResource{FungibleToken.Receiver}>
    let collectionCap: Capability<&AnyResource{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
    let storefront: &NFTStorefrontV2.Storefront
    let nftType: Type

    prepare(seller: AuthAccount) {
        if seller.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            // Create a new empty Storefront
            let storefront <- NFTStorefrontV2.createStorefront() as! @NFTStorefrontV2.Storefront
            // save it to the account
            seller.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
            // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present
            seller.unlink(NFTStorefrontV2.StorefrontPublicPath)
            seller.link<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontPublicPath, target: NFTStorefrontV2.StorefrontStoragePath)
        }

        let value = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

        // We need a provider capability, but one is not provided by default so we create one if needed.
        let nftCollectionProviderPrivatePath = PrivatePath(identifier: collectionIdentifier.concat("CollectionProviderForFlowtyNFTStorefront"))!

        // Receiver for the sale cut.
        self.paymentReceiver = seller.getCapability<&{FungibleToken.Receiver}>(/public/dapperUtilityCoinReceiver)
        assert(self.paymentReceiver.borrow() != nil, message: "Missing or mis-typed DapperUtilityCoin receiver")

        self.collectionCap = seller.getCapability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(nftCollectionProviderPrivatePath)
        if !self.collectionCap.check() {
            seller.unlink(nftCollectionProviderPrivatePath)
            seller.link<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(nftCollectionProviderPrivatePath, target: value.collectionData.storagePath)
        }

        let collection = self.collectionCap.borrow()
            ?? panic("Could not borrow a reference to the collection")
        assert(self.collectionCap.borrow() != nil, message: "Missing or mis-typed NonFungibleToken.Provider, NonFungibleToken.CollectionPublic provider")
        let nft = collection.borrowNFT(id: saleItemID)
        self.nftType = nft.getType()

        self.storefront = seller.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath)
            ?? panic("Missing or mis-typed NFTStorefront Storefront")
    }

    execute {
        // check for existing listings of the NFT
        var existingListingIDs = self.storefront.getExistingListingIDs(
            nftType: self.nftType,
            nftID: saleItemID
        )
        // remove existing listings
        for listingID in existingListingIDs {
            self.storefront.removeListing(listingResourceID: listingID)
        }

        // Create listing
        self.storefront.createListing(
            nftProviderCapability: self.collectionCap,
            paymentReceiver: self.paymentReceiver,
            nftType: self.nftType,
            nftID: saleItemID,
            salePaymentVaultType: Type<@DapperUtilityCoin.Vault>(),
            price: saleItemPrice,
            customID: customID,
            expiry: UInt64(getCurrentBlock().timestamp) + expiry,
            buyer: buyer
        )
    }
}`

const sellItemDapperFlow = (
	config: Config
): string => `import FlowUtilityToken from ${config.contractAddresses.FlowUtilityToken}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}
import TokenForwarding from ${config.contractAddresses.TokenForwarding}

/// Transaction used to facilitate the creation of the listing under the signer's owned storefront resource.
/// It accepts the certain details from the signer,i.e. -
///
/// \`saleItemID\` - ID of the NFT that is put on sale by the seller.
/// \`saleItemPrice\` - Amount of tokens (FT) buyer needs to pay for the purchase of listed NFT.
/// \`customID\` - Optional string to represent identifier of the dapp.
/// \`buyer\` - Optional address for the only address that is permitted to fill this listing
/// \`expiry\` - Unix timestamp at which created listing become expired.

/// If the given nft has a support of the RoyaltyView then royalties will added as the sale cut.

transaction(collectionIdentifier: String, saleItemID: UInt64, saleItemPrice: UFix64, customID: String?, buyer: Address?, expiry: UInt64) {
    let paymentReceiver: Capability<&AnyResource{FungibleToken.Receiver}>
    let collectionCap: Capability<&AnyResource{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
    let storefront: &NFTStorefrontV2.Storefront
    let nftType: Type

    prepare(seller: AuthAccount) {
        if seller.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            // Create a new empty Storefront
            let storefront <- NFTStorefrontV2.createStorefront() as! @NFTStorefrontV2.Storefront
            // save it to the account
            seller.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
            // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present
            seller.unlink(NFTStorefrontV2.StorefrontPublicPath)
            seller.link<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontPublicPath, target: NFTStorefrontV2.StorefrontStoragePath)
        }

        let value = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

        // We need a provider capability, but one is not provided by default so we create one if needed.
        let nftCollectionProviderPrivatePath = PrivatePath(identifier: collectionIdentifier.concat("CollectionProviderForFlowtyNFTStorefront"))!

        if seller.borrow<&{FungibleToken.Receiver}>(from: /storage/flowUtilityTokenReceiver) == nil {
            let dapper = getAccount(${config.contractAddresses.FlowUtilityToken})
            let dapperFUTReceiver = dapper.getCapability<&{FungibleToken.Receiver}>(/public/flowUtilityTokenReceiver)!

            // Create a new Forwarder resource for FUT and store it in the new account's storage
            let futForwarder <- TokenForwarding.createNewForwarder(recipient: dapperFUTReceiver)
            seller.save(<-futForwarder, to: /storage/flowUtilityTokenReceiver)

            // Publish a Receiver capability for the new account, which is linked to the FUT Forwarder
            seller.link<&FlowUtilityToken.Vault{FungibleToken.Receiver}>(
                /public/flowUtilityTokenReceiver,
                target: /storage/flowUtilityTokenReceiver
            )
        }

        // Receiver for the sale cut.
        self.paymentReceiver = seller.getCapability<&{FungibleToken.Receiver}>(/public/flowUtilityTokenReceiver)
        assert(self.paymentReceiver.borrow() != nil, message: "Missing or mis-typed FlowUtilityToken receiver")

        self.collectionCap = seller.getCapability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(nftCollectionProviderPrivatePath)
        if !self.collectionCap.check() {
            seller.unlink(nftCollectionProviderPrivatePath)
            seller.link<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(nftCollectionProviderPrivatePath, target: value.collectionData.storagePath)
        }

        let collection = self.collectionCap.borrow()
            ?? panic("Could not borrow a reference to the collection")
        assert(self.collectionCap.borrow() != nil, message: "Missing or mis-typed NonFungibleToken.Provider, NonFungibleToken.CollectionPublic provider")
        let nft = collection.borrowNFT(id: saleItemID)
        self.nftType = nft.getType()

        self.storefront = seller.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath)
            ?? panic("Missing or mis-typed NFTStorefront Storefront")
    }

    execute {
        // check for existing listings of the NFT
        var existingListingIDs = self.storefront.getExistingListingIDs(
            nftType: self.nftType,
            nftID: saleItemID
        )
        // remove existing listings
        for listingID in existingListingIDs {
            self.storefront.removeListing(listingResourceID: listingID)
        }

        // Create listing
        self.storefront.createListing(
            nftProviderCapability: self.collectionCap,
            paymentReceiver: self.paymentReceiver,
            nftType: self.nftType,
            nftID: saleItemID,
            salePaymentVaultType: Type<@FlowUtilityToken.Vault>(),
            price: saleItemPrice,
            customID: customID,
            expiry: UInt64(getCurrentBlock().timestamp) + expiry,
            buyer: buyer
        )
    }
}`

const delistItem = (
	config: Config
): string => `import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}

/// Transaction to facilitate the removal of listing by the
/// listing owner.
/// Listing owner should provide the \`listingResourceID\` that
/// needs to be removed.

transaction(listingResourceID: UInt64) {
    let storefront: &NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontManager}

    prepare(acct: AuthAccount) {
        self.storefront = acct.borrow<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontManager}>(from: NFTStorefrontV2.StorefrontStoragePath)
            ?? panic("Missing or mis-typed NFTStorefrontV2.Storefront")
    }

    execute {
        self.storefront.removeListing(listingResourceID: listingResourceID)
    }
}`

const delistItemCrescendo = (
	config: Config
): string => `import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}

/// Transaction to facilitate the removal of listing by the
/// listing owner.
/// Listing owner should provide the \`listingResourceID\` that
/// needs to be removed.

transaction(listingResourceID: UInt64) {
    let storefront: auth(NFTStorefrontV2.List, NFTStorefrontV2.Cancel) &{NFTStorefrontV2.StorefrontManager}

    prepare(acct: auth(Storage) &Account) {
        self.storefront = acct.storage.borrow<auth(NFTStorefrontV2.List, NFTStorefrontV2.Cancel) &{NFTStorefrontV2.StorefrontManager}>(from: NFTStorefrontV2.StorefrontStoragePath)
            ?? panic("Missing or mis-typed NFTStorefrontV2.Storefront")
    }

    execute {
        self.storefront.removeListing(listingResourceID: listingResourceID)
    }
}`

const delistSharedStorefrontItem = (
	config: Config
): string => `import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2_Shared}

/// Transaction to facilitate the removal of listing by the
/// listing owner.
/// Listing owner should provide the \`listingResourceID\` that
/// needs to be removed.

transaction(listingResourceID: UInt64) {
    let storefront: &NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontManager}

    prepare(acct: AuthAccount) {
        self.storefront = acct.borrow<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontManager}>(from: NFTStorefrontV2.StorefrontStoragePath)
            ?? panic("Missing or mis-typed NFTStorefrontV2.Storefront")
    }

    execute {
        self.storefront.removeListing(listingResourceID: listingResourceID)
    }
}`

// there isn't a fully migrated shared storefront yet.
const delistItemSharedStorefrontItemCrescendo = (config: Config): string => ``

const bulkDelistTxn = (
	config: Config
): string => `import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}

/// Transaction to facilitate the bulk removal of listings by the
/// listing owner.
/// Listing owner should provide the \`existingListingIDs\` that
/// needs to be removed.
transaction(existingListingIDs: [UInt64]) {
    let storefront: &NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontManager}
    prepare(acct: AuthAccount) {
        self.storefront = acct.borrow<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontManager}>(from: NFTStorefrontV2.StorefrontStoragePath)
            ?? panic("Missing or mis-typed NFTStorefrontV2.Storefront")
    }
    execute {
        // remove existing listings
        for listingID in existingListingIDs {
            self.storefront.removeListing(listingResourceID: listingID)
        }
    }
}`

const bulkDelistTxnCrescendo = (
	config: Config
): string => `import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}

/// Transaction to facilitate the bulk removal of listings by the
/// listing owner.
/// Listing owner should provide the \`existingListingIDs\` that
/// needs to be removed.
transaction(existingListingIDs: [UInt64]) {
    let storefront: auth(NFTStorefrontV2.List, NFTStorefrontV2.Cancel) &{NFTStorefrontV2.StorefrontManager}
    prepare(acct: auth(Storage) &Account) {
        self.storefront = acct.storage.borrow<auth(NFTStorefrontV2.List, NFTStorefrontV2.Cancel) &{NFTStorefrontV2.StorefrontManager}>(from: NFTStorefrontV2.StorefrontStoragePath)
            ?? panic("Missing or mis-typed NFTStorefrontV2.Storefront")
    }
    execute {
        // remove existing listings
        for listingID in existingListingIDs {
            self.storefront.removeListing(listingResourceID: listingID)
        }
    }
}`
