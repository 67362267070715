import FlowtyLoadingGif from "../../../../assets/flowty-loading2.gif"
import FlowtyLink from "../FlowtyLink"

interface TransactionProcessingProps {
	transactionExplorerLink: string
}

export const TransactionProcessing: React.FunctionComponent<
	TransactionProcessingProps
> = ({ transactionExplorerLink }) => {
	return (
		<div className='w-full md:w-[400px] flex flex-col items-center justify-center gap-[8px] font-montserrat'>
			<img
				className='w-[7.75rem] md:h-[7.75rem]'
				src={FlowtyLoadingGif}
				alt='Transaction Loading GIF'
			/>
			<div className='text-[16px] font-semibold text-[#F8F9FA]'>
				Transaction Processing...
			</div>
			<div className='text-white text-center text-[14px] min-h-[21px] flex items-center justify-center gap-1'>
				{transactionExplorerLink !== "" && (
					<>
						Follow your transaction on{" "}
						<FlowtyLink href={transactionExplorerLink} text='Flowdiver' />
					</>
				)}
			</div>
		</div>
	)
}
