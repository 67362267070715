import {
	OpenSearchListingAvailableData,
	OpensearchRentalAvailableData,
	OpensearchStorefrontAvailableData,
} from "flowty-common"
import React from "react"
import {
	FlowtyWalletSelector,
	walletDisabledMessage,
} from "../../../../FlowtyWalletSelector/FlowtyWalletSelector"
import { AccountSummaries, AccountSummary } from "../../../../Types/GlobalTypes"
import { useFlowtyPurchaseModalContext } from "../../../contexts/FlowtyPurchaseModalContext/FlowtyPurchaseModalContext"
import DapperWarning from "../../common/DapperWarning"
import FundLoanView from "../FundLoanView"
import FundRentalView from "../FundRentalView"
import FundStorefrontView from "../FundStorefrontView"

interface PurchaseOrderWrapperProps {
	accountSummaries: AccountSummaries
	fundLoanOrders?: OpenSearchListingAvailableData[]
	singleLoanOrder?: OpenSearchListingAvailableData
	rentalOrders?: OpensearchRentalAvailableData[]
	singleRentalOrder?: OpensearchRentalAvailableData
	storefrontOrders?: OpensearchStorefrontAvailableData[]
	singleStorefrontOrder?: OpensearchStorefrontAvailableData
	isDapper: boolean
}

export const PurchaseOrderWrapper: React.FunctionComponent<
	PurchaseOrderWrapperProps
> = ({
	accountSummaries,
	fundLoanOrders,
	singleLoanOrder,
	rentalOrders,
	singleRentalOrder,
	storefrontOrders,
	singleStorefrontOrder,
	isDapper,
}) => {
	const {
		payWithSelectedAccount,
		sendToSelectedAccount,
		selectedOrder,
		listingType,
		updateSelectedAccount,
		hasChildAccounts,
		updateSelectedOrder,
		isPrivateListing,
		hasBalance,
		tokenProviderFilter,
		hasNftReceiver,
		dapperWalletNoOrder,
		nonDapperNoOrder,
		isLoggedUser,
	} = useFlowtyPurchaseModalContext()

	return (
		<div className='w-full h-full flex flex-col justify-between gap-5'>
			<div className='h-full flex flex-col gap-3'>
				{hasChildAccounts && (
					<div className='flex flex-col gap-3'>
						<FlowtyWalletSelector
							selected={payWithSelectedAccount}
							setSelected={(account: AccountSummary) =>
								updateSelectedAccount(account, "payWith")
							}
							label='Pay With'
							summaries={accountSummaries}
							listingType={"storefront"}
							selectorFilter={tokenProviderFilter}
							disabledMessage={walletDisabledMessage.payWith}
						/>
						<div className='flex flex-col gap-1'>
							<FlowtyWalletSelector
								selected={sendToSelectedAccount}
								setSelected={(account: AccountSummary) =>
									updateSelectedAccount(account, "sendTo")
								}
								label='Send To'
								summaries={accountSummaries}
								listingType={"storefront"}
								selectorFilter={hasNftReceiver}
								disabledMessage={walletDisabledMessage.sendTo}
							/>
							{listingType === "fundLoan" ? (
								<p className='text-white text-xs'>
									Account to receive collateral (NFT) if loan is not repaid
								</p>
							) : (
								<p className='h-[14px]'></p>
							)}
						</div>
					</div>
				)}
				{(listingType === "purchase" || listingType === "makeOffer") && (
					<FundStorefrontView
						storefrontOrders={storefrontOrders}
						singleStorefrontOrder={singleStorefrontOrder}
						selectedOrder={
							selectedOrder.purchase
								? selectedOrder.purchase
								: storefrontOrders?.[0]
						}
					/>
				)}
				{listingType === "fundLoan" && (
					<FundLoanView
						updateSelectedOrder={updateSelectedOrder}
						fundLoanOrders={fundLoanOrders}
						singleLoanOrder={singleLoanOrder}
						selectedOrder={
							selectedOrder.fundLoan
								? selectedOrder.fundLoan
								: fundLoanOrders?.[0]
						}
					/>
				)}
				{listingType === "fundRental" && (
					<FundRentalView
						updateSelectedOrder={updateSelectedOrder}
						rentalOrders={rentalOrders}
						singleRentalOrder={singleRentalOrder}
						selectedOrder={
							selectedOrder.fundRental
								? selectedOrder.fundRental
								: rentalOrders?.[0]
						}
						isDapper={isDapper}
						isPrivateListing={isPrivateListing}
					/>
				)}
			</div>
			<div className='flex flex-col gap-3'>
				{hasBalance === false &&
					!dapperWalletNoOrder &&
					!nonDapperNoOrder &&
					isLoggedUser && (
						<p className='w-full text-[#FF6E25] text-sm'>
							Insufficient funds to complete the transaction!
						</p>
					)}
				<div className='mt-auto flex flex-col gap-[0.75rem] justify-end self-stretch'>
					{(dapperWalletNoOrder || nonDapperNoOrder) && (
						<DapperWarning
							isLoanOrRental={
								listingType === "fundLoan" || listingType === "fundRental"
							}
							isDapper={isDapper}
							isNonDapper={nonDapperNoOrder}
						/>
					)}
				</div>
			</div>
		</div>
	)
}
