import { useState } from "react"
import { CheckIcon, LinkIcon } from "@heroicons/react/outline"
import { cn } from "../../util/cn"

export const CopyPublicProfileLinkButton = ({
	address,
}: {
	address: string
}) => {
	const [copied, setCopied] = useState(false)

	const resetCopy = () => setCopied(false)

	const handleCopyClick = () => {
		setCopied(true)
		setTimeout(resetCopy, 5000)
	}

	const domainUrl = window.location.origin

	return (
		<>
			<button
				disabled={copied}
				data-tooltip-id={`copy-wallet-url-tooltip`}
				data-tooltip-content={`Share Profile URL`}
				data-tooltip-place='right'
				className={cn(
					"rounded-lg flex items-center justify-center group font-semibold py-1.5 px-2 text-xs",
					{
						"bg-white text-black hover:bg-opacity-80": !copied,
						"text-white bg-primary": copied,
					}
				)}
				onClick={() => {
					handleCopyClick()
					;(navigator as Navigator).clipboard.writeText(
						`${domainUrl}/user/${address}`
					)
				}}
			>
				{copied ? (
					<div className='flex items-center space-x-0.5'>
						<div>Profile Copied</div>
						<CheckIcon className='w-4 h-4 stroke-white' />
					</div>
				) : (
					<div className='flex items-center space-x-0.5'>
						<div>Share Profile</div>
						<LinkIcon className='w-4 h-4 stroke-black' />
					</div>
				)}
			</button>
		</>
	)
}
