import React, { useEffect, useRef } from "react"
import { Oval as OvalLoader } from "react-loader-spinner"
import { ReactComponent as KeepInMindIcon } from "../../../../assets/media/loanInfo/keepInMindIcon.svg"
import { flowdiverBaseURL } from "../../../../util/settings"
import { Alert } from "../../../Alert/Alert"

interface TransactionHandlingProps {
	transactionId: string | null
	callTransaction: () => void
}

const TransactionHandling: React.FC<TransactionHandlingProps> = ({
	transactionId,
	callTransaction,
}) => {
	const effectRan = useRef(false)
	useEffect(() => {
		if (effectRan.current) return
		callTransaction()
		return () => {
			effectRan.current = true
		}
	}, [])
	return (
		<div className='flex flex-col justify-center items-center'>
			<OvalLoader color='#04E5A3' height={45} width={45} />
			<h5 className='text-lightText mt-4'>
				{transactionId ? (
					<p>
						Follow your transaction on&nbsp;
						<a
							className='modal-external-link'
							href={`${flowdiverBaseURL}/tx/${transactionId}/`}
							target='_blank'
							rel='noreferrer'
						>
							Flowdiver
						</a>
					</p>
				) : (
					<p>Transaction is processing...</p>
				)}
			</h5>

			<Alert variant='dark' className='mt-2'>
				<div className='flex items-center'>
					<KeepInMindIcon />
					<div>
						<h5 className='text-white'>Note</h5>
						<p className='text-lightText'>
							It may take up to five minutes for your transaction to process
						</p>
					</div>
				</div>
			</Alert>
		</div>
	)
}
export default TransactionHandling
