import React from "react"
import { cn } from "../../../../utils/cn"
import { DROPSTATUS } from "../../../hooks/useDropPage"

interface DropStatusPillProps {
	dropStatus: DROPSTATUS
}

export const DropStatusPill: React.FunctionComponent<DropStatusPillProps> = ({
	dropStatus,
}) => {
	return (
		<div className='bg-dropStatusIndicatorBackground w-fit items-center flex gap-[12px] px-[12px] py-[6px] border-[1px] rounded-[4px] border-dropStatusIndicatorBorder'>
			<div
				className={cn(`w-[10px] h-[10px] rounded-full`, {
					"bg-[#00FFB4] shadow-pendingActiveDrop":
						dropStatus === "ACTIVE" || dropStatus === "NEVER_ENDING",
					"bg-[#ADB5BD] shadow-endedDrop": dropStatus === "ENDED",
					"bg-[#FAFF06] shadow-pendingActiveDrop": dropStatus === "PENDING",
				})}
			/>
			<span className='uppercase text-neutral-500 font-montserrat text-[12px] font-[600] leading-[16px]'>
				{dropStatus === "PENDING"
					? "COMING SOON"
					: dropStatus === "ACTIVE" || dropStatus === "NEVER_ENDING"
					? "LIVE"
					: "DROP ENDED"}
			</span>
		</div>
	)
}
