/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react"
import useInfiniteScroll from "../../../../hooks/infiniteScroll"
import { fetchUsersTransactions } from "../../../../services/firestore/UserService"
import { Err, Log } from "../../../../util/Log"
import { ScrollToLoadMsg } from "../../../../components/Loaders/ScrollToLoadMsg"
import { TransactionTable } from "./TransactionsTable"

interface UserActivityTabProps {
	address: string
}
export interface Transactions {
	blockTimestamp: number
	events: any[]
	id: string
}
const limit = 10

export const UserActivityTab: React.FC<UserActivityTabProps> = ({
	address,
}) => {
	const [transactions, setTransactions] = useState<Transactions[]>([])
	const [page, setPage] = useState(1)
	const [hasMore, setHasMore] = useState(true)

	const scroll = useInfiniteScroll(
		{
			isLoading: false,
			onInfiniteScroll() {
				if (hasMore) {
					Log("Scroll Triggered", { hasMore, page })
					setPage(page + 1)
				}
			},
		},
		[transactions, hasMore]
	)
	useEffect(() => {
		// setLoading(true)
		fetchUsersTransactions(address, limit, page)
			.then(res => {
				Log({ transactions }, "Got Transactions")
				if (res.length < limit * page) setHasMore(false)
				setTransactions(res)
			})
			.catch(err => {
				Err(err)
			})
	}, [page])
	return (
		<>
			<TransactionTable transactions={transactions} />
			<ScrollToLoadMsg />
			<div ref={scroll} />
		</>
	)
}
