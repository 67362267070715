/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	get,
	makeAutoObservable,
	observable,
	ObservableMap,
	remove,
	runInAction,
	set,
} from "mobx"
import { onAuthStateChanged, User as FbUser } from "firebase/auth"

import { makePersistable } from "mobx-persist-store"
import { subscribeForAuthChange } from "../services/AuthService"
import { actions as Mixpanel } from "../util/Mixpanel"

import RootStore from "./RootStore"

import globalAlertConfig, {
	GlobalAlertProps,
} from "../config/globalAlertConfig"
import {
	getAccountGlobalAlertInfoByAddress,
	AccountGlobalAlert,
	AccountGlobalAlertConfig,
	setAccountGlobalAlert,
} from "../services/firestore/GlobalAlertService"
import { Log } from "../util/Log"
import { PaymentTokenToIdentifier } from "../util/tokens"
import { SupportedTokens, ViewSetting } from "flowty-common"
import { FTBalances, FTProviderStatus } from "../contexts/HybridCustodyContext"
import { auth } from "../firebase"
import { User } from "../models/user"
import { AccountSummaries } from "flowty-sdk"

export interface AuthStoreProp {
	authStore?: AuthStore
	marketplaceHeader?: any
	hasNoSpecifiedOrderTypeSelected?: boolean
	isGridView?: boolean
	setIsGridView?: (val: boolean) => void
	isMarketplacePage?: boolean
	landingPage?: boolean
}

class AuthStore {
	activeGlobalAlerts: ObservableMap<string, GlobalAlertProps> = observable.map(
		{}
	)
	loadingUser: boolean = false

	loggedUser: User | null = null

	rootStore: RootStore

	constructor(rootStore: RootStore) {
		Log("AuthStore constructor")
		this.rootStore = rootStore
		Log(rootStore)
		makeAutoObservable(this)
		makePersistable(this, {
			name: "AuthStore",
			properties: ["loggedUser"],
			storage: window.localStorage,
		}).then(async (res: any) => {
			Log("AuthStore", "res", res, res.target)

			const fbUser: FbUser | null = await new Promise(resolve => {
				const stateChanged = onAuthStateChanged(
					auth,
					u => {
						// unsubscribe
						stateChanged()
						resolve(u)
					},
					err => {
						Mixpanel.track("[PREVIEW 2-4233]ErrorOnAuthStateChanged", {
							err,
						})
						// unsubscribe
						stateChanged()
						resolve(null)
					}
				)
			})

			subscribeForAuthChange(
				this.setUser,
				err => {
					const networkErrors =
						this.rootStore.flowNetworkStatus.errors.length > 0
							? [
									...this.rootStore.flowNetworkStatus.errors,
									...[err?.toString()],
							  ]
							: [err]

					rootStore.setFlowNetworkStatus({
						errors: networkErrors,
						healthy: false,
					})
				},
				fbUser,
				this.setLoadingUser
			)
		})
	}

	setUser = async (user: User | null): Promise<void> => {
		Log("setUser", user)
		this.loadingUser = false
		this.loggedUser = user
	}

	setLoadingUser = async (val: boolean): Promise<void> => {
		this.loadingUser = val
	}

	setEmail = (email: string | null): void => {
		if (!this.loggedUser) {
			return
		}

		this.loggedUser.email = email
	}

	setMarketingEmail = (email: string | null): void => {
		if (!this.loggedUser) {
			return
		}

		this.loggedUser.marketingEmail = email
	}

	setWelcomeMessagePopupAcknowledged = (value: boolean): void => {
		if (!this.loggedUser) {
			return
		}

		this.loggedUser.welcomePopupAcknowledged = value
	}

	setNewTermsAcknowledged = (value: boolean): void => {
		if (!this.loggedUser) {
			return
		}

		this.loggedUser.hasAcceptedTermsV2 = value
	}

	setOptIn = (value: boolean): void => {
		if (!this.loggedUser) {
			return
		}

		this.loggedUser.emailOptIn = value
	}

	setProfileImage(url: string): void {
		if (!this.loggedUser) {
			return
		}
		this.loggedUser.avatar = url
	}

	setPreferredCardSize(size: ViewSetting): void {
		if (!this.loggedUser) {
			return
		}
		this.loggedUser.preferredCardSize = size
	}

	setUsername(username: string): void {
		if (!this.loggedUser) {
			return
		}
		this.loggedUser.userName = username
	}

	setLinkedWalletImage(wallet: string, url: string): void {
		if (!this.loggedUser?.accountSummaries?.[wallet]?.display?.thumbnail) {
			return
		}
		this.loggedUser.accountSummaries[wallet].display.thumbnail = url
	}

	setLinkedWalletDisplayName(wallet: string, name: string): void {
		if (!this.loggedUser?.accountSummaries?.[wallet]?.display?.name) {
			return
		}
		this.loggedUser.accountSummaries[wallet].display.name = name
	}

	setChildBalances = (balances: FTBalances): void => {
		if (!this.loggedUser) {
			return
		}
		Log("setting child account balances", balances)

		this.loggedUser.childAccountBalances = balances ?? null
	}

	setChildFTProviders = (providers: FTProviderStatus): void => {
		if (!this.loggedUser?.childAccounts) {
			return
		}
		Log("setting child FT providers", providers)

		this.loggedUser.childAccountFTProviders = providers || null
	}

	setChildAccountFTSummaries = (val: AccountSummaries): void => {
		if (!this.loggedUser?.childAccounts) {
			return
		}
		Log("setting child account summaries", val)

		this.loggedUser.accountSummaries = val || null
	}

	isLoggedUserHasBallance = (
		requiredBallance: number,
		token: string
	): boolean => {
		const normalizedToken = (token?.toUpperCase() as SupportedTokens) || ""
		const tokenToIdentifier = PaymentTokenToIdentifier[normalizedToken]

		const balance =
			this.loggedUser?.balance?.balances &&
			tokenToIdentifier in this.loggedUser.balance.balances
				? Number(this.loggedUser.balance.balances[tokenToIdentifier])
				: 0

		return Number(balance) >= requiredBallance
	}

	loadActiveGlobalAlerts = (): void => {
		globalAlertConfig.alerts.map(async (alert: GlobalAlertProps) => {
			const isVisible = await this.isGlobalAlertVisible(alert.id)
			if (isVisible) {
				set(this.activeGlobalAlerts, alert.id, alert)
			}
		})
	}

	isGlobalAlertVisible = async (alertId: string): Promise<boolean> => {
		const address = this.loggedUser?.addr
		if (address) {
			const currGlobalAlert = globalAlertConfig.alerts.find(
				(alert: GlobalAlertProps) => alert.id === alertId
			)

			if (currGlobalAlert) {
				if (!currGlobalAlert.active) {
					return false
				}

				const accountGlobalAlertInfo = await getAccountGlobalAlertInfoByAddress(
					address
				)

				if (accountGlobalAlertInfo) {
					const currAlertInfo = accountGlobalAlertInfo.alerts[alertId]
					if (currAlertInfo) {
						return !currAlertInfo.dismissed
					}
				}

				return true
			}

			throw new Error(`Not found global alert config with id "${alertId}"`)
		}

		return !!get(this.activeGlobalAlerts, alertId)
	}

	globalAlertClose = async (alertId: string): Promise<void> => {
		runInAction(() => {
			remove(this.activeGlobalAlerts, alertId)
		})
	}

	globalAlertDismiss = async (alertId: string): Promise<void> => {
		const address = this.loggedUser?.addr
		if (address) {
			const accountGlobalAlertInfo = await getAccountGlobalAlertInfoByAddress(
				address
			)

			const newAlerts = accountGlobalAlertInfo?.alerts || {}

			newAlerts[alertId] = {
				dismissed: true,
				seen: true,
			} as AccountGlobalAlert

			setAccountGlobalAlert(address, {
				alerts: newAlerts,
			} as AccountGlobalAlertConfig).then(() => {
				remove(this.activeGlobalAlerts, alertId)
			})
		}
	}

	getAccountSummaryAddresses = (): string[] => {
		if (!this.loggedUser?.addr) return []

		const addresses = [this.loggedUser.addr || ""]
		if (this.loggedUser.accountSummaries) {
			Object.values(this.loggedUser.accountSummaries).forEach(summary => {
				addresses.push(summary.address)
			})
		}

		return addresses
	}
}

// eslint-disable-next-line import/no-default-export
export default AuthStore
