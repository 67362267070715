import { useState } from "react"
import { FlowtyButton } from "../FlowtyButton"
import { Divider } from "../Shared/Atoms/Divider"
import { actions as Mixpanel } from "../utils/MockMixpanel"
import BloctoLogo from "../assets/blocto-footer.png"
import BuiltonFlow from "../assets/builtonflow.png"
import DapperLogo from "../assets/dapper-footer.png"
import FlipperLogo from "../assets/flipper.png"
import { ReactComponent as DiscordLogo } from "../assets/discord-footer-icon.svg"
import { ReactComponent as GitBookLogo } from "../assets/gitbookLogo.svg"
import NufiLogo from "../assets/nufi-footer.png"
import { ReactComponent as TwitterLogo } from "../assets/twitter_icon.svg"
import FlowReferenceWallet from "../assets/flow-reference-wallet-small.png"
import * as Yup from "yup"
import { Formik, Form, ErrorMessage } from "formik"
import SnackbarGeneralMsg from "./SnackbarGeneralMsg"
import { flowtyDiscordLink } from "flowty-common"

interface FlowtyFooterProps {
	subscribeEmailToNotification: (email: string) => void
}

export const FlowtyFooter: React.FunctionComponent<FlowtyFooterProps> = ({
	subscribeEmailToNotification,
}) => {
	const emailSchema = Yup.object().shape({
		email: Yup.string()
			.email("Invalid email")
			.required("Please enter your email"),
	})

	const [showSnackbarNote, setShowSnackbarNote] = useState<boolean>(false)
	const [snackbarMessage, setSnackbarMessage] = useState<string>("")
	const [loading, setLoading] = useState<boolean>(false)

	const addNotificationEmail = async (email: string): Promise<void> => {
		setLoading(true)
		try {
			await subscribeEmailToNotification(email)
			setSnackbarMessage(
				"Your entered email is being added to our mailing list. It may take up to 5 minutes!"
			)
			setShowSnackbarNote(true)
			Mixpanel.track("Success: Subscribe to newsletter", {
				email: email,
			})
			setLoading(false)
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setSnackbarMessage(
				`Failed adding email. ${error?.message}. Please try again`
			)
			setShowSnackbarNote(true)
			Mixpanel.track("Failure: Subscribe to newsletter", {
				email: email,
				error: error,
			})
			setLoading(false)
		}
	}

	return (
		<div className='relative px-3 lg:px-0 w-full mt-8 pb-12'>
			<div className='flex flex-col lg:flex-row justify-between container'>
				<SnackbarGeneralMsg
					title='Flowty Email Subscription'
					message={snackbarMessage}
					show={showSnackbarNote}
					setShow={setShowSnackbarNote}
					seconds={5}
				/>
				<div className='flex flex-col lg:max-w-lg'>
					<div className='flex flex-col'>
						<h5 className='font-montserrat font-bold text-[18px] mb-8 text-white'>
							Sign up to Receive Flowty Emails
						</h5>
						<h6 className='font-montserrat font-[14px] text-[#CFCFCF]'>
							Get the latest updates, promotions, supported collections and more
							delivered straight to your inbox, along with notifications for
							offers received on your NFTs
						</h6>
						<div className='my-8 lg:w-4/5'>
							<Formik
								initialValues={{ email: "" }}
								onSubmit={async (values, { resetForm }) => {
									Mixpanel.track("Subscribe to newsletter attempt", {
										values: values,
									})
									addNotificationEmail(values.email)
									resetForm()
								}}
								validationSchema={emailSchema}
								enableReinitialize
							>
								{({ handleChange, values, touched }) => (
									<Form>
										<div className='flex flex-row items-center justify-around'>
											<input
												type='email'
												name='email'
												value={values.email}
												onChange={e => {
													handleChange(e)
												}}
												className='py-3 w-full focus:outline-none border-[1.5px] border-[#6C757D] text-white bg-[#ffffff14] bg-blur shadow-depressed px-4 rounded-md font-montserrat text-[12px] mr-4'
												placeholder='user@email.com'
											/>
											<FlowtyButton
												type='submit'
												disabled={loading}
												text={
													<>
														{!loading ? (
															"Subscribe"
														) : (
															<div className='flex flex-row items-center'>
																Loading
																<div
																	className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full ml-2'
																	role='status'
																	aria-label='loading'
																></div>
															</div>
														)}
													</>
												}
												variant={"primary"}
											/>
											<span>{touched.email}</span>
										</div>
										<div className='flex text-red-500 justify-center items-center text-center'>
											<ErrorMessage name={"email"} />
										</div>
									</Form>
								)}
							</Formik>
						</div>
					</div>
					<div className='flex flex-row justify-start items-center z-10 space-x-4'>
						<TwitterLogo
							className='footer-social-logos hover:cursor-pointer'
							onClick={() => {
								window.open("https://twitter.com/flowty_io")
								Mixpanel.track("Clicked on Footer Twitter link")
							}}
							height={30}
							width={30}
							id='twitter'
						/>
						<GitBookLogo
							className='footer-social-logos invert hover:cursor-pointer'
							onClick={() => {
								window.open("https://docs.flowty.io/reference")
								Mixpanel.track("Clicked on Footer Gitbook link")
							}}
							height={30}
							width={30}
							id='gitbook'
						/>

						<DiscordLogo
							className='footer-social-logos'
							onClick={() => {
								window.open(flowtyDiscordLink, "WindowName", "noopener")
								Mixpanel.track("Clicked on Footer Discord link")
							}}
							height={30}
							width={30}
							id='discord'
						/>
					</div>
				</div>
				<div className='flex flex-row mt-12 lg:mt-0 justify-between lg:justify-around flex-grow flex-wrap'>
					<div className='flex flex-col mx-4 lg:mx-0 z-10'>
						<a
							href='/marketplace'
							onClick={() => {
								Mixpanel.track("Clicked on Footer Collections Link")
							}}
							className='font-montserrat mb-6 text-white hover:text-white uppercase font-bold'
						>
							Marketplace
						</a>
						<div className='flex flex-col text-[#CED4DA]'>
							<a
								href='/collection/0x0b2a3299cc857e29/TopShot'
								onClick={() => {
									Mixpanel.track("Clicked on Footer NBA Top Shot Link")
								}}
								className='font-montserrat font-[14px] mb-6 hover:text-white'
							>
								NBA Top Shot
							</a>
							<a
								href='/collection/0xe4cf4bdc1751c65d/AllDay'
								onClick={() => {
									Mixpanel.track("Clicked on Footer NFL All Day Link")
								}}
								className='font-montserrat font-[14px] mb-6 hover:text-white'
							>
								NFL All Day
							</a>
							<a
								href='/collection/0x329feb3ab062d289/UFC_NFT'
								onClick={() => {
									Mixpanel.track("Clicked on Footer UFC NFT Link")
								}}
								className='font-montserrat font-[14px] mb-6 hover:text-white'
							>
								UFC Strike
							</a>
							<a
								href='/collection/0x87ca73a41bb50ad5/Golazos'
								onClick={() => {
									Mixpanel.track("Clicked on Footer La Liga Link")
								}}
								className='font-montserrat font-[14px] mb-6 hover:text-white'
							>
								La Liga Golazos
							</a>
						</div>
					</div>
					<div className='flex flex-col mx-4 lg:mx-0 z-10'>
						<a
							href='https://docs.flowty.io/reference/'
							onClick={() => {
								Mixpanel.track("Clicked on Footer Help Link")
							}}
							className='font-montserrat text-[14px] text-white mb-6 hover:text-white uppercase font-bold'
						>
							Help
						</a>
						<div className='flex flex-col text-[#CED4DA]'>
							<a
								href='https://docs.flowty.io/reference/'
								onClick={() => {
									Mixpanel.track("Clicked on Footer FAQ Link")
								}}
								className='font-montserrat font-[14px] mb-6 hover:text-white'
							>
								FAQ + Guides
							</a>
							<a
								href='https://docs.flowty.io/reference/general-platform/troubleshooting'
								onClick={() => {
									Mixpanel.track("Clicked on Footer Troubleshooting Link")
								}}
								className='font-montserrat font-[14px] mb-6 hover:text-white'
							>
								Troubleshooting
							</a>
							<a
								href='/tos'
								onClick={() => {
									Mixpanel.track("Clicked on Footer TOS Link")
								}}
								className='font-montserrat font-[14px] mb-6 hover:text-white'
							>
								Terms of Service
							</a>
							<a
								href='/privacy'
								onClick={() => {
									Mixpanel.track("Clicked on Footer Privacy Link")
								}}
								className='font-montserrat font-[14px] mb-6 hover:text-white'
							>
								Privacy Policy
							</a>
						</div>
					</div>
					<div className='flex flex-col mx-4 lg:mx-0'>
						<a
							href='https://docs.flowty.io/reference/general-platform/supported-crypto-wallets'
							onClick={() => {
								Mixpanel.track("Clicked on Footer Supported Wallets Link")
							}}
							className='font-montserrat text-[14px] mb-6 text-white hover:text-white uppercase font-bold'
						>
							Supported Wallets
						</a>
						<div className='grid grid-cols-2 gap-x-4 gap-y-4 text-[#CED4DA]'>
							<div
								className='col-span-1 hover:cursor-pointer'
								onClick={() => {
									window.open("https://www.dapperlabs.com/")
									Mixpanel.track("Clicked on Footer Dapper link")
								}}
							>
								<img src={DapperLogo} className='img-fluid' alt='Dapper Logo' />
							</div>
							<div
								className='col-span-1 hover:cursor-pointer'
								onClick={() => {
									window.open("https://blocto.app/")
									Mixpanel.track("Clicked on Footer Blocto link")
								}}
							>
								<img src={BloctoLogo} className='img-fluid' alt='Blocto Logo' />
							</div>
							<div
								className='col-span-1 hover:cursor-pointer'
								onClick={() => {
									window.open("https://nu.fi/")
									Mixpanel.track("Clicked on Footer Nufi link")
								}}
							>
								<img src={NufiLogo} className='img-fluid' alt='Nufi Logo' />
							</div>
							<div
								className='col-span-1 hover:cursor-pointer'
								onClick={() => {
									window.open("https://flipper.org/")
									Mixpanel.track("Clicked on Footer Flipper link")
								}}
							>
								<img
									src={FlipperLogo}
									className='img-fluid'
									alt='Flipper Logo'
								/>
							</div>
							<div
								className='col-span-1 hover:cursor-pointer'
								onClick={() => {
									window.open("https://frw.gitbook.io/doc/")
									Mixpanel.track("Clicked on Footer Flow Reference link")
								}}
							>
								<img
									src={FlowReferenceWallet}
									className='img-fluid'
									alt='Flow Reference Wallet Logo'
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Divider className='my-[3.75em]' />
			<div className='container flex flex-col lg:flex-row justify-between items-center'>
				<h6 className='font-montserrat font-[18px] text-[#CFCFCF]'>
					© 2021-2024 flowtyNFT Inc. All rights reserved.
				</h6>
				<div className='mt-4 lg:mt-0'>
					<div
						className='hover:cursor-pointer'
						onClick={() => {
							window.open("https://flow.com")
							Mixpanel.track("Clicked on Footer Flow link")
						}}
					>
						<img src={BuiltonFlow} className='' alt='Built on Flow' />
					</div>
				</div>
			</div>
		</div>
	)
}
