import React from "react"

interface FilterPillProps {
	dataTestId?: string
	onClick?: () => void
}

export const ClearFilterButton: React.FC<FilterPillProps> = ({
	dataTestId,
	onClick,
}) => {
	return (
		<button
			data-testid={dataTestId}
			onKeyDown={event => {
				if (event.key === "Enter" && onClick) {
					onClick()
				}
			}}
			onClick={onClick}
			className={`text-left text-white font-montserrat text-xs font-bold leading-4 tracking-[0.00625rem] underline cursor-pointer pb-[2.06rem]`}
		>
			CLEAR FILTERS
		</button>
	)
}
