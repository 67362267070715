const IS_MAINNET = Boolean(
	process.env.REACT_APP_ACCESS_API?.includes("mainnet")
)

export const NFT_CATALOG_ADDRESS = IS_MAINNET
	? "0x49a7cda3a1eecc29"
	: "0x324c34e1c517e4db"

export const NFT_CONTRACT_ADDRESS = IS_MAINNET
	? "0x1d7e57aa55817448"
	: "0x631e88ae7f1d7c20" // testnet

export const FUNGIBLE_TOKEN_CONTRACT_ADDRESS = IS_MAINNET
	? "0xf233dcee88fe0abe" // mainnet
	: "0x9a0766d93b6608b7" // testnet

export const TOKEN_FORWARDING_ADDRESS = IS_MAINNET
	? "0xe544175ee0461c4b" // mainnet
	: "0x51ea0e37c27a1f1a" // testnet

export const UTILITY_TOKEN_ADDRESS = IS_MAINNET
	? "0xead892083b3e2c6c" // mainnet
	: "0x82ec283f88a62e65" // testnet

const FLOWTY_CONTRACT_ADDR = process.env.REACT_APP_ADDRESS_FLOWTY_CONTRACT

export const FLOWTYV2_CONTRACT_ADDR =
	process.env.REACT_APP_ADDRESS_FLOWTYV2_CONTRACT || FLOWTY_CONTRACT_ADDR || ""
