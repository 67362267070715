import { FlowNFT } from "flowty-common"
import { useEffect, useState } from "react"
import { Flowty } from "../flowty"

interface GetCollectionRoyaltyProps {
	royaltyRate: number | null
	isLoading: boolean
}

interface UseCollectionRoyaltyProps {
	nft: FlowNFT | null
	flowty: Flowty
}

export const useGetCollectionRoyalty = ({
	nft,
	flowty,
}: UseCollectionRoyaltyProps): GetCollectionRoyaltyProps => {
	const [royaltyRate, setRoyaltyRate] = useState<number | null>(null)
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		if (nft) {
			const storagePath = nft?.nftView?.collectionData?.storagePath
			flowty.scripts
				.getRoyaltyRate(
					nft.owner || "",
					nft.id.toString(),
					storagePath?.identifier ?? ""
				)
				.then(res => {
					setRoyaltyRate(res)
					setIsLoading(false)
				})
		}
	}, [nft])

	return { isLoading, royaltyRate }
}
