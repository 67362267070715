import React, { useMemo, useState } from "react"
import { cn } from "../../../../../../util/cn"

interface FilterWrapperProps {
	child: React.ReactNode
	title: string
	filterIcon: React.ReactNode
}

export const FilterWrapper: React.FunctionComponent<FilterWrapperProps> = ({
	child,
	title,
	filterIcon,
}) => {
	const [isOpen, setIsOpen] = useState(true)

	const chevron = useMemo(() => {
		return (
			<svg
				data-testid='listing-type-chevron'
				className={cn({
					"rotate-0": !isOpen,
					"rotate-180": isOpen,
				})}
				width='14'
				height='8'
				viewBox='0 0 14 8'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M0 0L7 8L14 0L0 0Z'
					fill={!isOpen ? "#F8F9FA" : "#04e5a3"}
				/>
			</svg>
		)
	}, [isOpen])
	return (
		<div
			className={cn(
				"rounded-[0.375rem] cursor-pointer border-[1px] border-neutral-400 border-opacity-25 py-[0.875rem]",
				{
					"hover:bg-[#ffffff29]": !isOpen,
				}
			)}
		>
			<div
				className='flex items-center justify-between w-full px-[1rem]'
				onClick={() => setIsOpen(!isOpen)}
				onKeyDown={event => {
					if (event.key === "Enter") {
						setIsOpen(!isOpen)
					}
				}}
			>
				<div className='flex items-center gap-[0.5rem]'>
					{filterIcon}
					<span className='text-[0.875rem] font-montserrat font-[600]'>
						{title}
					</span>
				</div>
				{chevron}
			</div>
			{isOpen ? (
				<div className='space-y-2 mt-3 overflow-y-auto styled-scroll px-[1rem]'>
					{child}
				</div>
			) : null}
		</div>
	)
}
