import { TokenMetadata } from "flowty-common"
import { Config } from "../../types"
import { FlowNFTData } from "../../common/CommonTypes"

export const getFillRentalTxn = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData
): string =>
	config.crescendo
		? // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  fillRentalTxnCrescendo(config, token, nftData)
		: // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  fillRentalTxn(config, token, nftData)

const fillRentalTxn = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.NonFungibleToken}
import ${token.contractName} from ${token.contractAddress}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import ${nftData.contractName} from ${nftData.contractAddress}

import FlowtyRentals from ${config.contractAddresses.Flowty}

import HybridCustody from ${config.contractAddresses.HybridCustody}

transaction(
  listingResourceID: UInt64,
  flowtyStorefrontAddress: Address,
  autoReturn: Bool,
  nftReceiverAddress: Address,
  ftProviderAddress: Address,
  privateFTPath: String,
  nftProviderPathIdentifier: String
) {
  let paymentVault: @FungibleToken.Vault
  let storefront: &FlowtyRentals.FlowtyRentalsStorefront{FlowtyRentals.FlowtyRentalsStorefrontPublic}
  let listing: &FlowtyRentals.Listing{FlowtyRentals.ListingPublic}

  let tokenReceiver: Capability<&{FungibleToken.Receiver}>
  let nftReceiver: Capability<&AnyResource{NonFungibleToken.CollectionPublic}>
  let provider: Capability<&AnyResource{NonFungibleToken.CollectionPublic, NonFungibleToken.Provider}>?
  let ftProvider: Capability<&{FungibleToken.Provider}>

  prepare(acct: AuthAccount) {
    if(acct.borrow<&FlowtyRentals.FlowtyRentalsStorefront>(from: FlowtyRentals.FlowtyRentalsStorefrontStoragePath) == nil) {
      // Create a new empty .Storefront
      let storefront <- FlowtyRentals.createStorefront() as! @FlowtyRentals.FlowtyRentalsStorefront

      // save it to the account
      acct.save(<-storefront, to: FlowtyRentals.FlowtyRentalsStorefrontStoragePath)

      // create a public capability for the .Storefront
      acct.link<&FlowtyRentals.FlowtyRentalsStorefront{FlowtyRentals.FlowtyRentalsStorefrontPublic}>(FlowtyRentals.FlowtyRentalsStorefrontPublicPath, target: FlowtyRentals.FlowtyRentalsStorefrontStoragePath)
    }

    self.storefront = getAccount(flowtyStorefrontAddress)
      .getCapability<&FlowtyRentals.FlowtyRentalsStorefront{FlowtyRentals.FlowtyRentalsStorefrontPublic}>(
        FlowtyRentals.FlowtyRentalsStorefrontPublicPath
      ).borrow()
      ?? panic("Could not borrow FlowtyRentalsStorefront from provided address")

    self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
      ?? panic("No Listing with that ID in FlowtyRentalsStorefront. Already rented or delisted.")
    let price = self.listing.getDetails().amount

    let nft = self.listing.borrowNFT()
    let nftType = nft.getType()

    let catalogIdentifiers = NFTCatalog.getCollectionsForType(nftTypeIdentifier: nftType.identifier) ?? panic("not found in catalog")
    var catalogIdentifier = ""
    for k in catalogIdentifiers.keys {
        if catalogIdentifiers[k] == true {
            catalogIdentifier = k
            break
        }
    }

    assert(catalogIdentifier != "", message: "no valid catalog identifier found")

    let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: catalogIdentifier) ?? panic("catalog entry not found")

    let publicCollectionPath = catalogEntry.collectionData.publicPath
    let storageCollectionPath = catalogEntry.collectionData.storagePath

    if ftProviderAddress == acct.address {
      let flowtyFtProviderPath = /private/${token.contractName}${token.contractAddress}FtProviderForFlowty

      if !acct.getCapability<&{FungibleToken.Provider, FungibleToken.Balance, FungibleToken.Receiver}>(flowtyFtProviderPath).check() {
        acct.unlink(flowtyFtProviderPath)
        acct.link<&{FungibleToken.Provider, FungibleToken.Balance, FungibleToken.Receiver}>(flowtyFtProviderPath, target: ${token.storagePath})
      }

      self.ftProvider = acct.getCapability<&{FungibleToken.Provider}>(flowtyFtProviderPath)
      self.tokenReceiver = acct.getCapability<&{FungibleToken.Receiver}>(${token.receiverPath})
    } else {
      // signer is the parent account and ftProvider is child Account
      // get the manager resource and borrow proxyAccount
      let manager = acct.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
        ?? panic("manager does not exist")
      let childAcct = manager.borrowAccount(addr: ftProviderAddress) ?? panic("ftProvider account not found")

      let FTPrivatePath = PrivatePath(identifier: privateFTPath)!
      let providerCap = childAcct.getCapability(path: FTPrivatePath, type: Type<&{FungibleToken.Provider}>()) ?? panic("no cap found")

      self.ftProvider = providerCap as! Capability<&{FungibleToken.Provider}>

      self.tokenReceiver = getAccount(ftProviderAddress).getCapability<&${token.contractName}.Vault{FungibleToken.Receiver}>(${token.receiverPath})
    }
    assert(self.ftProvider.check(), message: "invalid provider capability")
    assert(self.tokenReceiver.check(), message: "Missing or mis-typed token receiver")

    let paymentAmount = self.listing.getDetails().getTotalPayment()
    self.paymentVault <- self.ftProvider.borrow()!.withdraw(amount: paymentAmount)

    if nftReceiverAddress == acct.address {
      // ensure collection exists
      if acct.borrow<&NonFungibleToken.Collection>(from: storageCollectionPath) == nil {
        // create a new Collection
        let collection <- ${nftData.contractName}.createEmptyCollection()

        // Put the new Collection in storage
        acct.save(<-collection, to: storageCollectionPath)
      }

      if !acct.getCapability<&AnyResource{NonFungibleToken.CollectionPublic}>(publicCollectionPath).check() {
        acct.unlink(publicCollectionPath)
        acct.link<&AnyResource{NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(publicCollectionPath, target: storageCollectionPath)
      }

      self.nftReceiver = acct.getCapability<&{NonFungibleToken.CollectionPublic}>(publicCollectionPath)

      if autoReturn {
        let flowtyNftCollectionProviderPath = /private/${nftData.contractName}${nftData.contractAddress}CollectionProviderForFlowty
        if !acct.getCapability<&{NonFungibleToken.CollectionPublic, NonFungibleToken.Provider}>(flowtyNftCollectionProviderPath).check() {
          acct.unlink(flowtyNftCollectionProviderPath)
          acct.link<&{NonFungibleToken.CollectionPublic, NonFungibleToken.Provider}>(flowtyNftCollectionProviderPath, target: storageCollectionPath)
        }

        self.provider = acct.getCapability<&AnyResource{NonFungibleToken.CollectionPublic, NonFungibleToken.Provider}>(flowtyNftCollectionProviderPath)
      } else {
        self.provider = nil
      }
    } else {
      self.nftReceiver = getAccount(nftReceiverAddress).getCapability<&AnyResource{NonFungibleToken.CollectionPublic}>(publicCollectionPath)

      if autoReturn {
        let collectionProviderPrivatePath = PrivatePath(identifier: nftProviderPathIdentifier) ?? panic("invalid provider path identifier")

        // Get child nft provider for auto return, if path doesn't exist we can't create it
        let manager = acct.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
          ?? panic("manager does not exist")
        let childAcct = manager.borrowAccount(addr: nftReceiverAddress) ?? panic("nftReceiver account not found")
        let providerCap = childAcct.getCapability(path: collectionProviderPrivatePath, type: Type<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()) ?? panic("no nft provider found")

        self.provider = providerCap as! Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
      } else {
        self.provider = nil
      }
    }
    assert(self.nftReceiver.check(), message: "Missing or mis-typed NFT Collection")
    assert(self.provider == nil || self.provider!.check(), message: "Missing or mis-typed NFT provider")
  }

  execute {
    self.listing.rent(
      payment: <-self.paymentVault,
      renterFungibleTokenReceiver: self.tokenReceiver,
      renterNFTCollection: self.nftReceiver,
      renterNFTProvider: self.provider
    )
  }
}`

const fillRentalTxnCrescendo = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.NonFungibleToken}
import ${token.contractName} from ${token.contractAddress}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import ${nftData.contractName} from ${nftData.contractAddress}

import FlowtyRentals from ${config.contractAddresses.Flowty}

import HybridCustody from ${config.contractAddresses.HybridCustody}

transaction(
  listingResourceID: UInt64,
  flowtyStorefrontAddress: Address,
  autoReturn: Bool,
  nftReceiverAddress: Address,
  ftProviderAddress: Address,
  ftProviderControllerID: UInt64,
  nftProviderControllerID: UInt64
) {
  let paymentVault: @{FungibleToken.Vault}
  let storefront: &{FlowtyRentals.FlowtyRentalsStorefrontPublic}
  let listing: &{FlowtyRentals.ListingPublic}

  let tokenReceiver: Capability<&{FungibleToken.Receiver}>
  let nftReceiver: Capability<&{NonFungibleToken.CollectionPublic}>
  let provider: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.CollectionPublic, NonFungibleToken.Provider}>?
  let ftProvider: Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>

  prepare(acct: auth(Storage, Capabilities) &Account) {
    if(acct.storage.borrow<&FlowtyRentals.FlowtyRentalsStorefront>(from: FlowtyRentals.FlowtyRentalsStorefrontStoragePath) == nil) {
      // Create a new empty .Storefront
      let storefront <- FlowtyRentals.createStorefront() as! @FlowtyRentals.FlowtyRentalsStorefront

      // save it to the account
      acct.storage.save(<-storefront, to: FlowtyRentals.FlowtyRentalsStorefrontStoragePath)

      acct.capabilities.publish(
        acct.capabilities.storage.issue<&{FlowtyRentals.FlowtyRentalsStorefrontPublic}>(FlowtyRentals.FlowtyRentalsStorefrontStoragePath),
        at: FlowtyRentals.FlowtyRentalsStorefrontPublicPath
    )
    }

    self.storefront = getAccount(flowtyStorefrontAddress)
      .capabilities.get<&{FlowtyRentals.FlowtyRentalsStorefrontPublic}>(
        FlowtyRentals.FlowtyRentalsStorefrontPublicPath
      )!.borrow()
      ?? panic("Could not borrow FlowtyRentalsStorefront from provided address")

    self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
      ?? panic("No Listing with that ID in FlowtyRentalsStorefront. Already rented or delisted.")
    let price = self.listing.getDetails().amount

    let nft = self.listing.borrowNFT() ?? panic("nft not found")
    let nftType = nft.getType()

    let catalogIdentifiers = NFTCatalog.getCollectionsForType(nftTypeIdentifier: nftType.identifier) ?? panic("not found in catalog")
    var catalogIdentifier = ""
    for k in catalogIdentifiers.keys {
        if catalogIdentifiers[k] == true {
            catalogIdentifier = k
            break
        }
    }

    assert(catalogIdentifier != "", message: "no valid catalog identifier found")

    let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: catalogIdentifier) ?? panic("catalog entry not found")

    let publicCollectionPath = catalogEntry.collectionData.publicPath
    let storageCollectionPath = catalogEntry.collectionData.storagePath

    if ftProviderAddress == acct.address {
      self.ftProvider = acct.capabilities.storage.issue<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>(/storage/flowTokenVault)
      self.tokenReceiver = acct.capabilities.get<&{FungibleToken.Receiver}>(/public/flowTokenReceiver)!
    } else {
      // signer is the parent account and ftProvider is child Account
      // get the manager resource and borrow proxyAccount
      let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
        ?? panic("manager does not exist")
      let childAcct = manager.borrowAccount(addr: ftProviderAddress) ?? panic("ftProvider account not found")

      let providerCap = childAcct.getCapability(controllerID: ftProviderControllerID, type: Type<&{FungibleToken.Provider}>()) ?? panic("no cap found")

      self.ftProvider = providerCap as! Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>

      self.tokenReceiver = getAccount(ftProviderAddress).capabilities.get<&{FungibleToken.Receiver}>(/public/flowTokenReceiver)!
    }
    assert(self.ftProvider.check(), message: "invalid provider capability")
    assert(self.tokenReceiver.check(), message: "Missing or mis-typed FlowToken receiver")

    let paymentAmount = self.listing.getDetails().getTotalPayment()
    self.paymentVault <- self.ftProvider.borrow()!.withdraw(amount: paymentAmount)

    if nftReceiverAddress == acct.address {
      // ensure collection exists
      if acct.storage.borrow<&{NonFungibleToken.Collection}>(from: storageCollectionPath) == nil {
        // create a new Collection
        let collection <- ${nftData.contractName}.createEmptyCollection(nftType: nftType)

        // Put the new Collection in storage
        acct.storage.save(<-collection, to: storageCollectionPath)
      }

      if !acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(publicCollectionPath).check() {
        acct.capabilities.unpublish(publicCollectionPath)
        acct.capabilities.publish(
            acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(storageCollectionPath),
            at: publicCollectionPath
        )
      }

      self.nftReceiver = acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(publicCollectionPath)!

      if autoReturn {
        self.provider = acct.capabilities.storage.issue<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.CollectionPublic, NonFungibleToken.Provider}>(storageCollectionPath)
      } else {
        self.provider = nil
      }
    } else {
      self.nftReceiver = getAccount(nftReceiverAddress).capabilities.get<&{NonFungibleToken.CollectionPublic}>(publicCollectionPath)!

      if autoReturn {
        // Get child nft provider for auto return, if path doesn't exist we can't create it
        let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
          ?? panic("manager does not exist")
        let childAcct = manager.borrowAccount(addr: nftReceiverAddress) ?? panic("nftReceiver account not found")
        let providerCap = childAcct.getCapability(controllerID: nftProviderControllerID, type: Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()) ?? panic("no nft provider found")

        self.provider = providerCap as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
      } else {
        self.provider = nil
      }
    }
    assert(self.nftReceiver.check(), message: "Missing or mis-typed NFT Collection")
    assert(self.provider == nil || self.provider!.check(), message: "Missing or mis-typed NFT provider")
  }

  execute {
    self.listing.rent(
      payment: <-self.paymentVault,
      renterFungibleTokenReceiver: self.tokenReceiver,
      renterNFTCollection: self.nftReceiver,
      renterNFTProvider: self.provider
    )
  }
}`
