import { useEffect, useState } from "react"
import { db } from "../../../../firebase"
import DiscordIcon from "../../../../assets/media/userProfile/discord.svg"
import DiscordSolidIcon from "../../../../assets/media/userProfile/discordSolid.svg"
import { Tooltip } from "react-tooltip"
import { actions as Mixpanel } from "../../../../util/Mixpanel"

interface DiscordProviderDocument {
	username: string
	id: string
}

export const DiscordIntegration: React.FC<{ walletAddress: string }> = ({
	walletAddress,
}) => {
	const [discordHandle, setDiscordHandle] = useState<string | null>(null)

	const [hovered, setHovered] = useState(false)

	const handleMouseEnter = () => {
		setHovered(true)
	}

	const handleMouseLeave = () => {
		setHovered(false)
	}
	useEffect(() => {
		if (walletAddress) {
			db.doc(`/accounts/${walletAddress}/providers/discord`)
				.get()
				.then(discordDoc => {
					if (discordDoc.exists) {
						setDiscordHandle(
							(discordDoc.data() as DiscordProviderDocument).username
						)
					}
				})
				.catch(e => {
					Mixpanel.track("ERROR_FIREBASE_FETCH_DISCORD", {
						e,
					})
				})
		}
	}, [walletAddress])

	if (discordHandle) {
		return (
			<div className='mr-[0.5rem]'>
				<Tooltip
					id={`view-discord-profile-tooltip`}
					style={{
						backgroundColor: "white",
						color: "#031021",
						fontSize: "0.75rem",
					}}
				/>
				<a
					data-tooltip-id={`view-discord-profile-tooltip`}
					data-tooltip-content={`View Discord Profile`}
					data-tooltip-place='right'
					href={`https://discordapp.com/users/${discordHandle}`}
					target='_blank'
					rel='noreferrer'
				>
					<img
						src={hovered ? DiscordSolidIcon : DiscordIcon}
						alt='Discord Icon'
						className='w-[24px] h-[24px]'
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					/>
				</a>
			</div>
		)
	}

	return <></>
}
