import { useContext } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import {
	OpenSearchContext,
	OpenSearchContextValues,
} from "../OpenSearchConnector"
import { AccountTags } from "./AccountTags"
import { CurrencyTags } from "./CurrencyTags"
import { FilterTags } from "./FilterTags"
import { ListingTypeTags } from "./ListingTypeTags"
import { ClearFilterButton } from "./ClearFilter"
import { FilterPill } from "./FilterPill"

export const AllTags: React.FunctionComponent<{ isMobile?: boolean }> = ({
	isMobile = false,
}) => {
	const {
		paymentTokens,
		setSort,
		orderFilters,
		filters,
		setFilters,
		setOrderFilters,
		setPaymentTokens,
	} = useContext<OpenSearchContextValues>(OpenSearchContext)
	const location = useLocation()
	const isCollectionPage = location.pathname.includes("/collection")

	const navigate = useNavigate()

	const hasPaymentTokens = paymentTokens?.dapper || paymentTokens?.other

	const hasCollectionsFilters = Object.keys(filters).length > 0

	const traitsObject = Object.values(filters)?.[0] || []
	const hasCollectionFilterValues = isCollectionPage
		? Object.keys(traitsObject)?.length > 0
		: hasCollectionsFilters

	const hasOrders =
		orderFilters.loan || orderFilters.rental || orderFilters.storefront

	const shouldShowClearAll =
		hasCollectionFilterValues || hasOrders || hasPaymentTokens

	const handleClearFilters = () => {
		setFilters({})
		if (location.pathname.includes("/user")) {
			setSort({
				direction: "desc",
				listingKind: null,
				path: "latestBlock",
				prefix: "",
			})
		} else {
			setSort({
				direction: "desc",
				listingKind: null,
				path: "blockTimestamp",
				prefix: undefined,
			})
		}
		setPaymentTokens(null)
		setOrderFilters({ all: {} })
		navigate(location.pathname, { replace: true })
	}

	if (!shouldShowClearAll) return null
	return (
		<div className='flex flex-col lg:flex-row lg:items-start justify-start gap-[0.63rem] w-full pb-4'>
			<div className='flex gap-[.75rem] lg:gap-[8px] w-full overflow-x-auto sm:flex-wrap pb-1 lg:pb-0'>
				<AccountTags />
				<ListingTypeTags />
				<CurrencyTags />
				<FilterTags />
			</div>
			{isMobile ? (
				<ClearFilterButton onClick={handleClearFilters} />
			) : (
				<FilterPill
					text='Clear All'
					onClick={handleClearFilters}
					isClearAll={true}
				/>
			)}
		</div>
	)
}
