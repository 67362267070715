import numeral from "numeral"
import { useMemo } from "react"
import { STOREFRONT_ENABLED } from "../../../util/settings"
import { useViewport } from "../../BulkActions/BulkActionsPopUp/hooks/useViewport"
import { BulkSelectButton } from "../../BulkActions/BulkSelectButton"
import { AccountsFilter } from "../../Facets/AccountsFilter/AccountsFilter"
import { CollectionsFilter } from "../../Facets/CollectionsFilter/CollectionsFilter"
import { ListingTypeFilter } from "../../Facets/ListingTypeFilter/ListingTypeFilter"
import { SerialRangeFilter } from "../../Facets/SerialRangeFilter/SerialRangeFilter"
import { useOpenSearchContext } from "../../OpenSearch/OpenSearchConnector"
import { AllTags } from "../../OpenSearch/filters/AllTags"
import { SortMenu } from "../../OpenSearch/sorting/SortMenu"
import { SaveView } from "../../Sidebar/SaveView/SaveView"
import { DesktopControllers } from "./components/DesktopControllers/DesktopControllers"
import { HideFiltersBtn } from "./components/HideFiltersBtn/HideFiltersBtn"
import { MobileControllers } from "./components/MobileControllers.tsx/MobileControllers"

interface NftListControllersProps {
	address?: string
	nftList: React.ReactNode
}

export const NftListControllers: React.FunctionComponent<
	NftListControllersProps
> = ({ address, nftList }) => {
	const { matchedCount, isFilterHidden, setIsFilterHidden } =
		useOpenSearchContext()
	const { width } = useViewport()
	const breakpoint = 1200
	const renderMobile = width < breakpoint

	const displayCount = useMemo(() => {
		if (matchedCount < 10000) {
			return numeral(matchedCount).format("0,0")
		} else {
			return "10,000+"
		}
	}, [matchedCount])

	return (
		<div>
			{renderMobile ? (
				<MobileControllers
					mobilePageFilters={
						<>
							<CollectionsFilter />
							<SerialRangeFilter />
							<ListingTypeFilter />
							{STOREFRONT_ENABLED && <AccountsFilter />}
						</>
					}
					address={address}
				/>
			) : (
				<>
					<div className='lg:flex flex-row gap-3 justify-between pb-4 sticky top-[142px] z-20 bg-darkBg lg:bg-blur-navbar'>
						<div className='min-w-[340px] flex flex-row justify-between gap-3'>
							<HideFiltersBtn
								isFilterHidden={isFilterHidden}
								setIsFilterHidden={setIsFilterHidden}
							/>
							<SaveView />
						</div>
						<div className='w-full flex-row gap-3 justify-between'>
							<div className='flex flex-row justify-between gap-3'>
								<div className='items-center flex flow-row gap-3'>
									<SortMenu />
									<div className='font-montserrat flex items-center font-[700] flex-shrink-0 mr-2'>
										{`${displayCount} Items`}
									</div>
								</div>
								<BulkSelectButton />
							</div>
						</div>
					</div>
				</>
			)}
			<div className='flex flex-row gap-3'>
				{!renderMobile && (
					<div
						className={`${
							isFilterHidden
								? "hidden"
								: "lg:w-1/4 xl:w-min min-w-[340px] sticky top-[193px] h-full"
						}`}
					>
						<DesktopControllers isFilterHidden={isFilterHidden} />
					</div>
				)}
				<div className='w-full flex flex-col'>
					{!renderMobile && (
						<div className='flex-row gap-3 hidden lg:flex'>
							<AllTags />
						</div>
					)}
					{nftList}
				</div>
			</div>
		</div>
	)
}
