import { FirestoreTimestamp } from "../firebase"
import { FLOWTY_LISTING_SUSPENSION_TIME } from "./settings"
import { getImageURL } from "flowty-common"

export const getDurationPeriod = (term: number): string => {
	if (!term) {
		return "0 Days"
	}

	const termNumber: number = Number(term)
	if (termNumber / 60 < 60) {
		return `${(termNumber / 60).toFixed(2)} Mins`
	}
	if (termNumber / (60 * 60) < 24) {
		return `${(termNumber / (60 * 60)).toFixed(1)} Hrs`
	}
	const days = termNumber / (60 * 60 * 24)
	return `${days.toFixed(0)} ${days > 1 ? "Days" : "Day"}`
}

export const getListingActionTime = (
	blockTimestamp: Date | FirestoreTimestamp
): Date => {
	const timestamp =
		(blockTimestamp as FirestoreTimestamp)?.toDate?.()?.getTime?.() ||
		(blockTimestamp as Date)?.getTime?.() ||
		Number(blockTimestamp)
	return new Date(timestamp + FLOWTY_LISTING_SUSPENSION_TIME)
}

export const isRepayedPeriodExpired = (blockTimestamp: Date): boolean =>
	getListingActionTime(blockTimestamp) < new Date()

export const isNftActive = (blockTimestamp: Date): boolean =>
	getListingActionTime(blockTimestamp) < new Date()

// TODO: remove this function and use getImageURL from flowty-common
export const formatImageSource = (source: string): string => {
	return getImageURL(source)
}
