import { Config } from "../types"

export const getIsCollectionSetupScript = (
	config: Config,
	isNFTCatalog: boolean
): string => {
	if (isNFTCatalog && !config.crescendo) {
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		return _isCollectionSetupScript(config)
	}
	return config.crescendo
		? // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  _isNonCatalogCollectionSetupScriptCrescendo(config)
		: // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  _isNonCatalogCollectionSetupScript(config)
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const _isCollectionSetupScript = (config: Config): string => `
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.NonFungibleToken}

pub fun main(addr: Address, contractAddress: Address, contractName: String, isDapper: Bool): Bool {
  let acct = getAuthAccount(addr)

  let contractAcct = getAccount(contractAddress)
  let tmp = contractAcct.contracts.borrow<&NonFungibleToken>(name: contractName)
  if tmp == nil {
  	return true
	}
	
	let c = tmp!

  let nftTypeIdentifier = c.getType().identifier.concat(".NFT")
  let catalogIdentifiers = NFTCatalog.getCollectionsForType(nftTypeIdentifier: nftTypeIdentifier)
  if catalogIdentifiers == nil {
    return false
  }

  var catalogIdentifier = ""
  for k in catalogIdentifiers!.keys {
    if catalogIdentifiers![k] == true {
      catalogIdentifier = k
      break
    }
  }

  if catalogIdentifier == "" {
    return false
  }

  let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: catalogIdentifier) ?? panic("catalog entry not found")

  let publicPath = catalogEntry.collectionData.publicPath
  let storagePath = catalogEntry.collectionData.storagePath
  let privatePath = catalogEntry.collectionData.privatePath

  if acct.borrow<&AnyResource>(from: storagePath) == nil {
    return false
  }

  if !acct.getCapability<&{NonFungibleToken.CollectionPublic, MetadataViews.ResolverCollection}>(publicPath).check() { 
    return false
  }

  if !acct.getCapability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(privatePath).check() { 
    return false
  }

  return true
}`

// eslint-disable-next-line @typescript-eslint/naming-convention
const _isNonCatalogCollectionSetupScript = (config: Config): string => `
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.NonFungibleToken}
import ViewResolver from ${config.contractAddresses.NonFungibleToken}

pub fun main(addr: Address, contractAddress: Address, contractName: String): Bool {
  let acct = getAuthAccount(addr)

  let contractAcct = getAccount(contractAddress)
  let tmp = contractAcct.contracts.borrow<&NonFungibleToken>(name: contractName)
  if tmp == nil {
  	return true
	}
	
	let c = tmp!


  let contract = getAccount(contractAddress).contracts.borrow<&ViewResolver>(name: contractName) ?? panic ("Specified contract address and name is not found or does not implement ViewResolver contract.")
  let md = contract.resolveView(Type<MetadataViews.NFTCollectionData>()) ?? panic("NFTCollectionData view not found on the contract.")
  let collectionData = md as! MetadataViews.NFTCollectionData

  let publicPath = collectionData.publicPath
  let storagePath = collectionData.storagePath
  let privatePath = collectionData.providerPath

  if acct.borrow<&AnyResource>(from: storagePath) == nil {
    return false
  }

  if !acct.getCapability<&{NonFungibleToken.CollectionPublic, MetadataViews.ResolverCollection}>(publicPath).check() { 
    return false
  }

  if !acct.getCapability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(privatePath).check() { 
    return false
  }

  return true
}
`
// eslint-disable-next-line @typescript-eslint/naming-convention
const _isNonCatalogCollectionSetupScriptCrescendo = (config: Config): string =>
	`import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import ViewResolver from ${config.contractAddresses.ViewResolver}

access(all) fun main(addr: Address, contractAddress: Address, contractName: String): Bool {
    let acct = getAuthAccount<auth(Capabilities, Storage) &Account>(addr)

    let contractAcct = getAccount(contractAddress)
    let publicTypes = contractAcct.contracts.get(name: contractName)!.publicTypes()
    let nftResourceType = Type<@{NonFungibleToken.NFT}>()
    for pt in publicTypes {
        if !pt.isSubtype(of: nftResourceType) {
            continue
        }

        let vr = getAccount(contractAddress).contracts.borrow<&{ViewResolver}>(name: contractName) ?? panic ("Specified contract address and name is not found or does not implement ViewResolver contract.")
        let md = vr.resolveContractView(resourceType: nil, viewType: Type<MetadataViews.NFTCollectionData>()) ?? panic("NFTCollectionData view not found on the contract.")
        let collectionData = md as! MetadataViews.NFTCollectionData

        let publicPath = collectionData.publicPath
        let storagePath = collectionData.storagePath

        if acct.storage.borrow<&AnyResource>(from: storagePath) == nil {
            return false
        }

        if !acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(publicPath).check() { 
            return false
        }

        let targetType = Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()
        var hasProvider = false
        acct.capabilities.storage.forEachController(forPath: storagePath, fun(c: &StorageCapabilityController): Bool {
            if c.borrowType.isSubtype(of: targetType) {
                hasProvider = true
            }
            return true
        })
    }


    return true
}`
