/* eslint-disable @typescript-eslint/no-explicit-any */
import { safeParseJSON } from "flowty-common"
import { useLocation } from "react-router-dom"

export function useJsonQueryParameter<T = any>(paramKey: string): T | null {
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)

	return safeParseJSON(queryParams.get(paramKey)) as T | null
}
