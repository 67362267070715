// We need this eslint to allow label wrapping checkbox input, without label wrapping it, input on click doesn't work
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useMemo, useState } from "react"
import { cn } from "../../../../util/cn"
import {
	OpenSearchContext,
	OpenSearchContextValues,
} from "../../../OpenSearch/OpenSearchConnector"
import { CurrencyCheckbox } from "../CurrencyCheckbox/CurrencyCheckbox"
import { ReactComponent as DapperDUCIcon } from "../../../../assets/media/tokens/dapperDUC.svg"
import { ReactComponent as DapperFUTIcon } from "../../../../assets/media/tokens/dapperFUT.svg"
import dapperPNG from "../../../../assets/media/dapper_logo.png"
import { ReactComponent as OtherWalletsIcon } from "../../../../assets/media/tokens/stackedOtherWalletTokens.svg"
import { ReactComponent as USDCIcon } from "../../../../assets/media/tokens/newUsdc.svg"
import { ReactComponent as FlowIcon } from "../../../../assets/media/tokens/flow.svg"
import { actions as Mixpanel } from "../../../../util/Mixpanel"

const dapperFilter = {
	dapper: {
		DUC: true,
		FUT: true,
	},
}
const otherFilter = {
	other: {
		FLOW: true,
		USDC: true,
	},
}

interface AccountProps {
	name: string
	value: string
	disabled: boolean
	onChange: (value: "dapper" | "other" | "all") => void
	isChecked: boolean
	icon: React.ReactNode
	iconPosition: "right" | "left"
	collapsible: boolean
	label: string
	children?: React.ReactNode | React.ReactNode[]
}

const Account: React.FunctionComponent<AccountProps> = ({
	name,
	value,
	disabled,
	onChange,
	isChecked,
	icon,
	iconPosition,
	children,
	collapsible,
	label,
}) => {
	const [isOpen, setIsOpen] = useState(false)

	const baseStyles = `hidden h-5 w-5 text-primary focus:outline-none focus:ring-0 focus:ring-offset-0 cursor-pointer`

	const collapsibleChevron = useMemo(() => {
		return (
			<svg
				data-testid='account-chevron'
				className={cn("w-[0.875rem] h-[0.5rem]", {
					"bg-transparent": !isChecked,
					"border-none": isChecked,
					"rotate-0": !isOpen,
					"rotate-180": isOpen,
					"text-primary": isChecked,
				})}
				viewBox='0 0 14 8'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M0 0L7 8L14 0L0 0Z'
					fill={!isOpen ? "#F8F9FA" : "#04e5a3"}
				/>
			</svg>
		)
	}, [isChecked, isOpen])

	return (
		<div
			data-testid='account-option'
			className='cursor-pointer py-[0.75rem] w-full font-montserrat border-[1px] border-neutral-400 border-opacity-25 text-[0.875rem] font-[600] px-[1rem] rounded-[0.375rem] text-white bg-[#ffffff0a] hover:bg-[#ffffff1f] flex flex-col items-center justify-between gap-[0.375rem] shadow-[0_2px_4px_0px_rgba(0,0,0,0.1)]'
		>
			<div className='flex w-full items-center'>
				<label className='flex items-center w-[70%] justify-between cursor-pointer'>
					<div className='flex items-center w-full'>
						<input
							data-testid='wallet-radio'
							type='radio'
							name={name}
							value={value}
							disabled={disabled}
							checked={isChecked}
							onChange={e => {
								e.stopPropagation()
								if (!isOpen) {
									setIsOpen(true)
								}
								onChange(e.target.value as "dapper" | "other" | "all")
							}}
							className={baseStyles}
						/>
						<div className='flex items-center gap-[0.5rem]'>
							<div
								className={`w-5 h-5 rounded-full border-2 border-white relative ${
									isChecked
										? "bg-primary border-none flex items-center justify-center"
										: "bg-transparent!"
								}`}
							>
								<div
									className={`rounded-full p-[0.27rem] ${
										isChecked ? "bg-[#031021]" : "bg-transparent"
									}`}
								></div>
							</div>
							{iconPosition === "left" && icon}
							<span className='text-[0.875rem] font-montserrat'>{label}</span>
							{iconPosition === "right" && icon}
						</div>
					</div>
				</label>
				<div
					className='ml-auto'
					onClick={e => {
						e.stopPropagation()
						setIsOpen(!isOpen)
					}}
				>
					{collapsible && collapsibleChevron}
				</div>
			</div>
			{isOpen && children}
		</div>
	)
}

export const Accounts: React.FunctionComponent = () => {
	const { paymentTokens, setPaymentTokens } =
		useContext<OpenSearchContextValues>(OpenSearchContext)

	const [selectedAccount, setSelectedAccount] = useState<
		"all" | "dapper" | "other"
	>("all")

	const activeTokens = Object.values(paymentTokens ?? {}).filter(
		value => Object.keys(value ?? {}).length !== 0
	)

	useEffect(() => {
		if (!activeTokens.length) {
			setSelectedAccount("all")
			return
		}
	}, [activeTokens.length])

	useEffect(() => {
		if (JSON.stringify(paymentTokens?.dapper) === "{}") {
			setSelectedAccount("all")
			return
		}
		if (JSON.stringify(paymentTokens?.other) === "{}") {
			setSelectedAccount("all")
			return
		}
		if (paymentTokens?.dapper) {
			setSelectedAccount("dapper")
			return
		}
		if (paymentTokens?.other) {
			setSelectedAccount("other")
			return
		}
	}, [paymentTokens])

	const changeAccount = (passedAccount: "all" | "dapper" | "other"): void => {
		Mixpanel.track("Account type toggled", {
			accountType: passedAccount,
		})

		if (passedAccount === "all") {
			setSelectedAccount("all")
			setPaymentTokens(null)
		}

		if (passedAccount === "dapper") {
			setSelectedAccount("dapper")
			setPaymentTokens({
				dapper: { ...dapperFilter.dapper },
				other: undefined,
			})
		}
		if (passedAccount === "other") {
			setSelectedAccount("other")
			setPaymentTokens({
				dapper: undefined,
				other: { ...otherFilter.other },
			})
		}
	}

	return (
		<div className='space-y-2'>
			<Account
				name='account'
				value='all'
				label='Show All'
				disabled={false}
				onChange={changeAccount}
				isChecked={selectedAccount === "all"}
				icon={undefined}
				iconPosition={"right"}
				collapsible={false}
			/>
			<Account
				name='account'
				value='dapper'
				label='Dapper Wallet'
				disabled={false}
				onChange={changeAccount}
				isChecked={selectedAccount === "dapper"}
				icon={
					<img
						src={dapperPNG}
						alt='Dapper'
						className='w-[1.4rem] h-[1.25rem]'
					/>
				}
				iconPosition={"left"}
				collapsible
			>
				<div className='w-full'>
					<CurrencyCheckbox
						label='Dapper Balance'
						onChange={checkedState => {
							setSelectedAccount("dapper")
							if (checkedState) {
								setPaymentTokens({
									dapper: {
										...paymentTokens?.dapper,
										DUC: checkedState,
									},
									other: undefined,
								})
							} else {
								const copyTokens = { ...paymentTokens }

								delete copyTokens?.dapper?.DUC
								setPaymentTokens(copyTokens)
							}
						}}
						isChecked={!!paymentTokens?.dapper?.DUC}
						icon={<DapperDUCIcon />}
					/>
					<CurrencyCheckbox
						label='Dapper Flow'
						onChange={checkedState => {
							setSelectedAccount("dapper")
							if (checkedState) {
								setPaymentTokens({
									dapper: {
										...paymentTokens?.dapper,
										FUT: checkedState,
									},
									other: undefined,
								})
							} else {
								const copyTokens = { ...paymentTokens }

								delete copyTokens?.dapper?.FUT
								setPaymentTokens(copyTokens)
							}
						}}
						isChecked={!!paymentTokens?.dapper?.FUT}
						icon={<DapperFUTIcon />}
					/>
				</div>
			</Account>
			<Account
				name='account'
				value='other'
				label='Other Wallets'
				disabled={false}
				onChange={changeAccount}
				isChecked={selectedAccount === "other"}
				icon={<OtherWalletsIcon />}
				iconPosition={"left"}
				collapsible
			>
				<div className='w-full'>
					<CurrencyCheckbox
						label='USDC'
						onChange={checkedState => {
							setSelectedAccount("other")
							if (checkedState) {
								setPaymentTokens({
									dapper: undefined,
									other: {
										...paymentTokens?.other,
										USDC: checkedState,
									},
								})
							} else {
								const copyTokens = { ...paymentTokens }

								delete copyTokens?.other?.USDC
								setPaymentTokens(copyTokens)
							}
						}}
						isChecked={!!paymentTokens?.other?.USDC}
						icon={<USDCIcon />}
					/>
					<CurrencyCheckbox
						label='FLOW'
						onChange={checkedState => {
							setSelectedAccount("other")
							if (checkedState) {
								setPaymentTokens({
									dapper: undefined,
									other: {
										...paymentTokens?.other,
										FLOW: checkedState,
									},
								})
							} else {
								const copyTokens = { ...paymentTokens }

								delete copyTokens?.other?.FLOW
								setPaymentTokens(copyTokens)
							}
						}}
						isChecked={!!paymentTokens?.other?.FLOW}
						icon={<FlowIcon height={18} width={18} />}
					/>
				</div>
			</Account>
		</div>
	)
}
