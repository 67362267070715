import React, { useEffect, useMemo, useRef } from "react"
import { ReactComponent as FlowtyLogo } from "../../assets/flowty_logo.svg"
import { cn } from "../../utils/cn"
import { FlowtyNavbarProps } from "../FlowtyNavbar"
import { HelpPopover } from "./HelpPopover"
import { SignInButton } from "./SignInButton"
import { FlowtyUserNotifications } from "../../FlowtyUserNotifications"
import { NotificationData, NotificationType } from "flowty-common"

const NavBarLogoAndLinks = ({
	navItems,
}: {
	navItems: JSX.Element[]
}): JSX.Element => {
	return (
		<div className={cn("flex items-center flex-shrink-0")}>
			<a href='/' className='flex items-center'>
				<FlowtyLogo className='h-full w-28 mr-[16px] ml-[-6px]' />
			</a>
			<ul className={cn("flex list-none")}>{navItems}</ul>
			<HelpPopover
				className='py-[12px] px-[24px]'
				mixpanelFn={function (event: string): void {
					console.log("Mixpanel Help Provider")
				}}
			/>
		</div>
	)
}

const NavBarSignInAndNotifications = ({
	isLogged,
	loggedPopoverComponent,
	isLoading,
	logUser,
	fetchMoreNotifications,
	notifications,
	queueNotification,
	loadingNotifications,
	hasMore,
}: {
	isLogged: boolean
	loggedPopoverComponent: React.ReactNode
	notifications: NotificationData[]
	fetchMoreNotifications: () => void
	queueNotification?: ({
		type,
		id,
	}: {
		type: NotificationType
		id: string
	}) => void
	hasMore: boolean
	isLoading: boolean
	loadingNotifications: boolean
	logUser: () => void
}): JSX.Element => {
	const authComponent = useMemo(() => {
		if (!isLogged) {
			return <SignInButton isLoading={isLoading} logUser={logUser} />
		}
		if (isLogged && !isLoading) {
			return loggedPopoverComponent
		}
	}, [isLogged, isLoading, loggedPopoverComponent])

	return (
		<div
			className={cn(
				"flex items-center justify-items-end gap-[16px] flex-shrink-0 relative z-[999]"
			)}
		>
			{isLogged && !isLoading && (
				<FlowtyUserNotifications
					loading={loadingNotifications}
					hasMore={hasMore}
					fetchMoreNotifications={fetchMoreNotifications}
					notifications={notifications}
					queueMessage={queueNotification}
				/>
			)}
			{authComponent}
		</div>
	)
}

export const NavbarDesktop: React.FunctionComponent<FlowtyNavbarProps> = ({
	bgColor,
	isLogged = false,
	loggedPopoverComponent,
	authLoading,
	logUser,
	className,
	navItems,
	notifications,
	loadingNotifications,
	fetchMoreNotifications,
	hasMore,
	queueNotification,
	registerHeight,
}) => {
	const baseStyles = `${bgColor} bg-darkBg flex flex-col w-full items-center fixed top-0 left-1/2 -translate-x-1/2 z-40 font-black text-white py-[18px]`

	const navbarRef = useRef<HTMLDivElement | null>(null)

	const getNavbarHeight = () => {
		if (navbarRef.current && registerHeight) {
			registerHeight(navbarRef.current.offsetHeight)
		}
	}

	useEffect(() => {
		getNavbarHeight()

		const handleResize = () => {
			getNavbarHeight()
		}

		window.addEventListener("resize", handleResize)

		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [navbarRef, registerHeight])

	return (
		<div
			className={cn(baseStyles, (className = ""))}
			ref={navbarRef}
			data-testid='navbar-component'
		>
			<nav
				className={`px-12 flex justify-between items-center w-full gap-[16px] relative h-[40px]`}
			>
				<NavBarLogoAndLinks navItems={navItems} />
				<NavBarSignInAndNotifications
					loadingNotifications={loadingNotifications}
					hasMore={hasMore}
					fetchMoreNotifications={fetchMoreNotifications}
					notifications={notifications}
					queueNotification={queueNotification}
					isLoading={authLoading}
					isLogged={isLogged}
					logUser={logUser}
					loggedPopoverComponent={loggedPopoverComponent}
				/>
			</nav>
		</div>
	)
}
