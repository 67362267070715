import { Config } from "../types"

export const getHybridCustodyFTProvidersScript = (config: Config): string =>
	config.crescendo
		? // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  _hybridCustodyFTProvidersCrescendoScript(config)
		: // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  _hybridCustodyFTProvidersScript(config)

// eslint-disable-next-line @typescript-eslint/naming-convention
const _hybridCustodyFTProvidersCrescendoScript = (
	config: Config
): string => `import HybridCustody from ${config.contractAddresses.HybridCustody}
import FungibleToken from ${config.contractAddresses.FungibleToken}

// This script iterates through a parent's child accounts, 
// identifies private paths with an accessible FungibleToken.Provider, and returns the corresponding typeIds
access(all) fun main(addr: Address): {Address: {String: UInt64}}  {
  let account = getAuthAccount<auth(Capabilities, Storage) &Account>(addr)
  
  var typeIdsWithProvider = {} as {Address: {String: UInt64}} 

  if getAuthAccount<auth(Capabilities, Storage) &Account>(addr).storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath) == nil {
    return typeIdsWithProvider
  }

  let manager = getAuthAccount<auth(Capabilities, Storage) &Account>(addr).storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath) ?? panic ("manager does not exist")

  // Iterate through child accounts
  for address in manager.getChildAddresses() {
  	if address == addr {
      continue
    }
  
    let addr = getAuthAccount<auth(Capabilities, Storage) &Account>(address)
    let foundTypes: {String: UInt64} = {}
    let childAcct = manager.borrowAccount(addr: address) ?? panic("child account not found")

    let storagePaths: [StoragePath] = [
        /storage/flowTokenVault,
        /storage/fiatTokenVault,
        /storage/dapperUtilityCoinVault,
        /storage/flowUtilityTokenVault
    ]

    let targetType = Type<auth(FungibleToken.Withdraw) &{FungibleToken.Provider, FungibleToken.Balance, FungibleToken.Receiver}>()
    for s in storagePaths {
        addr.capabilities.storage.forEachController(forPath: s, fun(con: &StorageCapabilityController): Bool {
            if !con.borrowType.isSubtype(of: targetType){
                return true
            }

            if let cap = childAcct.getCapability(controllerID: con.capabilityID, type: targetType) {
                let providerCap = cap as! Capability<&{FungibleToken.Provider}> 

                if !providerCap.check(){
                return true
                }

                let resourceTypeIdentifier = cap.borrow<&AnyResource>()!.getType().identifier

                foundTypes[resourceTypeIdentifier] = con.capabilityID
            }

            return true
        })
    }

    typeIdsWithProvider[address] = foundTypes
  }

  return typeIdsWithProvider
}`

// eslint-disable-next-line @typescript-eslint/naming-convention
const _hybridCustodyFTProvidersScript = (
	config: Config
): string => `import HybridCustody from ${config.contractAddresses.HybridCustody}
import FungibleToken from ${config.contractAddresses.FungibleToken}

// This script iterates through a parent's child accounts, 
// identifies private paths with an accessible FungibleToken.Provider, and returns the corresponding typeIds
pub fun main(addr: Address): {Address: {String: Path}}  {
  let account = getAuthAccount(addr)
  
  var typeIdsWithProvider = {} as {Address: {String: Path}} 

  if getAuthAccount(addr).borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath) == nil {
    return typeIdsWithProvider
  }

  let manager = getAuthAccount(addr).borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath) ?? panic ("manager does not exist")

  
  let providerType = Type<Capability<&{FungibleToken.Provider}>>()

  // Iterate through child accounts
  for address in manager.getChildAddresses() {
  	if address == addr {
      continue
    }
  
    let addr = getAuthAccount(address)
    let foundTypes: {String: Path} = {}
    let childAcct = manager.borrowAccount(addr: address) ?? panic("child account not found")
    // get all private paths
    addr.forEachPrivate(fun (path: PrivatePath, type: Type): Bool {
      // Check which private paths have FT Provider AND can be borrowed
      if !type.isSubtype(of: providerType){
        return true
      }
      if let cap = childAcct.getCapability(path: path, type: Type<&{FungibleToken.Provider}>()) {
        let providerCap = cap as! Capability<&{FungibleToken.Provider}> 

        if !providerCap.check(){
          return true
        }

        let resourceTypeIdentifier = cap.borrow<&AnyResource>()!.getType().identifier

        foundTypes[resourceTypeIdentifier] = path
      }
      return true
    })

    typeIdsWithProvider[address] = foundTypes
  }

  return typeIdsWithProvider
}`
