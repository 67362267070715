import React, { useEffect } from "react"
import FlowtyMobile from "../assets/media/flowty_logo.svg"
import { actions as Mixpanel } from "../util/Mixpanel"
import { PageHeader } from "../components/PageHeader"
import Layout from "./Layout"

const links = [
	{
		description: "View all collections on Flowty.",
		href: "/marketplace",
		title: "Marketplace",
	},
	{
		description: "View live platform activity.",
		href: "/activity",
		title: "Activity",
	},
	{
		description: "Learn everything there is to know about Flowty's platform.",
		href: "https://docs.flowty.io/reference/",
		title: "Flowty FAQ",
	},
]

const metaTitle = "Flowty: This page does not exist"
const metaDescription =
	"Flowty is an open, full-featured NFT marketplace for collectors, traders, investors and creators on the Flow blockchain. Flowty's transaction capabilities range from buying and selling to NFT-backed loans and NFT rentals."
const metaImage = "https://www.flowty.io/icons/icon192.png"

const NotFound = () => {
	useEffect(() => {
		Mixpanel.track("404 page visited", { url: window.location.href })
	}, [])

	return (
		<Layout>
			<PageHeader
				metaTitle={metaTitle}
				metaDescription={metaDescription}
				metaImage={metaImage}
			/>
			<div className='bg-darkBg'>
				<main className='max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8'>
					<div className='max-w-xl mx-auto py-16 sm:py-24'>
						<div className='text-center'>
							<p className='text-sm font-semibold uppercase tracking-wide welcome'>
								404 error
							</p>
							<h1 className='mt-2 text-4xl font-extrabold text-gray-200 tracking-tight sm:text-5xl'>
								This page does not exist.
							</h1>
							<p className='mt-2 text-lg text-gray-400'>
								The page you are looking for could not be found.
							</p>
						</div>
						<div className='mt-12'>
							<h2 className='text-sm font-semibold text-gray-300 tracking-wide uppercase'>
								Popular pages
							</h2>
							<ul className='mt-4 border-t border-b border-gray-200 divide-y divide-gray-200'>
								{links.map(link => (
									<li
										key={link.title}
										className='relative py-6 flex items-start space-x-4'
									>
										<div className='min-w-0 flex-1'>
											<h3 className='text-base font-medium text-gray-400'>
												<span className='rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary'>
													<a
														href={link.href}
														className='focus:outline-none hover:text-primary'
													>
														<span
															className='absolute inset-0'
															aria-hidden='true'
														/>
														{link.title}
													</a>
												</span>
											</h3>
											<p className='text-base text-gray-300'>
												{link.description}
											</p>
										</div>
										<div className='flex-shrink-0 self-center'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												className='h-6 w-6'
												fill='none'
												viewBox='0 0 24 24'
												stroke='currentColor'
												strokeWidth={2}
											>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													d='M9 5l7 7-7 7'
												/>
											</svg>
										</div>
									</li>
								))}
							</ul>
							<div className='mt-8'>
								<a
									href='/'
									className='text-base font-medium welcome hover:text-green-600'
								>
									Or visit the Flowty landing page
									<span aria-hidden='true'> &rarr;</span>
								</a>
							</div>
						</div>
					</div>
				</main>
			</div>
		</Layout>
	)
}

export default NotFound
