import { TokenMetadata } from "flowty-common"
import { Config } from "../../types"

export const getRepayLoanTxn = (config: Config, token: TokenMetadata): string =>
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	config.crescendo
		? // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  repayLoanCrescendo(config, token)
		: // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  repayLoan(config, token)

const repayLoan = (
	config: Config,
	token: TokenMetadata
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import ${token.contractName} from ${token.contractAddress}
import Flowty from ${config.contractAddresses.Flowty}

import HybridCustody from ${config.contractAddresses.HybridCustody}

transaction(fundingResourceID: UInt64, flowtyMarketplaceAddress: Address, ftProviderAddress: Address, ftPrivatePathIdentifier: String) {
    let paymentVault: @FungibleToken.Vault
    let marketplace: &Flowty.FlowtyMarketplace{Flowty.FlowtyMarketplacePublic}
    let funding: &Flowty.Funding{Flowty.FundingPublic}

    prepare(acct: AuthAccount) {
        self.marketplace = getAccount(flowtyMarketplaceAddress)
            .getCapability<&Flowty.FlowtyMarketplace{Flowty.FlowtyMarketplacePublic}>(
                Flowty.FlowtyMarketplacePublicPath
            )
            .borrow()
            ?? panic("Could not borrow FlowtyMarketplace from provided address")

        self.funding = self.marketplace.borrowFunding(fundingResourceID: fundingResourceID)
            ?? panic("No Funding with that ID in FlowtyMarketplace")

        let repaymentAmount = self.funding.getDetails().repaymentAmount

        if ftProviderAddress == acct.address{
            let vault = acct.borrow<&${token.contractName}.Vault>(from: ${token.storagePath})
                ?? panic("Cannot borrow vault from acct storage")
            self.paymentVault <- vault.withdraw(amount: repaymentAmount)
        } else {
            let manager = acct.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("manager does not exist")
            let childAcct = manager.borrowAccount(addr: ftProviderAddress) ?? panic("ftProvider account not found")

            let FTPrivatePath = PrivatePath(identifier: ftPrivatePathIdentifier) ?? panic("no private path found")
            let providerCap = childAcct.getCapability(path: FTPrivatePath, type: Type<&{FungibleToken.Provider}>()) ?? panic("no cap found")
            let ftProvider = providerCap as! Capability<&{FungibleToken.Provider}>
            let ftProviderVault = ftProvider.borrow() ?? panic("no vault found")

            self.paymentVault <- ftProviderVault.withdraw(amount: repaymentAmount)
        }
    }

    execute {
        self.funding.repay(payment: <-self.paymentVault)
    }
}`

const repayLoanCrescendo = (
	config: Config,
	token: TokenMetadata
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import ${token.contractName} from ${token.contractAddress}
import Flowty from ${config.contractAddresses.Flowty}

import HybridCustody from ${config.contractAddresses.HybridCustody}

transaction(fundingResourceID: UInt64, flowtyMarketplaceAddress: Address, ftProviderAddress: Address, ftProviderControllerID: UInt64) {
    let paymentVault: @{FungibleToken.Vault}
    let marketplace: &{Flowty.FlowtyMarketplacePublic}
    let funding: &{Flowty.FundingPublic}

    prepare(acct: auth(Capabilities, Storage) &Account) {
        self.marketplace = getAccount(flowtyMarketplaceAddress)
            .capabilities.get<&{Flowty.FlowtyMarketplacePublic}>(
                Flowty.FlowtyMarketplacePublicPath
            )!
            .borrow()
            ?? panic("Could not borrow FlowtyMarketplace from provided address")

        self.funding = self.marketplace.borrowFunding(fundingResourceID: fundingResourceID)
            ?? panic("No Funding with that ID in FlowtyMarketplace")

        let repaymentAmount = self.funding.getDetails().repaymentAmount

        if ftProviderAddress == acct.address{
            let vault = acct.storage.borrow<auth(FungibleToken.Withdraw) &${token.contractName}.Vault>(from: ${token.storagePath})
                ?? panic("Cannot borrow vault from acct storage")
            self.paymentVault <- vault.withdraw(amount: repaymentAmount)
        } else {
            let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("manager does not exist")
            let childAcct = manager.borrowAccount(addr: ftProviderAddress) ?? panic("ftProvider account not found")

            let providerCap = childAcct.getCapability(controllerID: ftProviderControllerID, type: Type<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>()) ?? panic("no cap found")
            let ftProvider = providerCap as! Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>
            let ftProviderVault = ftProvider.borrow() ?? panic("no vault found")

            self.paymentVault <- ftProviderVault.withdraw(amount: repaymentAmount)
        }
    }

    execute {
        self.funding.repay(payment: <-self.paymentVault)
    }
}`
