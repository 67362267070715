import { Formik } from "formik"
import React from "react"
import { DropModalView } from "./DropModalView/DropModalView"
import { FlowtyDropModalContextProvider } from "./contexts/FlowtyDropModalContext"
import { SpotPrice } from "flowty-common"
import { Flowty } from "flowty-sdk"
import { FlowtyDropModalFormType } from "./types/FlowtyDropModalTypes"

export interface FlowtyDropModalProps {
	isOpen: boolean
	onClose: () => void
	contractAddress: string
	contractName: string
	dropID: string
	dropImage: string
	nftIdentifier: string
	phasePrice: number
	flowty: Flowty
	spotPrice: SpotPrice | null
	mintCount: number
	increaseMintCount: () => void
	decreaseMintCount: () => void
	updateMintCount: (value: number) => void
	showNoFundsMessage: boolean
	setShowNoFundsMessage: (value: boolean) => void
}

export const FlowtyDropModal: React.FunctionComponent<FlowtyDropModalProps> = ({
	isOpen,
	onClose,
	contractAddress,
	contractName,
	dropID,
	dropImage,
	nftIdentifier,
	phasePrice,
	flowty,
	spotPrice,
	mintCount,
	increaseMintCount,
	decreaseMintCount,
	updateMintCount,
	showNoFundsMessage,
	setShowNoFundsMessage,
}) => {
	return (
		<Formik
			initialValues={
				{
					mintCount: mintCount ?? 1,
				} as FlowtyDropModalFormType
			}
			onSubmit={() => {}}
			validate={() => {}}
		>
			{({ values, resetForm }) => {
				return (
					<FlowtyDropModalContextProvider
						onClose={onClose}
						resetForm={resetForm}
						updateMintCount={updateMintCount}
						showNoFundsMessage={showNoFundsMessage}
						setShowNoFundsMessage={setShowNoFundsMessage}
						values={values}
						contractAddress={contractAddress}
						contractName={contractName}
						dropID={dropID}
						phasePrice={phasePrice}
						mintCount={mintCount}
						nftIdentifier={nftIdentifier}
						flowty={flowty}
					>
						<DropModalView
							dropImage={dropImage}
							isOpen={isOpen}
							phasePrice={phasePrice}
							spotPrice={spotPrice}
							mintCount={mintCount}
							increaseMintCount={increaseMintCount}
							decreaseMintCount={decreaseMintCount}
							updateMintCount={updateMintCount}
						/>
					</FlowtyDropModalContextProvider>
				)
			}}
		</Formik>
	)
}
