import { Card, SupportedTokens, TOKEN_TO_READABLE_TOKEN } from "flowty-common"

export type ListingType = "loan" | "rental" | "storefront" | "purchase"

export const getDetails = (card?: Card) => {
	const nftID = card?.num

	if (card?.additionalDetails) {
		const additionalDetails = card?.additionalDetails.map((trait: string) =>
			trait.includes("#") ? trait.replace("#", "%23") : trait
		)
		return " " + additionalDetails.join(" ").trim()
	}

	if (!nftID) {
		return
	}

	const serial = `%23${nftID}`
	const serialNumber = !card?.title.includes("#") ? " " + serial : ""

	return serialNumber
}

export const formatAmount = (price: number, token: SupportedTokens) => {
	const hasDucToken = token?.includes("DUC")

	const salePrice = price
		? hasDucToken
			? `$${price.toLocaleString()}`
			: price.toLocaleString()
		: undefined

	const paymentToken = hasDucToken
		? ""
		: `${TOKEN_TO_READABLE_TOKEN?.[token as SupportedTokens] ?? ""} `

	return `${[salePrice, paymentToken].filter(Boolean).join(" ")}`
}

export const getDurationPeriod = (term: number) => {
	if (typeof term !== "number" || term <= 0) {
		return "0 Days"
	}
	if (term / 60 < 60) {
		return `${(term / 60).toFixed(2)} Mins`
	}
	const numDays = term / (60 * 60 * 24)
	return `${numDays} ${numDays > 1 ? "Days" : "Day"}`
}
