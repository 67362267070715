import React from "react"
import numeral from "numeral"
import { FlowtyTokenIcon } from "../../../FlowtyTokenIcon"
import { NotificationType } from "flowty-common"

export function getNotificationMessage({
	type,
	amount,
	title,
	token,
}: {
	type: NotificationType
	amount: number
	title: string
	token: string
}): React.ReactNode {
	const formattedTitle = (
		<strong className='text-[#DEDEDE] font-montserrat text-[14px] font-black'>
			{title}
		</strong>
	)

	const tokenIcon = <FlowtyTokenIcon token={token?.toLowerCase()} />

	const formattedAmount = (
		<span className='inline-flex items-center gap-[4px]'>
			{tokenIcon}
			<strong className='text-[#DEDEDE] font-montserrat text-[14px] font-black'>
				{numeral(amount).format("0,0.00")}
			</strong>
		</span>
	)
	// Add remaining Transaction types here
	switch (type) {
		case NotificationType.StorefrontPurchased:
			return (
				<span className='text-[#DEDEDE] font-montserrat text-[14px] font-[400] flex flex-row gap-[4px] items-center flex-wrap'>
					Sold {formattedTitle} for {formattedAmount}
				</span>
			)
		default:
			return null
	}
}
