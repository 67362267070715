import { FCLTransactionResult } from "flowty-common"
import { Err, sendMutation } from "../../utils/Utils"
import { DropMintProps } from "./DropMintTypes"
import { mintDrop } from "../transactions/mintDrop"
import { Config } from "../../types"

const fcl = require("@onflow/fcl")
const t = require("@onflow/types")

export class DropMint {
	config: Config

	constructor(config: Config) {
		this.config = config
	}

	mint = async ({
		contractAddress,
		contractName,
		numToMint,
		totalCost,
		paymentIdentifier,
		paymentStoragePath,
		paymentReceiverPath,
		dropID,
		dropPhaseIndex,
		nftIdentifier,
		commissionAddress,
		txAvailableCallback,
	}: DropMintProps): Promise<FCLTransactionResult> => {
		const txArgs = [
			fcl.arg(contractAddress, t.Address),
			fcl.arg(contractName, t.String),
			fcl.arg(numToMint.toString(), t.Int),
			fcl.arg(totalCost.toFixed(2), t.UFix64),
			fcl.arg(paymentIdentifier, t.String),
			fcl.arg(paymentStoragePath, t.String),
			fcl.arg(paymentReceiverPath, t.String),
			fcl.arg(dropID, t.UInt64),
			fcl.arg(dropPhaseIndex.toString(), t.Int),
			fcl.arg(nftIdentifier, t.String),
			fcl.arg(commissionAddress, t.Address),
		]

		const mintTx = mintDrop(
			this.config.contractAddresses.ViewResolver,
			this.config.contractAddresses.MetadataViews,
			this.config.contractAddresses.NonFungibleToken,
			this.config.contractAddresses.FungibleToken,
			this.config.contractAddresses.FlowtyDrops
		)
		try {
			const res = await sendMutation(mintTx, txArgs, txAvailableCallback)
			return res
		} catch (e) {
			console.log("TX ERROR", e)
			Err("mintDrop Error", e)
			throw e
		}
	}
}
