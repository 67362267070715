/* eslint-disable @typescript-eslint/no-explicit-any */
import { format } from "date-fns"
import noImage from "../../../../assets/media/image_not_available.svg"
import { formatImageSource } from "../../../../util/nftDataUtil"
import { ActivityTableImage } from "../../../Shared/ActivityTableFallbackImage/ActivityTableImage"
import { TokenAmount } from "../../../Tokens/tokenDisplays"
import { getTokenFromType } from "flowty-common"

export const userStorefrontActivityTableFields = [
	{
		customRender: ({ item }: any) => {
			const label = item.type.split("_").pop().toLowerCase()
			const eventType = `${
				(item.type.match(/offer/i) && "Offer ") ?? ""
			}${label}`

			return (
				<div className='flex flex-row justify-start items-center capitalize'>
					{eventType === "destroyed" ? "delisted" : eventType}
				</div>
			)
		},
		name: "type",
		sortable: false,
		title: "Event",
	},

	{
		customRender: ({ item }: any) => (
			<div
				className='flex flex-row justify-start items-center'
				data-testid={`row-${item.activityType}`}
			>
				{item.activityType}
			</div>
		),
		name: "data.buyer",
		sortable: false,
		title: "Role",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				{item.blockTimestamp
					? format(item.blockTimestamp.toDate(), "yyyy-MM-dd HH:mm")
					: ""}
			</div>
		),
		name: "blockTimestamp",
		sortable: false,
		title: "Date",
	},
	{
		customRender: ({ item }: any) => (
			<div>
				{!!item.additionalData?.card && (
					<div className='flex flex-row justify-start items-center'>
						<div className='w-[40px] mr-2'>
							<ActivityTableImage
								collectionAddress={
									item?.additionalData?.card?.collectionAddress || ""
								}
								collectionName={
									item?.additionalData?.card?.collectionName || ""
								}
								srcImg={formatImageSource(
									item?.additionalData?.card?.images?.[0]?.url ?? noImage
								)}
							/>
						</div>
						<div className='flex flex-col'>
							{item.additionalData?.card ? (
								<span className='text-[12px] text-primary font-montserrat font-extrabold truncate'>
									{item.additionalData?.card?.title}
								</span>
							) : (
								<>
									<span className='text-[12px] text-primary font-montserrat font-extrabold truncate'>
										{item.additionalData?.card?.title}
									</span>
									{item.additionalData?.card?.num &&
										!item.additionalData?.card?.title.includes("#") && (
											<span className='text-[12px] text-primary font-montserrat font-extrabold ml-2'>
												#{item.additionalData?.card?.num}
											</span>
										)}
								</>
							)}
							{item?.additionalData?.card?.additionalDetails ? (
								<>
									{item?.additionalData?.card?.additionalDetails ? (
										item?.additionalData?.card?.additionalDetails?.map(
											(detail: any) => (
												<span
													key={detail}
													className='font-montserrat text-primary text-[12px] font-light'
												>
													{detail}
												</span>
											)
										)
									) : (
										<div className='flex flex-row items-center justify-start mt-2 h-4'></div>
									)}
								</>
							) : (
								<>
									{item?.additionalData?.card?.headerTraits &&
										item?.additionalData?.card?.headerTraits?.map(
											(trait: any) => (
												<span
													key={trait.displayType}
													className='font-montserrat text-primary text-[12px] font-light'
												>
													{trait.value}
												</span>
											)
										)}
								</>
							)}
						</div>
					</div>
				)}
			</div>
		),
		name: "detail",
		sortable: false,
		title: "Details",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				<TokenAmount
					amount={Number(item?.amount || item?.data?.salePrice)}
					isSmall
					token={getTokenFromType(
						item?.data?.salePaymentVaultType || item?.data?.paymentTokenType
					)}
				/>
			</div>
		),
		name: "amount",
		sortable: false,
		title: "Amount",
	},
]
