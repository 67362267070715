import { Card, ChildAccountDisplay, Display, Path } from "flowty-common"

export type ContractNames =
	| "TransactionTypes"
	| "Doodles"
	| "ViewResolver"
	| "MetadataViews"
	| "NonFungibleToken"
	| "FungibleToken"
	| "FlowtyDrops"
	| "DropTypes"
	| "FlowtyUtils"
	| "DapperUtilityCoin"
	| "FlowUtilityToken"
	| "NFTStorefrontV2"
	| "NFTCatalog"
	| "HybridCustody"
	| "FlowToken"
	| "FiatToken"
	| "TokenForwarding"
	| "DapperWalletCollections"
	| "FlowtyWrapped"
	| "Flowty"
	| "TopShot"
	| "NFTStorefrontV2_Shared"
	| "StringUtils"
	| "OffersV2_Dapper"
	| "DapperOffersV2"
	| "Resolver"
	| "FlowtyOffersResolver"
	| "Offers"

export type Network = "mainnet" | "testnet" | "emulator"

export class Config {
	apiURL: string

	contractAddresses: Contracts

	network: "mainnet" | "testnet" | "emulator" | ""

	crescendo: boolean

	constructor(
		apiURL: string,
		contractAddresses: Contracts,
		network: Network,
		crescendo: boolean
	) {
		this.apiURL = apiURL
		this.contractAddresses = contractAddresses
		this.network = network
		this.crescendo = crescendo
	}

	getIdentifier(contractName: ContractNames, resourceName: string): string {
		return `A.${this.contractAddresses[contractName].substring(
			2
		)}.${contractName}.${resourceName}`
	}
}

export type Contracts = {
	[K in ContractNames]: string
}

export interface Fees {
	dapper: number
	flowty: number
	royalties: number
	seller: number
}

export interface WalletBalance {
	address: string
	balances?: { [key: string]: number }
}

export interface TokenSummary {
	balance: number | null
	receiverPath: Path | null
	providerPaths: Path[] | null
	type: string
}

export interface AccountSummary {
	address: string
	display: Display
	isDapper: boolean
	tokens: { [key: string]: TokenSummary }
	isMain: boolean
}

export type AccountSummaries = { [key: string]: AccountSummary }

export interface ChildAccountKV {
	[key: string]: ChildAccountDisplay
}

export interface NFTProviderStatus {
	[address: string]: {
		[data: string]: [Path]
	}
}

export interface FTProviderStatus {
	[address: string]: {
		[data: string]: Path
	}
}

export interface FTBalance {
	[data: string]: string
}

export interface FTBalances {
	[address: string]: [FTBalance]
}

export interface StorefrontListingRequest {
	nftProviderAddress: string
	nftProviderPathIdentifier: string
	nftTypeIdentifier: string
	nftID: string
	price: number
	customID: string | null
	expiry: number
	buyerAddress: string | null
	catalogCollection: boolean
	nftStoragePathIdentifier: string
}

export interface LoanRentalFilteredData {
	type: "loan" | "rental"
	listingResourceID?: string
	rentalResourceID?: string
	renterAddress?: string
	fundingResourceID?: string
	nftData: Card
	nftContractStoragePath: string
	nftType: string
	nftID: string
	paymentTokenName: string
	settleDeadline: number
	enabledAutoRepayment: boolean
	term: number
	loanAmount?: number
	repaymentDue?: number
	rentalFee?: number
	rentalRefundableDeposit?: number
	blockTimestamp?: number
}
