/* eslint-disable @typescript-eslint/no-use-before-define */

import {
	NFTLocationData,
	nftTypeAndIdToLocationData,
	TokenMetadata,
} from "flowty-common"
import { Config } from "../../types"
import { FlowNFTData } from "../../common/CommonTypes"

export const getLoanListingTransactionContent = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData
): string => {
	const locationData = nftTypeAndIdToLocationData(nftData.type, nftData.id)

	return config.crescendo
		? getLoanListingTransactionCrescendo(config, token, nftData, locationData)
		: getLoanListingTransaction(config, token, nftData)
}

export const getDelistLoanListingTransactionContent = (
	config: Config
): string =>
	config.crescendo
		? delistLoanListingTransactionCrescendo(config)
		: delistLoanListingTransaction(config)

const getLoanListingTransaction = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import ${token.contractName} from ${token.contractAddress}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import Flowty from ${config.contractAddresses.Flowty}
import ${nftData.contractName} from ${nftData.contractAddress}

import HybridCustody from ${config.contractAddresses.HybridCustody}

transaction(listItemID: UInt64, amount: UFix64, interestRate: UFix64, term: UFix64, autoRepaymentEnabled: Bool, loanExpiresAfter: UFix64, ftPrivatePathIdentifier: String, nftProviderPathIdentifier: String, collectionIdentifier: String, nftProviderAddress: Address, ftReceiverAddress: Address) {
    let tokenReceiver: Capability<&${token.contractName}.Vault{FungibleToken.Receiver}>
    let nftProvider: Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
    let storefront: &Flowty.FlowtyStorefront
    let nftReceiver: Capability<&{NonFungibleToken.CollectionPublic}>
    let paymentVault: @FungibleToken.Vault
    let tokenProvider: Capability<&{FungibleToken.Provider}>?

    prepare(acct: AuthAccount) { 
        if(acct.borrow<&Flowty.FlowtyStorefront>(from: Flowty.FlowtyStorefrontStoragePath) == nil) {
            // Create a new empty .Storefront
            let storefront <- Flowty.createStorefront() as! @Flowty.FlowtyStorefront

            // save it to the account
            acct.save(<-storefront, to: Flowty.FlowtyStorefrontStoragePath)
            // create a public capability for the .Storefront
            acct.link<&Flowty.FlowtyStorefront{Flowty.FlowtyStorefrontPublic}>(Flowty.FlowtyStorefrontPublicPath, target: Flowty.FlowtyStorefrontStoragePath)
        }

        let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

        // We need a provider capability, but one is not provided by default so we create one if needed.
        let publicCollectionPath = catalogEntry.collectionData.publicPath
        let storageCollectionPath = catalogEntry.collectionData.storagePath

        if autoRepaymentEnabled {
            if ftReceiverAddress == acct.address {
                let flowtyFtProviderPath = /private/${token.contractName}${token.contractAddress}FtProviderForFlowty

                if !acct.getCapability<&${token.contractName}.Vault{FungibleToken.Provider}>(flowtyFtProviderPath).check() {
                  acct.unlink(flowtyFtProviderPath)
                  acct.link<&${token.contractName}.Vault{FungibleToken.Provider}>(flowtyFtProviderPath, target: ${token.storagePath})
                }
                self.tokenProvider = acct.getCapability<&${token.contractName}.Vault{FungibleToken.Provider}>(flowtyFtProviderPath)
            } else {
                let manager = acct.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

                let child = manager.borrowAccount(addr: ftReceiverAddress) ?? panic("no child account with that address")
                let tokenProviderPrivatePath = PrivatePath(identifier: ftPrivatePathIdentifier) ?? panic("invalid private path")
                let ftProviderCap = child.getCapability(path: tokenProviderPrivatePath, type: Type<&{FungibleToken.Provider}>()) ?? panic("no ft provider found")
                self.tokenProvider = ftProviderCap as! Capability<&{FungibleToken.Provider}>?
            }

            assert(self.tokenProvider!.check(), message: "Missing or mis-typed provider")
        } else {
            self.tokenProvider = nil
        }

        if ftReceiverAddress == acct.address {
            if acct.borrow<&${token.contractName}.Vault>(from: ${token.storagePath}) == nil {
                acct.save(<-${token.contractName}.createEmptyVault(), to: ${token.storagePath})
                acct.link<&${token.contractName}.Vault{FungibleToken.Receiver}>(
                    ${token.receiverPath},
                    target: ${token.storagePath}
                )
                acct.link<&${token.contractName}.Vault{FungibleToken.Balance}>(
                    ${token.balancePath},
                    target: ${token.storagePath}
                )
            }

            self.tokenReceiver = acct.getCapability<&${token.contractName}.Vault{FungibleToken.Receiver}>(${token.receiverPath})!
        } else {
            let manager = acct.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: ftReceiverAddress) ?? panic("no child account with that address")
            self.tokenReceiver = getAccount(ftReceiverAddress).getCapability<&${token.contractName}.Vault{FungibleToken.Receiver}>(${token.receiverPath})
        }

        assert(self.tokenReceiver.check(), message: "Missing or mis-typed token receiver")

        if nftProviderAddress == acct.address {
            let flowtyNftCollectionProviderPath = /private/${nftData.contractName}${nftData.contractAddress}CollectionProviderForFlowty

            if !acct.getCapability<&${nftData.contractName}.Collection{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(flowtyNftCollectionProviderPath).check() {
                acct.unlink(flowtyNftCollectionProviderPath)
                acct.link<&${nftData.contractName}.Collection{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(flowtyNftCollectionProviderPath, target: storageCollectionPath)
            }

            if !acct.getCapability<&{NonFungibleToken.CollectionPublic}>(publicCollectionPath)!.check() {
                acct.unlink(publicCollectionPath)
                acct.link<&{NonFungibleToken.CollectionPublic, MetadataViews.ResolverCollection, NonFungibleToken.Receiver}>(publicCollectionPath, target: storageCollectionPath)
            }

            self.nftProvider = acct.getCapability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(flowtyNftCollectionProviderPath)
            self.nftReceiver = acct.getCapability<&{NonFungibleToken.CollectionPublic}>(publicCollectionPath)
        } else {
            let collectionProviderPrivatePath = PrivatePath(identifier: nftProviderPathIdentifier) ?? panic("invalid provider path identifier")

            let manager = acct.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: nftProviderAddress) ?? panic("no child account with that address")

            let providerCap = child.getCapability(path: collectionProviderPrivatePath, type: Type<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()) ?? panic("no nft provider found")
            self.nftProvider = providerCap as! Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>

            let receiverCap = child.getCapability(path: publicCollectionPath, type: Type<&{NonFungibleToken.CollectionPublic}>()) ?? panic("no nft collection public found")
            self.nftReceiver = receiverCap as! Capability<&{NonFungibleToken.CollectionPublic}>
        }

        assert(self.nftProvider.check(), message: "Missing or mis-typed NFT Provider")
        assert(self.nftReceiver.check(), message: "Missing or mis-typed NFT Receiver")

        self.storefront = acct.borrow<&Flowty.FlowtyStorefront>(from: Flowty.FlowtyStorefrontStoragePath)
            ?? panic("Missing or mis-typed Flowty FlowtyStorefront")

        let tokenVault = acct.borrow<&${token.contractName}.Vault>(from: ${token.storagePath})
            ?? panic("Cannot borrow token vault from acct storage")

        self.paymentVault <- tokenVault.withdraw(amount: Flowty.ListingFee)
    }

    execute {
        let paymentCut = Flowty.PaymentCut(
            receiver: self.tokenReceiver,
            amount: amount
        )
        self.storefront.createListing(
            payment: <-self.paymentVault,
            nftProviderCapability: self.nftProvider,
            nftPublicCollectionCapability: self.nftReceiver,
            fusdProviderCapability: self.tokenProvider,
            nftType: Type<@${nftData.contractName}.NFT>(),
            nftID: listItemID,
            amount: amount,
            interestRate: interestRate,
            term: term,
            paymentVaultType: Type<@${token.contractName}.Vault>(),
            paymentCuts: [paymentCut],
            expiresAfter: loanExpiresAfter
        )
    }
}`

const getLoanListingTransactionCrescendo = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData,
	locationData: NFTLocationData
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import ${token.contractName} from ${token.contractAddress}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import Flowty from ${config.contractAddresses.Flowty}
import ${nftData.contractName} from ${nftData.contractAddress}

import HybridCustody from ${config.contractAddresses.HybridCustody}

transaction(
    listItemID: UInt64,
    amount: UFix64,
    interestRate: UFix64,
    term: UFix64,
    autoRepaymentEnabled: Bool,
    loanExpiresAfter: UFix64,
    ftProviderControllerID: UInt64,
    nftProviderControllerID: UInt64,
    collectionIdentifier: String,
    nftProviderAddress: Address,
    ftReceiverAddress: Address
) {
    let tokenReceiver: Capability<&{FungibleToken.Receiver}>
    let nftProvider: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
    let storefront: auth(Flowty.List) &Flowty.FlowtyStorefront
    let nftReceiver: Capability<&{NonFungibleToken.CollectionPublic}>
    let paymentVault: @{FungibleToken.Vault}
    let tokenProvider: Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>?

    prepare(acct: auth(Storage, Capabilities) &Account) { 
        if(acct.storage.borrow<&Flowty.FlowtyStorefront>(from: Flowty.FlowtyStorefrontStoragePath) == nil) {
            // Create a new empty .Storefront
            let storefront <- Flowty.createStorefront() as! @Flowty.FlowtyStorefront

            // save it to the account
            acct.storage.save(<-storefront, to: Flowty.FlowtyStorefrontStoragePath)
            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{Flowty.FlowtyStorefrontPublic}>(Flowty.FlowtyStorefrontStoragePath),
                at: Flowty.FlowtyStorefrontPublicPath
            )
        }

        let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

        // We need a provider capability, but one is not provided by default so we create one if needed.
        let publicCollectionPath = catalogEntry.collectionData.publicPath
        let storageCollectionPath = catalogEntry.collectionData.storagePath

        if autoRepaymentEnabled {
            if ftReceiverAddress == acct.address {
                self.tokenProvider = acct.capabilities.storage.issue<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>(${token.storagePath})
            } else {
                let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

                let child = manager.borrowAccount(addr: ftReceiverAddress) ?? panic("no child account with that address")
                let ftProviderCap = child.getCapability(controllerID: ftProviderControllerID, type: Type<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>()) ?? panic("no ft provider found")
                self.tokenProvider = ftProviderCap as! Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>?
            }

            assert(self.tokenProvider!.check(), message: "Missing or mis-typed token provider")
        } else {
            self.tokenProvider = nil
        }

        if ftReceiverAddress == acct.address {
            if acct.storage.borrow<&${token.contractName}.Vault>(from: ${token.storagePath}) == nil {
                acct.storage.save(<-${token.contractName}.createEmptyVault(vaultType: Type<@${token.contractName}.Vault>()), to: ${token.storagePath})
                acct.capabilities.publish(
                    acct.capabilities.storage.issue<&{FungibleToken.Receiver}>(${token.storagePath}),
                    at: ${token.receiverPath}
                )

                acct.capabilities.publish(
                    acct.capabilities.storage.issue<&{FungibleToken.Balance}>(${token.storagePath}),
                    at: ${token.balancePath}
                )
            }

            self.tokenReceiver = acct.capabilities.get<&{FungibleToken.Receiver}>(${token.receiverPath})!
        } else {
            let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: ftReceiverAddress) ?? panic("no child account with that address")
            self.tokenReceiver = getAccount(ftReceiverAddress).capabilities.get<&{FungibleToken.Receiver}>(${token.receiverPath})!
        }

        assert(self.tokenReceiver.check(), message: "Missing or mis-typed token receiver")

        if nftProviderAddress == acct.address {
            if !acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(publicCollectionPath).check() {
                acct.capabilities.unpublish(publicCollectionPath)

                acct.capabilities.publish(
                    acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(storageCollectionPath),
                    at: publicCollectionPath
                )
            }

            self.nftProvider = acct.capabilities.storage.issue<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(catalogEntry.collectionData.storagePath)
            self.nftReceiver = acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(publicCollectionPath)!
        } else {
            let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: nftProviderAddress) ?? panic("no child account with that address")

            let providerCap = child.getCapability(controllerID: nftProviderControllerID, type: Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()) ?? panic("no nft provider found")
            self.nftProvider = providerCap as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>

            let receiverCap = child.getPublicCapability(path: publicCollectionPath, type: Type<&{NonFungibleToken.CollectionPublic}>()) ?? panic("no nft collection public found")
            self.nftReceiver = receiverCap as! Capability<&{NonFungibleToken.CollectionPublic}>
        }

        assert(self.nftProvider.check(), message: "Missing or mis-typed NFT Provider")
        assert(self.nftReceiver.check(), message: "Missing or mis-typed NFT Receiver")

        self.storefront = acct.storage.borrow<auth(Flowty.List) &Flowty.FlowtyStorefront>(from: Flowty.FlowtyStorefrontStoragePath)
            ?? panic("Missing or mis-typed Flowty FlowtyStorefront")

        let tokenVault = acct.storage.borrow<auth(FungibleToken.Withdraw) &${token.contractName}.Vault>(from: ${token.storagePath})
            ?? panic("Cannot borrow token vault from acct storage")

        self.paymentVault <- tokenVault.withdraw(amount: Flowty.ListingFee)
    }

    execute {
        let paymentCut = Flowty.PaymentCut(
            receiver: self.tokenReceiver,
            amount: amount
        )
        self.storefront.createListing(
            payment: <-self.paymentVault,
            nftProviderCapability: self.nftProvider,
            nftPublicCollectionCapability: self.nftReceiver,
            fusdProviderCapability: self.tokenProvider,
            nftType: Type<@${nftData.contractName}.${locationData.resourceName}>(),
            nftID: listItemID,
            amount: amount,
            interestRate: interestRate,
            term: term,
            paymentVaultType: Type<@${token.contractName}.Vault>(),
            paymentCuts: [paymentCut],
            expiresAfter: loanExpiresAfter
        )
    }
}`

const delistLoanListingTransaction = (
	config: Config
): string => `import Flowty from ${config.contractAddresses.Flowty}

transaction(listingResourceID: UInt64) {
	let storefront: &Flowty.FlowtyStorefront{Flowty.FlowtyStorefrontManager}

	prepare(acct: AuthAccount) {
		self.storefront = acct.borrow<&Flowty.FlowtyStorefront{Flowty.FlowtyStorefrontManager}>(from: Flowty.FlowtyStorefrontStoragePath)
			?? panic("Missing or mis-typed Flowty.FlowtyStorefront")
	}

	execute {
		self.storefront.removeListing(listingResourceID: listingResourceID)
	}
}`

const delistLoanListingTransactionCrescendo = (
	config: Config
): string => `import Flowty from ${config.contractAddresses.Flowty}

transaction(listingResourceID: UInt64) {
	let storefront: auth(Flowty.List, Flowty.Cancel) &{Flowty.FlowtyStorefrontManager}

	prepare(acct: auth(Storage) &Account) {
		self.storefront = acct.storage.borrow<auth(Flowty.List, Flowty.Cancel) &{Flowty.FlowtyStorefrontManager}>(from: Flowty.FlowtyStorefrontStoragePath)
			?? panic("Missing or mis-typed Flowty.FlowtyStorefront")
	}

	execute {
		self.storefront.removeListing(listingResourceID: listingResourceID)
	}
}`
