/* eslint-disable @typescript-eslint/no-explicit-any */
import { format } from "date-fns"
import { Trait } from "flowty-common"
import { SortableTableField } from ".."
import noImage from "../../../assets/media/image_not_available.svg"
import { formatImageSource, getDurationPeriod } from "../../../util/nftDataUtil"
import { ActivityItem } from "../../Shared/ActivityTableFields"
import { TokenAmount } from "../../Tokens/tokenDisplays"

const collectionRentalActivityFields: Array<SortableTableField<ActivityItem>> =
	[
		{
			customRender: ({ item }) => {
				const eventType = item?.type?.split("_").pop()?.toLowerCase() ?? ""

				return (
					<div className='flex flex-row justify-start items-center capitalize'>
						{eventType === "destroyed" ? "delisted" : eventType}
					</div>
				)
			},
			name: "type",
			sortable: false,
			title: "Event",
		},
		{
			customRender: ({ item }) => (
				<div className='flex flex-row justify-start items-center'>
					{format(item?.blockTimestamp?.toDate(), "yyyy-MM-dd HH:mm")}
				</div>
			),
			name: "blockTimestamp",
			sortable: true,
			title: "Date",
		},
		{
			customRender: ({ item }) => (
				<div>
					{!!item.additionalData?.card && (
						<div className='flex flex-row justify-start items-center'>
							<div className='w-[40px] mr-2'>
								<img
									src={formatImageSource(
										item?.additionalData?.card?.images?.[0]?.url ?? noImage
									)}
									alt={`${item?.card?.title} asset`}
									className='object-cover aspect-square rounded-[4px]'
								/>
							</div>
							<div className='flex flex-col'>
								{item?.additionalData?.card?.additionalDetails ? (
									<span className='text-[12px] text-primary font-montserrat font-extrabold truncate'>
										{item?.additionalData?.card?.title}
									</span>
								) : (
									<>
										<span className='text-[12px] text-primary font-montserrat font-extrabold truncate'>
											{item?.additionalData?.card?.title}
										</span>
										{item?.additionalData?.card?.num &&
											!item.additionalData?.card?.title.includes("#") && (
												<span className='text-[12px] text-primary font-montserrat font-extrabold ml-2'>
													#{item?.additionalData?.card?.num}
												</span>
											)}
									</>
								)}
								{item?.additionalData?.card?.additionalDetails ? (
									<>
										{item?.additionalData?.card?.additionalDetails ? (
											item?.additionalData?.card?.additionalDetails?.map(
												(detail: string) => (
													<span
														key={detail}
														className='font-montserrat text-primary text-[12px] font-light'
													>
														{detail}
													</span>
												)
											)
										) : (
											<div className='flex flex-row items-center justify-start mt-2 h-4'></div>
										)}
									</>
								) : (
									<>
										{item?.additionalData?.card?.headerTraits &&
											item?.additionalData?.card?.headerTraits?.map(
												(trait: Trait) => (
													<span
														key={trait?.displayType}
														className='font-montserrat text-primary text-[12px] font-light'
													>
														{trait?.value}
													</span>
												)
											)}
									</>
								)}
							</div>
						</div>
					)}
				</div>
			),
			name: "detail",
			sortable: false,
			title: "Details",
		},
		{
			customRender: ({ item }: any) => (
				<div className='flex flex-row justify-start items-center'>
					<TokenAmount
						amount={Number(item?.additionalData?.amount)}
						isSmall
						token={item?.data.paymentTokenName}
					/>
				</div>
			),
			name: "amount",
			sortable: false,
			title: "Rental Fee",
		},
		{
			customRender: ({ item }: any) => (
				<div className='flex flex-row justify-start items-center'>
					<TokenAmount
						amount={Number(item?.additionalData?.deposit)}
						isSmall
						token={item?.data?.paymentTokenName}
					/>
				</div>
			),
			name: "deposit",
			sortable: false,
			title: "Returnable Deposit",
		},
		{
			customRender: ({ item }: any) => (
				<div className='flex flex-row justify-start items-center'>
					{getDurationPeriod(item?.additionalData?.term)}
				</div>
			),
			name: "duration",
			sortable: false,
			title: "Duration",
		},
		{
			customRender: ({ item }: any) => (
				<div className='flex flex-row justify-start items-center'>
					{item?.additionalData?.depositToValueRatio
						? `${item?.additionalData?.depositToValueRatio?.toFixed(2)}x`
						: "N/A"}
				</div>
			),
			name: "duration",
			sortable: false,
			title: "3rd Party DTV",
		},
	]

export default collectionRentalActivityFields
