import { Listbox } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { FlowtyTokenIcon } from "../FlowtyTokenIcon"
import { useFlowtyListboxTokenSelector } from "./hooks/useFlowtyListboxTokenSelector"
import { AccountSummary, Flowty } from "flowty-sdk"

interface ListBoxTokenSelectorProps {
	name: string
	orderType: "loan" | "rental" | "storefront" | "offer"
	selectedAccount: AccountSummary
	isDapper: boolean
	flowty: Flowty
}

export const FlowtyListBoxTokenSelector: React.FunctionComponent<
	ListBoxTokenSelectorProps
> = ({ name, selectedAccount, isDapper, orderType, flowty }) => {
	const { supportedTokensMap, field, defaultToken, allTokens } =
		useFlowtyListboxTokenSelector({
			flowty,
			isDapper,
			name,
			orderType,
			selectedAccount,
		})

	return (
		<div className='w-full'>
			<Listbox
				value={field.value || defaultToken}
				onChange={(value: string) => {
					field.onChange({ target: { name, value } })
				}}
			>
				<div className='relative'>
					<Listbox.Button
						className='w-full text-[12px] font-bold'
						data-testid='token-selector'
					>
						{({ open }) => (
							<div
								className={`w-full flex justify-between font-bold h-[37px] font-montserrat-bold bg-[#606E7D33] border border-[#FFFFFF1F] hover:bg-[#606E7D4D] rounded-md p-4 items-center group gap-2 text-[#F8F9FA] ${
									open && "border-[#FFFFFF7A]"
								}`}
							>
								<div className='flex flex-row items-center'>
									<FlowtyTokenIcon token={field.value || defaultToken} />
									<span
										className={`whitespace-nowrap font-bold font-montserrat-bold h-full flex justify-center items-center group-hover:text-white pl-2`}
									>
										{getTokenDisplayName(field.value || defaultToken)}
									</span>
								</div>

								<ChevronDownIcon
									className={`h-7 w-7 transition-transform duration-300  ${
										open && "rotate-180 transform"
									}`}
								/>
							</div>
						)}
					</Listbox.Button>
					<Listbox.Options className='absolute z-10 bg-[#232D39] rounded-md mt-1 text-[#F8F9FA] border border-[#FFFFFF7A] overflow-hidden w-full p-2 text-[12px] font-bold'>
						{allTokens.map(token => {
							const isSupported = supportedTokensMap[token]
							return isSupported ? (
								<Listbox.Option
									key={token}
									value={token}
									disabled={false}
									className='hover:bg-[#6C757D40] hover:text-white cursor-pointer transition-all ease-in-out duration-300 w-full py-1 flex flex-col items-end rounded-md'
								>
									<div
										className='flex w-full px-3 items-center'
										data-testid={`${token}-token-selector`}
									>
										<FlowtyTokenIcon token={token} />
										<span className='grow p-2 font-bold font-montserrat-bold text-nowrap'>
											{getTokenDisplayName(token)}
										</span>
									</div>
								</Listbox.Option>
							) : null
						})}
					</Listbox.Options>
				</div>
			</Listbox>
		</div>
	)
}

function getTokenDisplayName(token: string): string {
	switch (token) {
		case "DUC":
			return "Dapper Balance"
		case "FUT":
			return "Dapper FLOW"
		default:
			return token
	}
}
