import BackgroundSquares from "./SVGs/BackgroundSquares.png"
import { ReactComponent as LongArrow } from "./SVGs/LongArrow.svg"
import { ReactComponent as GradientStar } from "./SVGs/GradientStar.svg"
import AllDay from "./SVGs/AllDay.png"
import Doodles from "./SVGs/doodles.png"
import JollyJokers from "./SVGs/JollyJokers.png"
import Ufc from "./SVGs/ufc.png"
import TopShot from "./SVGs/TopShot.png"
import Verified from "./SVGs/Verified.png"
import GrayHover from "./SVGs/GrayHover.svg"

export const LandingPageHead: React.FC = () => {
	const FeaturedCollections = [
		{
			image: TopShot,
			url: "/collection/0x0b2a3299cc857e29/TopShot",
		},
		{
			image: AllDay,
			url: "/collection/0xe4cf4bdc1751c65d/AllDay",
		},
		{
			image: Ufc,
			url: "/collection/0x329feb3ab062d289/UFC_NFT",
		},
		{
			image: Doodles,
			url: "/collection/0xe81193c424cfd3fb/Doodles",
		},
		{
			image: JollyJokers,
			url: "/collection/0x699bf284101a76f1/JollyJokers",
		},
	]

	return (
		<div
			style={{
				backgroundImage: `url(${BackgroundSquares})`,
			}}
			className={`bg-cover bg-center bg-no-repeat flex flex-col justify-center items-center container pt-[40px] pb-[76px] relative z-10 overflow-hidden`}
		>
			<div className='flex flex-col'>
				<h2
					className='text-center font-montserrat text-[40px] lg:text-[64px] font-extrabold pb-[3px] md:pb-[12] lg:pb-[18px] leading-normal'
					style={{
						WebkitBackgroundClip: "text",
						WebkitTextFillColor: "transparent",
						background: "linear-gradient(90deg, #FFF 0%, #AEAEAE 100%)",
						backgroundClip: "text",
						lineHeight: "normal !important",
					}}
				>
					Buy, Sell,{" "}
					<div className='block md:inline'>
						{" "}
						<span className='font-montserrat text-[40px] lg:text-[64px] font-extrabold leading-normal'>
							Rent,
						</span>{" "}
						<span className='font-montserrat text-[40px] lg:text-[64px] font-normal leading-normal'>
							&
						</span>{" "}
						<span className='font-montserrat text-[40px] lg:text-[64px] font-extrabold leading-normal'>
							Loan
						</span>
					</div>
				</h2>
				<div className='mb-[3px] md:mb-[12] lg:mb-[18px] flex flex-row gap-[2px] md:gap-[4px] justify-center text-center'>
					<h1
						className='text-center font-montserrat text-[12px] lg:text-[18px] font-medium leading-normal'
						style={{
							WebkitBackgroundClip: "text",
							WebkitTextFillColor: "transparent",
							background: "linear-gradient(90deg, #AA4FFF 0%, #40FFC7 100%)",
							backgroundClip: "text",
						}}
					>
						on the highest volume marketplace for Flow NFTs
					</h1>
					<GradientStar />
				</div>
				<h4 className='text-center leading-normal font-montserrat text-[#ADB5BD] text-[12px] lg:text-[16px] font-normal'>
					In 2023, the{" "}
					<span className='text-center leading-normal font-montserrat text-[#ADB5BD] text-[12px] lg:text-[16px] font-bold'>
						top 5 sales
					</span>{" "}
					across the entire Flow Blockchain took place on Flowty
				</h4>
			</div>
			<div className='mt-[28px] md:mt-[50px] lg:mt-[80px]'>
				<div className='flex flex-row justify-center flex-wrap gap-[18px] md:gap-[15px] lg:gap-x-[24px]'>
					{FeaturedCollections.map(collection => (
						<a
							href={collection.url}
							key={collection.url}
							className='custom-bg-collections-landing rounded-[8px] border-[#343A40] hover:border-[#5E5E5E] backdrop-blur-xl w-[90px] h-[90px] md:w-[80px] md:h-[80px] lg:w-[124px] lg:h-[124px] p-[12px]'
						>
							<img
								src={collection.image}
								alt={collection.url}
								className='w-full h-full'
							/>
						</a>
					))}
				</div>
				<a
					href='/marketplace'
					className='flex flex-row justify-center items-center gap-[8px] mt-[24px] cursor-pointer'
				>
					<img
						src={Verified}
						alt='Flowty Verified Collection'
						className='w-[16px] h-[16px] lg:w-[24px] lg:h-[24px]'
					/>
					<span className='text-[#F8F9FA] font-montserrat leading-normal text-[14px] sm:text-[18px] font-semibold'>
						View 200+ verified TopCollections
					</span>
					<LongArrow />
				</a>
			</div>
			<img
				src={GrayHover}
				alt='Gray Hover'
				className='h-[640px] absolute bottom-[-150px] md:bottom-[-320px] rounded-[1177px] -z-50'
				style={{
					background: `radial-gradient(50% 50% at 50% 50%, rgba(157, 157, 157, 0.19) 0%, rgba(3, 16, 33, 0.10) 99.99%, rgba(3, 16, 33, 0.00) 100%)`,
				}}
			/>
		</div>
	)
}
