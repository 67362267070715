import { Menu, Popover, Transition } from "@headlessui/react"
import { Fragment, useEffect, useRef, useState } from "react"
import { ReactComponent as ExternalLink } from "../../assets/external-link-white.svg"
import { ReactComponent as ExternalLinkDark } from "../../assets/external-link-dark.svg"
import { ReactComponent as ChevronUp } from "../../assets/chevron-up.svg"
import { ReactComponent as Wrench } from "../../assets/wrench.svg"
import { ReactComponent as WrenchDark } from "../../assets/wrench-dark.svg"
import { ReactComponent as Book } from "../../assets/book.svg"
import { ReactComponent as BookDark } from "../../assets/book-dark.svg"
import { cn } from "../../utils/cn"
import { actions as Mixpanel } from "../../utils/MockMixpanel"

interface HelpPopoverProps {
	mixpanelFn: (event: string) => void
	className?: string
}

export const HelpPopoverItems = () => {
	return (
		<div className='flex flex-col rounded-t-[4px] rounded-b-[4px] bg-[#FFFFFF] bg-opacity-5 min-w-[218px]'>
			<a
				href='https://docs.flowty.io/reference/'
				target='_blank'
				rel='noreferrer'
				id='faq'
				className='cursor-pointer flex justify-between items-center uppercase font-montserrat text-[12px] py-[12px] px-[16px] font-bold text-white group'
				onClick={() => Mixpanel.track("Clicked on FAQ page link for guides")}
			>
				<div className='flex flex-row items-center justify-start gap-x-[8px]'>
					<Book className='w-[22px] h-[22px] group-hover:hidden' />
					<BookDark className='w-[22px] h-[22px] opacity-70 hidden group-hover:block' />
					<span className='group-hover:text-[#ADB5BD] text-white font-black tracking-wider'>
						Product Docs
					</span>
				</div>
				<ExternalLink className='w-[16px] h-[16px] group-hover:hidden' />
				<ExternalLinkDark className='w-[16px] h-[16px] hidden group-hover:block' />
			</a>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='200'
				height='2'
				viewBox='0 0 200 2'
				fill='none'
				className='w-3/4 mx-auto'
			>
				<path d='M0 1H200' stroke='#333333' strokeWidth='1px' />
			</svg>
			<a
				href='https://docs.flowty.io/reference/general-platform/troubleshooting'
				target='_blank'
				rel='noreferrer'
				id='troubleshooting'
				className='cursor-pointer flex justify-between items-center uppercase font-montserrat text-[12px] py-[12px] px-[16px] font-bold group'
				onClick={() => {
					Mixpanel.track("Clicked on Troubleshooting page link")
				}}
			>
				<div className='flex flex-row items-center justify-start gap-x-[8px] group-hover:text-white text-[#ADB5BD]'>
					<Wrench className='w-[22px] h-[22px] group-hover:hidden' />
					<WrenchDark className='w-[22px] h-[22px] opacity-70 hidden group-hover:block' />
					<span className='group-hover:text-[#ADB5BD] text-white font-black tracking-wider'>
						Troubleshoot
					</span>
				</div>
				<ExternalLink className='w-[16px] h-[16px] group-hover:hidden' />
				<ExternalLinkDark className='w-[16px] h-[16px] hidden group-hover:block' />
			</a>
		</div>
	)
}

export const HelpPopover = ({ mixpanelFn, className }: HelpPopoverProps) => {
	const [isHelpPopoverOpen, setIsHelpPopoverOpen] = useState(false)
	const onMouseHover = (open: boolean) => {
		setIsHelpPopoverOpen(open)
	}
	const ref = useRef<HTMLDivElement>(null)
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent | TouchEvent) => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				setIsHelpPopoverOpen(false)
			}
		}

		if (isHelpPopoverOpen) {
			document.addEventListener("mousedown", handleClickOutside)
			document.addEventListener("touchstart", handleClickOutside)
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
			document.removeEventListener("touchstart", handleClickOutside)
		}
	}, [isHelpPopoverOpen])

	return (
		<Popover className={`relative ${className}`} ref={ref}>
			<Menu
				as='div'
				className='relative cursor-pointer text-left'
				onMouseEnter={() => onMouseHover(true)}
				onMouseLeave={() => onMouseHover(false)}
			>
				<Menu.Button className='flex items-center justify-center transform cursor-pointer rounded-md p-0 duration-300 ease-in-out gap-x-[8px]'>
					HELP <ChevronUp className='' />
				</Menu.Button>

				<Transition
					show={isHelpPopoverOpen}
					as={Fragment}
					enter='transition ease-out duration-100'
					enterFrom='transform opacity-0 scale-95'
					enterTo='transform opacity-100 scale-100'
					leave='transition ease-in duration-75'
					leaveFrom='transform opacity-100 scale-100'
					leaveTo='transform opacity-0 scale-95'
				>
					<Menu.Items
						className={cn(
							"absolute z-10 mt-[8px] inline-flex p-[4px] flex-col justify-center items-start border border-[#6C757D] border-opacity-25 bg-[#04070b] bg-opacity-80 rounded-[4px] backdrop-blur-sm"
						)}
					>
						<HelpPopoverItems />
					</Menu.Items>
				</Transition>
			</Menu>
		</Popover>
	)
}
