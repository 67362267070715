import { ReactComponent as NoneFoundIcon } from "../../../../../../../assets/icons/none-found.svg"
import { useMemo } from "react"
import { FlowtyButton } from "ds-flowty"
import { ListingIndicatorPill } from "../../../../../../../components/Shared/ListingIndicatorPill/ListingIndicatorPill"

interface NoListingPreviewProps {
	type: string
	isOwner: boolean
	isDapper: boolean
	updateListing: () => void
}

export const NoListingPreview: React.FC<NoListingPreviewProps> = ({
	type,
	isOwner,
	isDapper,
	updateListing,
}) => {
	const translateType = useMemo(() => {
		if (type === "BUY NOW") {
			return {
				actionName: "Sale",
				listingName: "",
			}
		}
		if (type === "RENT") {
			return {
				actionName: "Rent",
				listingName: "Rental",
			}
		}
		if (type === "LOAN") {
			return {
				actionName: "Loan",
				listingName: "Loan",
			}
		}
	}, [type])

	const buttonText = useMemo(() => {
		return isDapper && type !== "BUY NOW"
			? "How to Access Feature"
			: `List for ${translateType?.actionName}`
	}, [isDapper, translateType])

	return (
		<>
			<div className='rounded-[0.5rem] flex flex-col flex-grow px-[1.5rem] py-[1.5rem] bg-[#606e7d33] w-full md:w-[25rem] justify-between gap-x-[1.5rem] items-start'>
				<ListingIndicatorPill symbol={type} />
				{isDapper && isOwner && type !== "BUY NOW" ? (
					<div className='w-full h-full flex justify-center items-center flex-col gap-[0.75rem] my-[1.5rem]'>
						Feature not available{" "}
					</div>
				) : (
					<div className='w-full h-full flex flex-col justify-center items-center animate-pulse gap-[0.75rem] my-[1.5rem]'>
						<div className='relative mr-5'>
							<NoneFoundIcon />
							<div className='flex space-x-2 left-11 bottom-0 absolute'>
								<div className='w-5 h-5 rounded-full bg-[#B3BBBE]'></div>
								<div className='w-5 h-5 rounded-full bg-[#B3BBBE]'></div>
								<div className='w-5 h-5 rounded-full bg-[#B3BBBE]'></div>
							</div>
						</div>
						<div className='text-white text-montserrat text-[0.875rem]'>
							No {translateType?.listingName} Listings Found
						</div>
					</div>
				)}
				{!isOwner ? null : (
					<FlowtyButton
						onClick={updateListing}
						text={<div>{buttonText}</div>}
						variant={"secondary"}
						btnSize={"small"}
					/>
				)}
			</div>
		</>
	)
}
