import {
	NotificationType,
	OfferCreated,
	OpenSearchListingAvailableData,
	OpensearchFlowNFT,
	OpensearchRentalAvailableData,
	Order,
	OrdersType,
	SpotPrice,
	SupportedTokens,
	Valuation,
	getContractAddressFromType,
	getContractNameFromType,
	nftTypeAndIdToLocationData,
	InitiatedTransactionNotification,
} from "flowty-common"
import {
	AccountSummaries,
	AccountSummary,
	Flowty,
	LoanRentalFilteredData,
	PaymentTokenToIdentifier,
	connectWallet,
} from "flowty-sdk"
import {
	Dispatch,
	PropsWithChildren,
	SetStateAction,
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react"
import { useLocation } from "react-router-dom"
import {
	NftReceiverFilter,
	PassThroughFilter,
	SelectorFilter,
	TokenNameParams,
	TokenProviderFilter,
} from "../../../FlowtyWalletSelector/FlowtyWalletSelector"
import {
	LoanRentalActionsOrder,
	MakeOfferOrder,
	ValuationData,
} from "../../../Types/GlobalTypes"
import { getBestFtPrivatePath } from "../../../utils/GlobalUtils"
import { FlowtyPurchaseFormValues } from "../../FlowtyPurchaseModal"
import { FlowtyModalNavBtnProps } from "../../types/FlowtyModalTypes"
import {
	calcDiscountWithAmountAndValuation,
	checkAccountHasBalanceForLoan,
	checkAccountHasBalanceForOffer,
	checkAccountHasBalanceForRental,
	checkAccountHasBalanceForStorefront,
} from "../../utils/FlowtyModalUtils"

import { PurchaseOrderDataType } from "./types/PurchaseModalContextTypes"
import {
	getMinListingDuration,
	MAX_LISTING_DURATION,
} from "../../utils/constants"
import { purchaseModalFnBuilder } from "../../utils/PurchaseModalFnBuilder"

export interface PurchaseState {
	purchase: boolean
	fundLoan: boolean
	fundRental: boolean
	makeOffer: boolean
	cancelOffer?: boolean
	loanBorrower?: boolean
	rentalBorrower?: boolean
}

export interface PuchaseModalOfferType extends OfferCreated {
	listingKind: "cancelOffer"
}

interface FlowtyPurchaseModalContextValues {
	activeTab: "1" | "2" | "3"
	collectionImage: string | null
	setActiveTab: (tab: "1" | "2" | "3") => void
	closeBtnProps: FlowtyModalNavBtnProps
	confirmBtnProps: FlowtyModalNavBtnProps
	dapperOffer?: OfferCreated | null
	dapperWalletNoOrder: boolean
	nonDapperNoOrder: boolean
	nonCustodialOffer?: OfferCreated | null
	error: PurchaseState
	hasChildAccounts: boolean
	hasBalance: boolean
	hasNftReceiver: SelectorFilter<string[]>
	isDapper?: boolean
	isLoadingOrders?: boolean
	isLoggedUser?: boolean
	isLoadingValuation?: boolean
	isPrivateListing: boolean
	isFormError: PurchaseState
	isLoading: PurchaseState
	isCancelOffer: boolean
	isMakeOffer: boolean
	isMainnet: boolean
	isOrderListedByLoggedAccount: boolean
	listingType:
		| "purchase"
		| "fundRental"
		| "fundLoan"
		| "makeOffer"
		| "cancelOffer"
		| "loanBorrower"
		| "rentalBorrower"
	loanRentalActionsData?: LoanRentalFilteredData
	mainAccount?: AccountSummary
	mixPanelFn: (event: string, data: unknown) => void
	nftOwnerAccountSummary?: AccountSummary
	openSearchFlowNFT?: OpensearchFlowNFT
	onCloseModal: () => void
	offerDuration: number | undefined
	offerValue: string | number
	offerTokenType: SupportedTokens
	payWithSelectedAccount?: AccountSummary
	orderData?: PurchaseOrderDataType
	resetModal: () => void
	sealed: PurchaseState
	selectedOrder: {
		purchase?: Order | null
		fundLoan?: Order | null
		fundRental?: Order | null
	}
	setSelectedOrder: Dispatch<
		SetStateAction<{
			purchase?: Order | null
			fundLoan?: Order | null
			fundRental?: Order | null
		}>
	>
	sendToSelectedAccount?: AccountSummary
	singleOffer?: "make-offer" | "cancel-offer"
	spotPrice?: SpotPrice
	strapiUrl?: string
	tokenProviderFilter: SelectorFilter<TokenNameParams>
	updateSelectedOrder: (order: Order | null) => void
	updateSelectedAccount: (
		account: AccountSummary,
		type: "payWith" | "sendTo"
	) => void
	transactionExplorerLink: string | null
	valuation?: Valuation
	valuationData: ValuationData
}

const defaultContext: FlowtyPurchaseModalContextValues = {
	activeTab: "1",
	closeBtnProps: {
		onClick: () => {},
		text: "",
	},
	collectionImage: null,
	confirmBtnProps: {
		onClick: () => {},
		text: "",
	},
	dapperOffer: null,
	dapperWalletNoOrder: false,
	error: {
		fundLoan: false,
		fundRental: false,
		loanBorrower: false,
		makeOffer: false,
		purchase: false,
		rentalBorrower: false,
	},
	hasBalance: true,
	hasChildAccounts: false,
	hasNftReceiver: new PassThroughFilter(),
	isCancelOffer: false,
	isDapper: false,
	isFormError: {
		fundLoan: false,
		fundRental: false,
		loanBorrower: false,
		makeOffer: false,
		purchase: false,
		rentalBorrower: false,
	},
	isLoading: {
		fundLoan: false,
		fundRental: false,
		loanBorrower: false,
		makeOffer: false,
		purchase: false,
		rentalBorrower: false,
	},
	isMainnet: false,
	isMakeOffer: false,
	isOrderListedByLoggedAccount: false,
	isPrivateListing: false,
	listingType: "purchase",
	mainAccount: undefined,
	mixPanelFn: () => {},
	nftOwnerAccountSummary: undefined,
	nonCustodialOffer: null,
	nonDapperNoOrder: false,
	offerDuration: undefined,
	offerTokenType: "FLOW",
	offerValue: "",
	onCloseModal: () => {},
	payWithSelectedAccount: undefined,
	resetModal: () => {},
	sealed: {
		fundLoan: false,
		fundRental: false,
		loanBorrower: false,
		makeOffer: false,
		purchase: false,
		rentalBorrower: false,
	},
	selectedOrder: {
		fundLoan: null,
		fundRental: null,
		purchase: null,
	},
	sendToSelectedAccount: undefined,
	setActiveTab: (_: "1" | "2" | "3") => {},
	setSelectedOrder: () => {},
	singleOffer: undefined,
	strapiUrl: "",
	tokenProviderFilter: new PassThroughFilter(),
	transactionExplorerLink: "",
	updateSelectedAccount: () => {},
	updateSelectedOrder: () => {},
	valuationData: {
		percentage: null,
		source: "",
		usdValue: 0,
	},
}

const FlowtyPurchaseModalContext =
	createContext<FlowtyPurchaseModalContextValues>(defaultContext)

interface FlowtyPurchaseModalContextProps extends PropsWithChildren {
	accountSummaries: AccountSummaries
	collectionImage: string | null
	addressesWithCollectionPublic?: string[]
	isLoggedUser: boolean
	mainAccount: AccountSummary | undefined
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mixPanelFn: (event: string, data: any) => void
	nftProviderPathIdentifier: string
	nftOrders?: OrdersType | null
	isLoadingOrders?: boolean
	loanRentalActionsData?: LoanRentalFilteredData
	offer?: OfferCreated
	singleOffer?: "make-offer" | "cancel-offer"
	openSearchFlowNFT: OpensearchFlowNFT
	onClose: () => void
	purchaseType?: "purchase" | "loan" | "rental"
	resetForm: () => void
	singleListing?: Order
	spotPrice?: SpotPrice
	strapiUrl?: string
	values: FlowtyPurchaseFormValues
	valuation?: Valuation
	createTransactionNotification?: (
		data: InitiatedTransactionNotification
	) => void
	isLoadingValuation: boolean
	flowty: Flowty
}

export const FlowtyPurchaseModalContextProvider: React.FC<
	FlowtyPurchaseModalContextProps
> = ({
	accountSummaries,
	addressesWithCollectionPublic,
	children,
	collectionImage,
	isLoggedUser,
	mainAccount,
	mixPanelFn,
	nftOrders,
	nftProviderPathIdentifier,
	isLoadingOrders,
	loanRentalActionsData,
	offer,
	singleOffer,
	openSearchFlowNFT,
	onClose,
	purchaseType,
	resetForm,
	singleListing,
	createTransactionNotification,
	spotPrice,
	strapiUrl,
	values,
	valuation,
	isLoadingValuation,
	flowty,
}) => {
	const [activeTab, setActiveTab] = useState<"1" | "2" | "3">(
		purchaseType === "purchase"
			? "1"
			: purchaseType === "loan"
			? "2"
			: purchaseType === "rental"
			? "3"
			: openSearchFlowNFT?.orders?.[0]?.listingKind === "rental"
			? "3"
			: openSearchFlowNFT?.orders?.[0]?.listingKind === "loan"
			? "2"
			: "1"
	)
	const [payWithSelectedAccount, setPayWithSelectedAccount] =
		useState<AccountSummary>(
			mainAccount || Object.values(accountSummaries || {})[0]
		)
	const [sendToSelectedAccount, setSendToSelectedAccount] =
		useState<AccountSummary>(
			mainAccount || Object.values(accountSummaries || {})[0]
		)
	const [transactionID, setTransactionID] = useState<string | null>(null)
	const [error, setError] = useState<PurchaseState>({
		fundLoan: false,
		fundRental: false,
		makeOffer: false,
		purchase: false,
	})
	const [sealed, setIsSealed] = useState<PurchaseState>({
		fundLoan: false,
		fundRental: false,
		makeOffer: false,
		purchase: false,
	})
	const [isLoading, setIsLoading] = useState<PurchaseState>({
		fundLoan: false,
		fundRental: false,
		makeOffer: false,
		purchase: false,
	})
	const [isFormError, setIsFormError] = useState<PurchaseState>({
		fundLoan: false,
		fundRental: false,
		makeOffer: false,
		purchase: false,
	})
	const [isMakeOffer, setIsMakeOffer] = useState<boolean>(
		singleOffer === "make-offer"
	)
	const isMainnet = flowty.config.network === "mainnet"
	const isDapper = payWithSelectedAccount?.isDapper
	const nftOwnerAccountSummary = accountSummaries?.[openSearchFlowNFT?.owner]

	const transactionExplorerLink = useMemo(() => {
		if (!transactionID) return null
		const flowdiverBaseURL = isMainnet
			? "https://flowdiver.io"
			: "https://testnet.flowdiver.io"
		return `${flowdiverBaseURL}/${transactionID}`
	}, [])

	const isCancelOffer = useMemo(() => {
		return singleOffer === "cancel-offer"
	}, [singleOffer])

	const hasChildAccounts = useMemo(() => {
		return Object.values(accountSummaries || {}).length > 1
	}, [accountSummaries])

	const updateSelectedOrder = (order: Order | null): void => {
		if (activeTab === "1") {
			setSelectedOrder(prev => ({ ...prev, purchase: order }))
		} else if (activeTab === "2") {
			setSelectedOrder(prev => ({ ...prev, fundLoan: order }))
		} else {
			setSelectedOrder(prev => ({ ...prev, fundRental: order }))
		}
	}

	const updateSelectedAccount = (
		account: AccountSummary,
		type: "payWith" | "sendTo"
	): void => {
		if (type === "payWith") {
			setPayWithSelectedAccount(account)
		} else {
			setSendToSelectedAccount(account)
		}
	}

	const txAvailableCallback = (res: string | null): void =>
		setTransactionID(res)

	const listingType:
		| "fundLoan"
		| "fundRental"
		| "purchase"
		| "makeOffer"
		| "cancelOffer"
		| "loanBorrower"
		| "rentalBorrower" = useMemo(() => {
		if (isMakeOffer) return "makeOffer"
		if (isCancelOffer) return "cancelOffer"
		if (loanRentalActionsData) {
			return loanRentalActionsData.type === "loan"
				? "loanBorrower"
				: "rentalBorrower"
		}
		if (Boolean(singleListing)) {
			return singleListing?.listingKind === "storefront"
				? "purchase"
				: singleListing?.listingKind === "loan"
				? "fundLoan"
				: "fundRental"
		}
		return activeTab === "1"
			? "purchase"
			: activeTab === "2"
			? "fundLoan"
			: "fundRental"
	}, [activeTab, purchaseType, isMakeOffer, loanRentalActionsData])

	const [selectedOrder, setSelectedOrder] = useState<{
		purchase?: Order | null
		fundLoan?: Order | null
		fundRental?: Order | null
	}>({
		fundLoan: singleListing ? singleListing : nftOrders?.loan?.[0] || null,
		fundRental: singleListing ? singleListing : nftOrders?.rental?.[0] || null,
		purchase: singleListing
			? singleListing
			: nftOrders?.storefront?.[0] || null,
	})

	const currentOrder = useMemo(() => {
		if (activeTab === "1") {
			return selectedOrder?.purchase || nftOrders?.storefront?.[0]
		} else if (activeTab === "2") {
			return selectedOrder?.fundLoan || nftOrders?.loan?.[0]
		} else {
			return selectedOrder?.fundRental || nftOrders?.rental?.[0]
		}
	}, [activeTab, selectedOrder, nftOrders])

	const tokenProviderFilter = useMemo((): SelectorFilter<TokenNameParams> => {
		if (isMakeOffer) {
			const tokenIdentifier = flowty.tokens.getTokenIdentifier(
				values.offerTokenType as SupportedTokens
			)
			if (
				(values.offerTokenType === "DUC" || values.offerTokenType === "FUT") &&
				mainAccount?.isDapper
			) {
				return new PassThroughFilter()
			} else {
				return new TokenProviderFilter({
					tokenIdentifier: tokenIdentifier,
				})
			}
		}

		if (
			(currentOrder?.paymentTokenName === "DUC" ||
				currentOrder?.paymentTokenName === "FUT") &&
			mainAccount?.isDapper
		) {
			return new PassThroughFilter()
		}

		return new TokenProviderFilter({
			tokenIdentifier: flowty.tokens.getTokenIdentifier(
				(currentOrder?.paymentTokenName || "FLOW") as SupportedTokens
			),
		})
	}, [currentOrder, isMakeOffer, values])

	const hasNftReceiver = useMemo((): SelectorFilter<string[]> => {
		return new NftReceiverFilter(addressesWithCollectionPublic || [])
	}, [addressesWithCollectionPublic])

	const ftPaths = useMemo(() => {
		const tokenType = isMakeOffer
			? PaymentTokenToIdentifier[values.offerTokenType as SupportedTokens]
			: currentOrder?.paymentTokenType || ""

		return payWithSelectedAccount?.tokens?.[tokenType]?.providerPaths || []
	}, [isMakeOffer, currentOrder, payWithSelectedAccount])

	const privateFTPath = useMemo(() => {
		try {
			if (!currentOrder) return ""

			const ftContractName = getContractNameFromType(
				currentOrder?.paymentTokenType || ""
			)
			const ftContractAddress = getContractAddressFromType(
				currentOrder?.paymentTokenType || ""
			)
			return getBestFtPrivatePath(ftPaths, ftContractAddress, ftContractName)
		} catch (e) {
			return ""
		}
	}, [currentOrder, payWithSelectedAccount])

	const isOrderListedByLoggedAccount =
		mainAccount?.address === offer?.flowtyStorefrontAddress

	const orderData = useMemo(() => {
		return loanRentalActionsData
			? ({
					listingKind:
						loanRentalActionsData.type === "loan"
							? "loanBorrower"
							: "rentalBorrower",
					...loanRentalActionsData,
			  } as LoanRentalActionsOrder)
			: singleListing
			? isMakeOffer
				? ({
						currentOrderSalePrice: singleListing.amount,
						currentOrderTokenName: singleListing.paymentTokenName,
						listingKind: "makeOffer",
						offerAmount: values.offerAmount,
						paymentTokenName: values.offerTokenType,
				  } as MakeOfferOrder)
				: singleListing
			: listingType === "purchase"
			? selectedOrder.purchase || nftOrders?.storefront?.[0]
			: listingType === "fundRental"
			? selectedOrder.fundRental || nftOrders?.rental?.[0]
			: listingType === "fundLoan"
			? selectedOrder.fundLoan || nftOrders?.loan?.[0]
			: listingType === "cancelOffer"
			? ({
					listingKind: "cancelOffer",
					...offer,
			  } as PuchaseModalOfferType)
			: ({
					currentOrderSalePrice: selectedOrder.purchase
						? selectedOrder.purchase.amount
						: null,
					currentOrderTokenName: selectedOrder.purchase
						? selectedOrder.purchase.paymentTokenName
						: null,
					listingKind: "makeOffer",
					offerAmount: values.offerAmount,
					paymentTokenName: values.offerTokenType,
			  } as MakeOfferOrder)
	}, [
		loanRentalActionsData,
		singleListing,
		listingType,
		selectedOrder,
		nftOrders,
		values,
		offer,
		isMakeOffer,
	])

	useEffect(() => {
		if (transactionID && createTransactionNotification) {
			switch (listingType) {
				case "purchase":
					const purchaseOrderData = orderData as Order

					createTransactionNotification({
						amount: Number(purchaseOrderData?.amount ?? 0),
						collectionAddress: openSearchFlowNFT.contractAddress,
						collectionName: openSearchFlowNFT.contractName,
						imageUrl: openSearchFlowNFT.card.images?.[0]?.url || "",
						listingType,
						nftId: openSearchFlowNFT.id,
						nftType: openSearchFlowNFT.type,
						status: "PROCESSING",
						timestamp: Date.now(),
						title: openSearchFlowNFT.card.title ?? "",
						token: purchaseOrderData.paymentTokenName,
						transactionID,
						type: NotificationType.Transaction,
					})
					break
				case "makeOffer":
					const offerOrderData = orderData as MakeOfferOrder
					createTransactionNotification({
						amount: offerOrderData.offerAmount,
						collectionAddress: openSearchFlowNFT.contractAddress,
						collectionName: openSearchFlowNFT.contractName,
						imageUrl: openSearchFlowNFT.card.images?.[0]?.url || "",
						listingType,
						nftId: openSearchFlowNFT.id,
						nftType: openSearchFlowNFT.type,
						status: "PROCESSING",
						timestamp: Date.now(),
						title: openSearchFlowNFT.card.title ?? "",
						token: offerOrderData.paymentTokenName,
						transactionID,
						type: NotificationType.Transaction,
					})
					break
				case "cancelOffer":
					const cancelOfferData = orderData as Order
					createTransactionNotification({
						amount: Number(cancelOfferData.amount),
						collectionAddress: openSearchFlowNFT.contractAddress,
						collectionName: openSearchFlowNFT.contractName,
						imageUrl: openSearchFlowNFT.card.images?.[0]?.url || "",
						listingType,
						nftId: openSearchFlowNFT.id,
						nftType: openSearchFlowNFT.type,
						status: "PROCESSING",
						timestamp: Date.now(),
						title: openSearchFlowNFT.card.title ?? "",
						token: cancelOfferData.paymentTokenName,
						transactionID,
						type: NotificationType.Transaction,
					})
					break
				case "fundLoan":
					const loanOrderData = orderData as Order & {
						derivations: {
							calculatedValues: {
								marketplaceAmount: number
							}
						}
					}

					createTransactionNotification({
						amount:
							loanOrderData?.derivations.calculatedValues.marketplaceAmount ??
							0,
						collectionAddress: openSearchFlowNFT.contractAddress,
						collectionName: openSearchFlowNFT.contractName,
						imageUrl: openSearchFlowNFT.card.images?.[0]?.url || "",
						listingType,
						nftId: openSearchFlowNFT.id,
						nftType: openSearchFlowNFT.type,
						status: "PROCESSING",
						timestamp: Date.now(),
						title: openSearchFlowNFT.card.title ?? "",
						token: loanOrderData.paymentTokenType,
						transactionID,
						type: NotificationType.Transaction,
					})
					break
				case "fundRental": {
					const rentalOrderData = orderData as Order
					createTransactionNotification({
						amount: Number(rentalOrderData.amount),
						collectionAddress: openSearchFlowNFT.contractAddress,
						collectionName: openSearchFlowNFT.contractName,
						imageUrl: openSearchFlowNFT.card.images?.[0]?.url || "",
						listingType,
						nftId: openSearchFlowNFT.id,
						nftType: openSearchFlowNFT.type,
						status: "PROCESSING",
						timestamp: Date.now(),
						title: openSearchFlowNFT.card.title ?? "",
						transactionID,
						type: NotificationType.Transaction,
					})
				}
			}
		}
	}, [
		transactionID,
		openSearchFlowNFT,
		listingType,
		orderData,
		createTransactionNotification,
	])

	const submitTransaction = async (): Promise<void> => {
		await purchaseModalFnBuilder({
			enabledAutoReturn: values.automaticReturn,
			flowty,
			ftProviderAddress: payWithSelectedAccount.address,
			listingType,
			loanRentalActionsData,
			mixPanelFn,
			nftOrders,
			nftProviderPathIdentifier,
			nftReceiverAddress: sendToSelectedAccount.address,
			offer,
			offerAmount: +values.offerAmount,
			offerDuration: +values.offerDuration,
			openSearchFlowNFT,
			privateFTPath,
			selectedOrder,
			setError,
			setIsLoading,
			setIsSealed,
			singleListing,
			token: values.offerTokenType,
			txAvailableCallback,
		})
	}

	const makeOffer = (): void => {
		setIsMakeOffer(true)
	}

	const resetModal = (): void => {
		setIsMakeOffer(false)
	}

	const onCloseModal = (): void => {
		if (
			!isLoading.purchase &&
			!isLoading.fundLoan &&
			!isLoading.fundRental &&
			!isLoading.makeOffer
		) {
			setError({
				fundLoan: false,
				fundRental: false,
				makeOffer: false,
				purchase: false,
			})
			setIsSealed({
				fundLoan: false,
				fundRental: false,
				makeOffer: false,
				purchase: false,
			})
			setIsFormError({
				fundLoan: false,
				fundRental: false,
				makeOffer: false,
				purchase: false,
			})
			setActiveTab(
				purchaseType === "purchase" ? "1" : purchaseType === "loan" ? "2" : "3"
			)
		}
		resetForm()
		onClose()
	}

	const hasBalance = useMemo(() => {
		if (isDapper) return true
		if (isMakeOffer) {
			return checkAccountHasBalanceForOffer(
				+values.offerAmount,
				values.offerTokenType,
				payWithSelectedAccount,
				flowty
			)
		} else if (loanRentalActionsData) {
			if (loanRentalActionsData.type === "loan") {
				return checkAccountHasBalanceForStorefront(
					payWithSelectedAccount,
					Number(loanRentalActionsData?.repaymentDue),
					loanRentalActionsData?.paymentTokenName as SupportedTokens,
					flowty
				)
			} else return true
		} else if (listingType === "fundLoan") {
			const order =
				(singleListing as OpenSearchListingAvailableData) ||
				selectedOrder.fundLoan ||
				nftOrders?.loan?.[0]

			const marketplaceAmount =
				order?.derivations?.calculatedValues?.marketplaceAmount
			return checkAccountHasBalanceForLoan(
				payWithSelectedAccount,
				order?.paymentTokenType,
				marketplaceAmount
			)
		} else if (listingType === "fundRental") {
			const order =
				(singleListing as OpensearchRentalAvailableData) ||
				selectedOrder.fundRental ||
				nftOrders?.rental?.[0]

			return checkAccountHasBalanceForRental(
				payWithSelectedAccount,
				order?.deposit,
				order?.amount,
				order?.paymentTokenName || "FLOW",
				flowty
			)
		} else if (listingType === "purchase") {
			const order =
				(singleListing as OpenSearchListingAvailableData) ||
				selectedOrder.purchase ||
				nftOrders?.storefront?.[0]

			return checkAccountHasBalanceForStorefront(
				payWithSelectedAccount,
				+order?.amount,
				order?.paymentTokenName || "FLOW",
				flowty
			)
		}
		return true
	}, [
		loanRentalActionsData,
		payWithSelectedAccount,
		values,
		singleListing,
		selectedOrder,
		nftOrders,
		payWithSelectedAccount,
		isMakeOffer,
	])

	const assetPageUrl = useMemo(() => {
		const locationData = nftTypeAndIdToLocationData(
			openSearchFlowNFT.type,
			openSearchFlowNFT.id
		)

		const pageOrigin = window.location.origin
		return `${pageOrigin}/asset/${locationData.contract.address}/${locationData.contract.name}/${locationData.resourceName}/${locationData.nftID}`
	}, [openSearchFlowNFT])

	const madeOffersPageUrl = useMemo(() => {
		const pageOrigin = window.location.origin
		return `${pageOrigin}/user/profile?tab=1&offersTab=1`
	}, [])

	const redirectToMadeOffersPage = (): void => {
		window.open(madeOffersPageUrl, "_blank")
	}

	const redirectToAssetPage = (): void => {
		window.open(assetPageUrl, "_blank")
	}

	const redirectToRentalsPage = (): void => {
		const pageOrigin = window.location.origin
		const rentalsPageUrl = `${pageOrigin}/user/profile?tab=3`
		window.open(rentalsPageUrl, "_blank")
	}

	const redirectToLoansPage = (): void => {
		const pageOrigin = window.location.origin
		const loansPageUrl = `${pageOrigin}/user-profile/loans`
		window.open(loansPageUrl, "_blank")
	}

	const setFormError = (): void => {
		setIsFormError(prev => ({ ...prev, [listingType]: true }))
	}

	const isPrivateListing = useMemo(() => {
		if (!(selectedOrder?.fundRental as OpensearchRentalAvailableData)?.renter)
			return false
		return (
			(selectedOrder?.fundRental as OpensearchRentalAvailableData)?.renter !==
			mainAccount?.address
		)
	}, [selectedOrder])

	const dapperWalletNoOrder =
		currentOrder?.paymentTokenName !== "FUT" &&
		currentOrder?.paymentTokenName !== "DUC" &&
		isDapper

	const nonDapperNoOrder =
		(currentOrder?.paymentTokenName === "FUT" ||
			currentOrder?.paymentTokenName === "DUC") &&
		!isDapper &&
		isLoggedUser

	const MIN_LISTING_DURATION = getMinListingDuration(isMainnet)

	const isInvalidOfferAmount =
		+values.offerAmount <= 0 || +values.offerAmount > 10000000
	const isInvalidOfferDuration =
		!values?.offerDuration ||
		+values?.offerDuration < MIN_LISTING_DURATION ||
		+values?.offerDuration > MAX_LISTING_DURATION

	const invalidOffer = isInvalidOfferAmount || isInvalidOfferDuration

	const location = useLocation()
	const isAssetDetailPage = useMemo(() => {
		return location.pathname.includes("/asset/")
	}, [location.pathname])

	const confirmBtnProps: FlowtyModalNavBtnProps = useMemo(() => {
		return activeTab === "1" //purchase or make offer
			? loanRentalActionsData
				? {
						bgColor: "primary",
						disabled:
							isLoading.loanBorrower ||
							isLoading.rentalBorrower ||
							(!hasBalance && isLoggedUser),
						onClick:
							sealed.loanBorrower || sealed.rentalBorrower
								? redirectToAssetPage
								: submitTransaction,
						text:
							sealed.loanBorrower || sealed.rentalBorrower
								? "VIEW ITEM"
								: isLoading.loanBorrower
								? "REPAYING"
								: isLoading.rentalBorrower
								? "RETURNING"
								: error.loanBorrower || error.rentalBorrower
								? "TRY AGAIN"
								: loanRentalActionsData.type === "loan"
								? "REPAY"
								: "RETURN",
				  }
				: isMakeOffer
				? {
						bgColor: "primary",
						disabled: isLoading.makeOffer || (!hasBalance && isLoggedUser),
						onClick: !isLoggedUser
							? connectWallet
							: sealed.makeOffer
							? redirectToMadeOffersPage
							: invalidOffer
							? setFormError
							: submitTransaction,
						text: sealed.makeOffer
							? "VIEW OFFERS"
							: isLoading.makeOffer
							? "MAKING OFFER"
							: error.makeOffer
							? "TRY AGAIN"
							: "SUBMIT OFFER",
				  }
				: isCancelOffer
				? {
						bgColor: "primary",
						disabled: isLoading.cancelOffer || !isOrderListedByLoggedAccount,
						onClick: sealed.cancelOffer
							? redirectToMadeOffersPage
							: submitTransaction,
						text: sealed.cancelOffer
							? "VIEW OFFERS"
							: isLoading.cancelOffer
							? "CANCELING OFFER"
							: error.cancelOffer
							? "TRY AGAIN"
							: "CANCEL OFFER",
				  }
				: {
						bgColor: "primary",
						disabled:
							(!Boolean(singleListing) &&
								(!Boolean(nftOrders?.storefront) ||
									nftOrders?.storefront?.length === 0)) ||
							isLoading.purchase ||
							dapperWalletNoOrder ||
							nonDapperNoOrder ||
							(!hasBalance && isLoggedUser),
						notDisplay: sealed.purchase && isAssetDetailPage,
						onClick: !isLoggedUser
							? connectWallet
							: sealed.purchase
							? redirectToAssetPage
							: submitTransaction,
						text: sealed.purchase
							? "VIEW ITEM"
							: isLoading.purchase
							? "BUYING ITEM"
							: error.purchase
							? "TRY AGAIN"
							: "BUY",
				  }
			: activeTab === "2" //fundLoan
			? {
					bgColor: "primary",
					disabled:
						(!Boolean(singleListing) &&
							(!Boolean(nftOrders?.loan) || nftOrders?.loan?.length === 0)) ||
						isLoading.fundLoan ||
						isDapper ||
						(!hasBalance && isLoggedUser),
					notDisplay: sealed.fundLoan && isAssetDetailPage,
					onClick: !isLoggedUser
						? connectWallet
						: sealed.fundLoan
						? redirectToLoansPage
						: submitTransaction,
					text: sealed.fundLoan
						? "VIEW LOANS"
						: isLoading.fundLoan
						? "FUNDING LOAN"
						: error.fundLoan
						? "TRY AGAIN"
						: "FUND LOAN",
			  }
			: {
					bgColor: "primary",
					disabled:
						(!Boolean(singleListing) &&
							(!Boolean(nftOrders?.rental) ||
								nftOrders?.rental?.length === 0)) ||
						isLoading.fundRental ||
						isDapper ||
						isPrivateListing ||
						(!hasBalance && isLoggedUser),
					notDisplay: sealed.fundRental && isAssetDetailPage,
					onClick: !isLoggedUser
						? connectWallet
						: sealed.fundRental
						? redirectToRentalsPage
						: submitTransaction,
					text: sealed.fundRental
						? "VIEW RENTALS"
						: isLoading.fundRental
						? "RENTING ITEM"
						: error.fundRental
						? "TRY AGAIN"
						: "RENT",
			  }
	}, [
		activeTab,
		values,
		isLoading,
		sealed,
		error,
		isMakeOffer,
		isCancelOffer,
		isDapper,
		nftOrders,
		singleListing,
		isPrivateListing,
		hasBalance,
		loanRentalActionsData,
		sendToSelectedAccount,
		payWithSelectedAccount,
		selectedOrder,
	])

	const closeBtnProps: FlowtyModalNavBtnProps = useMemo(() => {
		return activeTab === "1" // purchase or makeOffer
			? loanRentalActionsData
				? {
						bgColor: "white",
						notDisplay: isLoading.loanBorrower || isLoading.rentalBorrower,
						onClick: () => onCloseModal(),
						text:
							sealed.loanBorrower || sealed.rentalBorrower ? "CLOSE" : "CANCEL",
				  }
				: isMakeOffer
				? {
						bgColor: "white",
						notDisplay: isLoading.makeOffer,
						onClick:
							(sealed.makeOffer ||
								!isLoggedUser ||
								singleOffer === "make-offer") &&
							!error.makeOffer
								? () => onCloseModal()
								: error.makeOffer
								? () => {
										setError((prevState: PurchaseState) => ({
											...prevState,
											makeOffer: false,
										}))
								  }
								: () => setIsMakeOffer(false),
						text:
							(sealed.makeOffer ||
								singleOffer === "make-offer" ||
								!isLoggedUser) &&
							!error.makeOffer
								? "CLOSE"
								: "BACK",
				  }
				: isCancelOffer
				? {
						bgColor: "white",
						notDisplay: isLoading.cancelOffer,
						onClick: () => onCloseModal(),
						text: "CLOSE",
				  }
				: {
						bgColor: "white",
						notDisplay: isLoading.purchase,
						onClick:
							sealed.purchase || error.purchase || !isLoggedUser
								? () => onCloseModal()
								: makeOffer,
						text:
							sealed.purchase || error.purchase || !isLoggedUser
								? "CLOSE"
								: "MAKE OFFER",
				  }
			: activeTab === "2" // fundLoan
			? {
					bgColor: "white",
					notDisplay: isLoading.fundLoan,
					onClick: onCloseModal,
					text: "CLOSE",
			  }
			: {
					bgColor: "white",
					notDisplay: isLoading.fundRental,
					onClick: onCloseModal,
					text: "CLOSE",
			  }
	}, [
		activeTab,
		sealed,
		isLoading,
		sealed,
		error,
		values,
		isMakeOffer,
		loanRentalActionsData,
	])

	const discount = isMakeOffer
		? calcDiscountWithAmountAndValuation({
				salePrice: +values.offerAmount,
				spotPrice: spotPrice?.value || 0,
				token: values.offerTokenType || "FLOW",
				valuation: valuation?.usdValue || 0,
		  })
		: calcDiscountWithAmountAndValuation({
				salePrice: +(selectedOrder?.purchase?.amount
					? selectedOrder?.purchase?.amount
					: nftOrders?.storefront?.[0]?.amount || 0),
				spotPrice: spotPrice?.value || 0,
				token: selectedOrder?.purchase?.paymentTokenName
					? selectedOrder?.purchase?.paymentTokenName || "FLOW"
					: nftOrders?.storefront?.[0]?.paymentTokenName || "FLOW",
				valuation: valuation?.usdValue || 0,
		  })

	const valuationData: ValuationData = {
		percentage: valuation?.usdValue ? discount : null,
		source: valuation?.source || "",
		usdValue: valuation?.usdValue || 0,
	}

	const offerValue = useMemo(() => {
		return values.offerAmount
	}, [values])

	const offerTokenType = useMemo(() => {
		return values.offerTokenType
	}, [values])

	const offerDuration = useMemo(() => {
		return values.offerDuration
	}, [values])

	const dapperOffer = useMemo(() => {
		return openSearchFlowNFT?.bestDapperOffer
	}, [openSearchFlowNFT])

	const nonCustodialOffer = useMemo(() => {
		return openSearchFlowNFT?.bestNonCustodialOffer
	}, [openSearchFlowNFT])

	return (
		<FlowtyPurchaseModalContext.Provider
			value={{
				activeTab,
				closeBtnProps,
				collectionImage,
				confirmBtnProps,
				dapperOffer,
				dapperWalletNoOrder,
				error,
				hasBalance,
				hasChildAccounts,
				hasNftReceiver,
				isCancelOffer,
				isDapper,
				isFormError,
				isLoading,
				isLoadingOrders,
				isLoadingValuation,
				isLoggedUser,
				isMainnet,
				isMakeOffer,
				isOrderListedByLoggedAccount,
				isPrivateListing,
				listingType,
				loanRentalActionsData,
				mainAccount,
				mixPanelFn,
				nftOwnerAccountSummary,
				nonCustodialOffer,
				nonDapperNoOrder,
				offerDuration,
				offerTokenType,
				offerValue,
				onCloseModal,
				openSearchFlowNFT,
				orderData,
				payWithSelectedAccount,
				resetModal,
				sealed,
				selectedOrder,
				sendToSelectedAccount,
				setActiveTab,
				setSelectedOrder,
				singleOffer,
				spotPrice,
				strapiUrl,
				tokenProviderFilter,
				transactionExplorerLink,
				updateSelectedAccount,
				updateSelectedOrder,
				valuationData,
			}}
		>
			{children}
		</FlowtyPurchaseModalContext.Provider>
	)
}

export const useFlowtyPurchaseModalContext = () =>
	useContext(FlowtyPurchaseModalContext) as FlowtyPurchaseModalContextValues
