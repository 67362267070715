import { useMemo } from "react"
import useInfiniteScroll from "../hooks/useInfiniteScroll"
import { ReactComponent as Bell } from "./assets/bell.svg"
import { TransactionNotificationItem } from "../NotificationItem/TransactionNotificationItem/TransactionNotificationItem"
import NotificationsList from "../NotificationsList"
import { returnPastAndTodaysNotifications } from "../util/returnPastAndTodaysNotifications"
import {
	BaseNotification,
	NotificationData,
	NotificationType,
	StorefrontPurchasedNotification,
	InitiatedTransactionNotification,
} from "flowty-common"
import { StorefrontPurchasedNotificationItem } from "../NotificationItem/StorefrontPurchasedNotificationItem/StorefrontPurchasedNotificationItem"

export const renderNotificationItem = (
	type: BaseNotification["type"],
	notification: NotificationData,
	queueMessage?: ({ type, id }: { type: NotificationType; id: string }) => void
): React.ReactNode | null => {
	switch (type) {
		case "TRANSACTION":
			return (
				<TransactionNotificationItem
					{...(notification as InitiatedTransactionNotification)}
					queueMessage={queueMessage}
				/>
			)
		case "STOREFRONT_PURCHASED":
			return (
				<StorefrontPurchasedNotificationItem
					{...(notification as StorefrontPurchasedNotification)}
				/>
			)
		default:
			return null
	}
}

export interface NotificationMenuProps {
	notifications: NotificationData[]
	queueMessage?: ({ type, id }: { type: NotificationType; id: string }) => void
	fetchMoreNotifications?: () => void
	hasMore: boolean
	onMouseEnter?: () => void
	onMouseLeave?: () => void
}

export const NotificationMenu: React.FunctionComponent<
	NotificationMenuProps
> = ({
	notifications,
	queueMessage,
	onMouseEnter,
	hasMore,
	fetchMoreNotifications,
	onMouseLeave,
}) => {
	const { notificationsFromToday, notificationsFromBeforeToday } = useMemo(
		() => returnPastAndTodaysNotifications(notifications),
		[notifications]
	)

	const sentinelRef = useInfiniteScroll(() => {
		if (fetchMoreNotifications) {
			fetchMoreNotifications()
		}
	}, hasMore)

	return (
		<div
			data-testid='notification-menu'
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			className='flex flex-col justify-center items-start gap-[10px] rounded-[6px] border-[1px] border-neutral-400 bg-notifications backdrop-blur-[50px] w-max'
		>
			<div className='max-h-[450px] md:max-h-[700px] flex flex-col items-start gap-[24px] overflow-auto py-[24px] px-[28px]'>
				<div className='inline-flex gap-[8px] items-center w-full md:w-[168px]'>
					<Bell />
					<span className='text-neutral-700 text-[16px] font-[900] leading-[16px] text-center'>
						NOTIFICATIONS
					</span>
				</div>
				<div className='flex flex-col p-2 md:py-[18px] md:px-[24px] items-start gap-[16px] rounded-[4px] border-[1px] border-tipBorder bg-tipBg'>
					<p className='w-full md:w-[321px] text-[#DEDEDE] text-sm md:text-[14px] leading-normal'>
						<strong>Tip:</strong> Complete one transaction before starting
						another to limit failed transactions.
					</p>
				</div>

				<div className='w-full md:w-[369px] flex flex-col items-start gap-[8px]'>
					<NotificationsList
						label='Today'
						notifications={notificationsFromToday}
						queueMessage={queueMessage}
					/>

					<NotificationsList
						label='Past Notifications'
						notifications={notificationsFromBeforeToday}
						queueMessage={queueMessage}
					/>
					<div className='border-2 border-transparent' ref={sentinelRef}></div>
				</div>
			</div>
		</div>
	)
}
