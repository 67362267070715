import React, { useEffect, useMemo, useRef } from "react"
import { ReactComponent as FlowtyLogo } from "../../assets/flowty_logo.svg"
import { cn } from "../../utils/cn"
import { FlowtyNavbarProps } from "../FlowtyNavbar"
import { HelpPopover } from "./HelpPopover"
import { SignInButton } from "./SignInButton"
import { FlowtyUserNotifications } from "../../FlowtyUserNotifications"
import { NotificationData, NotificationType } from "flowty-common"

const NavBarLogoAndLinks = ({
	navItems,
}: {
	navItems: JSX.Element[]
}): JSX.Element => {
	return (
		<div className={cn("flex items-center flex-shrink-0")}>
			<a href='/' className='flex items-center'>
				<FlowtyLogo className='h-full w-28 mr-[16px] ml-[-6px]' />
			</a>
			<ul className={cn("flex list-none")}>{navItems}</ul>
			<HelpPopover
				className='py-[12px] px-[24px]'
				mixpanelFn={function (event: string): void {
					console.log("Mixpanel Help Provider")
				}}
			/>
		</div>
	)
}

const NavBarSignInAndNotifications = ({
	isLogged,
	loggedPopoverComponent,
	isLoading,
	logUser,
	notifications,
	queueNotification,
	loadingNotifications,
	hasMore,
	fetchMoreNotifications,
}: {
	isLogged: boolean
	loggedPopoverComponent: React.ReactNode
	isLoading: boolean
	logUser: () => void
	notifications: NotificationData[]
	hasMore: boolean
	loadingNotifications: boolean
	queueNotification?: ({
		type,
		id,
	}: {
		type: NotificationType
		id: string
	}) => void
	fetchMoreNotifications: () => void
}): JSX.Element => {
	const authComponent = useMemo(() => {
		if (!isLogged) {
			return <SignInButton isLoading={isLoading} logUser={logUser} />
		}
		if (isLogged && !isLoading) {
			return loggedPopoverComponent
		}
	}, [isLogged, isLoading, loggedPopoverComponent])

	return (
		<div
			className={cn(
				"flex items-center justify-items-end gap-[16px] relative z-[999]"
			)}
		>
			{isLogged && !isLoading && (
				<FlowtyUserNotifications
					loading={loadingNotifications}
					hasMore={hasMore}
					notifications={notifications}
					queueMessage={queueNotification}
				/>
			)}
			{authComponent}
		</div>
	)
}

export const NavbarLaptop: React.FunctionComponent<FlowtyNavbarProps> = ({
	bgColor,
	isLogged = false,
	loggedPopoverComponent,
	mixpanelFn,
	authLoading,
	logUser,
	registerHeight,
	notifications,
	hasMore,
	queueNotification,
	loadingNotifications,
	fetchMoreNotifications,
	navItems,
}) => {
	const baseStyles = `${bgColor} py-[18px] flex flex-col w-full items-center fixed top-0 left-1/2 -translate-x-1/2 z-50 font-black text-white`

	const navbarRef = useRef<HTMLDivElement | null>(null)

	const getNavbarHeight = () => {
		if (navbarRef.current && registerHeight) {
			registerHeight(navbarRef.current.offsetHeight)
		}
	}

	useEffect(() => {
		getNavbarHeight()

		const handleResize = () => {
			getNavbarHeight()
		}

		window.addEventListener("resize", handleResize)

		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [navbarRef, registerHeight])

	return (
		<div>
			<div
				className={baseStyles}
				ref={navbarRef}
				data-testid='navbar-component'
			>
				<nav className={`flex w-full justify-between relative px-12 h-[40px]`}>
					<NavBarLogoAndLinks navItems={navItems} />

					<NavBarSignInAndNotifications
						fetchMoreNotifications={fetchMoreNotifications}
						loadingNotifications={loadingNotifications}
						hasMore={hasMore}
						notifications={notifications}
						queueNotification={queueNotification}
						isLogged={isLogged}
						loggedPopoverComponent={loggedPopoverComponent}
						logUser={logUser}
						isLoading={authLoading}
					/>
				</nav>
			</div>
		</div>
	)
}
