import React, { ReactNode, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { TooltipElement } from "../../../../components/InfoText"
import { actions as Mixpanel } from "../../../../util/Mixpanel"
import { ingestAccount } from "../../../../services/flow/AccountService"
import { ReactComponent as RefreshButton } from "../../../../assets/media/refresh.svg"

interface Tab {
	name: string
	component: ReactNode
}
interface TabWindowProps {
	tabs: Tab[]
	address: string
}

export const TabWindow: React.FC<TabWindowProps> = ({ tabs, address }) => {
	const navigate = useNavigate()
	const { search } = useLocation()
	const query = new URLSearchParams(search)
	const defaultTab = query.get("tab") || 0
	const [selectedTab, setSelectedTab] = useState(Number(defaultTab))
	const [loadingInventory, setLoadingInventory] = useState<boolean>(false)

	const onSelectTab = (index: number): void => {
		query.set("tab", `${index}`)
		navigate({ search: query.toString() })
		setSelectedTab(index)
	}

	const ingestAccountClick = async (): Promise<void> => {
		if (loadingInventory) return

		setLoadingInventory(true)
		Mixpanel.track("Refresh Inventory Click")
		setLoadingInventory(true)
		ingestAccount(address ?? "")
		setTimeout(() => {
			setLoadingInventory(false)
		}, 4000)
	}

	const nameTooltip = (
		<button
			onClick={ingestAccountClick}
			className='flex flex-row items-center hover:cursor-pointer group hover:opacity-75'
		>
			<p className='font-montserrat text-primary font-[0.875rem] capitalize mr-[0.25rem]'>
				Refresh Profile
			</p>
			<RefreshButton
				className={`w-[1.25rem] ${loadingInventory && "animate-spin"}`}
			/>
		</button>
	)

	return (
		<div className='mt-14 mb-1'>
			<div className='py-3 pb-[28px] md:sticky md:top-[76px] z-20 bg-darkBg'>
				<div className='flex justify-between border-b border-[#6C757D40]'>
					<nav
						className='-mb-px flex space-x-8 overflow-x-auto'
						aria-label='Tabs'
					>
						{tabs.map((tab, index) => (
							<button
								key={tab.name}
								onClick={() => onSelectTab(index)}
								className={`font-montserrat uppercase pb-2.5 flex items-center
							${
								selectedTab === index
									? "border-primary text-primary"
									: "border-transparent text-lightText hover:text-primary"
							} 
							whitespace-nowrap tab-window-padding justify-center border-b-[3px] font-medium text-sm sm:text-base
						`}
							>
								{tab.name}
								{tab.name === "Offers" && (
									<div className='w-[10px] h-[10px] bg-primary rounded-full ml-2 drop-shadow-sm'></div>
								)}
							</button>
						))}
					</nav>
					<div className='items-center justify-center hidden lg:flex'>
						<p className='font-montserrat text-[#C8C8C8] font-[0.875rem] capitalize mr-[0.75rem]'>
							Missing items?
						</p>
						<TooltipElement
							Comp={nameTooltip}
							tooltipText={
								"Refreshes this account's inventory. Can take a few minutes to complete"
							}
							tooltipId='refresh-profile-tooltip'
						/>
					</div>
				</div>
			</div>

			{tabs[selectedTab].component}
		</div>
	)
}
