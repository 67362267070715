/* eslint-disable @typescript-eslint/no-use-before-define */
import { TokenMetadata } from "flowty-common"
import { Config } from "../../types"

export const getAcceptOfferTxn = (
	config: Config,
	token: TokenMetadata,
	isNFTCatalog: boolean
): string => {
	if (token.symbol === "DUC") {
		return config.crescendo
			? acceptOfferDapperWalletCrescendo(config)
			: acceptOfferDapperWallet(config)
	}

	if (isNFTCatalog) {
		return config.crescendo
			? acceptOfferCatalogCrescendo(config)
			: acceptOfferCatalog(config)
	}

	return config.crescendo
		? acceptOfferNotCatalogCrescendo(config)
		: acceptOfferNotCatalog(config)
}

const acceptOfferCatalog = (config: Config): string =>
	`import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}

import Offers from ${config.contractAddresses.Offers}
import FlowtyUtils from ${config.contractAddresses.FlowtyUtils}

import HybridCustody from ${config.contractAddresses.HybridCustody}

transaction(nftID: UInt64, offerID: UInt64, storefrontAddress: Address, nftProviderPathIdentifier: String, collectionIdentifier: String, nftProviderAddress: Address, ftReceiverAddress: Address) {
    let nftProvider: Capability<&{NonFungibleToken.Provider}>
    let ftReceiver: Capability<&{FungibleToken.Receiver}>

    prepare(acct: AuthAccount) {
        let storefront = getAccount(storefrontAddress).getCapability<&Offers.Storefront{Offers.StorefrontPublic}>(Offers.OffersPublicPath)
            .borrow() ?? panic("storefront not found")
        let offer = storefront.borrowOffer(offerResourceID: offerID) ?? panic("offer not found")
        let details = offer.getDetails()
        let tokenInfo = FlowtyUtils.getTokenInfo(details.paymentTokenType) ?? panic("token info not found")

        let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

        let storageCollectionPath = catalogEntry.collectionData.storagePath

        if nftProviderAddress == acct.address {
            let contractName: String = catalogEntry.contractName
            let contractAddress: String = catalogEntry.contractAddress.toString()
            let flowtyNftCollectionProviderPath =  PrivatePath(identifier: contractName.concat(contractAddress).concat("CollectionProviderForFlowty"))!
            
            if !acct.getCapability<&{NonFungibleToken.Provider}>(flowtyNftCollectionProviderPath).check() {
                acct.unlink(flowtyNftCollectionProviderPath)
                acct.link<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(flowtyNftCollectionProviderPath, target: storageCollectionPath)
            }

            self.nftProvider = acct.getCapability<&{NonFungibleToken.Provider}>(flowtyNftCollectionProviderPath)
            assert(self.nftProvider.check(), message: "invalid provider capability")
        } else {
            let collectionProviderPrivatePath = PrivatePath(identifier: nftProviderPathIdentifier) ?? panic("invalid provider path identifier")

            // Get child nft provider for auto return, if path doesn't exist we can't create it
            let manager = acct.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
            ?? panic("manager does not exist")
            let childAcct = manager.borrowAccount(addr: nftProviderAddress) ?? panic("nftProvider account not found")
            let receiverCap = childAcct.getCapability(path: collectionProviderPrivatePath, type: Type<&{NonFungibleToken.Provider}>()) ?? panic("no cap found")

            self.nftProvider = receiverCap as! Capability<&{NonFungibleToken.Provider}>
            assert(self.nftProvider.check(), message: "invalid provider capability")
        }

        let ref = self.nftProvider.borrow()!

        let nft <- ref.withdraw(withdrawID: nftID)

        if ftReceiverAddress == acct.address {
            self.ftReceiver = acct.getCapability<&{FungibleToken.Receiver}>(tokenInfo.receiverPath)
        } else {
            let manager = acct.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
            ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: ftReceiverAddress) ?? panic("no child account with that address")
            self.ftReceiver = getAccount(ftReceiverAddress).getCapability<&{FungibleToken.Receiver}>(tokenInfo.receiverPath)
        }

        assert(self.ftReceiver.borrow() != nil, message: "Missing or mis-typed FlowToken receiver")  

        storefront.acceptOffer(offerResourceID: offerID, nft: <-nft, receiver: self.ftReceiver)
    }
}`

const acceptOfferNotCatalog = (config: Config): string =>
	`import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import ViewResolver from ${config.contractAddresses.ViewResolver}

import Offers from ${config.contractAddresses.Offers}
import FlowtyUtils from ${config.contractAddresses.FlowtyUtils}

import HybridCustody from ${config.contractAddresses.HybridCustody}

transaction(
  collectionAddress: Address,
  nftID: UInt64,
  offerID: UInt64,
  storefrontAddress: Address,
  nftProviderPathIdentifier: String,
  collectionName: String,
  nftProviderAddress: Address,
  ftReceiverAddress: Address
) {
    let nftProvider: Capability<&{NonFungibleToken.Provider}>
    let ftReceiver: Capability<&{FungibleToken.Receiver}>

    prepare(acct: AuthAccount) {
        let storefront = getAccount(storefrontAddress).getCapability<&Offers.Storefront{Offers.StorefrontPublic}>(Offers.OffersPublicPath)
            .borrow() ?? panic("storefront not found")
        let offer = storefront.borrowOffer(offerResourceID: offerID) ?? panic("offer not found")
        let details = offer.getDetails()

        let contract = getAccount(collectionAddress).contracts.borrow<&ViewResolver>(name: collectionName) ?? panic ("Specified contract address and name is not found or does not implement ViewResolver contract.")
        let md = contract.resolveView(Type<MetadataViews.NFTCollectionData>()) ?? panic("NFTCollectionData view not found on the contract.")
        let collectionData = md as! MetadataViews.NFTCollectionData
        
        let tokenInfo = FlowtyUtils.getTokenInfo(details.paymentTokenType) ?? panic("token info not found")

        if nftProviderAddress == acct.address {
            let flowtyNftCollectionProviderPath = /private/ExampleNFT0xf8d6e0586b0a20c7CollectionProviderForFlowty
            
            if !acct.getCapability<&{NonFungibleToken.Provider}>(flowtyNftCollectionProviderPath).check() {
                acct.unlink(flowtyNftCollectionProviderPath)
                acct.link<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(flowtyNftCollectionProviderPath, target: collectionData.storagePath)
            }

            if !acct.getCapability<&{NonFungibleToken.CollectionPublic}>(collectionData.publicPath).check() {
                // we do not unlink first because this does not come from the NFT Catalog.
                acct.link<&{NonFungibleToken.CollectionPublic}>(collectionData.publicPath, target: collectionData.storagePath)
            }

            self.nftProvider = acct.getCapability<&{NonFungibleToken.Provider}>(flowtyNftCollectionProviderPath)
        } else {
            let collectionProviderPrivatePath = PrivatePath(identifier: nftProviderPathIdentifier) ?? panic("invalid provider path identifier")

            // Get child nft provider for auto return, if path doesn't exist we can't create it
            let manager = acct.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("manager does not exist")
            let childAcct = manager.borrowAccount(addr: nftProviderAddress) ?? panic("nftProvider account not found")
            let receiverCap = childAcct.getCapability(path: collectionProviderPrivatePath, type: Type<&{NonFungibleToken.Provider}>()) ?? panic("no cap found")

            self.nftProvider = receiverCap as! Capability<&{NonFungibleToken.Provider}>
        }

        let ref = self.nftProvider.borrow() ?? panic("invalid nft provider")

        let nft <- ref.withdraw(withdrawID: nftID)

        if ftReceiverAddress == acct.address {
            self.ftReceiver = acct.getCapability<&{FungibleToken.Receiver}>(tokenInfo.receiverPath)
        } else {
            let manager = acct.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: ftReceiverAddress) ?? panic("no child account with that address")
            self.ftReceiver = getAccount(ftReceiverAddress).getCapability<&{FungibleToken.Receiver}>(tokenInfo.receiverPath)
        }

        assert(self.ftReceiver.borrow() != nil, message: "Missing or mis-typed FlowToken receiver")  

        storefront.acceptOffer(offerResourceID: offerID, nft: <-nft, receiver: self.ftReceiver)
    }
}`

const acceptOfferDapperWallet = (
	config: Config
): string => `import OffersV2 from ${config.contractAddresses.OffersV2_Dapper}
import DapperOffersV2 from ${config.contractAddresses.OffersV2_Dapper}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import DapperUtilityCoin from ${config.contractAddresses.DapperUtilityCoin}

transaction(nftID: UInt64, offerId: UInt64, DapperOfferAddress: Address, storagePathIdentifier: String) {
	let dapperOffer: &DapperOffersV2.DapperOffer{DapperOffersV2.DapperOfferPublic}
	let offer: &OffersV2.Offer{OffersV2.OfferPublic}
	let receiverCapability: Capability<&{FungibleToken.Receiver}>
	prepare(signer: AuthAccount) {
		// Get the DapperOffers resource
		self.dapperOffer = getAccount(DapperOfferAddress)
			.getCapability<&DapperOffersV2.DapperOffer{DapperOffersV2.DapperOfferPublic}>(
			DapperOffersV2.DapperOffersPublicPath
		).borrow()
		?? panic("Could not borrow DapperOffer from provided address")
		// Set the fungible token receiver capabillity
		self.receiverCapability = signer.getCapability<&{FungibleToken.Receiver}>(/public/dapperUtilityCoinReceiver)
		assert(self.receiverCapability.borrow() != nil, message: "Missing or mis-typed DapperUtilityCoin receiver")
		// Get the DapperOffer details
		self.offer = self.dapperOffer.borrowOffer(offerId: offerId)
	?? panic("No Offer with that ID in DapperOffer")

		let details = self.offer.getDetails()

		// Get the NFT resource and withdraw the NFT from the signers account
		let nftCollection = signer.borrow<&{NonFungibleToken.Provider}>(from: StoragePath(identifier: storagePathIdentifier)!)
	?? panic("Cannot borrow NFT collection receiver from account")

		let nft <- (nftCollection.withdraw(withdrawID: nftID) as! @AnyResource) as! @AnyResource{NonFungibleToken.INFT, MetadataViews.Resolver}

		self.offer.accept(
			item: <-nft,
			receiverCapability: self.receiverCapability
	)
	}
	execute {
		// delete the offer
		self.dapperOffer.cleanup(offerId: offerId)
	}
}`

const acceptOfferCatalogCrescendo = (
	config: Config
): string => `import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}

import Offers from ${config.contractAddresses.Offers}
import FlowtyUtils from ${config.contractAddresses.FlowtyUtils}

import HybridCustody from ${config.contractAddresses.HybridCustody}

transaction(nftID: UInt64, offerID: UInt64, storefrontAddress: Address, nftProviderControllerID: UInt64, collectionIdentifier: String, nftProviderAddress: Address, ftReceiverAddress: Address) {
    let nftProvider: auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}
    let ftReceiver: Capability<&{FungibleToken.Receiver}>

    prepare(acct: auth(Storage, Capabilities) &Account) {
        let storefront = getAccount(storefrontAddress).capabilities.get<&{Offers.StorefrontPublic}>(Offers.OffersPublicPath)
            .borrow() ?? panic("storefront not found")
        let offer = storefront.borrowOffer(offerResourceID: offerID) ?? panic("offer not found")
        let details = offer.getDetails()
        let tokenInfo = FlowtyUtils.getTokenInfo(details.paymentTokenType) ?? panic("token info not found")

        let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

        let storageCollectionPath = catalogEntry.collectionData.storagePath

        if nftProviderAddress == acct.address {
            let contractName: String = catalogEntry.contractName
            let contractAddress: String = catalogEntry.contractAddress.toString()

            self.nftProvider = acct.storage.borrow<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}>(from: storageCollectionPath)
                ?? panic("provider not found")
        } else {
            // Get child nft provider for auto return, if path doesn't exist we can't create it
            let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
            ?? panic("manager does not exist")
            let childAcct = manager.borrowAccount(addr: nftProviderAddress) ?? panic("nftProvider account not found")
            let receiverCap = childAcct.getCapability(controllerID: nftProviderControllerID, type: Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}>()) ?? panic("no cap found")

            let cap = receiverCap as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}>
            self.nftProvider = cap.borrow() ?? panic("invalid provider capability")
        }

        let nft <- self.nftProvider.withdraw(withdrawID: nftID)

        if ftReceiverAddress == acct.address {
            self.ftReceiver = acct.capabilities.get<&{FungibleToken.Receiver}>(tokenInfo.receiverPath)
        } else {
            let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
            ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: ftReceiverAddress) ?? panic("no child account with that address")
            self.ftReceiver = getAccount(ftReceiverAddress).capabilities.get<&{FungibleToken.Receiver}>(tokenInfo.receiverPath)
        }

        assert(self.ftReceiver.borrow() != nil, message: "Missing or mis-typed FlowToken receiver")  

        storefront.acceptOffer(offerResourceID: offerID, nft: <-nft, receiver: self.ftReceiver)
    }
}`

const acceptOfferNotCatalogCrescendo = (
	config: Config
): string => `import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import ViewResolver from ${config.contractAddresses.ViewResolver}

import Offers from ${config.contractAddresses.Offers}
import FlowtyUtils from ${config.contractAddresses.FlowtyUtils}

import HybridCustody from ${config.contractAddresses.HybridCustody}

transaction(
  collectionAddress: Address,
  nftID: UInt64,
  offerID: UInt64,
  storefrontAddress: Address,
  nftProviderControllerID: UInt64,
  collectionName: String,
  nftProviderAddress: Address,
  ftReceiverAddress: Address
) {
    let nftProvider: auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}
    let ftReceiver: Capability<&{FungibleToken.Receiver}>

    prepare(acct: auth(Capabilities, Storage) &Account) {
        let storefront = getAccount(storefrontAddress).capabilities.get<&{Offers.StorefrontPublic}>(Offers.OffersPublicPath)
            .borrow() ?? panic("storefront not found")
        let offer = storefront.borrowOffer(offerResourceID: offerID) ?? panic("offer not found")
        let details = offer.getDetails()

        let c = getAccount(collectionAddress).contracts.borrow<&{ViewResolver}>(name: collectionName) ?? panic ("Specified contract address and name is not found or does not implement ViewResolver contract.")
        let md = c.resolveContractView(resourceType: nil, viewType: Type<MetadataViews.NFTCollectionData>()) ?? panic("NFTCollectionData view not found on the contract.")
        let collectionData = md as! MetadataViews.NFTCollectionData
        
        let tokenInfo = FlowtyUtils.getTokenInfo(details.paymentTokenType) ?? panic("token info not found")

        if nftProviderAddress == acct.address {
            if !acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(collectionData.publicPath).check() {
                // we do not unlink first because this does not come from the NFT Catalog.
                acct.capabilities.publish(
                    acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(collectionData.storagePath),
                    at: collectionData.publicPath
                )
            }

            self.nftProvider = acct.capabilities.storage.issue<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}>(collectionData.storagePath).borrow()
                ?? panic("invalid nft provider borrowed from storage")
        } else {
            // Get child nft provider for auto return, if path doesn't exist we can't create it
            let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("manager does not exist")
            let childAcct = manager.borrowAccount(addr: nftProviderAddress) ?? panic("nftProvider account not found")
            let receiverCap = childAcct.getCapability(controllerID: nftProviderControllerID, type: Type<&{NonFungibleToken.Provider}>()) ?? panic("no cap found")

            let cap = receiverCap as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}>
            self.nftProvider = cap.borrow() ?? panic("invalid nft provider")
        }

        let nft <- self.nftProvider.withdraw(withdrawID: nftID)

        if ftReceiverAddress == acct.address {
            self.ftReceiver = acct.capabilities.get<&{FungibleToken.Receiver}>(tokenInfo.receiverPath)
        } else {
            let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: ftReceiverAddress) ?? panic("no child account with that address")
            self.ftReceiver = getAccount(ftReceiverAddress).capabilities.get<&{FungibleToken.Receiver}>(tokenInfo.receiverPath)
        }

        assert(self.ftReceiver.borrow() != nil, message: "Missing or mis-typed FlowToken receiver")  

        storefront.acceptOffer(offerResourceID: offerID, nft: <-nft, receiver: self.ftReceiver)
    }
}`

const acceptOfferDapperWalletCrescendo = (config: Config): string => ``
