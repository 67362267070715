import { OpensearchStorefrontAvailableData, Order } from "flowty-common"
import { FlowtyDiscountBanner } from "../../../../FlowtyDiscountBanner"
import { FlowtyModalCarousel } from "../../../../FlowtyModalCarousel"
import { useFlowtyPurchaseModalContext } from "../../../contexts/FlowtyPurchaseModalContext/FlowtyPurchaseModalContext"
import { NftPrice } from "../../common/NftPrice"

interface FundStorefrontViewProps {
	storefrontOrders?: OpensearchStorefrontAvailableData[]
	singleStorefrontOrder?: OpensearchStorefrontAvailableData
	selectedOrder?: Order | null
}

export const FundStorefrontView: React.FunctionComponent<
	FundStorefrontViewProps
> = ({ storefrontOrders, singleStorefrontOrder, selectedOrder }) => {
	const { spotPrice, updateSelectedOrder, valuationData } =
		useFlowtyPurchaseModalContext()

	return (
		<div className='flex flex-col gap-3'>
			{storefrontOrders && storefrontOrders.length > 1 && (
				<FlowtyModalCarousel
					orders={storefrontOrders}
					selectedOrder={selectedOrder as Order}
					updateSelectedOrder={updateSelectedOrder}
				/>
			)}
			{(singleStorefrontOrder ||
				(storefrontOrders && storefrontOrders.length === 1)) && (
				<div className='w-full flex flex-col justify-between my-4 gap-3'>
					<div className='w-full flex justify-between'>
						<p className='font-[400] text-[14px] text-white'>Purchase Price</p>
						<NftPrice
							token={
								singleStorefrontOrder
									? singleStorefrontOrder.paymentTokenName
									: storefrontOrders?.[0].paymentTokenName || "FLOW"
							}
							price={
								singleStorefrontOrder
									? singleStorefrontOrder.amount
									: storefrontOrders?.[0].amount || 0
							}
							spotPriceValue={
								spotPrice
									? spotPrice.value *
									  Number(
											singleStorefrontOrder
												? singleStorefrontOrder.amount
												: storefrontOrders?.[0].amount || 0
									  )
									: undefined
							}
						/>
					</div>
				</div>
			)}
			{valuationData?.percentage ? (
				<FlowtyDiscountBanner
					source={valuationData.source}
					percent={valuationData?.percentage || 0}
				/>
			) : null}
		</div>
	)
}
