import EmptyNotificationMenu from "./EmptyNotificationMenu"
import NotificationButton from "./NotificationButton"
import NotificationMenu from "./NotificationMenu"
import { useControlNotificationsMenu } from "./hooks/useControlNotificationsMenu"
import { FlowtyUserNotificationsProps } from "./types"

export const FlowtyUserNotifications = ({
	notifications,
	queueMessage,
	hasMore,
	fetchMoreNotifications,
	loading,
}: FlowtyUserNotificationsProps) => {
	const { setMouseExited, showMenuNotifications, toggleMenu } =
		useControlNotificationsMenu()
	return (
		<div>
			<div className='md:relative'>
				<NotificationButton
					onMouseEnter={() => setMouseExited(false)}
					onMouseLeave={() => setMouseExited(true)}
					isPulsing={
						notifications?.find(item => item.status === "PROCESSING") !==
						undefined
					}
					onClick={toggleMenu}
					isActive={showMenuNotifications}
				/>
				<div className='mt-4 px-[32px] md:px-0 fixed md:absolute mx-auto w-screen md:w-auto left-1/2 -translate-x-1/2 md:left-0 md:translate-x-0 md:right-0 flex justify-end'>
					{!loading && showMenuNotifications && notifications.length > 0 && (
						<NotificationMenu
							notifications={notifications}
							queueMessage={queueMessage}
							hasMore={hasMore}
							fetchMoreNotifications={fetchMoreNotifications}
							onMouseEnter={() => setMouseExited(false)}
							onMouseLeave={() => setMouseExited(true)}
						/>
					)}
					{!loading && showMenuNotifications && notifications.length <= 0 && (
						<EmptyNotificationMenu
							onMouseEnter={() => setMouseExited(false)}
							onMouseLeave={() => setMouseExited(true)}
						/>
					)}
				</div>
			</div>
		</div>
	)
}
