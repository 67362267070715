import * as admin from "firebase-admin"
import { NFT } from "./alchemy"
import { Card } from "./flow"
import { ChildAccountDisplay } from "./hybridCustody"
import { CalculatedLoanValues } from "./loanDataUtil"
import { SupportedTokens } from "./tokens"
import { Valuation } from "./valuations/types"

// common
export interface Taskable {
	tasks: Task[]
}

export interface Task {
	gcTaskName: string
	taskType: string
}

// nftCollection
export enum NFTCollectionNames {
	Example = "Example Nft",
	Versus = "Versus",
	NBATopShot = "NBA Top Shot",
	Gaia = "Gaia",
}

export const NFT_COLLECTION_PATHS: Record<NFTCollectionNames, string> = {
	[NFTCollectionNames.Example]: "ExampleNFT.CollectionPublicPath",
	[NFTCollectionNames.Versus]: "/public/versusArtCollection",
	[NFTCollectionNames.NBATopShot]: "/public/MomentCollection",
	[NFTCollectionNames.Gaia]: "Gaia.CollectionPublicPath",
}

export const NFT_CONTRACT_NAMES: Record<NFTCollectionNames, string> = {
	[NFTCollectionNames.Example]: "ExampleNFT",
	[NFTCollectionNames.Versus]: "", // TODO
	[NFTCollectionNames.NBATopShot]: "TopShot",
	[NFTCollectionNames.Gaia]: "Gaia",
}

export type CurrentlySupportedCollectionNames = Exclude<
	NFTCollectionNames,
	NFTCollectionNames.Versus
>

export interface NftCollection {
	id?: string
	collectionName: NFTCollectionNames
	collectionPath: string
	nftType: string
	contractName: string
}

export interface NFTCollection {
	contractName: string // or maybe resourceName?
	address: string
	storagePath: string
	publicPath: string
	publicCollectionType?: string // TopShot's collectionType is "MomentCollectionPublic", defaults to
	nftType: string
}

// nftInfo

export interface NftMetadata {
	name: string
	description: string
	url: string
	metadata?: { [key: string]: any } // a catch-all for collections that don't need special attention like topshot does.
}

export interface NftModel {
	id: string
	metadata: NftMetadata
	url: string
	collectionPath: string
	nftId: string
	royalty?: number
	backlink?: string
	nft?: NFT
	card?: Card
}

export interface ListingDetail {
	nft?: NFT
	storefrontAddress: string
	card?: Card
}

export type SupportedCurrencies = SupportedTokens | "USD"

export interface CurrencyAmount {
	currency: SupportedCurrencies
	amount: number
}

// TODO should valuation types be mapped to providers? so we always know if a
// type/provider pair is valid
// TODO semantic naming for valuation types - we can keep a single type of
// "USDValue" and then add provider specific types as needed, e.g. "MRValueBeta"
export type NFTValuationType = "Flov.dev" | "OwnTheMoment" | "Blended Average"

export interface NFTValuationDescription {
	name: NFTValuationType
	summary: string
}

export enum NFTValuationProvider {
	FlovDev = "Flov.dev",
	OwnTheMoment = "OwnTheMoment",
	Blended = "Blended",
}

// listingAvailable

export enum ListingStatus {
	Listed = "LISTED",
	Funded = "FUNDED",
	Repaid = "REPAID",
	Delisted = "DELISTED",
	Cancelled = "CANCELLED",
	Settled = "SETTLED",
	Invalid = "INVALID",
	Rented = "RENTED",
	Returned = "RETURNED",
	Expired = "EXPIRED",
	Purchased = "PURCHASED",
}

export interface SingleValuationBase {
	dateAdded: Date
	description: NFTValuationDescription
	source: NFTValuationProvider
	value: CurrencyAmount
	// type: ContractsWithValuations
}

export interface SingleValuation extends SingleValuationBase {
	value: {
		amount: number
		currency: "USD"
	}
	url?: string // for FE consumption
}

export const NFT_CONTRACT_TO_VALUATION_PROVIDER = {
	AllDay: [NFTValuationProvider.OwnTheMoment],
	Flovatar: [NFTValuationProvider.FlovDev],
	TopShot: [NFTValuationProvider.OwnTheMoment],
} as const

// TODO would be nice to derive this from CurrentlySupportedCollectionNames
export type ContractsWithValuations =
	keyof typeof NFT_CONTRACT_TO_VALUATION_PROVIDER

export const isContractWithValuation = (
	contractName: string
): contractName is ContractsWithValuations => {
	return contractName in NFT_CONTRACT_TO_VALUATION_PROVIDER
}

export type AllDayValuationProviders =
	(typeof NFT_CONTRACT_TO_VALUATION_PROVIDER)["AllDay"][number]

export type TopShotValuationProviders =
	(typeof NFT_CONTRACT_TO_VALUATION_PROVIDER)["TopShot"][number]

export type FlovatarValuationProviders =
	(typeof NFT_CONTRACT_TO_VALUATION_PROVIDER)["Flovatar"][number]

export type AllDayValuations<ValuationListingType extends SingleValuationBase> =
	{
		[NFTValuationProvider.OwnTheMoment]: ValuationListingType | null
	}

export type TopShotValuations<
	ValuationListingType extends SingleValuationBase
> = {
	[NFTValuationProvider.OwnTheMoment]: ValuationListingType | null
}

export type FlovatarValuations<
	ValuationListingType extends SingleValuationBase
> = {
	[NFTValuationProvider.FlovDev]: ValuationListingType
}

export type NFTValuations<ValuationsType extends SingleValuationBase> =
	| AllDayValuations<ValuationsType>
	| FlovatarValuations<ValuationsType>
	| TopShotValuations<ValuationsType>

export type LoanValuation = SingleValuationBase & {
	loanToValueRatio: number
}

interface AggregateValuationBase extends SingleValuationBase {
	tokenValue: CurrencyAmount
}

export type AggregateLoanValuation = AggregateValuationBase & LoanValuation

// complete interface, stored in the DB
export interface NFTValuationsData extends SingleValuationBase {
	nftID: number
	nftType: string // type identifier
	collectionName: string
	collectionAddress: string
}

export type ListingValuations = {
	aggregate: AggregateLoanValuation
	sourceValuations: NFTValuations<LoanValuation>
}

export interface ListingAvailableBase {
	id: string
	listingResourceID: string
	listingType: string
	blockTimestamp: Date
	blockHeight: number
	latestBlockHeight: number
	transactionId: string
	state: ListingStatus
	type: string

	flowtyStorefrontAddress: string
	nftID: string
	nftType: string // cadence type identifier
	flowtyStorefrontID?: number
	paymentTokenType: string // cadence type identifier
	paymentTokenName: SupportedTokens
	expiresAfter?: string

	detail?: ListingDetail | NftModel

	royaltyRate?: number

	lastValuation?: number
}

export interface ListingAvailableData extends ListingAvailableBase {
	amount: string
	interestRate: string
	derivations?: {
		apr?: number
		calculatedValues: CalculatedLoanValues
		marketplaceAPR?: number
	}
	settleDeadline?: admin.firestore.Timestamp
	valuations: ListingValuations | null
	enabledAutoRepayment: boolean
	term: string
	lastValidated: Date
}

// rentalAvailable

export interface RentalValuation extends SingleValuationBase {
	depositToValueRatio: number
}

export type AggregateRentalValuation = RentalValuation & AggregateValuationBase

export interface RentalListingValuations {
	aggregate: AggregateRentalValuation
	sourceValuations: NFTValuations<RentalValuation>
}

export interface RentalListingAvailableData extends ListingAvailableBase {
	renter: string | null
	deposit: number
	valuations: RentalListingValuations | null
	rentalResourceID: number
	detail?: ListingDetail
	lastValidated?: Date
	amount: number
	term: number
}

export interface StorefrontV2ListingAvailableEvent {
	storefrontAddress: string
	listingResourceID: string
	nftID: string
	nftType: string
	nftUUID: string
	salePaymentVaultType: string
	expiry: number
	salePrice: number
	commissionAmount: number
	commissionReceivers: string[] | null
	customID: string | null
}

export interface StorefrontV2ListingAvailable
	extends StorefrontV2ListingAvailableEvent,
		ListingAvailableBase {
	id: string
	blockTimestamp: Date
	transactionId: string
	state: ListingStatus
	card?: Card
	buyer?: string

	flowtyStorefrontAddress: string // keeping this for legacy reasons, the real event emits `storefrontAddress`
	lastValidated?: Date
	paymentTokenType: string // keeping this for legacy reasons, the real event emits `salePaymentVaultType`
	paymentTokenName: SupportedTokens

	amount: number // synthetic field copied for uniformity with other event types

	usdValue: number
	usdValueTimestamp: number
	valuations: { [key: string]: Valuation } | null
	valuationTimestamp: number | null
	valuationRatio: number | null
	valuationDifference: number | null

	// This field is only populated if the listing was purchased
	commissionReceiver?: string | null
	// This field is only populated if a listing was cancelled in the same transaction
	// as this one was made
	cancelledListingIDs?: string[]

	bulkListing?: boolean
	bulkPurchased?: boolean
	bulkCancelled?: boolean
}

export interface StorefrontV2ListingCompleted {
	blockTimestamp: Date
	transactionId: string
	state: ListingStatus

	// listing details
	listingResourceID: number
	storefrontAddress: string
	storefrontResourceID: number
	purchased: boolean
	nftID: number
	nftType: string
	nftUUID: string
	salePaymentVaultType: string
	salePrice: number
	customID: string | null
	commissionAmount: number
	commissionReceiver: string | null
	expiry: number

	flowtyStorefrontAddress: string // keeping this for legacy reasons, the real event emits `storefrontAddress`
	lastValidated?: Date
	paymentTokenType: string // keeping this for legacy reasons, the real event emits `salePaymentVaultType`
	paymentTokenName: SupportedTokens
	buyer: string | null

	amount: number // synthetic field copied for uniformity with other event types
	blockHeight: number

	bulkPurchased?: boolean
	bulkCancelled?: boolean
}

export interface OfferBase extends UsdValue {
	blockTimestamp: number
	transactionId: string
	storefrontAddress: string
	offerResourceID: string
	offerKind: OfferKind
	paymentTokenName: string
	paymentTokenType: string
	offeredAmount: number
	amount: number
	flowtyStorefrontAddress: string
	taker: string | null
	payer?: string

	card: Card | null

	offerResourceType: string
	expiry: number

	typeAndIDOffer?: TypeAndIDFilter | null
	globalOffer?: GlobalOffer | null
	editionNameOffer?: EditionNameOffer | null

	// the remaining number of tokens this offer amounts to
	remainingValue: number
	// the USD value of the remaining token count of this offer
	remainingValueUSD: number

	lastValidated?: number | null

	type?: string | null
}

export interface UsdValue {
	usdValue: number
}

export interface OfferCreated extends OfferBase, UsdValue {
	paymentTokenType: string
	numAcceptable: number
	expiry: number
	taker: string | null

	// fields obtained from the details struct of the offer
	filterGroup: FilterGroup | null
	remaining: number

	// synthetic fields to normalize against our other kinds of events
	lastValidated?: number
	active: boolean
	hidden: boolean
	state: string

	valuations: { [key: string]: Valuation } | null
	valuationTimestamp: number | null
	valuationRatio: number | null
	valuationDifference: number | null
}

export interface DapperOfferCompleted extends OfferBase, UsdValue {
	offerAddress: string
	offerId: string
	offerAmount: number
	offerType: string
	nftID: string
	nftType: string
}

export interface DapperOfferAvailable extends OfferBase, UsdValue {
	offerAddress: string
	offerId: string
	nftType: string
	offerAmount: number
	offerType: string
	offerParamsString: { [key: string]: string } // this field will not be searchable in elastic!
	offerParamsUFix64: { [key: string]: number } // this field will not be searchable in elastic!
	offerParamsUInt64: { [key: string]: number } // this field will not be searchable in elastic!
	paymentVaultType: string

	active: boolean
	lastValidated?: number
	resolverType?: string | null // this is a synthetic field which doesn't exist on the Offer event itself
	resolverKind?: DapperOfferKind // this is a synthetic field and is derived from resolverType and offerType
	hidden: boolean

	state: string
	usdValue: number // this is always going to be the same as offer amount

	// these fields will always be equal to offerAmount and the use value of offer amount,
	// but we need to ensure that we have all the same shared fields across our offers and dapper's offer
	remainingValue: number
	remainingValueUSD: number

	valuations: { [key: string]: Valuation } | null
	valuationTimestamp: number | null
	valuationRatio: number | null
	valuationDifference: number | null
}

export interface OfferCancelled extends OfferBase {
	typeAndIDOffer?: TypeAndIDFilter | null
	globalOffer?: GlobalOffer | null
}

export type OfferCompleted = OfferBase

export interface OfferAccepted extends OfferBase {
	paymentTokenType: string
	numAcceptable: number
	remaining: number
	dateTimestamp: admin.firestore.Timestamp
	taker: string
	nftID: string
	nftType: string
}

export enum OfferKind {
	None = "",
	Global = "global",
	EditionName = "editionName",
	TypeAndID = "typeAndID",
}

export enum DapperOfferKind {
	None = "None",
	DapperNFT = "DapperNFT",
	GaiaNFT = "GaiaNFT",
	FlowtyNFT = "FlowtyNFT",
	FlowtyGlobal = "FlowtyGlobal",
}

export interface FilterBase {
	filterType: string
}

export interface GlobalOffer extends FilterBase {
	nftType: string
}

export interface EditionNameOffer extends FilterBase {
	type: string
	name: string
}

export interface TypeAndIDFilter extends FilterBase {
	nftType: string
	nftID: string
}

export interface FilterGroup {
	filters: FilterBase[]
}

export interface OfferDetails {
	offerResourceID: number
	offeredAmount: number
	paymentTokenType: string
	filterGroup: FilterGroup
	expiry: number
	taker: string | null
	numAcceptable: number
	remaining: number
	commission: number
}

// fundingAvailable
export interface FundingAvailableEventData {
	borrower: string
	fundingResourceID: string
	lender: string
	listingResourceID: string
	nftID: string
	repaymentAmount: string
	settleDeadline: Date
	nftType: string
	card: Card | undefined
	paymentTokenName: SupportedTokens
	derivations: {
		apr: number
		calculatedValues: CalculatedLoanValues
		marketplaceAPR: number
	}
}

// fundingAvailable
export interface FundingAvailableData
	extends Taskable,
		FundingAvailableEventData {
	listingAvailable: ListingAvailableData
}

// listingRented
export interface ListingRentedData extends Taskable {
	flowtyStorefrontAddress: string
	flowtyStorefrontID: number
	renterAddress: string
	listingResourceID: number
	rentalResourceID: number
	nftID: number
	nftType: string
	amount: number
	deposit: number
	enabledAutomaticReturn: boolean
	returned: boolean
	settled: boolean
	settleDeadline: Date

	listingAvailable: RentalListingAvailableData
}

// swapListed
// TODO expand SwapListingAvailableData
// Will swaps have a flowtyStorefrontAddress or just a
export interface SwapListingAvailableData {
	listingResourceID: number
	blockTimestamp: Date
	transactionId: string
	flowtyStorefrontAddress: string
	nftType: string // cadence type identifier
	detail?: NftModel
}

// spotPrice
export interface SpotPrice {
	currency: string
	symbol: string
	value: number
	time: Date
}

// fundingDelisted
export interface FundingDelistedData {
	borrower: string
	fundingResourceID: number
	lender: string
	listingResourceID: number
	nftID: number
	repaymentAmount: string
	settleDeadline: Date
}

// event
export enum IEventType {
	Listed = "LISTED",
	Funded = "FUNDED",
	Repaid = "REPAID",
	Delisted = "DELISTED",
	Settled = "SETTLED",
	RentalListed = "RENTAL_LISTED",
	RentalRented = "RENTAL_RENTED",
	RentalReturned = "RENTAL_RETURNED",
	RentalSettled = "RENTAL_SETTLED",
	RentalDestroyed = "RENTAL_DESTROYED",
	StorefrontListed = "STOREFRONT_LISTED",
	StorefrontPurchased = "STOREFRONT_PURCHASED",
	StorefrontCompleted = "STOREFRONT_COMPLETED",
	StorefrontDelisted = "STOREFRONT_DELISTED",
	StorefrontOfferCreated = "STOREFRONT_OFFER_CREATED",
	StorefrontOfferCancelled = "STOREFRONT_OFFER_CANCELLED",
	StorefrontOfferAccepted = "STOREFRONT_OFFER_ACCEPTED",
	StorefrontOfferCompleted = "STOREFRONT_OFFER_COMPLETED",
}

export type IEventData =
	| ListingAvailableData
	| FundingAvailableData
	| FundingDelistedData
	| RentalListingAvailableData
	| EventBase

export interface EventModel {
	id: string
	accountAddress: string
	type: ListingStatus
	blockchainType: string
	blockTimestamp: Date
	transactionId: string
	paymentTokenType: string

	data: IEventData
}

export interface EventBase {
	borrower: string
	fundingResourceID: string
	lender: string
	listingResourceID: string
	nftID: number
	repaymentAmount: string
}

export interface PublicAccount {
	avatar: string | null
	userName: string | null
	addr: string | null
	childAccounts?: { [key: string]: ChildAccountDisplay } | null

	processed: boolean
	lastProcessed: number

	walletAddress: string | null
}

export type ViewSetting = "large" | "small"

export interface AccountData extends PublicAccount {
	email: string
	ingestOnCreate: boolean
	marketingEmail?: string
	hideDWAccountLinkingBanner?: boolean
	preferredCardSize: ViewSetting
	welcomePopupAcknowledged?: boolean
	hasAcceptedTermsV2?: boolean
	emailOptIn?: boolean
}

export type ListingKind = "loan" | "rental" | "storefront"

export type AllListingAvailableData =
	| StorefrontV2ListingAvailable
	| RentalListingAvailableData
	| ListingAvailableData
