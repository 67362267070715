import { OpensearchFlowNFT } from "flowty-common"
import React from "react"
import { BestStorefrontOffers } from "./BestStorefrontOffers"
import { NftOrderLoadingDisplay } from "./NftOrderLoadingDisplay"

interface NoOrdersDisplayProps {
	isLoading: boolean
	nft: OpensearchFlowNFT
}

export const NoOrdersDisplay: React.FC<NoOrdersDisplayProps> = ({
	isLoading,
	nft,
}) => (
	<>
		{isLoading ? (
			<NftOrderLoadingDisplay />
		) : (
			<>
				<span className='font-montserrat text-center mx-auto my-3 text-base flex flex-row justify-center items-center'>
					No Listings Available
				</span>
				<div className='w-full flex justify-end'>
					<BestStorefrontOffers
						dapperOffer={nft.bestDapperOffer}
						nonCustodialOffer={nft.bestNonCustodialOffer}
					/>
				</div>
			</>
		)}
	</>
)
