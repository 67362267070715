import { inject, observer } from "mobx-react"
import numeral from "numeral"
import { AuthStoreProp } from "../../../stores/AuthStore"
import { FLOW_IDENTIFIER, USDC_IDENTIFIER } from "../../../util/tokens"
import { TokenIcon } from "../../Tokens/tokenDisplays"

const TokenBalance: React.FC<AuthStoreProp> = ({ authStore }) => {
	return (
		<div className='flex flex-col items-start gap-y-[19px]'>
			<div className='flex flex-row items-center'>
				<span>
					<TokenIcon isLarge={true} token='FLOW' />
				</span>
				<span className='font-montserrat font-semibold text-white ml-2 text-sm'>
					{numeral(
						authStore?.loggedUser?.balance?.balances?.[FLOW_IDENTIFIER]
					).format("0,0.00")}
				</span>
				<span className='font-montserrat font-semibold text-white ml-2 text-sm'>
					FLOW
				</span>
			</div>
			<div className='flex flex-row items-center'>
				<span>
					<TokenIcon isLarge={true} token='USDC' />
				</span>
				<span className='font-montserrat font-semibold text-white ml-2 text-sm'>
					{numeral(
						authStore?.loggedUser?.balance?.balances?.[USDC_IDENTIFIER]
					).format("0,0.00")}
				</span>
				<span className='font-montserrat font-semibold text-white ml-2 text-sm'>
					USDC
				</span>
			</div>
		</div>
	)
}

export default inject("authStore")(observer(TokenBalance))
