/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	formatImageSource,
	getDurationPeriod,
} from "../../../../util/nftDataUtil"
import { OverlayValuation } from "../../../Shared/OverlayValuation"
import { TokenAmount } from "../../../Tokens/tokenDisplays"
import noImage from "../../../../assets/media/image_not_available.svg"
import { ActivityTableImage } from "../../../Shared/ActivityTableFallbackImage/ActivityTableImage"

// eslint-disable-next-line
export const userOwnerTableFields: (
	navigate: (path: string) => void
) => any = navigate => [
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center capitalize'>
				{item.state ? item.state.split("_").pop().toLowerCase() : ""}
			</div>
		),
		name: "state",
		sortable: false,
		title: "Status",
	},
	{
		customRender: ({ item }: any) => {
			return (
				<div>
					{!!item.detail?.card && (
						<div className='flex flex-row justify-start items-center'>
							<div className='w-[40px] mr-2'>
								<ActivityTableImage
									collectionAddress={
										item?.detail?.card?.collectionAddress || ""
									}
									collectionName={item?.detail?.card?.collectionName || ""}
									srcImg={formatImageSource(
										item?.detail?.card?.images?.[0]?.url ?? noImage
									)}
								/>
							</div>
							<div className='flex flex-col'>
								{item.detail?.card?.additionalDetails ? (
									<span className='text-[12px] text-primary font-montserrat font-extrabold truncate'>
										{item.detail?.card?.title}
									</span>
								) : (
									<>
										<span className='text-[12px] text-primary font-montserrat font-extrabold truncate'>
											{item.detail?.card?.title}
										</span>
										{item.detail?.card?.num &&
											!item.detail?.card?.title.includes("#") && (
												<span className='text-[12px] text-primary font-montserrat font-extrabold ml-2'>
													#{item.detail?.card?.num}
												</span>
											)}
									</>
								)}
								{item?.detail?.card?.additionalDetails ? (
									<>
										{item?.detail?.card?.additionalDetails ? (
											item?.detail?.card?.additionalDetails?.map(
												(detail: any) => (
													<span
														key={detail}
														className='font-montserrat text-primary text-[12px] font-light'
													>
														{detail}
													</span>
												)
											)
										) : (
											<div className='flex flex-row items-center justify-start mt-2 h-4'></div>
										)}
									</>
								) : (
									<>
										{item?.detail?.card?.headerTraits &&
											item?.detail?.card?.headerTraits?.map((trait: any) => (
												<span
													key={trait.displayType}
													className='font-montserrat text-primary text-[12px] font-light'
												>
													{trait.value}
												</span>
											))}
									</>
								)}
							</div>
						</div>
					)}
				</div>
			)
		},
		name: "detail",
		sortable: false,
		title: "Details",
	},
	{
		customRender: ({ item }: any) => {
			return (
				<div className='flex flex-row justify-start items-center'>
					<TokenAmount
						amount={item.amount}
						isSmall
						token={item.paymentTokenName}
					/>
				</div>
			)
		},
		name: "amount",
		sortable: false,
		title: "Rental Fee",
	},
	{
		customRender: ({ item }: any) => {
			return (
				<div className='flex flex-row justify-start items-center'>
					<TokenAmount
						amount={item.deposit}
						isSmall
						token={item.paymentTokenName}
					/>
				</div>
			)
		},
		name: "deposit",
		sortable: false,
		title: "Refundable Deposit",
	},
	{
		customRender: ({ item }: any) => {
			return (
				<div className='flex flex-row justify-start items-center'>
					<TokenAmount
						amount={item.deposit + item.amount}
						isSmall
						token={item.paymentTokenName}
					/>
				</div>
			)
		},
		name: "total",
		sortable: false,
		title: "Total Due Upfront",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				{getDurationPeriod(item.term)}
			</div>
		),
		name: "term",
		sortable: false,
		title: "Duration",
	},
	{
		customRender: ({ item }: any) => {
			if (item.valuations) {
				return (
					<OverlayValuation valuationType='DTV'>
						<div className='flex flex-row justify-start items-center'>
							{item.valuations.aggregate.depositToValueRatio.toFixed(2)}x
						</div>
					</OverlayValuation>
				)
			}
			return <div className='flex flex-row justify-start items-center'>N/A</div>
		},
		name: "valuations.aggregate.depositToValueRatio",
		sortable: false,
		title: "3rd Party DTV",
	},
	{
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		customRender: ({ item }: any) => (
			<div
				onClick={e => {
					e.preventDefault()
					e.stopPropagation()
					navigate(`/user/${item?.renter}`)
				}}
				className='flex flex-row justify-start items-center hover:text-green-300'
			>
				<a href={`/user/${item?.renter}`}>{item?.renter || "--"}</a>
			</div>
		),

		name: "flowtyStorefrontAddress",

		sortable: false,

		title: "Renter",
	},
]
