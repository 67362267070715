import React from "react"
import { TopAndRecentSalesResponse } from "../types"
import numeral from "numeral"

interface TopSalesDesktopProps {
	sales: TopAndRecentSalesResponse["data"] | null
	isLoading: boolean
}

export const TopSalesDesktop: React.FC<TopSalesDesktopProps> = ({
	sales,
	isLoading,
}) => {
	return (
		<div className='flex flex-col gap-[18px]'>
			{isLoading
				? sales?.map((_, idx) => (
						<div
							key={idx}
							className={`flex flex-row items-center bg-gray-400 animate-pulse cursor-wait h-[90px] rounded-[10px]`}
						></div>
				  ))
				: sales?.map((sale, index) => (
						<div
							key={index}
							className='flex flex-row items-center justify-between px-[24px] py-[12px] rounded-[10px]'
							style={{
								background: "rgba(255, 255, 255, 0.08)",
								boxShadow: "0px 4.11px 18.494px 0px rgba(0, 0, 0, 0.25)",
							}}
						>
							<a
								href={sale.attributes.assetDetailUrl}
								className='flex flex-row items-center top-sales-spacing'
							>
								<img
									src={sale.attributes.image.data[0].attributes.url}
									alt={sale.attributes.assetTitle}
									className='top-sales-image rounded-[10px]'
								/>
								<div className='flex flex-col gap-[6px] truncate'>
									<p className='flex flex-row items-center'>
										<span className='text-[#ADB5BD] font-bold top-sales-title truncate leading-normal'>
											{sale.attributes.assetTitle}
										</span>
										<span className='text-[#ADB5BD] font-bold top-sales-title leading-normal ml-1'>
											{sale.attributes.serial}
										</span>
									</p>
									<p className='text-[#ADB5BD] top-sales-subtitle leading-normal font-medium'>
										{sale.attributes.series}
									</p>
								</div>
							</a>
							<div className='flex flex-col gap-[6px] w-[70px] lg:w-[100px]'>
								<p className='text-[#A4A4A4] font-normal top-sales-describer leading-normal'>
									Sales Price
								</p>
								<p className='text-[#ADB5BD] font-bold top-sales-value leading-normal'>
									${numeral(sale.attributes.salePrice).format("0,0")}
								</p>
							</div>
							<a
								href={sale.attributes.buyerUrl}
								className='flex flex-col gap-[6px] w-[110px]'
							>
								<p className='text-[#A4A4A4] font-normal top-sales-describer leading-normal'>
									Buyer
								</p>
								<p className='text-[#ADB5BD] font-bold top-sales-value leading-normal'>
									{sale.attributes.buyerName}
								</p>
							</a>
							<div className='flex flex-col gap-[6px] '>
								<p className='text-[#A4A4A4] font-normal top-sales-describer leading-normal'>
									Purchased On
								</p>
								<p className='text-[#ADB5BD] font-bold top-sales-value leading-normal'>
									{sale.attributes.purchasedOn}
								</p>
							</div>
						</div>
				  ))}
		</div>
	)
}
