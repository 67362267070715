import { ChildAccountDisplay } from "flowty-common"
import sortBy from "lodash/sortBy"
import { inject, observer } from "mobx-react"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { AccountSummary } from "flowty-sdk"
import { useJsonQueryParameter } from "../../../hooks/useJsonQueryParameter"
import { User } from "../../../models/user"
import { AuthStoreProp } from "../../../stores/AuthStore"
import { selectedWalletFromLoggedUser } from "../../Modals/ListedActions/MultiActionModal/Reducer/checkAccount"
import { OpenSearchContext } from "../../OpenSearch/OpenSearchConnector"
import { LinkedAccount } from "./LinkedAccount"
import { FilterTag, ClearAllFilterTag } from "../FilterTag/FilterTag"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid"

type WalletDisplay = {
	address: string
	display: {
		name: string | undefined
		thumbnail: string | undefined
	}
	isMain?: boolean
}
interface LinkedAccountsProps extends AuthStoreProp {
	user: User | null
}

const LinkedAccountsComponent: React.FunctionComponent<LinkedAccountsProps> = ({
	user,
	authStore,
}) => {
	const loggedUser = authStore?.loggedUser
	const defaultWalletAddresses =
		useJsonQueryParameter<string[]>("walletAddresses")

	const [isOpen, setIsOpen] = useState(true)

	const {
		// walletAddresses: selectedWalletAddresses,
		setWalletAddresses,
	} = useContext(OpenSearchContext)
	const isAuthenticatedUser = loggedUser?.addr === user?.addr

	// TODO: this use of OpenSearchFilter I think is misapplied. Lets refactor out.
	// leaving in for the interest of time. To revisit with https://github.com/Flowtyio/flowty-app/issues/3874
	const [selectedWalletAddresses, setSelectedWalletAddresses] = useState<
		string[]
	>(defaultWalletAddresses ?? [])

	// TODO: REMOVE!
	// this is temporary while the wallet consumers pull from OpenSearchConnector
	// ideally this isn't the case anymore https://github.com/Flowtyio/flowty-app/issues/3874
	// I hate useEffects as a means of passing data
	useEffect(() => {
		// we're kind of masking wallet addresses.
		// none selected = all selected
		if (selectedWalletAddresses.length > 0) {
			setWalletAddresses(selectedWalletAddresses)
		} else {
			if (isAuthenticatedUser) {
				const addresses = Object.values(
					authStore?.loggedUser?.accountSummaries ?? {}
				).map(({ address }) => address)
				setWalletAddresses(addresses)
			} else {
				const childAddresses = Object.values(user?.accountSummaries ?? {}).map(
					({ address }) => address
				)

				const userAddress = user?.addr || ""

				const addresses: string[] = [userAddress, ...childAddresses]
				setWalletAddresses(addresses)
			}
		}
	}, [selectedWalletAddresses, authStore])

	const mainAccount = useMemo(() => {
		if (isAuthenticatedUser && authStore?.loggedUser) {
			return selectedWalletFromLoggedUser(authStore.loggedUser)
		}

		return {
			address: user?.addr || "",
			display: {
				name: user?.addr || "",
				thumbnail: user?.avatar || "",
			},
			isMain: true,
		}
	}, [])

	const childAccounts = useMemo<Array<WalletDisplay>>(() => {
		if (isAuthenticatedUser && authStore?.loggedUser) {
			return Object.values(authStore?.loggedUser?.accountSummaries ?? {})
		}

		if (user && user?.accountSummaries) {
			const userChilds = Object.values(user?.accountSummaries).map(acc => {
				return {
					address: acc.address,
					display: {
						name: acc.display?.name ?? "",
						thumbnail: acc.display?.thumbnail ?? "",
					},
					isMain: false,
				}
			})

			return [mainAccount, ...userChilds]
		}

		return []
	}, [user?.accountSummaries])

	const sortedAccounts = useMemo(() => {
		return sortBy(childAccounts, [
			(acc: ChildAccountDisplay) => acc.display?.name?.toLowerCase() ?? "",
			(acc: ChildAccountDisplay) =>
				acc.address ? acc.address.toLowerCase() : "",
		])
	}, [childAccounts])

	// state to indicate whether we've selected all or none.

	// on select none, revert to actually selecting no wallets

	const toggleWallet = useCallback(
		(address: string) => {
			setSelectedWalletAddresses(previousWalletAddresses => {
				// Toggle: if already there, remove it
				if (previousWalletAddresses.includes(address)) {
					return previousWalletAddresses.filter(
						walletAddress => walletAddress !== address
					)
				}
				// if not there, add it
				const nowSelected = [...previousWalletAddresses, address]

				// "No" wallets is the same as all wallets
				if (nowSelected.length === childAccounts.length) {
					return []
				}
				return nowSelected
			})
		},
		[childAccounts]
	)

	const isViewingAllWallets = useMemo(() => {
		const hasSelectedZeroWallets = selectedWalletAddresses.length === 0
		const hasSelectedAllWallets =
			selectedWalletAddresses.length === childAccounts.length

		return hasSelectedZeroWallets || hasSelectedAllWallets
	}, [selectedWalletAddresses, childAccounts])

	const walletAddressByDisplayMap = useMemo(() => {
		return childAccounts.reduce((result, account) => {
			return {
				...result,
				[account.address]: account.display?.name ?? account.address,
			}
		}, {} as Record<string, string>)
	}, [childAccounts])

	return (
		<div>
			{childAccounts.length > 1 && (
				<div className='!text-white bg-[#ffffff14] rounded-md p-[1.5rem]'>
					<div className='!border-none !rounded-md  w-full relative'>
						<div
							className='flex justify-between cursor-pointer'
							onClick={() => setIsOpen(!isOpen)}
						>
							<div className='flex flex-col md:flex-row justify-start md:items-center space-y-2 md:space-y-0 md:space-x-2'>
								<span className='text-white text-[1.125rem] font-[700]'>
									Viewing{" "}
								</span>
								{isViewingAllWallets ? (
									<span className='text-white underline text-[1.125rem] font-[700]'>
										All Accounts
									</span>
								) : (
									<div className='flex flex-col md:flex-row md:space-y-0 space-y-2 md:space-x-2'>
										{selectedWalletAddresses.map(wallet => (
											<div key={wallet}>
												<FilterTag
													label={walletAddressByDisplayMap?.[wallet] ?? wallet}
													onRemove={evt => {
														evt.preventDefault()
														evt.stopPropagation()

														toggleWallet(wallet)
													}}
												/>
											</div>
										))}
										<div className='hidden md:flex'>
											<ClearAllFilterTag
												onRemove={evt => {
													evt.preventDefault()
													evt.stopPropagation()
													setSelectedWalletAddresses([])
												}}
											/>
										</div>
										<div className='md:hidden'>
											<ClearAllFilterTag
												shouldHideBorder
												onRemove={evt => {
													evt.preventDefault()
													evt.stopPropagation()
													setSelectedWalletAddresses([])
												}}
											/>
										</div>
									</div>
								)}
							</div>
							{isOpen ? (
								<ChevronUpIcon height={24} width={24} />
							) : (
								<ChevronDownIcon height={24} width={24} />
							)}
						</div>
						{isOpen && (
							<div className='py-2 px-0 mt-4 overflow-x-auto styled-scroll'>
								<div className='flex flex-col items-start'>
									<div className='flex'>
										{mainAccount ? (
											<LinkedAccount
												belongsToAuthenticatedUser={isAuthenticatedUser}
												acc={mainAccount as AccountSummary}
												isSelected={selectedWalletAddresses.includes(
													`${mainAccount.address}`
												)}
												onSelect={toggleWallet}
											/>
										) : null}
										{sortedAccounts.map(acc => {
											if (
												(acc as ChildAccountDisplay).address ===
												mainAccount?.address
											)
												return null
											return (
												<LinkedAccount
													belongsToAuthenticatedUser={isAuthenticatedUser}
													key={(acc as ChildAccountDisplay).address}
													acc={acc as AccountSummary}
													isSelected={selectedWalletAddresses.includes(
														(acc as ChildAccountDisplay).address
													)}
													onSelect={toggleWallet}
												/>
											)
										})}
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	)
}

export const LinkedAccounts = inject("authStore")(
	observer(LinkedAccountsComponent)
)
