import { inject, observer } from "mobx-react"
import { useState } from "react"
import { HideFiltersBtn } from "../../../../../components/NftListComponents/NftListControllers/components/HideFiltersBtn/HideFiltersBtn"
import { MobileControllers } from "../../../../../components/NftListComponents/NftListControllers/components/MobileControllers.tsx/MobileControllers"
import { LoanRentalActionsView } from "../../../../../components/NftListComponents/NftListView/components/LoanRentalActionsView"
import { AuthStoreProp } from "../../../../../stores/AuthStore"
import { useLoanRentalActions } from "../../../hooks/useLoanRentalActions"
import { useViewport } from "../../../../../components/BulkActions/BulkActionsPopUp/hooks/useViewport"

const UserActionsNftListControllers: React.FunctionComponent<AuthStoreProp> = ({
	authStore,
}) => {
	const [isFilterHidden, setIsFilterHidden] = useState(false)
	const { allLoanRentalActivity, traitsFilters, isLoading } =
		useLoanRentalActions({
			accountSummaryAddresses: Object.keys(
				authStore?.loggedUser?.accountSummaries ?? {}
			),
			loggedUserAddress: authStore?.loggedUser?.addr ?? "",
		})

	const { width } = useViewport()
	const breakpoint = 1200
	const renderMobile = width < breakpoint

	return (
		<div className='w-full flex flex-col'>
			<div className='w-full flex gap-3'>
				<div
					className={`flex flex-col gap-4 lg:w-1/4 xl:w-1/6 min-w-[340px] max-lg:hidden sticky top-[148px] h-full ${
						isFilterHidden ? "hidden" : ""
					}`}
				>
					<div className='flex gap-3'>
						<HideFiltersBtn
							isFilterHidden={isFilterHidden}
							setIsFilterHidden={setIsFilterHidden}
						/>
					</div>
					<div
						className={`transition-width duration-200 ease-out ${
							isFilterHidden ? "w-0" : "min-w-[340px] lg:w-1/4 xl:w-1/6"
						}`}
					>
						<div
							className={`hidden flex-col xl:col-span-1 transition-opacity duration-[300ms] ease-out lg:flex ${
								isFilterHidden ? "opacity-0" : "opacity-100"
							}`}
						>
							<div className='border-glassFillGrayBorder p-[1rem] rounded-[0.5rem] border-[0.3px] hidden lg:overflow-y-auto overflow-x-hidden lg:flex lg:flex-col items-stretch'>
								<div className='mb-[1rem]'>{traitsFilters}</div>
							</div>
						</div>
					</div>
				</div>

				<div className='w-full flex flex-col gap-4'>
					{renderMobile ? (
						<MobileControllers
							mobilePageFilters={traitsFilters}
							isActionsTab
							address={authStore?.loggedUser?.addr ?? ""}
						/>
					) : (
						<div className='flex flex-col justify-center gap-3 max-lg:hidden h-[32px]'>
							<div className='flex justify-between'>
								<div className='flex gap-3'>
									<div
										className={`flex flex-col gap-3 lg:w-1/4 xl:w-1/6 min-w-[340px] ${
											!isFilterHidden ? "hidden" : ""
										}`}
									>
										<div className='flex gap-3'>
											<HideFiltersBtn
												isFilterHidden={isFilterHidden}
												setIsFilterHidden={setIsFilterHidden}
											/>
										</div>
									</div>

									<div className='font-montserrat flex items-center font-[700] flex-shrink-0 mr-2'>
										{!isLoading && (
											<p className='font-montserrat font-[700]'>{`${allLoanRentalActivity.length} Items`}</p>
										)}
									</div>
								</div>
							</div>
						</div>
					)}
					<div className={`w-full`}>
						<div className='min-h-screen relative flex flex-col justify-start w-full'>
							<LoanRentalActionsView
								allLoanBorrowerActivity={allLoanRentalActivity}
								isLoading={isLoading}
							/>
						</div>{" "}
					</div>
				</div>
			</div>
		</div>
	)
}

export const UserActionsControllers = inject("authStore")(
	observer(UserActionsNftListControllers)
)
