/* eslint-disable react/no-danger */
import React, { useEffect, useRef } from "react"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import {
	ExclamationCircleIcon,
	InformationCircleIcon,
	XIcon,
} from "@heroicons/react/solid"
import { useSporkContext } from "../../contexts/SporkContext"

const PageBanner: React.FC = () => {
	const { messageType, sporkMessage, isShowing, setIsShowing } =
		useSporkContext()
	const bannerRef = useRef<HTMLParagraphElement>(null)
	const scriptRan = useRef(false)

	useEffect(() => {
		if (!scriptRan.current && sporkMessage && isShowing) {
			const fragment = document
				.createRange()
				.createContextualFragment(sporkMessage)
			bannerRef.current?.append(fragment)
			scriptRan.current = true
		}
	}, [sporkMessage, isShowing])

	return (
		<>
			{sporkMessage && isShowing && (
				<div
					className={`rounded-md  bg-opacity-10 border-[0.5px]  p-6 mb-8 flex w-full
			${
				messageType === "info"
					? "bg-[#3A70D9] border-[#3A70D9]"
					: messageType === "warning"
					? "border-[#FF6E25] bg-[#FF6E25]"
					: "border-danger bg-danger"
			}
		`}
				>
					<div className='flex justify-between w-full'>
						<div className='flex'>
							<div className='px-2'>
								{messageType === "info" ? (
									<InformationCircleIcon
										className='h-6 w-6 text-[#3A70D9]'
										aria-hidden='true'
									/>
								) : (
									<ExclamationCircleIcon
										className={`h-6 w-6 ${
											messageType === "warning"
												? "text-[#FF6E25]"
												: "text-danger"
										}`}
									/>
								)}
							</div>
							<div className='flex flex-col px-1 py-0.5 '>
								<h2
									className={`uppercase  
				${
					messageType === "info"
						? "text-[#3A70D9]"
						: messageType === "warning"
						? "text-[#FF6E25]"
						: "text-danger"
				}
				`}
								>
									{messageType}
								</h2>
								<ReactMarkdown
									remarkPlugins={[remarkGfm]}
									className='text-base markdown-messages'
								>
									{sporkMessage}
								</ReactMarkdown>
							</div>
						</div>
						<div className='h-full flex items-center px-2'>
							<XIcon
								onClick={() => setIsShowing(false)}
								className='h-7 w-7 cursor-pointer hover:text-danger'
							/>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default PageBanner
