import numeral from "numeral"
import { useMemo } from "react"
import { TokenAmount } from "../../../../components/Tokens/tokenDisplays"
import BloctoLogo from "../../../../assets/media/blocto_logo.png"
import DapperLogo from "../../../../assets/media/dapper_logo.png"

interface CollectionMetrics {
	totalItems: number | undefined
	floorPrice: number | undefined
	totalOwners: number | undefined
	weekTopSales: number | undefined
	createdDate: string | undefined
}

export const CollectionMetrics: React.FunctionComponent<CollectionMetrics> = ({
	totalItems,
	floorPrice,
	totalOwners,
	weekTopSales,
	createdDate,
}) => {
	const calculateNumeral = (total: number | undefined) => {
		const numeralItemValue = numeral(total).format("0.0a").toLocaleUpperCase()

		if (numeralItemValue.endsWith(".0")) {
			return total
		}

		return numeralItemValue
	}

	const currentRoute = window.location.pathname

	const isCollectionRoute = useMemo(
		() => currentRoute.includes("/collection"),
		[currentRoute]
	)

	const collectionOffersEnabled = false

	return (
		<div className='flex flex-col md:flex-row gap-3 pb-2 justify-end'>
			<div className='flex flex-wrap gap-8 rounded-md border-1'>
				{totalItems && (
					<div className='flex flex-col'>
						<p className='text-sm font-medium text-[#ADB5BD] whitespace-nowrap'>
							Items
						</p>
						<p className='text-lg font-semibold leading-8'>
							{calculateNumeral(totalItems)}
						</p>
					</div>
				)}
				<div className='flex flex-col'>
					<p className='text-sm font-medium text-[#ADB5BD] whitespace-nowrap'>
						Floor Price
					</p>
					<p className='text-lg font-semibold leading-8'>
						{floorPrice ? `$${floorPrice.toFixed(2)}` : "-"}
					</p>
				</div>
				{weekTopSales && (
					<div className='flex flex-col'>
						<p className='text-sm font-medium text-[#ADB5BD] whitespace-nowrap'>
							7D Top Sales
						</p>
						<p className='text-lg font-semibold leading-8'>
							${weekTopSales.toFixed(2)}
						</p>
					</div>
				)}
				{totalOwners && totalItems && (
					<div className='flex flex-col'>
						<p className='text-sm font-medium text-[#ADB5BD] whitespace-nowrap'>
							Unique Owners
						</p>
						<p className='text-lg font-semibold leading-8'>
							{calculateNumeral(totalOwners)} (
							{((totalOwners * 100) / totalItems).toFixed(2)}%)
						</p>
					</div>
				)}
				{createdDate && (
					<div className='flex flex-col'>
						<p className='text-sm font-medium text-[#ADB5BD] whitespace-nowrap'>
							Created
						</p>
						<p className='text-lg font-semibold leading-8'>Feb 2022</p>
					</div>
				)}
			</div>
			{collectionOffersEnabled && isCollectionRoute && (
				<>
					<div className='rounded-md border-1 border-[#343a40a3] bg-[#ffffff05] backdrop-blur-[20px] px-[24px] py-[16px]'>
						<div className='flex gap-2'>
							<div className='w-[47px] h-[47px] flex items-center justify-center p-2 bg-[#FFFFFF29] rounded-md'>
								<img src={BloctoLogo} alt='offer' className='w-full h-full' />
							</div>
							<div className='flex flex-col'>
								<p className='text-sm font-medium text-[#ADB5BD] whitespace-nowrap'>
									Best Offer{" "}
								</p>
								<div className='flex gap-1'>
									<TokenAmount amount={1} token='USDC' isSmall />
									<p className='text-[#ADB5BD] font-bold'>≈0.99 USD</p>
								</div>
							</div>
						</div>
					</div>
					<div className='rounded-md border-1 border-[#343a40a3] bg-[#ffffff05] backdrop-blur-[20px] px-[24px] py-[16px]'>
						<div className='flex gap-2'>
							<div className='w-[47px] h-[47px] flex items-center justify-center p-2 bg-[#FFFFFF29] rounded-md'>
								<img src={DapperLogo} alt='offer' className='w-full h-full' />
							</div>
							<div className='flex flex-col'>
								<p className='text-sm font-medium text-[#ADB5BD] whitespace-nowrap'>
									Best Offer{" "}
								</p>
								<div className='flex gap-1'>
									<TokenAmount amount={1} token='DUC' isSmall />
									<p className='text-[#ADB5BD] font-bold'>≈0.99 USD</p>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	)
}
