import Layout from "./Layout"
import { PageHeader } from "../components/PageHeader"
import { Settings } from "../components/UserProfile/Settings"
import { subscribeEmailToNotification } from "../services/EmailService"
import { FlowtyFooter } from "ds-flowty"

const metaTitle = "Flowty: Your Profile Settings"
const metaDescription =
	"Flowty is an open, full-featured NFT marketplace for collectors, traders, investors and creators on the Flow blockchain. Flowty's transaction capabilities range from buying and selling to NFT-backed loans and NFT rentals."
const metaImage = "https://www.flowty.io/icons/icon192.png"

const SettingsScreen = () => (
	<div className='flex flex-col h-screen'>
		<div className='flex-grow'>
			<Layout>
				<div className='container'>
					<PageHeader
						metaTitle={metaTitle}
						metaDescription={metaDescription}
						metaImage={metaImage}
					/>
					<Settings />
				</div>
			</Layout>
		</div>
		<FlowtyFooter subscribeEmailToNotification={subscribeEmailToNotification} />
	</div>
)

export default SettingsScreen
