import { LoanRentalFilteredData } from "flowty-sdk"
import { useFlowtyModalsContext } from "../../../../contexts/FlowtyModalsContext"
import { NftActionsCard } from "../../../Shared/NftCard/NftActionsCard"
import { NftListLoading } from "../../NftListLoading/NftListLoading"
import { EmptyNftView } from "./EmptyNftView"

interface LoanRentalActionsViewProps {
	allLoanBorrowerActivity: LoanRentalFilteredData[]
	isLoading: boolean
}

export const LoanRentalActionsView: React.FC<LoanRentalActionsViewProps> = ({
	allLoanBorrowerActivity,
	isLoading,
}) => {
	const { selectCard } = useFlowtyModalsContext()
	return isLoading ? (
		<NftListLoading />
	) : (
		<>
			<ul
				className={`w-full h-full grid pb-4 gap-4`}
				style={{
					gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
				}}
			>
				{allLoanBorrowerActivity?.map(
					(item: LoanRentalFilteredData, i: number) => {
						const handleClick = (
							event:
								| React.MouseEvent<HTMLDivElement, MouseEvent>
								| React.KeyboardEvent<HTMLDivElement>
						): void => {
							event.preventDefault()
							selectCard({
								loanRentalActions: item,
								selected: {
									contractAddress: item.nftData.collectionAddress,
									contractName: item.nftData.collectionName,
									nftID: item.nftID,
									nftType: item.nftType,
								},
							})
						}

						return (
							<NftActionsCard
								key={`${item?.nftID}-${i}`}
								handleClick={handleClick}
								nft={item}
								isLoading={isLoading}
								isCatalog={true}
								didImageLoadFail={false}
							/>
						)
					}
				)}
			</ul>
			{allLoanBorrowerActivity.length === 0 && !isLoading && (
				<div className='pt-6'>
					<EmptyNftView isUnsupportedSelection={false} emptyActionsPage />
				</div>
			)}
		</>
	)
}
