import { IS_CRESCENDO, NFT_CONTRACT_ADDRESS } from "../../../util/settings"
import { sendTx } from "../../../services/flow/FlowUtil"
import { Err, Log } from "../../../util/Log"
import { FUNGIBLE_TOKEN_CONTRACT_ADDRESS } from "../../../util/tokens"

const fixExposedProviderTxn = `import NonFungibleToken from ${NFT_CONTRACT_ADDRESS}
import MetadataViews from ${NFT_CONTRACT_ADDRESS}
import FungibleToken from ${FUNGIBLE_TOKEN_CONTRACT_ADDRESS}

transaction {
  prepare(acct: AuthAccount) {
    let nftProviderType = Type<Capability<&{NonFungibleToken.Provider}>>()
    let ftProviderType = Type<Capability<&{FungibleToken.Provider}>>()

    let nftPaths: [PublicPath] = []
    let ftPaths: [PublicPath] = []

    acct.forEachPublic(fun (path: PublicPath, type: Type): Bool {
      if type.isSubtype(of: nftProviderType) {
        nftPaths.append(path)
      }

      if type.isSubtype(of: ftProviderType) {
        ftPaths.append(path)
      } 

      return true
    })

    for path in nftPaths {
      let t = acct.getLinkTarget(path)
      if t == nil {
        acct.unlink(path)
      }

      let target = t! as! StoragePath

      acct.unlink(path)
      acct.link<&{NonFungibleToken.CollectionPublic, MetadataViews.ResolverCollection}>(path, target: target)
    }

    for path in ftPaths {
      let t = acct.getLinkTarget(path)
      if t == nil {
        acct.unlink(path)
      }

      let target = t! as! StoragePath

      acct.unlink(path)
      acct.link<&{FungibleToken.Balance, FungibleToken.Receiver}>(path, target: target)
    }
  }
}`

const fixExposedProviderCrescendo = `
import NonFungibleToken from ${NFT_CONTRACT_ADDRESS}
import MetadataViews from ${NFT_CONTRACT_ADDRESS}
import FungibleToken from ${FUNGIBLE_TOKEN_CONTRACT_ADDRESS}

transaction {
  prepare(acct: auth(Storage, Capabilities) &Account) {
    let nftProviderType = Type<Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}>>()
    let ftProviderType = Type<Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>>()

    let nftPaths: [PublicPath] = []
    let ftPaths: [PublicPath] = []

    acct.storage.forEachPublic(fun (path: PublicPath, type: Type): Bool {
        if type.isSubtype(of: nftProviderType) {
            nftPaths.append(path)
        }

        if type.isSubtype(of: ftProviderType) {
            ftPaths.append(path)
        } 

        return true
    })

    for path in nftPaths {
        let id = acct.capabilities.get<&AnyResource>(path).id
        if id == 0 {
            acct.capabilities.unpublish(path)
            continue
        }
        
        if let controller = acct.capabilities.storage.getController(byCapabilityID: id) {
            let s = controller.target()
            acct.capabilities.unpublish(path)
            controller.delete()

            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{NonFungibleToken.Collection}>(s),
                at: path
            )
        }
    }

    for path in ftPaths {
        let id = acct.capabilities.get<&AnyResource>(path).id
            if id == 0 {
                continue
            }
            
            if let controller = acct.capabilities.storage.getController(byCapabilityID: id) {
                let s = controller.target()
                acct.capabilities.unpublish(path)
                controller.delete()

                acct.capabilities.publish(
                    acct.capabilities.storage.issue<&{FungibleToken.Vault}>(s),
                    at: path
                )
            }
        }
    }
}`

export const fixExposedProviders = async (
	address: string | null | undefined,
	txAvailableCallback?: (transactionId: string) => void
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
	if (!address) {
		return
	}

	try {
		const res = await sendTx({
			args: [],
			transactionCdcScript: IS_CRESCENDO
				? fixExposedProviderCrescendo
				: fixExposedProviderTxn,
			txAvailableCallback,
		})
		Log("fixExposedProviders", { res })
		return res
	} catch (e) {
		Err("fixExposedProviders", e)
	}
}
