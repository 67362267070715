import React from "react"
import { ReactComponent as FlowtyLogoDarkLg } from "../../src/assets/flowty-logo-1.svg"
import { ReactComponent as FlowtyLogoDarkMd } from "../../src/assets/flowty-logo-2.svg"
import { ReactComponent as FlowtyLogoLightLg } from "../../src/assets/flowty-logo-3.svg"
import { ReactComponent as FlowtyLogoLightMd } from "../../src/assets/flowty-logo-4.svg"
import { ReactComponent as FlowtyLogoLightSm } from "../../src/assets/flowty-logo-5.svg"
import { ReactComponent as FlowtyLogoDarkSm } from "../../src/assets/flowty-logo-6.svg"

type FlowtyLogoSize = "small" | "medium" | "large"
type FlowtyLogoTheme = "dark" | "light"

export interface FlowtyLogoProps {
	size: FlowtyLogoSize
	theme: FlowtyLogoTheme
}

function getFlowtyLogo(size: FlowtyLogoSize, theme: FlowtyLogoTheme) {
	if (size === "small" && theme === "dark") {
		return <FlowtyLogoDarkSm />
	}
	if (size === "small" && theme === "light") {
		return <FlowtyLogoLightSm />
	}
	if (size === "medium" && theme === "dark") {
		return <FlowtyLogoDarkMd />
	}
	if (size === "medium" && theme === "light") {
		return <FlowtyLogoLightMd />
	}
	if (size === "large" && theme === "dark") {
		return <FlowtyLogoDarkLg />
	}
	if (size === "large" && theme === "light") {
		return <FlowtyLogoLightLg />
	}
}

export const FlowtyLogo = ({ size, theme }: FlowtyLogoProps) => {
	return <>{getFlowtyLogo(size, theme)}</>
}
