import { Timestamp } from "firebase/firestore"
import { Card } from "flowty-common"
import { LoanRentalFilteredData } from "flowty-sdk"
import { useCallback, useEffect, useMemo, useState } from "react"
import { FilterWrapper } from "../components/UserActions/UserActionsNftListControllers/Filters/FilterWrapper"
import { UserActionsListingTypeFilter } from "../components/UserActions/UserActionsNftListControllers/Filters/UserActionsListingTypeFilter/UserActionsListingTypeFilter"
import { ReactComponent as ListingIcon } from "../components/UserActions/UserActionsNftListControllers/Filters/assets/listing-type-icon.svg"
import {
	subscribeToLoanBorrower,
	subscribeToRentalBorrower,
} from "../firebase/LoanRentalQueries"
import { Log } from "../../../util/Log"
import { actions as Mixpanel } from "../../../util/Mixpanel"

interface LoanRentalActionsProps {
	loggedUserAddress?: string
	accountSummaryAddresses?: string[]
}

interface LoanRentalActionsValues {
	allLoanRentalActivity: LoanRentalFilteredData[]
	isLoading: boolean
	traitsFilters: React.ReactNode
}

export const useLoanRentalActions = ({
	loggedUserAddress,
	accountSummaryAddresses,
}: LoanRentalActionsProps): LoanRentalActionsValues => {
	const [allLoanBorrowerActivity, setAllLoanBorrowerActivity] = useState<
		Array<LoanRentalFilteredData>
	>([])
	const [allRentalBorrowerActivity, setAllRentalBorrowerActivity] = useState<
		Array<LoanRentalFilteredData>
	>([])
	const [filterListingType, setFilterListingType] = useState<string>("all")
	const [isLoading, setIsLoading] = useState<boolean>(true)

	const getLoanBorrowerDocs = useCallback(
		async (userAddress: string) => {
			try {
				await subscribeToLoanBorrower({
					callbackFn: docs => {
						const docsList: LoanRentalFilteredData[] = []
						docs.map(doc => {
							if (!doc) return
							docsList.push({
								enabledAutoRepayment: doc.enabledAutoRepayment,
								listingResourceID: doc.listingResourceID,
								loanAmount: +doc.amount,
								nftContractStoragePath: String(
									doc.detail?.nft?.contract?.contractMetadata?.storagePath
								),
								nftData: {
									...(doc.detail?.card as Card),
								},
								nftID: doc.nftID,
								nftType: doc.nftType,
								paymentTokenName: doc.paymentTokenName,
								repaymentDue:
									doc.derivations?.calculatedValues?.repaymentDue ?? 0,
								settleDeadline: Number(doc.settleDeadline?.seconds),
								term: +doc.term,
								type: "loan",
							})
						})
						setAllLoanBorrowerActivity(docsList)
					},
					userAddress: userAddress,
				})
			} catch (e) {
				Mixpanel.track("ACTIVITY_CENTER_LOAN_SNAPSHOT_ERROR", {
					error: e,
				})
			}
		},
		[loggedUserAddress]
	)

	const getRentalBorrowerDocs = useCallback(
		async (userAddresses: string[]) => {
			try {
				await subscribeToRentalBorrower({
					callbackFn: docs => {
						const docsList: LoanRentalFilteredData[] = []
						docs.map(doc => {
							if (!doc) return
							const settleDate = doc.settleDeadline as unknown as Timestamp
							docsList.push({
								enabledAutoRepayment: doc.enabledAutomaticReturn,
								nftContractStoragePath: String(
									doc.listingAvailable?.detail?.nft?.contract?.contractMetadata
										?.storagePath
								),
								nftData: {
									...(doc.listingAvailable?.detail?.card as Card),
								},
								nftID: doc.listingAvailable?.nftID,
								nftType: doc.listingAvailable?.nftType,
								paymentTokenName: doc.listingAvailable?.paymentTokenName,
								rentalFee: doc.amount,
								rentalRefundableDeposit: doc.deposit,
								rentalResourceID: String(doc.rentalResourceID),
								renterAddress: doc.renterAddress,
								settleDeadline: +settleDate.seconds,
								term: doc.listingAvailable?.term,
								type: "rental",
							})
						})
						setAllRentalBorrowerActivity(docsList)
					},
					userAddresses: userAddresses,
				})
			} catch (e) {
				Mixpanel.track("ACTIVITY_CENTER_RENTAL_SNAPSHOT_ERROR", {
					error: e,
				})
				Log("error getting rentals", e)
			}
		},
		[loggedUserAddress]
	)

	const allLoanRentalActivity = useMemo(() => {
		return filterListingType === "loan"
			? allLoanBorrowerActivity
			: filterListingType === "rental"
			? allRentalBorrowerActivity
			: [...allLoanBorrowerActivity, ...allRentalBorrowerActivity]
	}, [filterListingType, allLoanBorrowerActivity, allRentalBorrowerActivity])

	const sortFn = (
		a: LoanRentalFilteredData,
		b: LoanRentalFilteredData
	): number => {
		return a.settleDeadline - b.settleDeadline
	}

	useEffect(() => {
		if (loggedUserAddress) {
			setIsLoading(true)
			getLoanBorrowerDocs(loggedUserAddress)
			getRentalBorrowerDocs(accountSummaryAddresses ?? [])
			setIsLoading(false)
		}
	}, [loggedUserAddress, filterListingType, accountSummaryAddresses])

	const traitsFilters = (
		<FilterWrapper
			title={"Listing Type"}
			child={
				<UserActionsListingTypeFilter
					filter={filterListingType}
					setFilter={setFilterListingType}
				/>
			}
			filterIcon={<ListingIcon />}
		/>
	)

	return {
		allLoanRentalActivity: allLoanRentalActivity.sort(sortFn),
		isLoading,
		traitsFilters,
	}
}
