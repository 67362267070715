import { ListingStatus, Transaction } from "flowty-common"
import { db } from "../../firebase"

export type HistoryListingEventType = Extract<
	ListingStatus,
	| ListingStatus.Funded
	| ListingStatus.Listed
	| ListingStatus.Repaid
	| ListingStatus.Delisted
	| ListingStatus.Settled
>

export const fetchUsersTransactions = async (
	address: string,
	limit: number,
	page: number
): Promise<Transaction[]> => {
	const { docs } = await db
		.collection("flowTransaction")
		.where("authorizers", "array-contains", address.slice(2))
		.orderBy("blockTimestamp", "desc")
		.limit((limit + 1) * page)
		.get()
	const transactions = docs.map(doc => {
		const event = doc.data() as Transaction

		return event
	})
	return transactions
}

export const fetchAssetTransactions = async (
	nftID: string,
	limit: number,
	page: number
): Promise<Transaction[]> => {
	const { docs } = await db
		.collection("flowTransaction")
		.where("nftIDs", "array-contains", nftID)
		.orderBy("blockTimestamp", "desc")
		.limit((limit + 1) * page)
		.get()
	const transactions = docs.map(doc => {
		const event = doc.data() as Transaction

		return event
	})
	return transactions
}
