import React, { useMemo } from "react"
import { FlowtyModalListingType } from "../../types/FlowtyModalTypes"

export interface ListingTitleProps {
	listingType?: FlowtyModalListingType
	nftName: string
	isPurchase?: boolean
	hasProvider: boolean
	isDelist?: boolean
}

export const ListingTitle: React.FunctionComponent<ListingTitleProps> = ({
	listingType,
	nftName,
	isPurchase,
	hasProvider,
	isDelist,
}) => {
	const title = useMemo(() => {
		let titleText = ""
		if (isPurchase) {
			titleText =
				listingType === "purchase"
					? `Buy Now -`
					: listingType === "fundLoan"
					? `Loan -`
					: listingType === "fundRental"
					? `Rental -`
					: listingType === "makeOffer"
					? `Make Offer -`
					: listingType === "cancelOffer"
					? `Cancel Offer -`
					: listingType === "loanBorrower"
					? `Repay Loan -`
					: listingType === "rentalBorrower"
					? `Return Rental -`
					: `${nftName}`
		} else if (!hasProvider) {
			titleText = `View Only -`
		} else {
			titleText = isDelist
				? `Delist -`
				: listingType === "storefront"
				? `New Sale Listing -`
				: listingType === "rental"
				? `New Rental Listing -`
				: listingType === "transfer"
				? `Transfer -`
				: listingType === "loan"
				? `New Loan Listing -`
				: listingType === "view-only"
				? `View Only -`
				: listingType === "acceptOffer"
				? `Accept Offer -`
				: ""
		}
		return titleText
	}, [listingType, isPurchase, nftName, isDelist, hasProvider])

	return (
		<p className='text-lg md:text-2xl leading-6 text-white font-black'>
			{title}{" "}
			<span className='text-neutral-500 text-lg md:text-2xl'>{nftName}</span>
		</p>
	)
}
