import { Link } from "react-router-dom"
import { ActivityTableImage } from "../../../../../components/Shared/ActivityTableFallbackImage/ActivityTableImage"
import React from "react"
import { formatImageSource } from "../../../../../util/nftDataUtil"
import { NFTLocationData, OfferCreated } from "flowty-common"
import noImage from "../../../../../assets/media/image_not_available.svg"

interface OffersTableNftRowProps {
	locationData: NFTLocationData
	offer: OfferCreated
}

export const OffersTableNftRow: React.FunctionComponent<
	OffersTableNftRowProps
> = ({ locationData, offer }) => {
	return (
		<Link
			to={`/asset/${locationData.contract.address}/${locationData.contract.name}/${locationData.resourceName}/${locationData.nftID}`}
		>
			<div className='flex cursor-pointer'>
				<div className='h-16 w-16 mr-3'>
					<ActivityTableImage
						collectionAddress={offer?.card?.collectionAddress || ""}
						collectionName={offer?.card?.collectionName || ""}
						srcImg={formatImageSource(offer?.card?.images?.[0]?.url ?? noImage)}
					/>
				</div>
				<div className='flex flex-col justify-center text-base text-primary whitespace-nowrap h-16'>
					<div className='font-extrabold '>{offer?.card?.title}</div>
					{offer?.card?.additionalDetails?.map(detail => (
						<div className='font-normal text-sm' key={detail}>
							{detail}
						</div>
					))}
				</div>
			</div>
		</Link>
	)
}
