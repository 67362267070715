import { SpotPrice } from "flowty-common"
import { NftPrice } from "../../../FlowtyModal/components/common/NftPrice"
import { ReactComponent as EditIcon } from "../../../../src/assets/edit.svg"
import { ReactComponent as XIcon } from "../../../../src/assets/circle-close-x.svg"

import { useState } from "react"
import { NumberStepper } from "../../../FlowtyDropPage/components/common/NumberStepper"

interface DropQuantityTotalProps {
	price: number
	spotPrice: SpotPrice | null
	mintCount: number
	increaseMintCount: () => void
	decreaseMintCount: () => void
	updateMintCount: (value: number) => void
}

export const DropQuantityTotal: React.FunctionComponent<
	DropQuantityTotalProps
> = ({
	price,
	spotPrice,
	mintCount,
	increaseMintCount,
	decreaseMintCount,
	updateMintCount,
}) => {
	const [showEdit, setShowEdit] = useState(false)

	const toggleEdit = () => {
		setShowEdit(prev => !prev)
	}
	return (
		<div className='flex-col justify-start items-start'>
			<div className='h-[44px] flex justify-between w-full'>
				<div
					className={`flex items-center gap-[.375rem] text-sm font-normal text-white pb-[.75rem]`}
				>
					<span>Quantity</span>
					<button
						className='cursor-pointer'
						onClick={toggleEdit}
						data-testid={
							!showEdit ? "drop-quantity-edit" : "drop-quantity-close"
						}
					>
						{!showEdit ? (
							<EditIcon height={14} width={14} />
						) : (
							<XIcon height={14} width={14} />
						)}
					</button>
				</div>
				<div className={`${showEdit ? "" : "hidden"}`}>
					<NumberStepper
						mintCount={mintCount}
						increaseMintCount={increaseMintCount}
						decreaseMintCount={decreaseMintCount}
						updateMintCount={updateMintCount}
					/>
				</div>

				<div
					className={`flex items-center text-sm font-semibold text-white ${
						!showEdit ? "" : "hidden"
					}`}
				>
					x{mintCount}
				</div>
			</div>
			<div className='flex justify-between w-full'>
				<div className='text-sm font-normal text-white'>Total</div>
				<div className='text-sm font-bold text-white'>
					<NftPrice
						price={price * mintCount}
						token='FLOW'
						spotPriceValue={(spotPrice?.value || 0) * price * mintCount}
						toolTipCustomId='drop-quantity-total'
					/>
				</div>
			</div>
		</div>
	)
}
