import { FlowtyDropPage } from "ds-flowty"
import { getBannerImage } from "flowty-common"
import { inject, observer } from "mobx-react"
import React, { useCallback, useEffect, useMemo } from "react"
import { NftListTabWrapper } from "../../components/NftListComponents/NftListTabWrapper/NftListTabWrapper"
import { OpenSearchConnector } from "../../components/OpenSearch/OpenSearchConnector"
import { flowty } from "../../config/config"
import {
	NftDetails,
	useFlowtyModalsContext,
} from "../../contexts/FlowtyModalsContext"
import { useTokenSpotPrice } from "../../hooks/data/collections/useTokenSpotPrice"
import { AuthStoreProp } from "../../stores/AuthStore"
import { actions as Mixpanel } from "../../util/Mixpanel"
import Layout from "../Layout"
import { CollectionActivityTablesWrapper } from "./components/CollectionActivityTablesWrapper/CollectionActivityTablesWrapper"
import { CollectionPageBanner } from "./components/CollectionPageBanner/CollectionPageBanner"
import { CollectionPageHeader } from "./components/CollectionPageHeader/CollectionPageHeader"
import { CollectionTabs } from "./components/CollectionTabs/CollectionTabs"
import { DropLoading } from "./components/DropLoading/DropLoading"
import { useCollectionPage } from "./hooks/useCollectionPage"

const CollectionPageComponent: React.FC<AuthStoreProp> = ({ authStore }) => {
	const {
		onSelectedTabChange,
		selectedTab,
		collection: nftCollection,
		collectionAddress,
		collectionName,
		collectionDisplayName,
		isDapper,
		flowtyTokenBalance,
		collectionDropData,
		isLoadingDrop,
	} = useCollectionPage(authStore)
	const { selectCard } = useFlowtyModalsContext()

	const spotPrice = useTokenSpotPrice("FLOW")

	useEffect(() => {
		Mixpanel.track(`${collectionName} page visited`, { tab: "Collection" })
	}, [])

	const name = nftCollection?.collectionDisplay?.name
		? nftCollection.collectionDisplay.name
		: collectionDisplayName

	const removeDashesName = name?.replaceAll("-", " ")

	const metaTitle = `${
		removeDashesName ?? "collections"
	} on Flowty: Buy, Sell, Loan and Rent ${removeDashesName ?? ""} NFTs`
	const metaDescription =
		nftCollection?.collectionDisplay?.description ??
		"Flowty is an open, full-featured NFT marketplace for collectors, traders, investors and creators on the Flow blockchain. Flowty's transaction capabilities range from buying and selling to NFT-backed loans and NFT rentals."

	const metaImage = useMemo(() => {
		return getBannerImage(collectionName as string, nftCollection)
	}, [collectionName, nftCollection])

	const handleSelectCard = useCallback((selected: NftDetails) => {
		selectCard({
			selected: {
				contractAddress: selected.contractAddress,
				contractName: selected.contractName,
				nftID: selected.nftID,
				nftType: selected.nftType,
			},
		})
	}, [])

	const showVisitDrop = useMemo(() => {
		return (
			!isLoadingDrop &&
			collectionDropData &&
			collectionDropData.length > 0 &&
			!collectionDropData[0].phases[0].hasStarted
		)
	}, [collectionDropData, isLoadingDrop, selectedTab])

	return (
		<>
			<CollectionPageHeader
				metaImage={metaImage}
				metaDescription={metaDescription}
				metaTitle={metaTitle}
			/>
			<OpenSearchConnector
				endpoint='collection'
				collectionPage={[collectionAddress, collectionName]}
			>
				{() => (
					<Layout>
						<div className='container'>
							<div className='lg:mt-0'>
								<CollectionPageBanner
									collectionName={collectionDisplayName}
									loggedIsDapper={authStore?.loggedUser?.isDapper || false}
									loggedUserAddr={authStore?.loggedUser?.addr || ""}
									createdDate={undefined}
									floorPrice={nftCollection?.floorPrice?.valueInUSD}
									totalItems={nftCollection?.totalItems}
									totalOwners={nftCollection?.totalOwners}
									weekTopSales={undefined}
								/>
							</div>
							<div className='py-3 pb-7 md:sticky md:top-[74px] z-20 bg-darkBg'>
								<CollectionTabs
									isDrop={collectionDropData?.length > 0}
									onSelectedTabChange={onSelectedTabChange}
									selectedTab={selectedTab}
								/>
							</div>
							<div>
								{selectedTab === "LISTINGS" && (
									<NftListTabWrapper
										handleSelectCard={handleSelectCard}
										showVisitDrop={showVisitDrop}
										onSelectedTabChange={onSelectedTabChange}
									/>
								)}
								{selectedTab === "ACTIVITY" && (
									<CollectionActivityTablesWrapper />
								)}
								{selectedTab === "DROP" && (
									<>
										{isLoadingDrop ? (
											<DropLoading />
										) : (
											collectionDropData && (
												<FlowtyDropPage
													isDapper={isDapper}
													flowTokenBalance={flowtyTokenBalance}
													spotPrice={spotPrice}
													dropData={collectionDropData?.[0]}
													contractAddress={collectionAddress || ""}
													contractName={collectionName || ""}
													sendToCollectionPage={() =>
														onSelectedTabChange("LISTINGS")
													}
													flowty={flowty}
												/>
											)
										)}
									</>
								)}
							</div>
						</div>
					</Layout>
				)}
			</OpenSearchConnector>
		</>
	)
}

export const CollectionPage = inject("authStore")(
	observer(CollectionPageComponent)
)
