import React, { useEffect } from "react"
import Layout from "./Layout"
import { actions as Mixpanel } from "../util/Mixpanel"
import { PageHeader } from "../components/PageHeader"

const PrivacyScreen = () => {
	useEffect(() => {
		Mixpanel.track("Privacy Page Visited")
	}, [])

	const metaTitle = "Flowty: Privacy Policy"
	const metaDescription =
		"Flowty is an open, full-featured NFT marketplace for collectors, traders, investors and creators on the Flow blockchain. Flowty's transaction capabilities range from buying and selling to NFT-backed loans and NFT rentals."
	const metaImage = "https://www.flowty.io/icons/icon192.png"

	return (
		<Layout>
			<PageHeader
				metaTitle={metaTitle}
				metaDescription={metaDescription}
				metaImage={metaImage}
			/>
			<section>
				<div className='mt-5 mb-4 pt-4 container'>
					<h1 className='pt-3 text-3xl text-center'>Privacy Policy</h1>
					<br />
					<iframe
						title='privacy'
						src='https://storage.googleapis.com/flowty-docs/flowty-privacy-policy.pdf'
						width='100%'
						height='700px'
					>
						Privacy Policy
					</iframe>
				</div>
			</section>
		</Layout>
	)
}

export default PrivacyScreen
