const NFT_CATALOG_ADDRESS = process.env.REACT_APP_ACCESS_API?.includes(
	"mainnet"
)
	? "0x49a7cda3a1eecc29"
	: "0x324c34e1c517e4db"

export default `import NFTCatalog from ${NFT_CATALOG_ADDRESS}

pub struct Paths {
    pub let storagePath: String
    pub let publicPath: String

    init(storagePath: String, publicPath: String) {
        self.storagePath = storagePath
        self.publicPath = publicPath
    }
}

pub fun main(contractAddress: Address, contractName: String) : Paths? {
    let addrString = contractAddress.toString()
    let nftTypeIdentifier = "A.".concat(addrString.slice(from: 2, upTo: addrString.length)).concat(".").concat(contractName).concat(".NFT")
    let tmp = NFTCatalog.getCollectionsForType(nftTypeIdentifier: nftTypeIdentifier)
    if tmp == nil {
    return nil
    }

    let catalogIdentifiers = tmp!
    var catalogIdentifier = ""
    for k in catalogIdentifiers.keys {
    if catalogIdentifiers[k] == true {
        catalogIdentifier = k
        break
    }
    }

    if catalogIdentifier != "" {
        if let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: catalogIdentifier) {
            return Paths(
                storagePath: catalogEntry.collectionData.storagePath.toString(),
                publicPath: catalogEntry.collectionData.publicPath.toString()
            )   
        }
    }

    return nil
}`
