import { useState } from "react"

export const CopyWalletAddressButton = ({ address }: { address: string }) => {
	const [copied, setCopied] = useState(false)

	const resetCopy = () => setCopied(false)

	const handleCopyClick = () => {
		setCopied(true)
		setTimeout(resetCopy, 5000)
	}

	return (
		<>
			<button
				data-tooltip-id={`copy-wallet-tooltip`}
				data-tooltip-content={`Copy Wallet Address`}
				data-tooltip-place='right'
				className='btn-copy flex items-center group text-white'
				onClick={() => {
					handleCopyClick()
					;(navigator as Navigator).clipboard.writeText(address)
				}}
			>
				<span className='text-[#DEDEDE] font-normal text-[0.875rem] md:text-[14px] mr-[0.25rem]'>
					{address}
				</span>
				<div className='px-1'>
					{copied ? (
						<svg
							xmlns='http://www.w3.org/2000/svg'
							fill='none'
							viewBox='0 0 24 24'
							strokeWidth={1.5}
							stroke='currentColor'
							className='w-[14px] h-[14px] text-primary'
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								d='M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75'
							/>
						</svg>
					) : (
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='14'
							height='15'
							viewBox='0 0 14 15'
							fill='none'
						>
							<path
								d='M9.1875 10.5625V12.5312C9.1875 12.8937 8.89369 13.1875 8.53125 13.1875H2.84375C2.48131 13.1875 2.1875 12.8937 2.1875 12.5312V5.09375C2.1875 4.73131 2.48131 4.4375 2.84375 4.4375H3.9375C4.23562 4.4375 4.52794 4.46235 4.8125 4.51009M9.1875 10.5625H11.1562C11.5187 10.5625 11.8125 10.2687 11.8125 9.90625V7.0625C11.8125 4.46113 9.9205 2.30166 7.4375 1.88509C7.15294 1.83735 6.86062 1.8125 6.5625 1.8125H5.46875C5.10631 1.8125 4.8125 2.10631 4.8125 2.46875V4.51009M9.1875 10.5625H5.46875C5.10631 10.5625 4.8125 10.2687 4.8125 9.90625V4.51009M11.8125 8.375V7.28125C11.8125 6.19394 10.9311 5.3125 9.84375 5.3125H8.96875C8.60631 5.3125 8.3125 5.01869 8.3125 4.65625V3.78125C8.3125 2.69394 7.43106 1.8125 6.34375 1.8125H5.6875'
								stroke='#DEDEDE'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</svg>
					)}
				</div>
			</button>
		</>
	)
}
