import { useState } from "react"
import { ReactComponent as Website } from "../../../../assets/media/websiteOutlined.svg"
import { ReactComponent as WebsiteFilled } from "../../../../assets/media/websiteFilled.svg"

interface WebsiteIconProps {
	link: string
}

export const WebsiteIcon: React.FunctionComponent<WebsiteIconProps> = ({
	link,
}) => {
	const [onHover, setOnHover] = useState(false)
	return (
		<>
			{onHover ? (
				<WebsiteFilled
					className='cursor-pointer h-5'
					onClick={() => {
						window.open(link)
					}}
					onMouseEnter={() => setOnHover(true)}
					onMouseLeave={() => setOnHover(false)}
				/>
			) : (
				<Website
					className='cursor-pointer h-5'
					onClick={() => {
						window.open(link)
					}}
					onMouseEnter={() => setOnHover(true)}
					onMouseLeave={() => setOnHover(false)}
				/>
			)}
		</>
	)
}
