import { SupportedTokens } from "flowty-common"
import { AccountSummaries, Flowty, useGetFees } from "flowty-sdk"
import { useEffect, useRef } from "react"
import { FlowtyAccordion } from "../../../../FlowtyAccordion"
import {
	FlowtyWalletSelector,
	ValidTokenReceiverFilter,
	walletDisabledMessage,
} from "../../../../FlowtyWalletSelector/FlowtyWalletSelector"
import { useFlowtyListingModalContext } from "../../../contexts/FlowtyListingModalContext/FlowtyListingModalContext"
import { NftPrice } from "../../common/NftPrice"

interface AcceptOfferProps {
	accountSummaries: AccountSummaries
	offerListingError: boolean
	flowty: Flowty
}

export const AcceptOfferView: React.FunctionComponent<AcceptOfferProps> = ({
	accountSummaries,
	offerListingError,
	flowty,
}) => {
	const {
		hasChildAccounts,
		selectedAccount,
		setSelectedAccount,
		spotPrice,
		isDapper,
		flowNft,
		bestOffer,
		selectedOffer,
		isParentDapper,
		isLoadingCollectionRoyalty,
		isNftOnDapperChild,
		isNftOnNonDapperChild,
	} = useFlowtyListingModalContext()

	const serviceTermRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (offerListingError) {
			if (serviceTermRef?.current?.scrollIntoView)
				serviceTermRef?.current?.scrollIntoView({ behavior: "smooth" })
		}
	}, [offerListingError])

	const offer = selectedOffer || bestOffer

	let { fees, isLoading } = useGetFees({
		flowty,
		nft: flowNft ? flowNft : null,
		price: offer?.amount || 0,
		token: offer?.paymentTokenName as SupportedTokens,
	})

	const IS_STORYBOOK = process.env.IS_STORYBOOK
	if (IS_STORYBOOK) {
		isLoading = false
		fees = {
			dapper: Number(offer?.amount) * 0.01,
			flowty: Number(offer?.amount) * 0.02,
			royalties: Number(offer?.amount) * 0.06,
			seller: Number(offer?.amount) - Number(offer?.amount) * 0.09,
		}
	}

	const flowtyFee = fees?.flowty
	const flowtyRate = (flowtyFee || 0) / (offer?.amount || 0)

	const dapperWalletFee = fees?.dapper
	const dapperWalletRate = (dapperWalletFee || 0) / (offer?.amount || 0)

	const royaltyFee = fees?.royalties
	const royaltyRate = (royaltyFee || 0) / (offer?.amount || 0)

	const dapperWalletFeeLabel = `Dapper Wallet Fee (${(
		dapperWalletRate * 100
	).toFixed(2)}%)`

	const amountReceived = fees?.seller

	return (
		<div className='w-full h-full flex flex-col'>
			<div className='flex flex-col justify-between h-full'>
				<div className='flex flex-col gap-[8px]'>
					{hasChildAccounts && !isParentDapper && (
						<FlowtyWalletSelector
							selected={selectedAccount}
							setSelected={setSelectedAccount}
							label='Pay To'
							summaries={accountSummaries}
							listingType={"acceptOffer"}
							disabledMessage={walletDisabledMessage.payTo}
							selectorFilter={
								new ValidTokenReceiverFilter({
									flowty,
									listingType: "storefront",
								})
							}
						/>
					)}
					<div className='flex justify-between text-white items-start'>
						<p className='text-sm font-normal text-white'>Offer Price</p>
						<NftPrice
							price={offer?.amount || 0}
							token={(offer?.paymentTokenName as SupportedTokens) || "FLOW"}
							spotPriceValue={Number(spotPrice?.value) * (offer?.amount || 0)}
							toolTipCustomId='saleListingReceivedValue'
						/>
					</div>
					<div ref={serviceTermRef}>
						{isLoadingCollectionRoyalty || isLoading || flowtyFee === 0 ? (
							<div className='flex flex-col gap-[11px]'>
								<div className='w-full h-[50px] animate-pulse bg-[#edf3f60a] rounded-lg'></div>
								<div className='w-full h-[50px] animate-pulse bg-[#edf3f60a] rounded-lg'></div>
							</div>
						) : (
							<FlowtyAccordion
								items={[
									{
										addOn: (
											<div className='flex items-start font-montserrat space-x-2'>
												<span className='font-bold'>-</span>
												{royaltyFee && (
													<NftPrice
														price={royaltyFee}
														token={
															(offer?.paymentTokenName as SupportedTokens) ||
															"FLOW"
														}
														spotPriceValue={
															Number(spotPrice?.value) * royaltyFee
														}
														toolTipCustomId='saleListingCreatorRoyalty'
													/>
												)}
											</div>
										),
										content: (
											<div className='text-neutral-500 font-montserrat'>
												Transferred directly to the creator of an NFT
												collection. This is not a Flowty fee.{" "}
												<a
													href='https://docs.flowty.io/reference/secondary-marketplace/secondary-marketplace-fees'
													target='_blank'
													className='text-[#04E5A3] font-montserrat underline'
													rel='noreferrer'
												>
													Learn More
												</a>
											</div>
										),
										id: "item-1",
										notDisplay: !royaltyFee,
										title: `Creator Royalty (${(royaltyRate * 100).toFixed(
											2
										)}%)`,
									},
									{
										addOn: (
											<div className='flex items-start space-x-2'>
												<span className='font-bold font-montserrat'>-</span>
												{flowtyFee && (
													<NftPrice
														price={flowtyFee}
														token={
															(offer?.paymentTokenName as SupportedTokens) ||
															"FLOW"
														}
														spotPriceValue={
															Number(spotPrice?.value) * flowtyFee
														}
														toolTipCustomId='saleListingFlowtyFee'
													/>
												)}
											</div>
										),
										content: (
											<div className='text-neutral-500 font-montserrat'>
												A fee charged by Flowty for providing the services on
												the platform.{" "}
												<a
													href='https://docs.flowty.io/reference/secondary-marketplace/secondary-marketplace-fees'
													target='_blank'
													className='text-[#04E5A3] font-montserrat underline'
													rel='noreferrer'
												>
													Learn More
												</a>
											</div>
										),
										id: "item-2",
										title: `Flowty Fee (${(flowtyRate * 100).toFixed(2)}%)`,
									},
								]}
							/>
						)}
					</div>
					<hr className='border-[#495057]'></hr>
					{isLoadingCollectionRoyalty || isLoading || flowtyFee === 0 ? (
						<div className='flex flex-col gap-[11px]'>
							<div className='w-full h-[40px] animate-pulse bg-[#edf3f60a] rounded-lg'></div>
						</div>
					) : (
						<div className='flex justify-between text-white items-start'>
							<p>You&apos;ll Receive</p>
							{amountReceived ? (
								<NftPrice
									price={amountReceived}
									token={(offer?.paymentTokenName as SupportedTokens) || "FLOW"}
									spotPriceValue={Number(spotPrice?.value) * amountReceived}
									toolTipCustomId='saleListingReceivedValue'
								/>
							) : (
								"-"
							)}
						</div>
					)}
				</div>
				{}
				{isDapper &&
					!offer?.paymentTokenName?.includes("DUC") &&
					!offer?.paymentTokenName?.includes("FUT") && (
						<div
							data-testid='non-dapper-offer-warning'
							className='text-orange-500 text-sm font-normal pb-3'
						>
							{isNftOnNonDapperChild
								? "Log in with Child Non-Dapper Account to accept offer."
								: "Warning: Transfer NFT to Non-Dapper Wallet to accept offer."}
						</div>
					)}
				{!isDapper &&
					(offer?.paymentTokenName?.includes("DUC") ||
						offer?.paymentTokenName?.includes("FUT")) && (
						<div
							data-testid='dapper-offer-warning'
							className='text-orange-500 text-sm font-normal pb-3'
						>
							{isNftOnDapperChild
								? "Log in with Child Dapper Account to accept offer."
								: "Warning: Transfer NFT to Dapper Wallet to accept offer."}
						</div>
					)}
			</div>
		</div>
	)
}
