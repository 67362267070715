import { flowdiverBaseURL } from "../../../../util/settings"
interface StatusUncertainProps {
	transactionID: string | null
}
export const StatusUncertain = ({ transactionID }: StatusUncertainProps) => {
	return (
		<div className='w-full flex flex-col justify-center items-center h-full'>
			<div className='px-6 w-full flex justify-center items-center my-3'>
				<span className='text-base text-center'>
					Uh oh! Something went wrong while trying to follow your transaction
					results
				</span>
			</div>
			{transactionID && (
				<div className='w-full flex flex-col items-center'>
					<p className='h-12'>
						You can verify its outcome on&nbsp;
						<a
							className='modal-external-link'
							href={`${flowdiverBaseURL}/tx/${transactionID}`}
							target='_blank'
							rel='noreferrer'
						>
							Flowdiver
						</a>
					</p>
				</div>
			)}
		</div>
	)
}
