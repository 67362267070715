import * as fcl from "@onflow/fcl"
import { FlowtyButton } from "ds-flowty"
import {
	OpenSearchListingAvailableData,
	OpensearchFlowNFT,
	Order,
	calcAPRInterest,
} from "flowty-common"
import { inject, observer } from "mobx-react"
import numeral from "numeral"
import { useCallback, useMemo } from "react"
import { AuthStoreProp } from "../../../../../../stores/AuthStore"
import { getDurationPeriod } from "../../../../../../util/nftDataUtil"
import { ListingCooldown } from "./ListingCooldownTimer/ListingCooldownTimer"
import { NoListingPreview } from "./NoListingsPreview/NoListingPreview"
import { ListingIndicatorPill } from "../../../../../../components/Shared/ListingIndicatorPill/ListingIndicatorPill"
import { TokenAmount } from "../../../../../../components/Tokens/tokenDisplays"

interface LoanPreviewProps extends AuthStoreProp {
	asset: OpensearchFlowNFT
	orders: OpenSearchListingAvailableData[]
	setSelectedListing: ({
		listing,
		listingType,
		singleDelistAction,
		singleDelistOrder,
	}: {
		listing?: Order
		listingType?: "loan" | "rent" | "transfer"
		singleDelistOrder?: Order
		singleDelistAction?: "delistLoan" | "delistRental" | "delistSale"
	}) => void
	isAssetOwner: boolean
}

const LoansPreviewComponent: React.FC<LoanPreviewProps> = ({
	asset,
	orders,
	setSelectedListing,
	authStore,
	isAssetOwner,
}) => {
	const userAddresses = authStore?.getAccountSummaryAddresses() ?? []

	const loggedUser = authStore?.loggedUser

	const connectWallet = () => {
		fcl.authenticate()
	}

	const AuthService = useMemo(() => {
		return loggedUser?.services[0].uid.toLocaleLowerCase() || ""
	}, [loggedUser])

	const isDapper = AuthService.includes("dapper")

	const returnActionButtonsBasedOnAuth = useCallback(
		(isOwner: boolean, loan: OpenSearchListingAvailableData) => {
			if (!loggedUser) {
				return (
					<FlowtyButton
						onClick={connectWallet}
						text={
							<b>
								{loggedUser ? (
									<div
										className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'
										role='status'
										aria-label='loading'
									>
										<span className='sr-only'>Loading...</span>
									</div>
								) : (
									<span className='text-base font-montserrat'>Sign in</span>
								)}
							</b>
						}
						variant={"secondary"}
						btnSize='small'
					/>
				)
			}
			if (isOwner) {
				return (
					<>
						<FlowtyButton
							text={"Delist"}
							onClick={() =>
								setSelectedListing({
									listingType: "loan",
									singleDelistAction: "delistLoan",
									singleDelistOrder: loan,
								})
							}
							variant={"secondary"}
							btnSize='small'
							bgColor='danger'
						/>
						<FlowtyButton
							text={"Add loan"}
							onClick={() =>
								setSelectedListing({
									listingType: "loan",
									singleDelistAction: undefined,
									singleDelistOrder: undefined,
								})
							}
							variant={"secondary"}
							btnSize='small'
							bgColor='primary'
						/>
					</>
				)
			}
			return (
				<FlowtyButton
					text={"FUND"}
					onClick={() => setSelectedListing({ listing: loan })}
					variant={"secondary"}
					btnSize='small'
				/>
			)
		},
		[loggedUser]
	)

	return (
		<div className='mt-[1.5rem] md:min-h-[19.65rem] flex flex-row flex-wrap gap-[1.5rem] items-stretch'>
			{orders?.length > 0 ? (
				orders.map((loan, idx) => {
					const isOwner =
						userAddresses.includes(loan.flowtyStorefrontAddress) &&
						!!loggedUser?.loggedIn

					return (
						<div
							className='rounded-[0.5rem]  flex flex-col p-[1.5rem] bg-[#606e7d33] w-full lg:w-[25rem] gap-y-[1.5rem] gap-x-[1.5rem] items-start'
							key={idx}
						>
							<ListingIndicatorPill symbol='LOAN' />
							<div className='flex flex-col gap-y-[1.2rem] w-full'>
								<div className='flex flex-row justify-between items-center'>
									<p className='font-montserrat text-[0.875rem]'>Loan Amount</p>
									<TokenAmount
										fontSize='text-[0.875rem]'
										isSmall
										amount={
											loan?.derivations?.calculatedValues?.marketplaceAmount
										}
										token={loan?.paymentTokenName}
									/>
								</div>
								<div className='flex flex-row justify-between items-center'>
									<p className='font-montserrat text-[0.875rem]'>
										Repayment Amount
									</p>
									<TokenAmount
										fontSize='text-[0.875rem]'
										isSmall
										amount={loan.derivations?.calculatedValues?.totalRepayment}
										token={loan.paymentTokenName}
									/>
								</div>
								<div className='flex flex-row justify-between items-center'>
									<p className='font-montserrat text-[0.875rem]'>Loan Rate</p>
									<p className='font-montserrat text-[0.875rem] font-bold'>
										{(
											(loan?.derivations?.calculatedValues?.periodicInterest ??
												0) * 100
										)?.toFixed(2)}
										%
									</p>
								</div>
								<div className='flex flex-row justify-between items-center'>
									<p className='font-montserrat text-[0.875rem]'>APR</p>
									<p className='font-montserrat text-[0.875rem] font-bold'>
										{numeral(
											calcAPRInterest(
												loan
													? Number(
															loan?.derivations?.calculatedValues
																?.periodicInterest
													  )
													: 0,
												loan ? Number(loan?.term) : 0
											)
										).format("0,0.00")}
										%
									</p>
								</div>
								<div className='flex flex-row justify-between items-center'>
									<p className='font-montserrat text-[0.875rem]'>Duration</p>
									<p className='font-montserrat text-[0.875rem] font-bold'>
										{getDurationPeriod(Number(loan?.term))}
									</p>
								</div>
								{loan?.valuations?.aggregate?.loanToValueRatio && (
									<div className='flex flex-row justify-between items-center'>
										<p className='font-montserrat text-[0.875rem]'>
											Blended LTV
										</p>
										<p className='font-montserrat text-[0.875rem] font-bold'>
											{loan?.valuations
												? `${numeral(
														loan?.valuations?.aggregate?.loanToValueRatio * 100
												  ).format("0,0.0")}%`
												: "-"}{" "}
										</p>
									</div>
								)}
							</div>
							<ListingCooldown blockTimestamp={loan?.blockTimestamp} />
							<div className='w-full flex gap-[0.5rem]'>
								{returnActionButtonsBasedOnAuth(isOwner, loan)}
							</div>
						</div>
					)
				})
			) : (
				<div className='min-h-full w-full flex flex-col items-stretch'>
					<NoListingPreview
						type={"LOAN"}
						isOwner={isAssetOwner}
						isDapper={isDapper}
						updateListing={() => setSelectedListing({ listingType: "loan" })}
					/>
				</div>
			)}
		</div>
	)
}

export const LoansPreview = inject("authStore")(observer(LoansPreviewComponent))
