import React from "react"
import { LoanRentalFilteredData } from "flowty-sdk"
import { LoadingOrderDisplay } from "../LoadingOrderDisplay/LoadingOrderDisplay"
import { NftPrice } from "../../NftPrice"
import { SupportedTokens } from "flowty-common"

interface FundedLoanRentalOrderDisplay {
	order: LoanRentalFilteredData
	isLoading?: boolean
}

export const FundedLoanRentalOrderDisplay: React.FC<
	FundedLoanRentalOrderDisplay
> = ({ isLoading, order }) => {
	const dueDateFormat = (dateValue: number, dateOnly?: boolean): string => {
		const date = new Date(dateValue * 1000)

		const formattedDate = new Intl.DateTimeFormat("en-US", {
			day: "numeric",
			month: "long",
		}).format(date)
		const formattedTime = new Intl.DateTimeFormat("en-US", {
			hour: "2-digit",
			hour12: true,
			minute: "2-digit",
		}).format(date)

		return dateOnly ? formattedDate : `${formattedDate}, ${formattedTime}`
	}
	return (
		<div className='h-[10rem] flex flex-col justify-between'>
			{isLoading ? (
				<LoadingOrderDisplay />
			) : (
				<>
					<div className='flex flex-row justify-between mt-4 items-center'>
						<div>
							<span className='text-[#AAAAAA] leading-6 text-base font-montserrat font-normal'>
								{order.type === "loan" ? "Loan" : "Fee"}
							</span>
						</div>
						<div className='self-center text-end'>
							<span className='self-center text-bold text-base font-montserrat'>
								<NftPrice
									price={
										order.type === "loan"
											? order?.loanAmount ?? 0
											: order.rentalFee ?? 0
									}
									token={order?.paymentTokenName as SupportedTokens}
									toolTipCustomId='loanRental-price-tooltip'
								/>
							</span>
						</div>
					</div>
					<div className='flex flex-row justify-between mt-1 items-center'>
						<div>
							<span className='text-[#AAAAAA] leading-6 text-base font-montserrat font-normal'>
								{order.type === "loan" ? "Repayment" : "Deposit"}
							</span>
						</div>
						<div className='self-center text-end'>
							<span className='text-bold text-base font-montserrat'>
								<NftPrice
									price={
										order.type === "loan"
											? order?.repaymentDue ?? 0
											: order.rentalRefundableDeposit ?? 0
									}
									token={order?.paymentTokenName as SupportedTokens}
									toolTipCustomId='loanRental-repayment-price-tooltip'
								/>
							</span>
						</div>
					</div>
					<div className='my-1 border-gradient' />
					<div className='flex flex-row justify-between mt-1 items-center'>
						<div>
							<span className='text-[#AAAAAA] leading-6 text-base font-montserrat font-normal'>
								{order.type === "loan" ? "Funded" : "Rented"}
							</span>
						</div>
						<div className='self-center text-end'>
							<span className='font-bold text-white text-base font-montserrat'>
								{dueDateFormat(+order.settleDeadline - order.term, true)}
							</span>
						</div>
					</div>
					<div className='flex flex-row justify-between items-center'>
						<div>
							<span className='text-[#AAAAAA] leading-6 text-base font-montserrat font-normal'>
								Due
							</span>
						</div>
						<div className='self-center text-end'>
							<span className='font-bold text-white text-base font-montserrat'>
								{dueDateFormat(+order.settleDeadline)}
							</span>
						</div>
					</div>
				</>
			)}
		</div>
	)
}
