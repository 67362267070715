import { XIcon } from "@heroicons/react/solid"
import { FlowtyButton } from "ds-flowty"
import { Tooltip } from "react-tooltip"
import { useMarketplaceAppContext } from "../../contexts/MarketplaceAppContext/MarketplaceAppContext"
import { actions as Mixpanel } from "../../util/Mixpanel"
import { Oval } from "react-loader-spinner"

export const BulkActionsFooter: React.FunctionComponent = () => {
	const {
		bulkSelectedLength,
		bulkLimit,
		toggleBulkList,
		unSelectAll,
		openBulkPopUp,
		isBulkPopUpOpen,
		listedSelected,
		notListedSelected,
	} = useMarketplaceAppContext()
	return (
		<div className='w-full fixed bottom-0 py-3 bg-[#04070b] backdrop-blur-2xl border-t border-[#495057]'>
			<div className='flex justify-between items-center container'>
				<div className='flex items-center gap-2 ml-3'>
					{bulkSelectedLength > 0 && (
						<div
							onClick={() => {
								unSelectAll()
								Mixpanel.track("BULK_UNSELECTALL_CLICKED", {
									bulkSelectedLength,
								})
							}}
							className='p-[6px] rounded-md bg-[#ffffff14] border-[0.3px] border-[#6C757D40] cursor-pointer'
							data-testid='unselect-all-btn'
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='20'
								height='20'
								viewBox='0 0 20 20'
								fill='none'
							>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M5 10C5 9.44772 5.44772 9 6 9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11L6 11C5.44772 11 5 10.5523 5 10Z'
									fill='#F8F9FA'
								/>
							</svg>
						</div>
					)}
					<p
						className={`text-sm font-bold ${
							bulkSelectedLength === bulkLimit && "text-red-500"
						}`}
					>
						{" "}
						{bulkSelectedLength} Selected
					</p>
					<p className='text-sm font-normal max-md:hidden'>
						<span className='font-bold'>•</span> Select NFTs to perform bulk
						actions
					</p>
				</div>
				<div className='flex items-center gap-2'>
					{notListedSelected.length > 0 && (
						<Tooltip
							id={`nft-listed-only`}
							style={{
								backgroundColor: "#ffffffe6",
								color: "#031021",
								fontSize: "14px",
							}}
						>
							<p className='text-xs font-bold'>Select only listed</p>
							<p className='text-xs'> NFTs to delist</p>
						</Tooltip>
					)}
					<div data-tooltip-id={`nft-listed-only`} data-tooltip-place='top'>
						{" "}
						<FlowtyButton
							text={"Delist"}
							onClick={() => {
								openBulkPopUp("delist")
								Mixpanel.track("BULK_DELISTING_CLOSED", {
									bulkSelectedLength,
								})
							}}
							disabled={
								bulkSelectedLength === 0 || notListedSelected.length > 0
							}
							variant={"secondary"}
							btnSize={"small"}
						/>{" "}
					</div>

					<FlowtyButton
						text={listedSelected.length > 0 ? "List/Edit" : "List"}
						disabled={bulkSelectedLength === 0}
						onClick={() => {
							Mixpanel.track("BULK_LISTING_OPENED", {
								action: listedSelected.length > 0 ? "List/Edit" : "List",
							})
							openBulkPopUp("list")
						}}
						variant={"secondary"}
						btnSize='small'
					/>
					<XIcon
						onClick={() => {
							Mixpanel.track("BULK_LISTING_CLOSED", {
								bulkSelectedLength,
							})
							toggleBulkList()
						}}
						className='h-6 mx-3 w-[40px] cursor-pointer max-md:hidden'
					/>
				</div>
			</div>
		</div>
	)
}
