/* eslint-disable @typescript-eslint/no-use-before-define */
import { Config } from "../types"

export const getSetupCollectionTxn = (
	config: Config,
	isDapper: boolean,
	contractAddress: string,
	contractName: string,
	isNFTCatalog: boolean
): string => {
	if (config.crescendo) return setupCollectionCrescendo(config)

	if (
		contractName === "TopShot" &&
		contractAddress === config.contractAddresses.TopShot
	) {
		return config.crescendo
			? setupTopShotCollectionCrescendo(config)
			: setupTopShotCollection(config)
	}

	if (isDapper) {
		return config.crescendo
			? setupCollectionDapperCrescendo(config)
			: setupCollectionDapper(config)
	}

	if (isNFTCatalog) {
		return setupCollectionCatalog(config)
	}

	return config.crescendo
		? setupCollectionWithoutCatalogCrescendo(config)
		: setupWithoutCatalog(config)
}

const setupCollectionCatalog = (
	config: Config
): string => `// flowty.io - Setup Collection with the NFT Catalog

import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}

transaction(contractAddress: Address, contractName: String) {
  prepare(acct: AuthAccount) {
    let contractAcct = getAccount(contractAddress)
    let c = contractAcct.contracts.borrow<&NonFungibleToken>(name: contractName) ?? panic("contract not found")

    let nftTypeIdentifier = c.getType().identifier.concat(".NFT")
    let catalogIdentifiers = NFTCatalog.getCollectionsForType(nftTypeIdentifier: nftTypeIdentifier) ?? panic("not found in catalog")
    var catalogIdentifier = ""
    for k in catalogIdentifiers.keys {
      if catalogIdentifiers[k] == true {
        catalogIdentifier = k
        break
      }
    }

    assert(catalogIdentifier != "", message: "no valid catalog identifier found")

    let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: catalogIdentifier) ?? panic("catalog entry not found")

    let publicPath = catalogEntry.collectionData.publicPath
    let storagePath = catalogEntry.collectionData.storagePath
    let privatePath = catalogEntry.collectionData.privatePath

    if acct.borrow<&AnyResource>(from: storagePath) == nil {
      let collection <- c.createEmptyCollection()
      acct.save(<-collection, to: storagePath)
    }

    if !acct.getCapability<&{NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(publicPath).check() {
      acct.unlink(publicPath)
      acct.link<&{NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(publicPath, target: storagePath)
    }

    if !acct.getCapability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(privatePath).check() {
      acct.unlink(privatePath)
      acct.link<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(privatePath, target: storagePath)
    }
  }
}
`
const setupWithoutCatalog = (
	config: Config
): string => `// flowty.io - Setup Collection Without NFT Catalog

import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.NonFungibleToken}
import ViewResolver from ${config.contractAddresses.NonFungibleToken}

transaction(contractAddress: Address, contractName: String) {
  prepare(acct: AuthAccount) {
    let contractAcct = getAccount(contractAddress)
    let c = contractAcct.contracts.borrow<&NonFungibleToken>(name: contractName) ?? panic("contract not found")

    let nftTypeIdentifier = c.getType().identifier.concat(".NFT")
    let contract = getAccount(contractAddress).contracts.borrow<&ViewResolver>(name: contractName) ?? panic ("Specified contract address and name is not found or does not implement ViewResolver contract.")
    let md = contract.resolveView(Type<MetadataViews.NFTCollectionData>()) ?? panic("NFTCollectionData view not found on the contract.")
    let collectionData = md as! MetadataViews.NFTCollectionData

    let publicPath = collectionData.publicPath
    let storagePath = collectionData.storagePath
    let providerPath = collectionData.providerPath

    if acct.borrow<&AnyResource>(from: storagePath) == nil {
      let collection <- c.createEmptyCollection()
      acct.save(<-collection, to: storagePath)
    }

    if !acct.getCapability<&{NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(publicPath).check() {
      acct.link<&{NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(publicPath, target: storagePath)
    }

    if !acct.getCapability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(providerPath).check() {
      acct.link<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(providerPath, target: storagePath)
    }
  }
}
`
const setupTopShotCollection = (
	config: Config
): string => `// flowty.io - Setup TopShot Collection

import TopShot from ${config.contractAddresses.TopShot}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}

transaction(address: Address, name: String) {
    prepare(acct: AuthAccount) {
        let storagePath = /storage/MomentCollection
        let publicPath = /public/MomentCollection
        let privatePath = /private/MomentCollection

        if acct.borrow<&{NonFungibleToken.CollectionPublic}>(from: storagePath) == nil {
            let c <- TopShot.createEmptyCollection()
            acct.save(<-c, to: storagePath)

            acct.link<&TopShot.Collection{TopShot.MomentCollectionPublic, NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(publicPath, target: storagePath)
        }

        if !acct.getCapability<&{NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(publicPath).check() {
          acct.unlink(publicPath)
          acct.link<&TopShot.Collection{TopShot.MomentCollectionPublic, NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(publicPath, target: storagePath)
        }

        if !acct.getCapability<&TopShot.Collection{NonFungibleToken.Provider,TopShot.MomentCollectionPublic,NonFungibleToken.Receiver,NonFungibleToken.CollectionPublic,MetadataViews.ResolverCollection}>(privatePath).check() {
          acct.unlink(privatePath)
          acct.link<&TopShot.Collection{NonFungibleToken.Provider,TopShot.MomentCollectionPublic,NonFungibleToken.Receiver,NonFungibleToken.CollectionPublic,MetadataViews.ResolverCollection}>(privatePath, target: storagePath)
        }
    }
}`

const setupCollectionDapper = (
	config: Config
): string => `// flowty.io - Setup Collection with the NFT Catalog

import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import DapperWalletCollections from ${config.contractAddresses.DapperWalletCollections}

transaction(contractAddress: Address, contractName: String) {
  prepare(acct: AuthAccount) {
    let contractAcct = getAccount(contractAddress)
    let c = contractAcct.contracts.borrow<&NonFungibleToken>(name: contractName) ?? panic("contract not found")

    let contractType = c.getType()
    assert(DapperWalletCollections.containsType(contractType), message: "not a valid NFT type")

    let nftTypeIdentifier = contractType.identifier.concat(".NFT")
    let catalogIdentifiers = NFTCatalog.getCollectionsForType(nftTypeIdentifier: nftTypeIdentifier) ?? panic("not found in catalog")
    var catalogIdentifier = ""
    for k in catalogIdentifiers.keys {
      if catalogIdentifiers[k] == true {
        catalogIdentifier = k
        break
      }
    }

    assert(catalogIdentifier != "", message: "no valid catalog identifier found")

    let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: catalogIdentifier) ?? panic("catalog entry not found")

    let publicPath = catalogEntry.collectionData.publicPath
    let storagePath = catalogEntry.collectionData.storagePath
    let privatePath = catalogEntry.collectionData.privatePath

    if acct.borrow<&AnyResource>(from: storagePath) == nil {
      let collection <- c.createEmptyCollection()
      acct.save(<-collection, to: storagePath)
    }

    if !acct.getCapability<&{NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(publicPath).check() {
      acct.unlink(publicPath)
      acct.link<&{NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(publicPath, target: storagePath)
    }

    if !acct.getCapability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(privatePath).check() {
      acct.unlink(privatePath)
      acct.link<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(privatePath, target: storagePath)
    }
  }
}`

const setupCollectionCrescendo = (
	config: Config
): string => `// flowty.io - Setup Collection with the NFT Catalog

import ViewResolver from ${config.contractAddresses.ViewResolver}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}

transaction(contractAddress: Address, contractName: String) {
    prepare(acct: auth(Capabilities, Storage) &Account) {
        let contractAcct = getAccount(contractAddress)

        let dc = contractAcct.contracts.get(name: contractName)
            ?? panic("contract not found")
        let vr = contractAcct.contracts.borrow<&{ViewResolver}>(name: contractName)
        let publicTypes = dc.publicTypes()
        let nftResourceType = Type<@{NonFungibleToken.NFT}>()
        for pt in publicTypes {
            if !pt.isSubtype(of: nftResourceType) {
                continue
            }

            let vr = getAccount(contractAddress).contracts.borrow<&{ViewResolver}>(name: contractName) ?? panic ("Specified contract address and name is not found or does not implement ViewResolver contract.")
            let md = vr.resolveContractView(resourceType: nil, viewType: Type<MetadataViews.NFTCollectionData>()) ?? panic("NFTCollectionData view not found on the contract.")
            let collectionData = md as! MetadataViews.NFTCollectionData

            let publicPath = collectionData.publicPath
            let storagePath = collectionData.storagePath

            if acct.storage.borrow<&AnyResource>(from: storagePath) == nil {
                acct.storage.save(<-collectionData.createEmptyCollection(), to: storagePath)
            }

            let targetType = Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()
            var hasProvider = false
            acct.capabilities.storage.forEachController(forPath: storagePath, fun(c: &StorageCapabilityController): Bool {
                if c.borrowType.isSubtype(of: targetType) {
                    hasProvider = true
                    return false
                }
                return true
            })

            if !hasProvider {
                acct.capabilities.storage.issue<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(storagePath)
            }

            if !acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(publicPath).check() {
                acct.capabilities.publish(
                    acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(storagePath),
                    at: publicPath
                )
            }
        }
    }
}`

const setupCollectionWithoutCatalogCrescendo = (config: Config): string => ``

const setupCollectionDapperCrescendo = (config: Config): string => ``

const setupTopShotCollectionCrescendo = (config: Config): string => ``
