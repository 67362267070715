/* eslint-disable import/no-unused-modules */
import {
	NotificationType,
	StorefrontPurchasedNotification,
} from "flowty-common"
import { NotificationDisplay } from "../util/NotificationDisplay"
import { getNotificationMessage } from "../util/getNotificationMessage"

export interface StorefrontPurchasedNotificationItemProps
	extends StorefrontPurchasedNotification {
	queueMessage?: ({
		type,
		id,
	}: {
		type: NotificationType.Transaction
		id: string
	}) => void
}

export const StorefrontPurchasedNotificationItem: React.FunctionComponent<
	StorefrontPurchasedNotificationItemProps
> = ({
	amount,
	transactionID,
	imageUrl,
	timestamp,
	title,
	id,
	token,
	nftId,
	nftType,
	flowtyStorefrontAddress,
	listingResourceID,
	type,
	listingStatus,
}) => {
	const message = getNotificationMessage({
		amount,
		title,
		token,
		type,
	})

	return (
		<NotificationDisplay
			title={title}
			imageUrl={imageUrl}
			listingStatus={listingStatus}
			token={token}
			nftId={nftId}
			nftType={nftType}
			flowtyStorefrontAddress={flowtyStorefrontAddress}
			listingResourceID={listingResourceID}
			transactionID={transactionID}
			message={message}
			timestamp={timestamp}
			listingType='storefront'
			type={type}
		/>
	)
}
