import { CollectionTabsType } from "../../hooks/useCollectionPage"

interface CollectionTabsProps {
	onSelectedTabChange: (tab: CollectionTabsType) => void
	selectedTab: CollectionTabsType
	isDrop: boolean
}

export const CollectionTabs: React.FC<CollectionTabsProps> = ({
	isDrop,
	selectedTab,
	onSelectedTabChange,
}) => {
	const tabs = [
		{
			key: "LISTINGS",
			name: "Listings",
		},
		{
			key: "ACTIVITY",
			name: "Activity",
		},
		{
			key: "DROP",
			name: "Drop",
		},
	]

	return (
		<div className='border-b border-neutral-200'>
			<nav className='flex justify-between' aria-label='Tabs'>
				<div className='-mb-px flex space-x-8'>
					{tabs.map(tab => {
						if (tab.name === "Drop" && !isDrop) return null
						return (
							<button
								onClick={() =>
									onSelectedTabChange(tab.key as CollectionTabsType)
								}
								key={tab.name}
								className={`font-bold font-montserrat uppercase text-base text-neutral-900 px-6 py-1.5
							${tab.key === selectedTab ? "border-neutral-900" : "border-transparent"} 
							"whitespace-nowrap px-2 border-b-[4px] text-sm"
						`}
							>
								{tab.name}
							</button>
						)
					})}
				</div>
			</nav>
		</div>
	)
}
