import { useState } from "react"
import { ReactComponent as Discord } from "../../../../assets/media/discordOutlined.svg"
import { ReactComponent as DiscordFilled } from "../../../../assets/media/discordFilled.svg"

interface DiscordIconProps {
	link: string
}

export const DiscordIcon: React.FunctionComponent<DiscordIconProps> = ({
	link,
}) => {
	const [onHover, setOnHover] = useState(false)
	return (
		<>
			{onHover ? (
				<DiscordFilled
					className='cursor-pointer h-5'
					onClick={() => {
						window.open(link)
					}}
					onMouseEnter={() => setOnHover(true)}
					onMouseLeave={() => setOnHover(false)}
				/>
			) : (
				<Discord
					className='cursor-pointer h-5'
					onClick={() => {
						window.open(link)
					}}
					onMouseEnter={() => setOnHover(true)}
					onMouseLeave={() => setOnHover(false)}
				/>
			)}
		</>
	)
}
