import React from "react"
import { LoanRentalFilteredData } from "flowty-sdk"
import { TokenAmount } from "../../../Tokens/tokenDisplays"
import { NftOrderLoadingDisplay } from "./NftOrderLoadingDisplay"

interface FundedLoanRentalOrderDisplay {
	order: LoanRentalFilteredData
	isLoading?: boolean
}

export const FundedLoanRentalOrderDisplay: React.FC<
	FundedLoanRentalOrderDisplay
> = ({ isLoading, order }) => {
	const dueDateFormat = (dateValue: number, dateOnly?: boolean): string => {
		const date = new Date(dateValue * 1000)

		const formattedDate = new Intl.DateTimeFormat("en-US", {
			day: "numeric",
			month: "short",
		}).format(date)
		const formattedTime = new Intl.DateTimeFormat("en-US", {
			hour: "2-digit",
			hour12: true,
			minute: "2-digit",
		}).format(date)

		return dateOnly ? formattedDate : `${formattedDate}, ${formattedTime}`
	}
	return (
		<div>
			{isLoading ? (
				<NftOrderLoadingDisplay />
			) : (
				<>
					<div className='flex flex-row justify-between mt-1 items-center '>
						<div>
							<span className='text-[#AAAAAA] text-base font-montserrat'>
								{order.type === "loan" ? "Loan" : "Fee"}
							</span>
						</div>
						<div className='self-center text-end'>
							<span className='self-center text-bold text-base font-montserrat'>
								<TokenAmount
									amount={
										order.type === "loan" ? order?.loanAmount : order.rentalFee
									}
									isSmall
									token={order?.paymentTokenName}
								/>
							</span>
						</div>
					</div>
					<div className='flex flex-row justify-between mt-1 items-center '>
						<div>
							<span className='text-[#AAAAAA] text-base font-montserrat'>
								{order.type === "loan" ? "Repayment" : "Deposit"}
							</span>
						</div>
						<div className='self-center text-end'>
							<span className='text-bold text-base font-montserrat'>
								<TokenAmount
									amount={
										order.type === "loan"
											? order?.repaymentDue
											: order.rentalRefundableDeposit
									}
									isSmall
									token={order?.paymentTokenName}
								/>
							</span>
						</div>
					</div>
					<div className='my-1 border-gradient' />
					<div className='flex flex-row justify-between mt-1 items-center'>
						<div className='text-[#AAAAAA] text-base font-montserrat'>
							{order.type === "loan" ? "Funded" : "Rented"}
						</div>
						<div className='self-center text-end'>
							<span className='text-bold text-base font-montserrat'>
								{dueDateFormat(+order.settleDeadline - order.term, true)}
							</span>
						</div>
					</div>
					<div className='flex flex-row justify-between items-center'>
						<div className='text-[#AAAAAA] text-base font-montserrat'>Due</div>
						<div className='self-center text-end'>
							<span className='text-bold text-base font-montserrat'>
								{dueDateFormat(+order.settleDeadline)}
							</span>
						</div>
					</div>
				</>
			)}
		</div>
	)
}
