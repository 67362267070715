import { FlowtySignInScreen } from "ds-flowty"
import { inject, observer } from "mobx-react"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { AuthStoreProp } from "../stores/AuthStore"
import Layout from "./Layout"
import { connectWallet } from "../util/ConnectWallet"

const SignInScreenComponent: React.FunctionComponent<AuthStoreProp> = ({
	authStore,
}) => {
	const navigate = useNavigate()

	useEffect(() => {
		if (authStore?.loggedUser?.loggedIn) {
			navigate("/user/profile")
		}
	}, [authStore?.loggedUser?.loggedIn])

	return (
		<Layout>
			<div className='w-full h-auto flex justify-center mt-[12.5rem]'>
				<FlowtySignInScreen onSignIn={() => connectWallet()} />
			</div>
		</Layout>
	)
}

export const SignInScreen = inject("authStore")(observer(SignInScreenComponent))
