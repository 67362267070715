import { Field } from "formik"

interface FlowtyFieldInputProps {
	name: string
	inputError: boolean
	valueError: boolean
	placeholder?: string
	textLeft?: boolean
	disabled?: boolean
	validateFn?: (value: string | number) => void
	errorMessage?: string | null
}

export const FlowtyFieldInput: React.FunctionComponent<
	FlowtyFieldInputProps
> = ({
	name,
	inputError,
	valueError,
	placeholder,
	textLeft,
	disabled,
	validateFn,
	errorMessage,
}) => {
	return (
		<div className='w-full relative'>
			<Field
				data-testid={name}
				disabled={disabled}
				placeholder={placeholder}
				className={`h-[37px] w-full text-[14px] bg-[#606E7D33] border font-semibold justify-end rounded-md px-3 hover:bg-[#606E7D4D] focus:border-[#FFFFFF7A] focus:outline-none ${
					inputError
						? "border-red-500 focus:border-red-500"
						: "border-[#FFFFFF1F]"
				}
						${valueError ? "text-[#FF6E25]" : "text-white"}
						${textLeft ? "text-left" : "text-right"}
						${disabled ? "cursor-not-allowed border-none" : "cursor-pointer"}
						`}
				name={name}
				type='text'
				min={0}
				validate={validateFn}
			/>
			<p className='w-full text-end text-[#FF6E25] text-xs absolute whitespace-nowrap'>
				{errorMessage}
			</p>
		</div>
	)
}
