/* eslint-disable @typescript-eslint/no-explicit-any */
import { format } from "date-fns"
import firebase from "firebase/compat/app"
import numeral from "numeral"
import {
	calcAPRInterest,
	NftMetadata,
	NftModel,
	Card,
	Trait,
} from "flowty-common"
import { formatImageSource, getDurationPeriod } from "../../../util/nftDataUtil"
import { TokenAmount } from "../../Tokens/tokenDisplays"
import { DEFAULT_TOKEN_TYPE } from "../../../util/settings"
import { SortableTableField } from "../index"
import { OverlayValuation } from "../../Shared/OverlayValuation"
import noImage from "../../../assets/media/image_not_available.svg"

// TODO remove this file (use collectionActivityTableFields for both) once migrations are finished and Global Activity page uses below data structure
interface CollectionActivityTableData {
	typeAndIDOffer: any
	nftID: string
	nftType: string
	flowtyStorefrontID: string
	funded: boolean
	listingResourceID: string
	borrower: string
	fundingResourceID: string
}

export interface CollectionActivityItem {
	id: string
	transactionId: string
	type: string
	activityType: string
	accountAddress: string
	blockchainType: string
	nftInfo?: NftModel
	data: CollectionActivityTableData
	blockTimestamp: firebase.firestore.Timestamp
	metadata: NftMetadata
	card?: Card
	additionalData?: any // TODO change any
}

// TODO CollectionActivityItem seems to be ListingAvailable or RentalListingAvailable?
// or at least contains some of that metadata?
//maybe itemtype should optionally intersect w those types
const collectionActivityTableFields: Array<
	SortableTableField<CollectionActivityItem>
> = [
	{
		customRender: ({ item }) => {
			const eventType = item?.type[0] + item?.type.slice(1).toLowerCase()
			return (
				<div className='flex flex-row justify-start items-center'>
					{eventType}
				</div>
			)
		},
		name: "type",
		sortable: false,
		title: "Status",
	},
	{
		customRender: ({ item }) => (
			<div className='flex flex-row justify-start items-center'>
				{item?.blockTimestamp
					? format(item?.blockTimestamp.toDate(), "yyyy-MM-dd HH:mm")
					: ""}
			</div>
		),
		name: "blockTimestamp",
		sortable: true,
		title: "Start Date",
	},
	{
		customRender: ({ item }) => (
			<div>
				{!!item.additionalData?.card && (
					<div className='flex flex-row justify-start items-center'>
						<div className='w-[40px] mr-2'>
							<img
								src={formatImageSource(
									item?.additionalData?.card?.images?.[0]?.url ?? noImage
								)}
								alt={`${item?.card?.title} asset`}
								className='object-cover aspect-square rounded-[4px]'
							/>
						</div>
						<div className='flex flex-col'>
							{item?.additionalData?.card?.additionalDetails ? (
								<span className='text-[12px] text-primary font-montserrat font-extrabold truncate'>
									{item?.additionalData?.card?.title}
								</span>
							) : (
								<>
									<span className='text-[12px] text-primary font-montserrat font-extrabold truncate'>
										{item?.additionalData?.card?.title}
									</span>
									{item?.additionalData?.card?.num &&
										!item.additionalData?.card?.title.includes("#") && (
											<span className='text-[12px] text-primary font-montserrat font-extrabold ml-2'>
												#{item?.additionalData?.card?.num}
											</span>
										)}
								</>
							)}
							{item?.additionalData?.card?.additionalDetails ? (
								<>
									{item?.additionalData?.card?.additionalDetails ? (
										item?.additionalData?.card?.additionalDetails?.map(
											(detail: any) => (
												<span
													key={detail}
													className='font-montserrat text-primary text-[12px] font-light'
												>
													{detail}
												</span>
											)
										)
									) : (
										<div className='flex flex-row items-center justify-start mt-2 h-4'></div>
									)}
								</>
							) : (
								<>
									{item?.additionalData?.card?.headerTraits &&
										item?.additionalData?.card?.headerTraits?.map(
											(trait: Trait) => (
												<span
													key={trait?.displayType}
													className='font-montserrat text-primary text-[12px] font-light'
												>
													{trait?.value}
												</span>
											)
										)}
								</>
							)}
						</div>
					</div>
				)}
			</div>
		),
		name: "detail",
		sortable: false,
		title: "Details",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				{item?.data ? (
					<TokenAmount
						amount={item?.additionalData?.amount}
						isSmall
						token={item?.paymentTokenName}
					/>
				) : (
					""
				)}
			</div>
		),
		name: "amount",
		sortable: false,
		title: "Amount",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				{item?.additionalData?.periodicInterest
					? numeral(item?.additionalData?.periodicInterest * 100).format(
							"0,0.00"
					  )
					: ""}
				%
			</div>
		),
		name: "rate",
		sortable: false,
		title: "Rate",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				{item?.additionalData?.term
					? getDurationPeriod(item?.additionalData?.term)
					: ""}
			</div>
		),
		name: "duration",
		sortable: false,
		title: "Duration",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				{numeral(
					calcAPRInterest(
						item ? Number(item.additionalData?.periodicInterest) : 0,
						item ? Number(item.additionalData?.term) : 0
					)
				).format("0,0.00")}
				%
			</div>
		),
		name: "apr",
		sortable: false,
		title: "APR",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				<TokenAmount
					amount={item?.additionalData?.repaymentDue}
					isSmall
					token={item?.paymentTokenName || DEFAULT_TOKEN_TYPE}
				/>
			</div>
		),
		name: "repayment",
		sortable: false,
		title: "Repayment Amount",
	},
	{
		customRender: ({ item }: any) => {
			if (item?.additionalData?.loanToValueRatio) {
				return (
					<OverlayValuation valuationType='LTV'>
						<div className='flex flex-row justify-start items-center'>
							{Math.round(item?.additionalData?.loanToValueRatio * 100)}%
						</div>
					</OverlayValuation>
				)
			}
			return <div className='flex flex-row justify-start items-center'>N/A</div>
		},
		name: "valuations.aggregate.loanToValueRatio",
		sortable: false,
		title: "3rd Party LTV",
	},
]

export default collectionActivityTableFields
