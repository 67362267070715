import Blocto from "../assets/media/blocto_logo.png"
import FlowReferenceWallet from "../assets/media/flow-reference-wallet.png"
import Dapper from "../assets/media/dapper_logo.png"

export function getProviderImage(walletServiceUid: string): string {
	const uid = walletServiceUid.toLowerCase()
	if (uid.includes("dapper")) return Dapper
	if (uid.includes("blocto")) return Blocto
	if (uid.includes("fcw")) return FlowReferenceWallet
	// Note- the wallet uuid might change at some point, making some guesses here
	// that support Flow Reference FlowReference  Flow-Reference(case insensitive)
	if (/flow.*reference/i.test(uid)) return FlowReferenceWallet

	return ""
}

export const getProviderName = (walletServiceUid: string): string => {
	const uid = walletServiceUid.toLowerCase()
	if (uid.includes("dapper")) return "Dapper"
	if (uid.includes("blocto")) return "Blocto"
	if (uid.includes("fcw")) return "Flow Reference Wallet"
	// Note- the wallet uuid might change at some point, making some guesses here
	// that support Flow Reference FlowReference  Flow-Reference(case insensitive)
	if (/flow.*reference/i.test(uid)) return "Flow Reference Wallet"

	return ""
}
