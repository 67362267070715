import { SpotPrice, getImageURL } from "flowty-common"
import { Flowty } from "flowty-sdk"
import numeral from "numeral"
import { useState } from "react"
import { ReactComponent as FLOWIcon } from "../../src/assets/tokens/flow.svg"
import { FlowtyButton } from "../FlowtyButton"
import { FlowtyDropModal } from "../FlowtyDropModal/FlowtyDropModal"
import DapperWarning from "../FlowtyModal/components/common/DapperWarning"
import { InsufficientFundsWarning } from "../FlowtyModal/components/common/InsufficientFundsWarning"
import CountdownTimer from "./components/common/CountdownTimer/CountdownTimer"
import { DropStatusPill } from "./components/common/DropStatusPill/DropStatusPill"
import { NumberStepper } from "./components/common/NumberStepper"
import { useDropPage } from "./hooks/useDropPage"
import { DropType } from "./types/DropType/DropType"

interface DropPageInterface {
	isDapper: boolean
	flowTokenBalance: number
	spotPrice: SpotPrice | null
	contractAddress: string
	contractName: string
	dropData: DropType
	sendToCollectionPage: () => void
	flowty: Flowty
}

export const FlowtyDropPage: React.FunctionComponent<DropPageInterface> = ({
	isDapper,
	flowTokenBalance,
	spotPrice,
	dropData,
	contractAddress,
	contractName,
	sendToCollectionPage,
	flowty,
}) => {
	const {
		dropStatus,
		dropStartDateFormat,
		dropEndDateFormat,
		dropPhasePrice,
		openModal,
		showNoFundsMessage,
		setShowNoFundsMessage,
		isModalOpen,
		closeModal,
		mintCount,
		increaseMintCount,
		decreaseMintCount,
		updateMintCount,
		countdownCurrentValue,
	} = useDropPage({
		dropData,
		flowTokenBalance,
	})

	const [imageLoaded, setImageLoaded] = useState(false)
	const handleImageLoad = (): void => {
		setImageLoaded(true)
	}

	const isActiveDrop = dropStatus === "ACTIVE"
	const isPendingDrop = dropStatus === "PENDING"
	const isEndedDrop = dropStatus === "ENDED"
	const isNeverEndingDrop = dropStatus === "NEVER_ENDING"

	const getDropStatus = (): string => {
		if (isPendingDrop) {
			return "Drop Time"
		} else if (isActiveDrop) {
			return "Drop Ends"
		} else {
			return "Ended On"
		}
	}

	console.log("getDropStatus(isPendingDrop, isActiveDrop)", getDropStatus())

	const isActiveOrNeverEndingDrop = isActiveDrop || isNeverEndingDrop
	const showDapperWarning = isActiveOrNeverEndingDrop && isDapper
	const showQuantityPurchased = isActiveOrNeverEndingDrop || isEndedDrop

	return (
		<div
			className='py-20 flex max-lg:flex-col justify-center gap-20 max-lg:gap-0'
			data-testid='flowty-drop-page'
		>
			{/* Drop phase image */}
			{!imageLoaded && (
				<div
					className={`flex w-full h-full max-w-[648px] tablet:w-[648px] tablet:h-[648px] animate-pulse bg-[#43ff640a] rounded-[2.5rem] ${
						imageLoaded ? "hidden" : "object-fill aspect-square"
					}`}
				></div>
			)}
			<div
				className={`lg:w-[648px] ${
					!imageLoaded ? "hidden" : "object-fill aspect-square"
				}`}
			>
				<img
					src={getImageURL(dropData?.display?.url)}
					alt='Drop Custom'
					className='rounded-[2.5rem] w-full h-full max-w-[648px] max-tablet:w-full'
					onLoad={handleImageLoad}
				/>
			</div>

			<div className='flex flex-col justify-center gap-10 py-16 max-lg:min-w-full min-w-[400px] max-w-[500px]'>
				<div>
					<DropStatusPill dropStatus={dropStatus} />
				</div>

				{!isNeverEndingDrop && (
					<div className='flex flex-col gap-4'>
						<p className='text-[#ADB5BD] text-[18px] max-tablet:text-[14px]'>
							{getDropStatus()}
						</p>
						<div className='flex flex-col gap-2'>
							<p className='text-white text-[24px] max-tablet:text-[18px] font-bold'>
								{isPendingDrop ? dropStartDateFormat : dropEndDateFormat}
							</p>
						</div>
					</div>
				)}
				{showQuantityPurchased && (
					<div className='flex flex-col gap-4'>
						<p className='text-[#ADB5BD] text-[18px] max-tablet:text-[14px]'>
							Total Purchased
						</p>
						<div className='flex gap-1'>
							<p className='text-white text-[24px] max-tablet:text-[18px] font-bold leading-none'>
								{dropData?.totalMinted}
							</p>
							{isActiveOrNeverEndingDrop && (
								<p className='text-[#ADB5BD] text-[14px] flex items-end leading-none'>
									/ unlimited
								</p>
							)}
						</div>
					</div>
				)}
				<div className='flex max-tablet:flex-col gap-14 max-tablet:gap-4 justify-between'>
					<div className='flex flex-col gap-4'>
						<p className='text-[#ADB5BD] text-[18px] max-tablet:text-[14px]'>
							Price
						</p>
						<div className='flex flex-col gap-1'>
							{dropPhasePrice ? (
								<div className='flex items-center w-auto justify-start gap-x-[0.75rem]'>
									<FLOWIcon className='w-[2rem] h-[2rem]' />
									<div className='flex items-end gap-1'>
										<span className='text-white font-montserrat text-5xl max-tablet:text-[34px] font-[700]'>
											{numeral(dropPhasePrice).format("0,0.00")}
										</span>
										<h4 className='text-[#ADB5BD] text-1xl font-normal leading-normal'>
											FLOW
										</h4>
									</div>
								</div>
							) : (
								"-"
							)}
						</div>
					</div>
					{isActiveOrNeverEndingDrop && (
						<div className='flex flex-col gap-4'>
							<p className='text-[#ADB5BD] text-[18px] max-tablet:text-[14px]'>
								Quantity
							</p>

							<NumberStepper
								mintCount={mintCount}
								increaseMintCount={increaseMintCount}
								decreaseMintCount={decreaseMintCount}
								updateMintCount={updateMintCount}
							/>
						</div>
					)}
				</div>
				{(isActiveDrop || isPendingDrop) && (
					<div className='flex flex-col gap-4'>
						<p className='text-[#ADB5BD] text-[18px] max-tablet:text-[14px]'>
							{isActiveDrop ? "Time Left" : "Countdown"}
						</p>
						<CountdownTimer countdownCurrentValue={countdownCurrentValue} />
					</div>
				)}
				<div className='h-[35px] flex flex-col gap-1'>
					{isEndedDrop ? (
						<FlowtyButton
							variant='secondary'
							bgColor='white'
							text='VIEW LISTINGS'
							onClick={sendToCollectionPage}
						/>
					) : (
						<>
							<FlowtyButton
								variant='secondary'
								bgColor='white'
								text={isPendingDrop ? "BUY NOW • COMING SOON" : "Buy now"}
								disabled={
									isPendingDrop ||
									(isActiveDrop && isDapper) ||
									showNoFundsMessage
								}
								onClick={openModal}
							/>
							{!isDapper && showNoFundsMessage && <InsufficientFundsWarning />}
							{showDapperWarning && (
								<DapperWarning isLoanOrRental={false} isDapper />
							)}
						</>
					)}
				</div>
			</div>
			<FlowtyDropModal
				isOpen={isModalOpen}
				onClose={closeModal}
				contractAddress={contractAddress}
				contractName={contractName}
				dropID={dropData?.id || ""}
				dropImage={getImageURL(dropData?.display?.url)}
				nftIdentifier={dropData?.nftType || ""}
				phasePrice={+dropPhasePrice}
				flowty={flowty}
				spotPrice={spotPrice}
				mintCount={mintCount}
				increaseMintCount={increaseMintCount}
				decreaseMintCount={decreaseMintCount}
				updateMintCount={updateMintCount}
				showNoFundsMessage={showNoFundsMessage}
				setShowNoFundsMessage={setShowNoFundsMessage}
			/>
		</div>
	)
}
