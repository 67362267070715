import React from "react"
import { SimpleModal } from "../../../Modals/SimpleModal/SimpleModal"
import { SnackbarMessage } from "../../../Shared/Snackbar/SnackbarAlert"
import { AddFilterViewProps } from "../hooks/useSaveView"

interface SaveCustomViewModalProps {
	isSaveModalOpen: boolean
	toggleIsSaveModalOpen: () => void
	addFilterView: (filterView: AddFilterViewProps) => void
	query: URLSearchParams
	onShowSnackbar?: (vals: SnackbarMessage) => void
	newPresetTitle: string
	setNewPresetTitle: (val: string) => void
}

export const SaveCustomViewModal: React.FunctionComponent<
	SaveCustomViewModalProps
> = ({
	isSaveModalOpen,
	toggleIsSaveModalOpen,
	addFilterView,
	query,
	onShowSnackbar,
	newPresetTitle,
	setNewPresetTitle,
}) => {
	return (
		<SimpleModal
			className='z-[500]'
			actionType='positive'
			isShowing={isSaveModalOpen}
			onClose={toggleIsSaveModalOpen}
			onAction={() => {
				if (newPresetTitle) {
					addFilterView({
						config: query.toString(),
						name: newPresetTitle,
					})
				} else {
					onShowSnackbar &&
						onShowSnackbar({
							message: "Must enter value for preset name!",
							type: "WARNING",
						})
				}
			}}
			action='save'
			head={
				<div className='flex w-full'>
					<div className='px-1 text-2xl font-bold text-primary'>Save</div>
					<div className='px-1 text-2xl font-bold text-white'>Custom View</div>
				</div>
			}
		>
			<div className='flex items-center justify-center w-full px-10 py-20 bg-darkBg'>
				<div className='flex flex-col items-center justify-center'>
					<div className='mb-4 font-bold'>
						What should we call this custom marketplace view?
					</div>
					<input
						onChange={e => setNewPresetTitle(e.target.value)}
						type='text'
						className='w-full px-2 text-lg font-bold bg-transparent border-2 border-white rounded-md lg:text-base'
						id=''
					/>
				</div>
			</div>
		</SimpleModal>
	)
}
