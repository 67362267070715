import {
	DocumentDuplicateIcon as CopiedIcon,
	EyeIcon,
	EyeOffIcon,
	PencilIcon,
} from "@heroicons/react/solid"
import { DocumentDuplicateIcon as CopyIcon } from "@heroicons/react/outline"
import { useCallback, useState, useMemo } from "react"
import { LinkedAccountBalance } from "./LinkedAccountBalance"
import ViewOnly from "../../../assets/media/hc/viewOnly.png"
import placeholderImage from "../../../assets/media/missing-collection-image-icon.svg"
import { setChildAccountDisplay } from "../../../services/flow/AccountService"
import { LoadingSpinner } from "../../Shared/LoadingSpinner"
import { FLOW_IDENTIFIER, USDC_IDENTIFIER } from "../../../util/tokens"
import { AccountSummary } from "flowty-sdk"
import { EditWalletDetails } from "./EditWalletDetails"
import Swal from "sweetalert2"
import { inject, observer } from "mobx-react"
import { AuthStoreProp } from "../../../stores/AuthStore"
import { FlowtyBadge } from "ds-flowty"

interface LinkedAccountProps extends AuthStoreProp {
	acc: AccountSummary
	onSelect: (id: string) => void
	isSelected: boolean
	belongsToAuthenticatedUser: boolean
}

const LinkedAccountComponent: React.FunctionComponent<LinkedAccountProps> = ({
	acc,
	authStore,
	onSelect,
	isSelected,
	belongsToAuthenticatedUser,
}) => {
	const border = isSelected
		? "border border-primary"
		: "border border-[#47515d] hover:border-[#606973]"

	const Toast = Swal.mixin({
		background: "#232D39E6", // Adding E6 at the end of the Hex value makes it 90% opaque
		customClass: {
			container: "py-1 mb-4",
			icon: "text-green-500",
		},
		iconColor: "#04F5A3",
		position: "bottom",
		showConfirmButton: false,
		timer: 3000,
		toast: true,
		width: "400px",
	})

	const [isCopied, setIsCopied] = useState(false)

	const [isEditing, setIsEditing] = useState(false)

	const [isLoading, setIsLoading] = useState(false)

	const modifiedDisplayName = useMemo(() => {
		const originalName = acc.display?.name

		if (originalName && originalName.startsWith("0x")) {
			return originalName.substring(0, 7)
		} else {
			return originalName
		}
	}, [acc.display?.name])

	const openEdit = useCallback(() => {
		setIsEditing(true)
	}, [])

	const closeEdit = useCallback(() => {
		setIsEditing(false)
	}, [])

	const edit = useCallback(async (name: string, imageUrl: string) => {
		setIsLoading(true)
		await setChildAccountDisplay(acc.address, name, "", imageUrl).then(() => {
			Toast.fire({
				icon: "success",
				text: "Successfully Updated Account Details",
			})
			authStore?.setLinkedWalletImage(acc.address, imageUrl)
			authStore?.setLinkedWalletDisplayName(acc.address, name)
		})
		setIsEditing(false)
		setIsLoading(false)
	}, [])

	const idTextColor = isCopied ? "text-primary" : "text-white"

	const visibilityColor = isSelected ? "text-primary" : "text-white"

	const copyId = (id: string): void => {
		setIsCopied(true)
		;(navigator as Navigator).clipboard.writeText(id)
	}

	const resetCopy = (): void => {
		setIsCopied(false)
	}

	const handleCopyClick = (id: string): void => {
		copyId(id)
		setTimeout(resetCopy, 5000)
	}

	if (isLoading) {
		return (
			<div
				className={`${border} bg-[#323d4b] bg-opacity-30 min-w-[18.0625rem] min-h-[11.4375rem] rounded-[0.625rem] mr-4 py-3 px-4 overflow-y-auto flex items-center justify-center`}
			>
				<LoadingSpinner />
			</div>
		)
	}

	return (
		<div
			onClick={() => onSelect(acc.address ?? "")}
			key={acc.address}
			className={`${border} ${
				isEditing
					? "border-[1px] border-dotted border-[#ffffff85]"
					: "border-[1px] border-transparent"
			}  bg-[#ffffff1f] bg-opacity-30 min-w-[18rem] min-h-[11rem] rounded-lg mr-4 py-[1rem] px-[1.25rem] relative cursor-pointer`}
		>
			<div className='space-y-1'>
				<div className='flex flex-row justify-between'>
					<div>
						{!acc.isMain && isEditing && belongsToAuthenticatedUser ? (
							<EditWalletDetails
								onSave={edit}
								onCancel={closeEdit}
								defaultName={acc.display?.name ?? ""}
								defaultImage={acc.display?.thumbnail ?? ""}
								childWalletAddress={acc.address}
								parentWalletAddress={authStore?.loggedUser?.addr ?? ""}
							/>
						) : (
							<div className='flex items-center space-x-3'>
								<img
									className='w-8 h-8 rounded-[0.25rem]'
									src={acc.display.thumbnail}
									alt='thumbnail'
									onError={({ currentTarget }) => {
										// eslint-disable-next-line no-param-reassign
										currentTarget.onerror = null // prevents looping
										// eslint-disable-next-line no-param-reassign
										currentTarget.src = placeholderImage
										// eslint-disable-next-line no-param-reassign
										currentTarget.className =
											"w-8 h-8 rounded-[0.25rem] text-white bg-glassFillGray"
									}}
								/>

								<p className='font-bold text-[1rem] truncate border border-transparent'>
									{modifiedDisplayName}
								</p>
							</div>
						)}

						<div
							className={`flex items-center ${isEditing ? "opacity-50" : ""}`}
						>
							<p
								className={`text-[#DEDEDE] text-[0.875rem] font-normal ${idTextColor}`}
							>
								{acc.address}
							</p>
							<div>
								<button
									className='ml-[5px] p-[5px] flex items-center group text-white'
									onClick={e => {
										e.stopPropagation()
										handleCopyClick(acc.address)
									}}
								>
									<div className='px-1'>
										<div className='px-1'>
											{isCopied ? (
												<CopiedIcon className='w-[1.125rem] h-[1.125rem] text-primary' />
											) : (
												<CopyIcon className='w-[1.125rem] h-[1.125rem]' />
											)}
										</div>
									</div>
								</button>
							</div>
						</div>
					</div>
					{isSelected ? (
						<EyeIcon
							className={`${
								isEditing ? "opacity-50" : ""
							} ${visibilityColor} w-[1.125rem] h-[1.125rem]`}
						/>
					) : (
						<EyeOffIcon
							className={`${
								isEditing ? "opacity-50" : ""
							} w-[1.125rem] h-[1.125rem] text-white`}
						/>
					)}
				</div>
				<div
					className={`${
						isEditing ? "opacity-50" : ""
					} flex flex-wrap space-x-2 items-center`}
				>
					{acc.isMain ? (
						<>
							<FlowtyBadge text={"MAIN"} type={"primary"} size={"small"} />
							<FlowtyBadge text={"FULL"} type={"tertiary"} size={"small"} />
						</>
					) : (
						<FlowtyBadge text={"CHILD"} type={"secondary"} size={"small"} />
					)}
					{acc.isMain ? null : (
						<img
							src={ViewOnly}
							alt='view only'
							className=' w-[1rem] h-[1rem] '
						/>
					)}
				</div>

				<div className='mt-3'>
					<LinkedAccountBalance
						isEditing={isEditing}
						flowTokenSummary={acc.tokens?.[FLOW_IDENTIFIER]}
						usdcTokenSummary={acc.tokens?.[USDC_IDENTIFIER]}
					/>
					<div className='absolute bottom-[1.125rem] right-[1.125rem]'>
						{!acc.isMain && !isEditing && belongsToAuthenticatedUser && (
							<button
								className='rounded-[0.5rem] backdrop-blur-[30px] bg-[#ffffff1f] p-[0.625rem] border-[1px] border-[#7C7C7C] hover:bg-[#ffffff33] hover:border-[#ffffff85]'
								onClick={e => {
									e.stopPropagation()
									openEdit()
								}}
							>
								<PencilIcon className='text-white h-[12px] w-[12px]' />
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export const LinkedAccount = inject("authStore")(
	observer(LinkedAccountComponent)
)
