import { useState } from "react"

interface DropAvatarProps {
	imgUrl: string
	price: number
	name: string
}

export const DropAvatar: React.FunctionComponent<DropAvatarProps> = ({
	imgUrl,
	price,
	name,
}) => {
	const [imageLoaded, setImageLoaded] = useState(false)
	const handleImageLoad = () => {
		setImageLoaded(true)
	}

	return (
		<div className='flex space-x-3'>
			{!imageLoaded && (
				<div
					className={`flex w-[48px] h-[48px] rounded-md ${
						imageLoaded ? "hidden" : ""
					}`}
				></div>
			)}
			<img
				onLoad={handleImageLoad}
				src={imgUrl}
				alt='Drop Avatar'
				className={`w-[48px] h-[48px] rounded-md ${
					!imageLoaded ? "hidden" : "object-fill aspect-square"
				}`}
			/>
			<div className='flex flex-col text-white text-xl'>
				<div className=' font-bold'>{name}</div>
				<div className='text-lg font-bold space-x-2'>
					<span className='text-gray-500 font-normal'>Price:</span>
					<span>{`$${price.toFixed(2)}`}</span>
				</div>
			</div>
		</div>
	)
}
