import FlowtyLoadingGif from "../../../assets/flowty-loading2.gif"
import FlowtyLink from "../../../FlowtyModal/components/common/FlowtyLink"

interface Props {
	transactionExplorerLink: string | null
}

export const DropPurchasing: React.FunctionComponent<Props> = ({
	transactionExplorerLink,
}) => {
	return (
		<div className='w-full flex flex-col items-center justify-center gap-[8px] font-montserrat'>
			<img
				className='w-[124px] h-[124px]'
				src={FlowtyLoadingGif}
				alt='Processing Transaction GIF
			'
			/>
			<div className='text-[16px] font-semibold text-[#F8F9FA]'>
				Transaction Processing...
			</div>
			<div className='text-white text-center text-[14px] min-h-[21px] flex items-center justify-center gap-1'>
				{transactionExplorerLink && transactionExplorerLink !== "" && (
					<>
						Follow your transaction on{" "}
						<FlowtyLink href={transactionExplorerLink} text='Flowdiver' />
					</>
				)}
			</div>
		</div>
	)
}
