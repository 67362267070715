import { Divider } from "ds-flowty/src/Shared/Atoms/Divider"
import { useState } from "react"
import { MobileSidebarDrawer } from "../../../../Modals/MobileSidebar/MobileSidebarDrawer"
import { OpenSearchBarMobile } from "../../../../OpenSearch/OpenSearchBarMobile"
import { AllTags } from "../../../../OpenSearch/filters/AllTags"
import { SaveView } from "../../../../Sidebar/SaveView/SaveView"
import { ReactComponent as CloseIcon } from "../../../../../assets/media/close-icon-2.svg"

interface MobileControllersProps {
	mobilePageFilters: React.ReactNode
	address?: string
	isActionsTab?: boolean
}

export const MobileControllers: React.FunctionComponent<
	MobileControllersProps
> = ({ mobilePageFilters, address, isActionsTab = false }) => {
	const [sidebarNavigation, setSidebarNavigation] = useState("0px")
	return (
		<>
			{sidebarNavigation === "390px" && (
				<div
					onClick={() => setSidebarNavigation("0px")}
					style={{
						backgroundColor: "rgba(0, 0, 0, 0.8)",
						height: "100vh",
						left: 0,
						position: "fixed",
						top: 0,
						width: "100vw",
						zIndex: 2,
					}}
				/>
			)}

			<OpenSearchBarMobile
				onClick={() => setSidebarNavigation("390px")}
				address={address}
				isActionsTab={isActionsTab}
			/>
			{!isActionsTab && (
				<div className='w-full'>
					<AllTags isMobile />
				</div>
			)}
			<MobileSidebarDrawer sidebarNavigation={sidebarNavigation}>
				<button
					onClick={() => setSidebarNavigation("0px")}
					className='sticky top-0 left-0 w-full z-40 text-white bg-[#04070B] min-h-[2.5rem] rounded-md flex items-center gap-[0.375rem] pt-[2.75rem] pb-[2.19rem]'
				>
					<div className='absolute left-5'>
						<CloseIcon className='h-4 w-4' />
					</div>
					<span className='mx-auto text-3xl font-bold '>Filters</span>
				</button>
				<div className='flex flex-col p-[0.5rem] items-stretch gap-[1rem] w-full px-4'>
					{!isActionsTab && <SaveView />}
					<Divider />
					{mobilePageFilters}
				</div>
			</MobileSidebarDrawer>
		</>
	)
}
