import React from "react"
import { renderNotificationItem } from "../NotificationMenu/NotificationMenu"
import { NotificationData, NotificationType } from "flowty-common"

interface NotificationsListProps {
	notifications: NotificationData[]
	queueMessage?: ({ type, id }: { type: NotificationType; id: string }) => void
	label: string
}

const NotificationsList: React.FunctionComponent<NotificationsListProps> = ({
	notifications,
	queueMessage,
	label,
}) => {
	if (notifications.length <= 0) {
		return null
	}

	return (
		<div className='flex flex-col'>
			<span className='text-[#DEE2E6] text-[14px] font-normal mb-[16px]'>
				{label}
			</span>
			{notifications.map((notification, index) => (
				<div
					key={`${notification.id}-${index}`}
					className={`${
						index === notifications.length - 1 ? "mb-0" : "mb-[24px]"
					}`}
				>
					{renderNotificationItem(
						notification.type,
						notification,
						queueMessage
					)}
				</div>
			))}
		</div>
	)
}

export default NotificationsList
