import { FCLTransactionResult } from "flowty-common"
import { Err, getCatalogEntryForType, sendMutation } from "../../utils/Utils"
import { MakeOfferProps } from "./MakeOfferTypes"
import getDapperRoyaltyDetails from "../../utils/GetDWRoyaltyScript"
import GetCollectionPaths from "../../utils/GetCollectionPaths"
import { Config } from "../../types"
import { getTargetedOfferTxn } from "./transactions"

const fcl = require("@onflow/fcl")
const t = require("@onflow/types")

export const FLOWTYV2_CONTRACT_ADDR =
	process.env.REACT_APP_ADDRESS_FLOWTYV2_CONTRACT ||
	process.env.REACT_APP_ADDRESS_FLOWTY_CONTRACT ||
	""

export class MakeOffer {
	config: Config

	constructor(config: Config) {
		this.config = config
	}

	makeOffer = async ({
		nftData,
		expiry,
		ftProviderPathIdentifier,
		offerAmount,
		nftReceiverAddress,
		ftProviderAddress,
		txAvailableCallback,
		token,
		tokenIdentifier,
	}: MakeOfferProps): Promise<FCLTransactionResult> => {
		const defaultNumAcceptable = 1
		if (!nftData) throw new Error("Flow NFT it's null")
		const { contractAddress, contractName, id, type, nftOwner } = nftData

		const catalogIdentifier = await getCatalogEntryForType(type, this.config)
		const isDapper = ["DUC", "FUT"].includes(token.symbol)

		const txContent = getTargetedOfferTxn(
			this.config,
			token,
			nftData,
			!!catalogIdentifier
		)

		console.debug("txContent", { txContent })

		const offerParamsString: { [key: string]: string } = {
			expiry: expiry.toString(),
		}
		const dictArgs = Object.entries(offerParamsString).map(entry => {
			return { key: entry[0], value: entry[1] }
		})
		const txArgs = []

		const ftProviderArg = this.config.crescendo
			? fcl.arg(ftProviderPathIdentifier || "0", t.UInt64)
			: fcl.arg(ftProviderPathIdentifier, t.String)

		if (catalogIdentifier && isDapper) {
			const collectionPathsArgs = [
				fcl.arg(contractAddress, t.Address),
				fcl.arg(contractName, t.String),
			]

			const collectionPaths = await fcl.query({
				args: (_arg: any, _t: any) => collectionPathsArgs,
				cadence: GetCollectionPaths,
			})

			const dWRoyaltiesScriptArgs = [
				fcl.arg(nftOwner as string, t.Address),
				fcl.arg(id.toString(), t.UInt64),
				fcl.arg(collectionPaths.storagePath.split("/")[2], t.String),
				fcl.arg(offerAmount.toFixed(6), t.UFix64),
			]

			// For DW Offers the royalty address MUST be a merchant address
			const getDWRoyaltiesAndFees = await fcl.query({
				args: (_arg: any, _t: any) => dWRoyaltiesScriptArgs,
				cadence: getDapperRoyaltyDetails,
			})

			const royaltiesDW = Object.entries(getDWRoyaltiesAndFees).map(entry => {
				return { key: entry[0], value: entry[1] }
			})

			// The proxy address is how dapper cancels offers automatically when a user's dapper balance
			// is too low to cover the offer amount.
			const proxyAddress = "0xe1f2a091f7bb5245"

			txArgs.push(
				fcl.arg(offerAmount.toFixed(6), t.UFix64),
				fcl.arg(royaltiesDW, t.Dictionary({ key: t.Address, value: t.UFix64 })),
				fcl.arg(dictArgs, t.Dictionary({ key: t.String, value: t.String })),
				fcl.arg(id.toString(), t.UInt64),
				fcl.arg(contractAddress, t.Address),
				fcl.arg(contractName, t.String),
				fcl.arg(collectionPaths.publicPath.split("/")[2], t.String),
				fcl.arg(collectionPaths.storagePath.split("/")[2], t.String),
				fcl.arg(proxyAddress, t.Address),
				fcl.arg(expiry.toString(), t.UInt64)
			)
		} else if (catalogIdentifier) {
			txArgs.push(
				fcl.arg(catalogIdentifier, t.String),
				fcl.arg(id.toString(), t.UInt64),
				fcl.arg(defaultNumAcceptable.toString(), t.Int),
				fcl.arg(offerAmount.toFixed(6), t.UFix64),
				fcl.arg(tokenIdentifier, t.String),
				fcl.arg(expiry.toString(), t.UInt64),
				fcl.arg(nftReceiverAddress, t.Address),
				fcl.arg(ftProviderAddress, t.Address),
				ftProviderArg
			)
		} else {
			txArgs.push(
				fcl.arg(nftData.type, t.String),
				fcl.arg(id.toString(), t.UInt64),
				fcl.arg(defaultNumAcceptable.toString(), t.Int),
				fcl.arg(offerAmount.toFixed(6), t.UFix64),
				fcl.arg(tokenIdentifier, t.String),
				fcl.arg(expiry.toString(), t.UInt64),
				fcl.arg(nftReceiverAddress, t.Address),
				fcl.arg(ftProviderAddress, t.Address),
				ftProviderArg
			)
		}

		try {
			const res = await sendMutation(txContent, txArgs, txAvailableCallback)
			return res
		} catch (e) {
			console.log("TX ERROR", e)
			Err("createStorefrontListing", e)
			throw e
		}
	}
}
