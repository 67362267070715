import useBreakpoint from "../../FlowtyNavbar/hooks/useBreakpoint"
import Desktop from "./Desktop"
import Mobile from "./Mobile"
import Tablet from "./Tablet"

interface GettingStartedProps {
	isUserLoggedIn: boolean
	connectWallet: () => void
}

export const GettingStarted: React.FC<GettingStartedProps> = ({
	isUserLoggedIn,
	connectWallet,
}) => {
	const breakpoint = useBreakpoint()

	const renderBasedOnBreakpoint = () => {
		if (["xl", "desktop", "laptop"].includes(breakpoint)) {
			return (
				<Desktop
					connectWallet={connectWallet}
					isUserLoggedIn={isUserLoggedIn}
				/>
			)
		} else if (["tablet"].includes(breakpoint)) {
			return (
				<Tablet connectWallet={connectWallet} isUserLoggedIn={isUserLoggedIn} />
			)
		} else {
			return (
				<Mobile connectWallet={connectWallet} isUserLoggedIn={isUserLoggedIn} />
			)
		}
	}

	return renderBasedOnBreakpoint()
}
