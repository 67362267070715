import { TokenIcon } from "../../Tokens/tokenDisplays"
import numeral from "numeral"
import { TokenSummary } from "flowty-sdk"

interface LinkedAccountBalanceProps {
	flowTokenSummary: TokenSummary
	usdcTokenSummary: TokenSummary
	isEditing?: boolean // isEditing prop passed to grey out balance when editing, applying to the parent div rendered action buttons non-responsive. There might be a better way to do this.
}

export const LinkedAccountBalance: React.FunctionComponent<
	LinkedAccountBalanceProps
> = ({ flowTokenSummary, usdcTokenSummary, isEditing }) => {
	return (
		<div className={`flex flex-col items-start`}>
			{flowTokenSummary?.balance && (
				<div
					className={`${
						isEditing ? "opacity-50" : ""
					}  flex flex-row items-center`}
				>
					<span>
						<TokenIcon token='FLOW' isLarge />
					</span>
					<span className='font-montserrat font-semibold text-white ml-2 text-sm'>
						{numeral(flowTokenSummary.balance).format("0,0.00")}
					</span>
					<span className='font-montserrat font-semibold text-white ml-2 text-sm'>
						FLOW
					</span>
				</div>
			)}
			{usdcTokenSummary?.balance && (
				<div
					className={`${
						isEditing ? "opacity-50" : ""
					}  flex flex-row items-center mt-3`}
				>
					<span>
						<TokenIcon token='USDC' isLarge />
					</span>
					<span className='font-montserrat font-semibold text-white ml-2 text-sm'>
						{numeral(usdcTokenSummary.balance).format("0,0.00")}
					</span>
					<span className='font-montserrat font-semibold text-white ml-2 text-sm'>
						USDC
					</span>
				</div>
			)}
		</div>
	)
}
