/* eslint-disable @typescript-eslint/no-use-before-define */
import { formatAddress, TokenMetadata } from "flowty-common"
import { Config } from "../../types"

export const getStorefrontPurchaseTxn = (
	config: Config,
	isNFTCatalog: boolean,
	listingType: string,
	token: TokenMetadata
): string => {
	const listingTypeAddress = formatAddress(listingType.split(".")[1])
	if (listingTypeAddress === config.contractAddresses.NFTStorefrontV2) {
		return getFlowtyStorefrontPurchaseTxn(config, isNFTCatalog, token)
	}

	if (listingTypeAddress === config.contractAddresses.NFTStorefrontV2_Shared) {
		return getSharedStorefrontPurchaseTxn(config, isNFTCatalog, token)
	}

	throw new Error("invalid listing type")
}

const getFlowtyStorefrontPurchaseTxn = (
	config: Config,
	isNFTCatalog: boolean,
	token: TokenMetadata
): string => {
	if (token.symbol === "DUC") {
		return config.crescendo
			? flowtyStorefrontPurchaseDapperBalanceCrescendo(config)
			: flowtyStorefrontPurchaseDapperBalance(config)
	}

	if (token.symbol === "FUT") {
		return config.crescendo
			? flowtyStorefrontPurchaseDapperFlowCrescendo(config)
			: flowtyStorefrontPurchaseDapperFlow(config)
	}

	if (isNFTCatalog) {
		return config.crescendo
			? flowtyStorefrontPurchaseNFTCatalogCrescendo(config, token)
			: flowtyStorefrontPurchaseNFTCatalog(config, token)
	}

	return config.crescendo
		? flowtyStorefrontPurchaseViewResolverCrescendo(config, token)
		: flowtyStorefrontPurchaseViewResolver(config, token)
}

const getSharedStorefrontPurchaseTxn = (
	config: Config,
	isNFTCatalog: boolean,
	token: TokenMetadata
): string => {
	if (token.symbol === "DUC") {
		return config.crescendo
			? sharedStorefrontPurchaseDapperBalanceCrescendo(config)
			: sharedStorefrontPurchaseDapperBalance(config)
	}

	if (token.symbol === "FUT") {
		return config.crescendo
			? sharedStorefrontPurchaseDapperFlowCrescendo(config)
			: sharedStorefrontPurchaseDapperFlow(config)
	}

	if (isNFTCatalog) {
		return config.crescendo
			? sharedStorefrontPurchaseNFTCatalogCrescendo(config, token)
			: sharedStorefrontPurchaseNFTCatalog(config, token)
	}

	return config.crescendo
		? sharedStorefrontPurchaseViewResolverCrescendo(config, token)
		: sharedStorefrontPurchaseViewResolver(config, token)
}

const flowtyStorefrontPurchaseDapperBalance = (
	config: Config
): string => `import DapperUtilityCoin from ${config.contractAddresses.DapperUtilityCoin}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}
import HybridCustody from ${config.contractAddresses.HybridCustody}
import TopShot from ${config.contractAddresses.TopShot}

/// Transaction facilitates the purcahse of listed NFT.
/// It takes the storefront address, listing resource that need
/// to be purchased & a address that will takeaway the commission.
///
/// Buyer of the listing (,i.e. underling NFT) would authorize and sign the
/// transaction and if purchase happens then transacted NFT would store in
/// buyer's collection.

transaction(storefrontAddress: Address, listingResourceID: UInt64, commissionRecipient: Address, collectionIdentifier: String, nftReceiverAddress: Address) {
    let paymentVault: @FungibleToken.Vault
    let collection: &AnyResource{NonFungibleToken.CollectionPublic}
    let collectionCap: Capability<&AnyResource{NonFungibleToken.CollectionPublic}>
    let storefront: &NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}
    let listing: &NFTStorefrontV2.Listing{NFTStorefrontV2.ListingPublic}
    var commissionRecipientCap: Capability<&{FungibleToken.Receiver}>?
    let balanceBeforeTransfer: UFix64
    let mainPaymentVault: &DapperUtilityCoin.Vault
    let listingAcceptor: &NFTStorefrontV2.Storefront{NFTStorefrontV2.PrivateListingAcceptor}

    prepare(dapper: AuthAccount, buyer: AuthAccount) {
        if buyer.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            // Create a new empty Storefront
            let storefront <- NFTStorefrontV2.createStorefront() as! @NFTStorefrontV2.Storefront
            // save it to the account
            buyer.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
            // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present
            buyer.unlink(NFTStorefrontV2.StorefrontPublicPath)
            buyer.link<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontPublicPath, target: NFTStorefrontV2.StorefrontStoragePath)
        }

        self.listingAcceptor = buyer.borrow<&NFTStorefrontV2.Storefront{NFTStorefrontV2.PrivateListingAcceptor}>(from: NFTStorefrontV2.StorefrontStoragePath) ?? panic("Buyer storefront is invalid")

        let value = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

        self.commissionRecipientCap = nil
        // Access the storefront public resource of the seller to purchase the listing.
        self.storefront = getAccount(storefrontAddress)
            .getCapability<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}>(
                NFTStorefrontV2.StorefrontPublicPath
            )!
            .borrow()
            ?? panic("Could not borrow Storefront from provided address")

        // Borrow the listing
        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("Listing not found")
        let listingDetails = self.listing.getDetails()
        let nftRef = self.listing.borrowNFT() ?? panic("nft not found")

        if nftReceiverAddress == buyer.address {
            self.collectionCap = buyer.getCapability<&AnyResource{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)
            // unlink and relink first, if it still isn't working, then we will try to save it!
            if !self.collectionCap.check() {
                if buyer.borrow<&AnyResource>(from: value.collectionData.storagePath) == nil {
                    // pull the metdata resolver for this listing's nft and use it to configure this account's collection
                    // if it is not already configured.
                    let collectionData = nftRef.resolveView(Type<MetadataViews.NFTCollectionData>())! as! MetadataViews.NFTCollectionData
                    buyer.save(<-collectionData.createEmptyCollection(), to: value.collectionData.storagePath)
                }

                buyer.unlink(value.collectionData.publicPath)
                if nftRef.getType() == Type<@TopShot.NFT>() {
                    buyer.link<&TopShot.Collection{TopShot.MomentCollectionPublic, NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(value.collectionData.publicPath, target: value.collectionData.storagePath)
                } else {
                    buyer.link<&AnyResource{NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(value.collectionData.publicPath, target: value.collectionData.storagePath)
                }
            }
        } else {
            // signer is the parent account and nftProvider is child Account
            // get the manager resource and borrow proxyAccount
            let manager = buyer.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("manager does not exist")
            let childAcct = manager.borrowAccount(addr: nftReceiverAddress) ?? panic("nft receiver address is not a child account")
            self.collectionCap = getAccount(nftReceiverAddress).getCapability<&AnyResource{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)
            // We can't change child account links in any way
        }

        // Access the buyer's NFT collection to store the purchased NFT.
        self.collection = self.collectionCap.borrow() ?? panic("Cannot borrow NFT collection receiver from account")

        let price = listingDetails.salePrice

        // Access the vault of the buyer to pay the sale price of the listing.
        self.mainPaymentVault = dapper.borrow<&DapperUtilityCoin.Vault>(from: /storage/dapperUtilityCoinVault)
            ?? panic("Cannot borrow DapperUtilityCoin vault from buyer storage")
        self.balanceBeforeTransfer = self.mainPaymentVault.balance
        self.paymentVault <- self.mainPaymentVault.withdraw(amount: price)

        // Fetch the commission amt.
        let commissionAmount = self.listing.getDetails().commissionAmount

        if commissionRecipient != nil && commissionAmount != 0.0 {
            // Access the capability to receive the commission.
            let _commissionRecipientCap = getAccount(commissionRecipient!).getCapability<&{FungibleToken.Receiver}>(/public/dapperUtilityCoinReceiver)
            assert(_commissionRecipientCap.check(), message: "Commission Recipient doesn't have DapperUtilityCoin receiving capability")
            self.commissionRecipientCap = _commissionRecipientCap
        } else if commissionAmount == 0.0 {
            self.commissionRecipientCap = nil
        } else {
            panic("Commission recipient can not be empty when commission amount is non zero")
        }

    }

    execute {
        // Purchase the NFT
        let item <- self.listing.purchase(
            payment: <-self.paymentVault,
            commissionRecipient: self.commissionRecipientCap,
            privateListingAcceptor: self.listingAcceptor
        )
        // Deposit the NFT in the buyer's collection.
        self.collection.deposit(token: <-item)
    }

    post {
        self.mainPaymentVault.balance == self.balanceBeforeTransfer: "DapperUtilityCoin leakage"
    }

}`

const flowtyStorefrontPurchaseDapperFlow = (
	config: Config
): string => `import FlowUtilityToken from ${config.contractAddresses.FlowUtilityToken}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}
import HybridCustody from ${config.contractAddresses.HybridCustody}
import TopShot from ${config.contractAddresses.TopShot}

/// Transaction facilitates the purcahse of listed NFT.
/// It takes the storefront address, listing resource that need
/// to be purchased & a address that will takeaway the commission.
///
/// Buyer of the listing (,i.e. underling NFT) would authorize and sign the
/// transaction and if purchase happens then transacted NFT would store in
/// buyer's collection.

transaction(storefrontAddress: Address, listingResourceID: UInt64, commissionRecipient: Address, collectionIdentifier: String, nftReceiverAddress: Address) {
    let paymentVault: @FungibleToken.Vault
    let collection: &AnyResource{NonFungibleToken.CollectionPublic}
    let collectionCap: Capability<&AnyResource{NonFungibleToken.CollectionPublic}>
    let storefront: &NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}
    let listing: &NFTStorefrontV2.Listing{NFTStorefrontV2.ListingPublic}
    var commissionRecipientCap: Capability<&{FungibleToken.Receiver}>?
    let balanceBeforeTransfer: UFix64
    let mainPaymentVault: &FlowUtilityToken.Vault
    let listingAcceptor: &NFTStorefrontV2.Storefront{NFTStorefrontV2.PrivateListingAcceptor}

    prepare(dapper: AuthAccount, buyer: AuthAccount) {
        if buyer.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            // Create a new empty Storefront
            let storefront <- NFTStorefrontV2.createStorefront() as! @NFTStorefrontV2.Storefront
            // save it to the account
            buyer.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
            // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present
            buyer.unlink(NFTStorefrontV2.StorefrontPublicPath)
            buyer.link<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontPublicPath, target: NFTStorefrontV2.StorefrontStoragePath)
        }

        self.listingAcceptor = buyer.borrow<&NFTStorefrontV2.Storefront{NFTStorefrontV2.PrivateListingAcceptor}>(from: NFTStorefrontV2.StorefrontStoragePath) ?? panic("Buyer storefront is invalid")

        let value = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

        self.commissionRecipientCap = nil
        // Access the storefront public resource of the seller to purchase the listing.
        self.storefront = getAccount(storefrontAddress)
            .getCapability<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}>(
                NFTStorefrontV2.StorefrontPublicPath
            )!
            .borrow()
            ?? panic("Could not borrow Storefront from provided address")

        // Borrow the listing
        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("Listing not found")
        let listingDetails = self.listing.getDetails()
        let nftRef = self.listing.borrowNFT() ?? panic("nft not found")

        if nftReceiverAddress == buyer.address {
            self.collectionCap = buyer.getCapability<&AnyResource{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)
            // unlink and relink first, if it still isn't working, then we will try to save it!
            if !self.collectionCap.check() {
                if buyer.borrow<&AnyResource>(from: value.collectionData.storagePath) == nil {
                    // pull the metdata resolver for this listing's nft and use it to configure this account's collection
                    // if it is not already configured.
                    let collectionData = nftRef.resolveView(Type<MetadataViews.NFTCollectionData>())! as! MetadataViews.NFTCollectionData
                    buyer.save(<-collectionData.createEmptyCollection(), to: value.collectionData.storagePath)
                }

                buyer.unlink(value.collectionData.publicPath)
                if nftRef.getType() == Type<@TopShot.NFT>() {
                    buyer.link<&TopShot.Collection{TopShot.MomentCollectionPublic, NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(value.collectionData.publicPath, target: value.collectionData.storagePath)
                } else {
                    buyer.link<&AnyResource{NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(value.collectionData.publicPath, target: value.collectionData.storagePath)
                }
            }
        } else {
            // signer is the parent account and nftProvider is child Account
            // get the manager resource and borrow proxyAccount
            let manager = buyer.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("manager does not exist")
            let childAcct = manager.borrowAccount(addr: nftReceiverAddress) ?? panic("nft receiver address is not a child account")
            self.collectionCap = getAccount(nftReceiverAddress).getCapability<&AnyResource{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)
            // We can't change child account links in any way
        }

        // Access the buyer's NFT collection to store the purchased NFT.
        self.collection = self.collectionCap.borrow() ?? panic("Cannot borrow NFT collection receiver from account")

        let price = listingDetails.salePrice

        // Access the vault of the buyer to pay the sale price of the listing.
        self.mainPaymentVault = dapper.borrow<&FlowUtilityToken.Vault>(from: /storage/flowUtilityTokenVault)
            ?? panic("Cannot borrow FlowUtilityToken vault from buyer storage")
        self.balanceBeforeTransfer = self.mainPaymentVault.balance
        self.paymentVault <- self.mainPaymentVault.withdraw(amount: price)

        // Fetch the commission amt.
        let commissionAmount = self.listing.getDetails().commissionAmount

        if commissionRecipient != nil && commissionAmount != 0.0 {
            // Access the capability to receive the commission.
            let _commissionRecipientCap = getAccount(commissionRecipient!).getCapability<&{FungibleToken.Receiver}>(/public/flowUtilityTokenReceiver)
            assert(_commissionRecipientCap.check(), message: "Commission Recipient doesn't have FlowUtilityToken receiving capability")
            self.commissionRecipientCap = _commissionRecipientCap
        } else if commissionAmount == 0.0 {
            self.commissionRecipientCap = nil
        } else {
            panic("Commission recipient can not be empty when commission amount is non zero")
        }

    }

    execute {
        // Purchase the NFT
        let item <- self.listing.purchase(
            payment: <-self.paymentVault,
            commissionRecipient: self.commissionRecipientCap,
            privateListingAcceptor: self.listingAcceptor
        )
        // Deposit the NFT in the buyer's collection.
        self.collection.deposit(token: <-item)
    }

    post {
        self.mainPaymentVault.balance == self.balanceBeforeTransfer: "FlowUtilityToken leakage"
    }

}`

const flowtyStorefrontPurchaseNFTCatalog = (
	config: Config,
	token: TokenMetadata
): string => `import ${token.contractName} from ${token.contractAddress}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}

import HybridCustody from ${config.contractAddresses.HybridCustody}

/// Storefront purchase txn that can route nfts to a child, and take tokens from a child to pay

transaction(storefrontAddress: Address, listingResourceID: UInt64, commissionRecipient: Address, collectionIdentifier: String, nftReceiverAddress: Address, ftProviderAddress: Address, privateFTPath: String ) {
  let paymentVault: @FungibleToken.Vault
  let collection: &AnyResource{NonFungibleToken.CollectionPublic}
  let storefront: &NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}
  let listing: &NFTStorefrontV2.Listing{NFTStorefrontV2.ListingPublic}
  var commissionRecipientCap: Capability<&{FungibleToken.Receiver}>?
  let collectionCap: Capability<&AnyResource{NonFungibleToken.CollectionPublic}>
  let listingAcceptor: &NFTStorefrontV2.Storefront{NFTStorefrontV2.PrivateListingAcceptor}

  prepare(buyer: AuthAccount) {
    if buyer.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
      // Create a new empty Storefront
      let storefront <- NFTStorefrontV2.createStorefront() as! @NFTStorefrontV2.Storefront
      // save it to the account
      buyer.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
      // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present
      buyer.unlink(NFTStorefrontV2.StorefrontPublicPath)
      buyer.link<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontPublicPath, target: NFTStorefrontV2.StorefrontStoragePath)
    }

    self.listingAcceptor = buyer.borrow<&NFTStorefrontV2.Storefront{NFTStorefrontV2.PrivateListingAcceptor}>(from: NFTStorefrontV2.StorefrontStoragePath) ?? panic("Buyer storefront is invalid")

    let value = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

    self.commissionRecipientCap = nil
    // Access the storefront public resource of the seller to purchase the listing.
    self.storefront = getAccount(storefrontAddress)
      .getCapability<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}>(
        NFTStorefrontV2.StorefrontPublicPath
      )!
      .borrow()
      ?? panic("Could not borrow Storefront from provided address")

    // Borrow the listing
    self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
      ?? panic("Listing not found")
    let listingDetails = self.listing.getDetails()
    let nftRef = self.listing.borrowNFT() ?? panic("nft not found")

    if nftReceiverAddress == buyer.address {
        self.collectionCap = buyer.getCapability<&AnyResource{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)
        // unlink and relink first, if it still isn't working, then we will try to save it!
        if !self.collectionCap.check() {
            if buyer.borrow<&AnyResource>(from: value.collectionData.storagePath) == nil {
                // pull the metdata resolver for this listing's nft and use it to configure this account's collection
                // if it is not already configured.
                let collectionData = nftRef.resolveView(Type<MetadataViews.NFTCollectionData>())! as! MetadataViews.NFTCollectionData
                buyer.save(<- collectionData.createEmptyCollection(), to: value.collectionData.storagePath)
            }

            buyer.unlink(value.collectionData.publicPath)
            buyer.link<&{NonFungibleToken.CollectionPublic, MetadataViews.ResolverCollection, NonFungibleToken.Receiver}>(value.collectionData.publicPath, target: value.collectionData.storagePath)
        }
    } else {
        // signer is the parent account and nftProvider is child Account
        // get the manager resource and borrow proxyAccount
        let manager = buyer.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
            ?? panic("manager does not exist")
        let childAcct = manager.borrowAccount(addr: nftReceiverAddress) ?? panic("nft receiver address is not a child account")
        self.collectionCap = getAccount(nftReceiverAddress).getCapability<&AnyResource{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)
        // We can't change child account links in any way
    }

    // Access the buyer or child's NFT collection to store the purchased NFT.
    self.collection = self.collectionCap.borrow() ?? panic("Cannot borrow NFT collection receiver")

    // Access the vault of the buyer/child to pay the sale price of the listing.
    if ftProviderAddress == buyer.address{
      let vault = buyer.borrow<&{FungibleToken.Provider}>(from: ${token.storagePath})
        ?? panic("Cannot borrow token vault from acct storage")
      self.paymentVault <- vault.withdraw(amount: listingDetails.salePrice)
    } else {
      // signer is the parent account and ftProvider is child Account
      // get the manager resource and borrow proxyAccount
      let manager = buyer.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
        ?? panic("manager does not exist")
      let childAcct = manager.borrowAccount(addr: ftProviderAddress) ?? panic("ftProvider account not found")

      let privatePath = PrivatePath(identifier: privateFTPath) ?? panic("private path identifier is not valid")
      let providerCap = childAcct.getCapability(path: privatePath, type: Type<&{FungibleToken.Provider}>()) ?? panic("token provider not found for supplied child account address")
      let ftProvider = providerCap as! Capability<&{FungibleToken.Provider}>
      let ftProviderVault = ftProvider.borrow() ?? panic("child account token vault could not be borrowed")
      self.paymentVault <- ftProviderVault.withdraw(amount: listingDetails.salePrice)
    }

    if commissionRecipient != nil && listingDetails.commissionAmount != 0.0 {
      // Access the capability to receive the commission.
      let _commissionRecipientCap = getAccount(commissionRecipient!).getCapability<&{FungibleToken.Receiver}>(${token.receiverPath})
      assert(_commissionRecipientCap.check(), message: "Commission Recipient doesn't have token receiving capability")
      self.commissionRecipientCap = _commissionRecipientCap
    } else if listingDetails.commissionAmount == 0.0 {
      self.commissionRecipientCap = nil
    } else {
      panic("Commission recipient can not be empty when commission amount is non zero")
    }
  }

  execute {
    // Purchase the NFT
    let item <- self.listing.purchase(
      payment: <-self.paymentVault,
      commissionRecipient: self.commissionRecipientCap,
      privateListingAcceptor: self.listingAcceptor
    )
    // Deposit the NFT in the buyer's collection.
    self.collection.deposit(token: <-item)
  }
}
`

const flowtyStorefrontPurchaseViewResolver = (
	config: Config,
	token: TokenMetadata
): string => `import ${token.contractName} from ${token.contractAddress}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import ViewResolver from ${config.contractAddresses.ViewResolver}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}

import HybridCustody from ${config.contractAddresses.HybridCustody}

/// Transaction facilitates the purcahse of listed NFT.
/// It takes the storefront address, listing resource that need
/// to be purchased & a address that will takeaway the commission.
///
/// Buyer of the listing (,i.e. underling NFT) would authorize and sign the
/// transaction and if purchase happens then transacted NFT would store in
/// buyer's collection.
transaction(
    storefrontAddress: Address,
    listingResourceID: UInt64,
    commissionRecipient: Address,
    nftReceiverAddress: Address,
    paymentAddress: Address,
    paymentProviderIdentifier: String
) {
    let paymentVault: @FungibleToken.Vault
    let collection: &AnyResource{NonFungibleToken.CollectionPublic}
    let storefront: &NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}
    let listing: &NFTStorefrontV2.Listing{NFTStorefrontV2.ListingPublic}
    var commissionRecipientCap: Capability<&{FungibleToken.Receiver}>?
    let listingAcceptor: &NFTStorefrontV2.Storefront{NFTStorefrontV2.PrivateListingAcceptor}
    let collectionCap: Capability<&{NonFungibleToken.CollectionPublic}>

    prepare(buyer: AuthAccount) {

        if buyer.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            // Create a new empty Storefront
            let storefront <- NFTStorefrontV2.createStorefront() as! @NFTStorefrontV2.Storefront
            // save it to the account
            buyer.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
            // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present
            buyer.unlink(NFTStorefrontV2.StorefrontPublicPath)
            buyer.link<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontPublicPath, target: NFTStorefrontV2.StorefrontStoragePath)
        }

        self.listingAcceptor = buyer.borrow<&NFTStorefrontV2.Storefront{NFTStorefrontV2.PrivateListingAcceptor}>(from: NFTStorefrontV2.StorefrontStoragePath) ?? panic("Buyer storefront is invalid")

        self.commissionRecipientCap = nil
        // Access the storefront public resource of the seller to purchase the listing.
        self.storefront = getAccount(storefrontAddress)
            .getCapability<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}>(
                NFTStorefrontV2.StorefrontPublicPath
            )!
            .borrow()
            ?? panic("Could not borrow Storefront from provided address")

        // Borrow the listing
        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("No Offer with that ID in Storefront")
        let listingDetails = self.listing.getDetails()
        let nftRef = self.listing.borrowNFT() ?? panic("nft not found")

        let md = nftRef.resolveView(Type<MetadataViews.NFTCollectionData>()) ?? panic("NFTCollectionData view not found on the contract.")
        let collectionData = md as! MetadataViews.NFTCollectionData

        if nftReceiverAddress == buyer.address {
            self.collectionCap = buyer.getCapability<&{NonFungibleToken.CollectionPublic}>(collectionData.publicPath)

            // unlink and relink first, if it still isn't working, then we will try to save it!
            if !self.collectionCap.check() {
                if buyer.borrow<&AnyResource>(from: collectionData.storagePath) == nil {
                    // pull the metdata resolver for this listing's nft and use it to configure this account's collection
                    // if it is not already configured.
                    let collectionData = nftRef.resolveView(Type<MetadataViews.NFTCollectionData>())! as! MetadataViews.NFTCollectionData
                    buyer.save(<- collectionData.createEmptyCollection(), to: collectionData.storagePath)
                }

                buyer.unlink(collectionData.publicPath)
                buyer.link<&{NonFungibleToken.CollectionPublic, MetadataViews.ResolverCollection, NonFungibleToken.Receiver}>(collectionData.publicPath, target: collectionData.storagePath)
            }
        } else {
            // signer is the parent account and nftProvider is child Account
            // get the manager resource and borrow proxyAccount
            let manager = buyer.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("manager does not exist")
            let childAcct = manager.borrowAccount(addr: nftReceiverAddress) ?? panic("nft receiver address is not a child account")
            self.collectionCap = getAccount(nftReceiverAddress).getCapability<&AnyResource{NonFungibleToken.CollectionPublic}>(collectionData.publicPath)
            // We can't change child account links in any way
        }

        // Access the buyer or child's NFT collection to store the purchased NFT.
        assert(self.collectionCap.check(), message: "Cannot borrow NFT collection receiver")
        self.collection = self.collectionCap.borrow()!

        if paymentAddress == buyer.address {
          let vault = buyer.borrow<&{FungibleToken.Provider}>(from: ${token.storagePath})
            ?? panic("Cannot borrow token vault from acct storage")
          self.paymentVault <- vault.withdraw(amount: listingDetails.salePrice)
        } else {
          // signer is the parent account and ftProvider is child Account
          // get the manager resource and borrow proxyAccount
          let manager = buyer.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
            ?? panic("manager does not exist")
          let childAcct = manager.borrowAccount(addr: paymentAddress) ?? panic("ftProvider account not found")

          let privatePath = PrivatePath(identifier: paymentProviderIdentifier) ?? panic("invalid payment provider identifier")
          let providerCap = childAcct.getCapability(path: privatePath, type: Type<&{FungibleToken.Provider}>()) ?? panic("token provider not found for supplied child account address")
          let ftProvider = providerCap as! Capability<&{FungibleToken.Provider}>
          assert(ftProvider.check(), message: "invalid provider capability")
          let ftProviderVault = ftProvider.borrow() ?? panic("child account token vault could not be borrowed")
          self.paymentVault <- ftProviderVault.withdraw(amount: listingDetails.salePrice)
        }

        if commissionRecipient != nil && listingDetails.commissionAmount != 0.0 {
            // Access the capability to receive the commission.
            let _commissionRecipientCap = getAccount(commissionRecipient!).getCapability<&{FungibleToken.Receiver}>(${token.receiverPath})
            assert(_commissionRecipientCap.check(), message: "Commission Recipient doesn't have receiving capability")
            self.commissionRecipientCap = _commissionRecipientCap
        } else if listingDetails.commissionAmount == 0.0 {
            self.commissionRecipientCap = nil
        } else {
            panic("Commission recipient can not be empty when commission amount is non zero")
        }
    }

    execute {
        // Purchase the NFT
        let item <- self.listing.purchase(
            payment: <-self.paymentVault,
            commissionRecipient: self.commissionRecipientCap,
            privateListingAcceptor: self.listingAcceptor
        )
        // Deposit the NFT in the buyer's collection.
        self.collection.deposit(token: <-item)
    }
}
`

const sharedStorefrontPurchaseDapperBalance = (
	config: Config
): string => `import DapperUtilityCoin from ${config.contractAddresses.DapperUtilityCoin}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2_Shared}
import TopShot from ${config.contractAddresses.TopShot}
import HybridCustody from ${config.contractAddresses.HybridCustody}

/// Transaction facilitates the purcahse of listed NFT.
/// It takes the storefront address, listing resource that need
/// to be purchased & a address that will takeaway the commission.
///
/// Buyer of the listing (,i.e. underling NFT) would authorize and sign the
/// transaction and if purchase happens then transacted NFT would store in
/// buyer's collection.

transaction(storefrontAddress: Address, listingResourceID: UInt64, commissionRecipient: Address, collectionIdentifier: String, nftReceiverAddress: Address) {
    let paymentVault: @FungibleToken.Vault
    let collection: &AnyResource{NonFungibleToken.CollectionPublic}
    let storefront: &NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}
    let listing: &NFTStorefrontV2.Listing{NFTStorefrontV2.ListingPublic}
    var commissionRecipientCap: Capability<&{FungibleToken.Receiver}>?
    let balanceBeforeTransfer: UFix64
    let mainPaymentVault: &DapperUtilityCoin.Vault
    let collectionCap: Capability<&AnyResource{NonFungibleToken.CollectionPublic}>

    prepare(dapper: AuthAccount, buyer: AuthAccount) {
        if buyer.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            // Create a new empty Storefront
            let storefront <- NFTStorefrontV2.createStorefront() as! @NFTStorefrontV2.Storefront
            // save it to the account
            buyer.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
            // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present
            buyer.unlink(NFTStorefrontV2.StorefrontPublicPath)
            buyer.link<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontPublicPath, target: NFTStorefrontV2.StorefrontStoragePath)
        }

        let value = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

        self.commissionRecipientCap = nil
        // Access the storefront public resource of the seller to purchase the listing.
        self.storefront = getAccount(storefrontAddress)
            .getCapability<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}>(
                NFTStorefrontV2.StorefrontPublicPath
            )!
            .borrow()
            ?? panic("Could not borrow Storefront from provided address")

        // Borrow the listing
        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
          ?? panic("Listing could not be found")
        let listingDetails = self.listing.getDetails()
        let nftRef = self.listing.borrowNFT() ?? panic("nft not found")

        if nftReceiverAddress == buyer.address {
            self.collectionCap = buyer.getCapability<&AnyResource{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)

            // unlink and relink first, if it still isn't working, then we will try to save it!
            if !self.collectionCap.check() {
                if buyer.borrow<&AnyResource>(from: value.collectionData.storagePath) == nil {
                    // pull the metdata resolver for this listing's nft and use it to configure this account's collection
                    // if it is not already configured.
                    let collectionData = nftRef.resolveView(Type<MetadataViews.NFTCollectionData>())! as! MetadataViews.NFTCollectionData
                    buyer.save(<- collectionData.createEmptyCollection(), to: value.collectionData.storagePath)

                    buyer.unlink(value.collectionData.publicPath)
                    if nftRef.getType() == Type<@TopShot.NFT>() {
                        buyer.link<&TopShot.Collection{TopShot.MomentCollectionPublic, NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(value.collectionData.publicPath, target: value.collectionData.storagePath)
                    } else {
                        buyer.link<&{NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(value.collectionData.publicPath, target: value.collectionData.storagePath)
                    }
                }
            }
        } else {
            let nftReceiver = getAccount(nftReceiverAddress)
            let manager = buyer.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("manager does not exist")
            manager.borrowAccount(addr: nftReceiverAddress) ?? panic("nft receiver address is not a child account")
            self.collectionCap = nftReceiver.getCapability<&AnyResource{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)
            // We can't change child account links in any way
        }

        if !self.collectionCap.check() {
            if buyer.borrow<&AnyResource>(from: value.collectionData.storagePath) == nil {
                // pull the metdata resolver for this listing's nft and use it to configure this account's collection
                // if it is not already configured.
                let collectionData = nftRef.resolveView(Type<MetadataViews.NFTCollectionData>())! as! MetadataViews.NFTCollectionData
                buyer.save(<-collectionData.createEmptyCollection(), to: value.collectionData.storagePath)
            }

            buyer.unlink(value.collectionData.publicPath)
        }

        // Access the buyer's NFT collection to store the purchased NFT.
        self.collection = self.collectionCap.borrow() ?? panic("Cannot borrow NFT collection receiver from account")

        let price = listingDetails.salePrice

        // Access the vault of the buyer to pay the sale price of the listing.
        self.mainPaymentVault = dapper.borrow<&DapperUtilityCoin.Vault>(from: /storage/dapperUtilityCoinVault)
            ?? panic("Cannot borrow DapperUtilityCoin vault from buyer storage")
        self.balanceBeforeTransfer = self.mainPaymentVault.balance
        self.paymentVault <- self.mainPaymentVault.withdraw(amount: price)

        // Fetch the commission amt.
        let commissionAmount = self.listing.getDetails().commissionAmount

        if commissionRecipient != nil && commissionAmount != 0.0 {
            // Access the capability to receive the commission.
            let _commissionRecipientCap = getAccount(commissionRecipient!).getCapability<&{FungibleToken.Receiver}>(/public/dapperUtilityCoinReceiver)
            assert(_commissionRecipientCap.check(), message: "Commission Recipient doesn't have DapperUtilityCoin receiving capability")
            self.commissionRecipientCap = _commissionRecipientCap
        } else if commissionAmount == 0.0 {
            self.commissionRecipientCap = nil
        } else {
            panic("Commission recipient can not be empty when commission amount is non zero")
        }

    }

    execute {
        let listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("Listing could not be found")

        let item <- listing.purchase(payment: <-self.paymentVault, commissionRecipient: self.commissionRecipientCap)
        self.collection.deposit(token: <-item)
    }

    post {
        self.mainPaymentVault.balance == self.balanceBeforeTransfer: "DapperUtilityCoin leakage"
    }

}`

const sharedStorefrontPurchaseDapperFlow = (
	config: Config
): string => `import FlowUtilityToken from ${config.contractAddresses.FlowUtilityToken}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2_Shared}
import TopShot from ${config.contractAddresses.TopShot}
import HybridCustody from ${config.contractAddresses.HybridCustody}

/// Transaction facilitates the purcahse of listed NFT.
/// It takes the storefront address, listing resource that need
/// to be purchased & a address that will takeaway the commission.
///
/// Buyer of the listing (,i.e. underling NFT) would authorize and sign the
/// transaction and if purchase happens then transacted NFT would store in
/// buyer's collection.

transaction(storefrontAddress: Address, listingResourceID: UInt64, commissionRecipient: Address, collectionIdentifier: String, nftReceiverAddress: Address) {
    let paymentVault: @FungibleToken.Vault
    let collection: &AnyResource{NonFungibleToken.CollectionPublic}
    let storefront: &NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}
    let listing: &NFTStorefrontV2.Listing{NFTStorefrontV2.ListingPublic}
    var commissionRecipientCap: Capability<&{FungibleToken.Receiver}>?
    let balanceBeforeTransfer: UFix64
    let mainPaymentVault: &FlowUtilityToken.Vault
    let collectionCap: Capability<&AnyResource{NonFungibleToken.CollectionPublic}>

    prepare(dapper: AuthAccount, buyer: AuthAccount) {
        if buyer.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            // Create a new empty Storefront
            let storefront <- NFTStorefrontV2.createStorefront() as! @NFTStorefrontV2.Storefront
            // save it to the account
            buyer.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
            // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present
            buyer.unlink(NFTStorefrontV2.StorefrontPublicPath)
            buyer.link<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontPublicPath, target: NFTStorefrontV2.StorefrontStoragePath)
        }

        let value = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

        self.commissionRecipientCap = nil
        // Access the storefront public resource of the seller to purchase the listing.
        self.storefront = getAccount(storefrontAddress)
            .getCapability<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}>(
                NFTStorefrontV2.StorefrontPublicPath
            )!
            .borrow()
            ?? panic("Could not borrow Storefront from provided address")

        // Borrow the listing
        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
          ?? panic("Listing could not be found")
        let listingDetails = self.listing.getDetails()
        let nftRef = self.listing.borrowNFT() ?? panic("nft not found")

        if nftReceiverAddress == buyer.address {
            self.collectionCap = buyer.getCapability<&AnyResource{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)

            // unlink and relink first, if it still isn't working, then we will try to save it!
            if !self.collectionCap.check() {
                if buyer.borrow<&AnyResource>(from: value.collectionData.storagePath) == nil {
                    // pull the metdata resolver for this listing's nft and use it to configure this account's collection
                    // if it is not already configured.
                    let collectionData = nftRef.resolveView(Type<MetadataViews.NFTCollectionData>())! as! MetadataViews.NFTCollectionData
                    buyer.save(<- collectionData.createEmptyCollection(), to: value.collectionData.storagePath)

                    buyer.unlink(value.collectionData.publicPath)
                    if nftRef.getType() == Type<@TopShot.NFT>() {
                        buyer.link<&TopShot.Collection{TopShot.MomentCollectionPublic, NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(value.collectionData.publicPath, target: value.collectionData.storagePath)
                    } else {
                        buyer.link<&{NonFungibleToken.CollectionPublic, NonFungibleToken.Receiver, MetadataViews.ResolverCollection}>(value.collectionData.publicPath, target: value.collectionData.storagePath)
                    }
                }
            }
        } else {
            let nftReceiver = getAccount(nftReceiverAddress)
            let manager = buyer.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("manager does not exist")
            manager.borrowAccount(addr: nftReceiverAddress) ?? panic("nft receiver address is not a child account")
            self.collectionCap = nftReceiver.getCapability<&AnyResource{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)
            // We can't change child account links in any way
        }

        if !self.collectionCap.check() {
            if buyer.borrow<&AnyResource>(from: value.collectionData.storagePath) == nil {
                // pull the metdata resolver for this listing's nft and use it to configure this account's collection
                // if it is not already configured.
                let collectionData = nftRef.resolveView(Type<MetadataViews.NFTCollectionData>())! as! MetadataViews.NFTCollectionData
                buyer.save(<-collectionData.createEmptyCollection(), to: value.collectionData.storagePath)
            }

            buyer.unlink(value.collectionData.publicPath)
        }

        // Access the buyer's NFT collection to store the purchased NFT.
        self.collection = self.collectionCap.borrow() ?? panic("Cannot borrow NFT collection receiver from account")

        let price = listingDetails.salePrice

        // Access the vault of the buyer to pay the sale price of the listing.
        self.mainPaymentVault = dapper.borrow<&FlowUtilityToken.Vault>(from: /storage/flowUtilityTokenVault)
            ?? panic("Cannot borrow FlowUtilityToken vault from buyer storage")
        self.balanceBeforeTransfer = self.mainPaymentVault.balance
        self.paymentVault <- self.mainPaymentVault.withdraw(amount: price)

        // Fetch the commission amt.
        let commissionAmount = self.listing.getDetails().commissionAmount

        if commissionRecipient != nil && commissionAmount != 0.0 {
            // Access the capability to receive the commission.
            let _commissionRecipientCap = getAccount(commissionRecipient!).getCapability<&{FungibleToken.Receiver}>(/public/flowUtilityTokenReceiver)
            assert(_commissionRecipientCap.check(), message: "Commission Recipient doesn't have FlowUtilityToken receiving capability")
            self.commissionRecipientCap = _commissionRecipientCap
        } else if commissionAmount == 0.0 {
            self.commissionRecipientCap = nil
        } else {
            panic("Commission recipient can not be empty when commission amount is non zero")
        }

    }

    execute {
        let listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("Listing could not be found")

        let item <- listing.purchase(payment: <-self.paymentVault, commissionRecipient: self.commissionRecipientCap)
        self.collection.deposit(token: <-item)
    }

    post {
        self.mainPaymentVault.balance == self.balanceBeforeTransfer: "FlowUtilityToken leakage"
    }

}`
const sharedStorefrontPurchaseNFTCatalog = (
	config: Config,
	token: TokenMetadata
): string => `import ${token.contractName} from ${token.contractAddress}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2_Shared}

import HybridCustody from ${config.contractAddresses.HybridCustody}

/// Shared Storefront purchase txn that can route nfts to a child, and take tokens from a child to pay

transaction(storefrontAddress: Address, listingResourceID: UInt64, commissionRecipient: Address, collectionIdentifier: String, nftReceiverAddress: Address, ftProviderAddress: Address, privateFTPath: String) {
  let paymentVault: @FungibleToken.Vault
  let collection: &AnyResource{NonFungibleToken.CollectionPublic}
  let storefront: &NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}
  let listing: &NFTStorefrontV2.Listing{NFTStorefrontV2.ListingPublic}
  var commissionRecipientCap: Capability<&{FungibleToken.Receiver}>?
  let collectionCap: Capability<&AnyResource{NonFungibleToken.CollectionPublic}>

  prepare(buyer: AuthAccount) {
    if buyer.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
      // Create a new empty Storefront
      let storefront <- NFTStorefrontV2.createStorefront() as! @NFTStorefrontV2.Storefront
      // save it to the account
      buyer.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
      // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present
      buyer.unlink(NFTStorefrontV2.StorefrontPublicPath)
      buyer.link<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontPublicPath, target: NFTStorefrontV2.StorefrontStoragePath)
    }

    let value = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

    self.commissionRecipientCap = nil
    // Access the storefront public resource of the seller to purchase the listing.
    self.storefront = getAccount(storefrontAddress)
      .getCapability<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}>(
        NFTStorefrontV2.StorefrontPublicPath
      )!
      .borrow()
      ?? panic("Could not borrow Storefront from provided address")

    // Borrow the listing
    self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
      ?? panic("Listing not found")
    let listingDetails = self.listing.getDetails()
    let nftRef = self.listing.borrowNFT() ?? panic("nft not found")

    if nftReceiverAddress == buyer.address {
      self.collectionCap = buyer.getCapability<&AnyResource{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)

      // unlink and relink first, if it still isn't working, then we will try to save it!
      if !self.collectionCap.check() {
        if buyer.borrow<&AnyResource>(from: value.collectionData.storagePath) == nil {
          // pull the metdata resolver for this listing's nft and use it to configure this account's collection
          // if it is not already configured.
          let collectionData = nftRef.resolveView(Type<MetadataViews.NFTCollectionData>())! as! MetadataViews.NFTCollectionData
          buyer.save(<- collectionData.createEmptyCollection(), to: value.collectionData.storagePath)
        }

        buyer.unlink(value.collectionData.publicPath)
        buyer.link<&AnyResource{NonFungibleToken.CollectionPublic, MetadataViews.ResolverCollection, NonFungibleToken.Receiver}>(value.collectionData.publicPath, target: value.collectionData.storagePath)
      }
    } else {
      let nftReceiver = getAccount(nftReceiverAddress)
      self.collectionCap = nftReceiver.getCapability<&AnyResource{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)
      // We can't change child account links in any way
    }
       
    // Access the buyer or child's NFT collection to store the purchased NFT.
    self.collection = self.collectionCap.borrow() ?? panic("Cannot borrow NFT collection receiver")

    // Access the vault of the buyer/child to pay the sale price of the listing.
    if ftProviderAddress == buyer.address {
      let vault = buyer.borrow<&{FungibleToken.Provider}>(from: ${token.storagePath})
        ?? panic("Cannot borrow token vault from acct storage")
      self.paymentVault <- vault.withdraw(amount: listingDetails.salePrice)
    } else {
      // signer is the parent account and ftProvider is child Account
      // get the manager resource and borrow proxyAccount
      let manager = buyer.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
        ?? panic("manager does not exist")
      let childAcct = manager.borrowAccount(addr: ftProviderAddress) ?? panic("ftProvider account not found")

      let privatePath = PrivatePath(identifier: privateFTPath) ?? panic("private path identifier is not valid")
      let providerCap = childAcct.getCapability(path: privatePath, type: Type<&{FungibleToken.Provider}>()) ?? panic("token provider not found for supplied child account address")
      let ftProvider = providerCap as! Capability<&{FungibleToken.Provider}>
      let ftProviderVault = ftProvider.borrow() ?? panic("child account token vault could not be borrowed")
      self.paymentVault <- ftProviderVault.withdraw(amount: listingDetails.salePrice)
    } 

    if commissionRecipient != nil && listingDetails.commissionAmount != 0.0 {
      // Access the capability to receive the commission.
      let _commissionRecipientCap = getAccount(commissionRecipient!).getCapability<&{FungibleToken.Receiver}>(${token.receiverPath})
      assert(_commissionRecipientCap.check(), message: "Commission Recipient doesn't have token receiving capability")
      self.commissionRecipientCap = _commissionRecipientCap
    } else if listingDetails.commissionAmount == 0.0 {
      self.commissionRecipientCap = nil
    } else {
      panic("Commission recipient can not be empty when commission amount is non zero")
    }
  }

  execute {
    // Purchase the NFT
    let item <- self.listing.purchase(
      payment: <-self.paymentVault,
      commissionRecipient: self.commissionRecipientCap
    )
    // Deposit the NFT in the buyer's (or child's) collection.
    self.collection.deposit(token: <-item)
  }
}
`

const sharedStorefrontPurchaseViewResolver = (
	config: Config,
	token: TokenMetadata
): string => `import ${token.contractName} from ${token.contractAddress}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import ViewResolver from ${config.contractAddresses.ViewResolver}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2_Shared}

import HybridCustody from ${config.contractAddresses.HybridCustody}

/// Transaction facilitates the purcahse of listed NFT.
/// It takes the storefront address, listing resource that need
/// to be purchased & a address that will takeaway the commission.
///
/// Buyer of the listing (,i.e. underling NFT) would authorize and sign the
/// transaction and if purchase happens then transacted NFT would store in
/// buyer's collection.
transaction(
    storefrontAddress: Address,
    listingResourceID: UInt64,
    commissionRecipient: Address,
    nftReceiverAddress: Address,
    paymentAddress: Address,
    privateFTPath: String
) {
    let paymentVault: @FungibleToken.Vault
    let collection: &AnyResource{NonFungibleToken.CollectionPublic}
    let storefront: &NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}
    let listing: &NFTStorefrontV2.Listing{NFTStorefrontV2.ListingPublic}
    var commissionRecipientCap: Capability<&{FungibleToken.Receiver}>?
    let collectionCap: Capability<&{NonFungibleToken.CollectionPublic}>

    prepare(buyer: AuthAccount) {
        if buyer.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            // Create a new empty Storefront
            let storefront <- NFTStorefrontV2.createStorefront() as! @NFTStorefrontV2.Storefront
            // save it to the account
            buyer.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
            // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present
            buyer.unlink(NFTStorefrontV2.StorefrontPublicPath)
            buyer.link<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontPublicPath, target: NFTStorefrontV2.StorefrontStoragePath)
        }

        self.commissionRecipientCap = nil
        // Access the storefront public resource of the seller to purchase the listing.
        self.storefront = getAccount(storefrontAddress)
            .getCapability<&NFTStorefrontV2.Storefront{NFTStorefrontV2.StorefrontPublic}>(
                NFTStorefrontV2.StorefrontPublicPath
            )!
            .borrow()
            ?? panic("Could not borrow Storefront from provided address")

        // Borrow the listing
        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("No Offer with that ID in Storefront")
        let listingDetails = self.listing.getDetails()
        let nftRef = self.listing.borrowNFT() ?? panic("nft not found")

        let md = nftRef.resolveView(Type<MetadataViews.NFTCollectionData>()) ?? panic("NFTCollectionData view not found on the contract.")
        let collectionData = md as! MetadataViews.NFTCollectionData

        if nftReceiverAddress == buyer.address {
          self.collectionCap = buyer.getCapability<&{NonFungibleToken.CollectionPublic}>(collectionData.publicPath)

          // unlink and relink first, if it still isn't working, then we will try to save it!
          if !self.collectionCap.check() {
            if buyer.borrow<&AnyResource>(from: collectionData.storagePath) == nil {
              // pull the metdata resolver for this listing's nft and use it to configure this account's collection
              // if it is not already configured.
              let collectionData = nftRef.resolveView(Type<MetadataViews.NFTCollectionData>())! as! MetadataViews.NFTCollectionData
              buyer.save(<- collectionData.createEmptyCollection(), to: collectionData.storagePath)
            }

            buyer.unlink(collectionData.publicPath)
            buyer.link<&{NonFungibleToken.CollectionPublic, MetadataViews.ResolverCollection, NonFungibleToken.Receiver}>(collectionData.publicPath, target: collectionData.storagePath)
          }
        } else {
          // signer is the parent account and nftReceiver is child Account
          // get the manager resource and borrow proxyAccount
          let manager = buyer.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
            ?? panic("manager does not exist")
          let childAcct = manager.borrowAccount(addr: nftReceiverAddress) ?? panic("nft receiver address is not a child account")
          self.collectionCap = getAccount(nftReceiverAddress).getCapability<&AnyResource{NonFungibleToken.CollectionPublic}>(collectionData.publicPath)
        }

        // Access the buyer or child's NFT collection to store the purchased NFT.
        assert(self.collectionCap.check(), message: "Cannot borrow NFT collection receiver")
        self.collection = self.collectionCap.borrow()!

        if paymentAddress == buyer.address {
          let vault = buyer.borrow<&{FungibleToken.Provider}>(from: ${token.storagePath})
            ?? panic("Cannot borrow token vault from acct storage")
          self.paymentVault <- vault.withdraw(amount: listingDetails.salePrice)
        } else {
          // signer is the parent account and ftProvider is child Account
          // get the manager resource and borrow proxyAccount
          let manager = buyer.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
            ?? panic("manager does not exist")
          let childAcct = manager.borrowAccount(addr: paymentAddress) ?? panic("ftProvider account not found")

          let privatePath = PrivatePath(identifier: privateFTPath) ?? panic("private path identifier is not valid")
          let providerCap = childAcct.getCapability(path: privatePath, type: Type<&{FungibleToken.Provider}>()) ?? panic("token provider not found for supplied child account address")
          let ftProvider = providerCap as! Capability<&{FungibleToken.Provider}>
          let ftProviderVault = ftProvider.borrow() ?? panic("child account token vault could not be borrowed")
          self.paymentVault <- ftProviderVault.withdraw(amount: listingDetails.salePrice)
        }

        if commissionRecipient != nil && listingDetails.commissionAmount != 0.0 {
            // Access the capability to receive the commission.
            let _commissionRecipientCap = getAccount(commissionRecipient!).getCapability<&{FungibleToken.Receiver}>(${token.receiverPath})
            assert(_commissionRecipientCap.check(), message: "Commission Recipient doesn't have token receiving capability")
            self.commissionRecipientCap = _commissionRecipientCap
        } else if listingDetails.commissionAmount == 0.0 {
            self.commissionRecipientCap = nil
        } else {
            panic("Commission recipient can not be empty when commission amount is non zero")
        }
    }

    execute {
        // Purchase the NFT
        let item <- self.listing.purchase(
            payment: <-self.paymentVault,
            commissionRecipient: self.commissionRecipientCap,
        )
        // Deposit the NFT in the buyer's collection.
        self.collection.deposit(token: <-item)
    }
}
`

const sharedStorefrontPurchaseViewResolverCrescendo = (
	config: Config,
	token: TokenMetadata
): string => ``

const flowtyStorefrontPurchaseNFTCatalogCrescendo = (
	config: Config,
	token: TokenMetadata
): string => `import ${token.contractName} from ${token.contractAddress}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}

import HybridCustody from ${config.contractAddresses.HybridCustody}

/// Storefront purchase txn that can route nfts to a child, and take tokens from a child to pay

transaction(storefrontAddress: Address, listingResourceID: UInt64, commissionRecipient: Address, collectionIdentifier: String, nftReceiverAddress: Address, ftProviderAddress: Address, ftProviderControllerID: UInt64 ) {
  let paymentVault: @{FungibleToken.Vault}
  let collection: &{NonFungibleToken.CollectionPublic}
  let storefront: &{NFTStorefrontV2.StorefrontPublic}
  let listing: &{NFTStorefrontV2.ListingPublic}
  var commissionRecipientCap: Capability<&{FungibleToken.Receiver}>?
  var collectionCap: Capability<&{NonFungibleToken.CollectionPublic}>
  let listingAcceptor: &{NFTStorefrontV2.PrivateListingAcceptor}

  prepare(buyer: auth(Storage, Capabilities) &Account) {
    if buyer.storage.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
      // Create a new empty Storefront
      let storefront <- NFTStorefrontV2.createStorefront()
      // save it to the account
      buyer.storage.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
      // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present
      buyer.capabilities.unpublish(NFTStorefrontV2.StorefrontPublicPath)
      buyer.capabilities.publish(
        buyer.capabilities.storage.issue<&{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontStoragePath),
        at: NFTStorefrontV2.StorefrontPublicPath
    )
    }

    self.listingAcceptor = buyer.storage.borrow<&{NFTStorefrontV2.PrivateListingAcceptor}>(from: NFTStorefrontV2.StorefrontStoragePath) ?? panic("Buyer storefront is invalid")

    let value = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

    self.commissionRecipientCap = nil
    // Access the storefront public resource of the seller to purchase the listing.
    self.storefront = getAccount(storefrontAddress)
      .capabilities.get<&{NFTStorefrontV2.StorefrontPublic}>(
        NFTStorefrontV2.StorefrontPublicPath
      ).borrow()
      ?? panic("Could not borrow Storefront from provided address")

    // Borrow the listing
    self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
      ?? panic("Listing not found")
    let listingDetails = self.listing.getDetails()
    let nftRef = self.listing.borrowNFT() ?? panic("nft not found")

    if nftReceiverAddress == buyer.address {
        self.collectionCap = buyer.capabilities.get<&{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)
        // unlink and relink first, if it still isn't working, then we will try to save it!
        if !self.collectionCap.check() {
            if buyer.storage.borrow<&AnyResource>(from: value.collectionData.storagePath) == nil {
                // pull the metdata resolver for this listing's nft and use it to configure this account's collection
                // if it is not already configured.
                let collectionData = nftRef.resolveView(Type<MetadataViews.NFTCollectionData>())! as! MetadataViews.NFTCollectionData
                buyer.storage.save(<- collectionData.createEmptyCollection(), to: value.collectionData.storagePath)
            }

            buyer.capabilities.unpublish(value.collectionData.publicPath)
            buyer.capabilities.publish(
                buyer.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(value.collectionData.storagePath),
                at: value.collectionData.publicPath
            )
            self.collectionCap = buyer.capabilities.get<&{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)
        }
    } else {
        // signer is the parent account and nftProvider is child Account
        // get the manager resource and borrow proxyAccount
        let manager = buyer.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
            ?? panic("manager does not exist")
        let childAcct = manager.borrowAccount(addr: nftReceiverAddress) ?? panic("nft receiver address is not a child account")
        self.collectionCap = getAccount(nftReceiverAddress).capabilities.get<&{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)
        // We can't change child account links in any way
    }

    // Access the buyer or child's NFT collection to store the purchased NFT.
    self.collection = self.collectionCap.borrow() ?? panic("Cannot borrow NFT collection receiver")

    // Access the vault of the buyer/child to pay the sale price of the listing.
    if ftProviderAddress == buyer.address{
      let vault = buyer.storage.borrow<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>(from: ${token.storagePath})
        ?? panic("Cannot borrow token vault from acct storage")
      self.paymentVault <- vault.withdraw(amount: listingDetails.salePrice)
    } else {
      // signer is the parent account and ftProvider is child Account
      // get the manager resource and borrow proxyAccount
      let manager = buyer.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
        ?? panic("manager does not exist")
      let childAcct = manager.borrowAccount(addr: ftProviderAddress) ?? panic("ftProvider account not found")

      let providerCap = childAcct.getCapability(controllerID: ftProviderControllerID, type: Type<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>()) ?? panic("token provider not found for supplied child account address")
      let ftProvider = providerCap as! Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>
      let ftProviderVault = ftProvider.borrow() ?? panic("child account token vault could not be borrowed")
      self.paymentVault <- ftProviderVault.withdraw(amount: listingDetails.salePrice)
    }

    if commissionRecipient != nil && listingDetails.commissionAmount != 0.0 {
      // Access the capability to receive the commission.
      let _commissionRecipientCap = getAccount(commissionRecipient).capabilities.get<&{FungibleToken.Receiver}>(/public/flowTokenReceiver)
      assert(_commissionRecipientCap.check(), message: "Commission Recipient doesn't have token receiving capability")
      self.commissionRecipientCap = _commissionRecipientCap
    } else if listingDetails.commissionAmount == 0.0 {
      self.commissionRecipientCap = nil
    } else {
      panic("Commission recipient can not be empty when commission amount is non zero")
    }
  }

  execute {
    // Purchase the NFT
    let item <- self.listing.purchase(
      payment: <-self.paymentVault,
      commissionRecipient: self.commissionRecipientCap,
      privateListingAcceptor: self.listingAcceptor
    )
    // Deposit the NFT in the buyer's collection.
    self.collection.deposit(token: <-item)
  }
}`

const flowtyStorefrontPurchaseViewResolverCrescendo = (
	config: Config,
	token: TokenMetadata
): string => `import ${token.contractName} from ${token.contractAddress}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import ViewResolver from ${config.contractAddresses.ViewResolver}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}

import HybridCustody from ${config.contractAddresses.HybridCustody}

/// Transaction facilitates the purcahse of listed NFT.
/// It takes the storefront address, listing resource that need
/// to be purchased & a address that will takeaway the commission.
///
/// Buyer of the listing (,i.e. underling NFT) would authorize and sign the
/// transaction and if purchase happens then transacted NFT would store in
/// buyer's collection.
transaction(
    storefrontAddress: Address,
    listingResourceID: UInt64,
    commissionRecipient: Address,
    nftReceiverAddress: Address,
    paymentAddress: Address,
    paymentProviderControllerID: UInt64
) {
    let paymentVault: @{FungibleToken.Vault}
    let collection: &{NonFungibleToken.CollectionPublic}
    let storefront: &{NFTStorefrontV2.StorefrontPublic}
    let listing: &{NFTStorefrontV2.ListingPublic}
    var commissionRecipientCap: Capability<&{FungibleToken.Receiver}>?
    let listingAcceptor: &{NFTStorefrontV2.PrivateListingAcceptor}
    var collectionCap: Capability<&{NonFungibleToken.CollectionPublic}>

    prepare(buyer: auth(Capabilities, Storage) &Account) {

        if buyer.storage.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            // Create a new empty Storefront
            let storefront <- NFTStorefrontV2.createStorefront()
            // save it to the account
            buyer.storage.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
            // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present
            buyer.capabilities.unpublish(NFTStorefrontV2.StorefrontPublicPath)
            buyer.capabilities.publish(
                buyer.capabilities.storage.issue<&{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontStoragePath),
                at: NFTStorefrontV2.StorefrontPublicPath
            )
        }

        self.listingAcceptor = buyer.storage.borrow<&{NFTStorefrontV2.PrivateListingAcceptor}>(from: NFTStorefrontV2.StorefrontStoragePath) ?? panic("Buyer storefront is invalid")

        self.commissionRecipientCap = nil
        // Access the storefront public resource of the seller to purchase the listing.
        self.storefront = getAccount(storefrontAddress)
            .capabilities.get<&{NFTStorefrontV2.StorefrontPublic}>(
                NFTStorefrontV2.StorefrontPublicPath
            ).borrow()
            ?? panic("Could not borrow Storefront from provided address")

        // Borrow the listing
        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("No Offer with that ID in Storefront")
        let listingDetails = self.listing.getDetails()
        let nftRef = self.listing.borrowNFT() ?? panic("nft not found")

        let md = nftRef.resolveView(Type<MetadataViews.NFTCollectionData>()) ?? panic("NFTCollectionData view not found on the contract.")
        let collectionData = md as! MetadataViews.NFTCollectionData

        if nftReceiverAddress == buyer.address {
            self.collectionCap = buyer.capabilities.get<&{NonFungibleToken.CollectionPublic}>(collectionData.publicPath)

            // unlink and relink first, if it still isn't working, then we will try to save it!
            if !self.collectionCap.check() {
                if buyer.storage.borrow<&AnyResource>(from: collectionData.storagePath) == nil {
                    // pull the metdata resolver for this listing's nft and use it to configure this account's collection
                    // if it is not already configured.
                    let collectionData = nftRef.resolveView(Type<MetadataViews.NFTCollectionData>())! as! MetadataViews.NFTCollectionData
                    buyer.storage.save(<- collectionData.createEmptyCollection(), to: collectionData.storagePath)
                }

                buyer.capabilities.unpublish(collectionData.publicPath)
                buyer.capabilities.publish(
                    buyer.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(collectionData.storagePath),
                    at: collectionData.publicPath
                )
            }
            self.collectionCap = buyer.capabilities.get<&{NonFungibleToken.CollectionPublic}>(collectionData.publicPath)
        } else {
            // signer is the parent account and nftProvider is child Account
            // get the manager resource and borrow proxyAccount
            let manager = buyer.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("manager does not exist")
            let childAcct = manager.borrowAccount(addr: nftReceiverAddress) ?? panic("nft receiver address is not a child account")
            self.collectionCap = getAccount(nftReceiverAddress).capabilities.get<&{NonFungibleToken.CollectionPublic}>(collectionData.publicPath)
            // We can't change child account links in any way
        }

        // Access the buyer or child's NFT collection to store the purchased NFT.
        assert(self.collectionCap.check(), message: "Cannot borrow NFT collection receiver")
        self.collection = self.collectionCap.borrow()!

        if paymentAddress == buyer.address {
          let vault = buyer.storage.borrow<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>(from: ${token.storagePath})
            ?? panic("Cannot borrow token vault from acct storage")
          self.paymentVault <- vault.withdraw(amount: listingDetails.salePrice)
        } else {
          // signer is the parent account and ftProvider is child Account
          // get the manager resource and borrow proxyAccount
          let manager = buyer.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
            ?? panic("manager does not exist")
          let childAcct = manager.borrowAccount(addr: paymentAddress) ?? panic("ftProvider account not found")

          let providerCap = childAcct.getCapability(controllerID: paymentProviderControllerID, type: Type<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>()) ?? panic("token provider not found for supplied child account address")
          let ftProvider = providerCap as! Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>
          assert(ftProvider.check(), message: "invalid provider capability")
          let ftProviderVault = ftProvider.borrow() ?? panic("child account token vault could not be borrowed")
          self.paymentVault <- ftProviderVault.withdraw(amount: listingDetails.salePrice)
        }

        if commissionRecipient != nil && listingDetails.commissionAmount != 0.0 {
            // Access the capability to receive the commission.
            let _commissionRecipientCap = getAccount(commissionRecipient).capabilities.get<&{FungibleToken.Receiver}>(${token.receiverPath})
            assert(_commissionRecipientCap.check(), message: "Commission Recipient doesn't have receiving capability")
            self.commissionRecipientCap = _commissionRecipientCap
        } else if listingDetails.commissionAmount == 0.0 {
            self.commissionRecipientCap = nil
        } else {
            panic("Commission recipient can not be empty when commission amount is non zero")
        }
    }

    execute {
        // Purchase the NFT
        let item <- self.listing.purchase(
            payment: <-self.paymentVault,
            commissionRecipient: self.commissionRecipientCap,
            privateListingAcceptor: self.listingAcceptor
        )
        // Deposit the NFT in the buyer's collection.
        self.collection.deposit(token: <-item)
    }
}`

const sharedStorefrontPurchaseNFTCatalogCrescendo = (
	config: Config,
	token: TokenMetadata
): string => ``

const sharedStorefrontPurchaseDapperBalanceCrescendo = (
	config: Config
): string => ``

const sharedStorefrontPurchaseDapperFlowCrescendo = (config: Config): string =>
	``

const flowtyStorefrontPurchaseDapperBalanceCrescendo = (
	config: Config
): string => ``

const flowtyStorefrontPurchaseDapperFlowCrescendo = (config: Config): string =>
	``
