/* eslint-disable sort-keys-fix/sort-keys-fix */
import { FlowNFTContract } from "flowty-common"

interface GlobalSearchHit<T> {
	score: number // float
	data: T
}

export interface GlobalSearchResponse {
	contracts: GlobalSearchHit<FlowNFTContract>[]
}

export type NavItemName = "Marketplace" | "Activity"

export const navItems: Record<NavItemName, string> = {
	Marketplace: "/marketplace",
	Activity: "/activity",
}
