import { FlowtyBadge } from "ds-flowty"
import { inject, observer } from "mobx-react"
import React, { useMemo } from "react"
import { useSporkContext } from "../../../../contexts/SporkContext"
import { AuthStoreProp } from "../../../../stores/AuthStore"
import { getProviderImage } from "../../../../util/wallet"
import { connectWallet } from "../../../../util/ConnectWallet"

function getProviderName(walletServiceUid: string): string {
	const uid = walletServiceUid.toLowerCase()
	if (uid.includes("dapper")) return "Dapper"
	if (uid.includes("blocto")) return "Blocto"
	if (uid.includes("lilico")) return "Flow Reference Wallet"
	// Note- the wallet uuid might change at some point, making some guesses here
	// that support Flow Reference FlowReference  Flow-Reference(case insensitive)
	if (/flow.*reference/i.test(uid)) return "Flow Reference Wallet"

	return ""
}

const ConnectedWalletIndicatorComponent: React.FC<AuthStoreProp> = ({
	authStore,
}) => {
	const AuthService = authStore?.loggedUser?.services[0].uid || ""
	const { disableActions } = useSporkContext()
	const hasChildAccounts = useMemo(() => {
		return (
			Object.values(authStore?.loggedUser?.accountSummaries || {}).length > 1
		)
	}, [authStore?.loggedUser?.accountSummaries])
	return (
		<>
			{authStore?.loggedUser?.loggedIn && hasChildAccounts ? (
				<div className='flex flex-col md:flex-row items-center py-3 justify-center w-full'>
					<span className='pl-[1rem] pr-4 text-base font-normal text-nowrap'>
						Connected Account:
					</span>
					<span className='flex items-center bg-[#ffffff1a] rounded-md px-[1.25rem] py-2'>
						<img
							src={getProviderImage(AuthService)}
							alt='Wallet Icon'
							className='h-6 w-6 rounded-full'
						/>
						<span className='ml-3 text-base font-semibold text-nowrap'>
							{getProviderName(AuthService)} Account
						</span>
						<FlowtyBadge text={"main"} type={"primary"} />
					</span>
				</div>
			) : !disableActions && !authStore?.loggedUser?.loggedIn ? (
				<div className='flex flex-col md:flex-row items-center py-3 justify-center w-full'>
					<button
						className='text-base px-[1.25rem] py-2 bg-primary rounded-lg shadow-lg w-3/4 text-darkBg uppercase hover-button transform duration-300 ease-in-out'
						onClick={() => connectWallet()}
					>
						<b>
							{authStore?.loadingUser ? (
								<div
									className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'
									role='status'
									aria-label='loading'
								>
									<span className='sr-only'>Loading...</span>
								</div>
							) : (
								<span className='font-black text-base font-montserrat-bold'>
									Sign in
								</span>
							)}
						</b>
					</button>
				</div>
			) : null}
		</>
	)
}

export const ConnectedWalletIndicator = inject("authStore")(
	observer(ConnectedWalletIndicatorComponent)
)
