import numeral from "numeral"
import Blocto from "../assets/blocto_logo.png"
import Dapper from "../assets/dapper_logo.png"
import FlowReferenceWallet from "../assets/flow-reference-wallet.png"
import { Path } from "flowty-common"
import { FlowtyListingFormValues } from "../FlowtyModal/FlowtyListingModal"
import {
	LoanListingOrderType,
	RentListingOrderType,
	StorefrontAcceptOfferOrderType,
} from "../Types/GlobalTypes"

export const getDurationPeriod = (term: number): string => {
	if (!term) {
		return "0 Days"
	}

	const termNumber: number = Number(term)
	if (termNumber / 60 < 60) {
		return `${(termNumber / 60).toFixed(2)} Mins`
	}
	if (termNumber / (60 * 60) < 24) {
		return `${(termNumber / (60 * 60)).toFixed(1)} Hrs`
	}
	const days = termNumber / (60 * 60 * 24)
	return `${days.toFixed(0)} ${days > 1 ? "Days" : "Day"}`
}

export function truncateCurrencyAmount(_currency: number): string {
	return truncateNumber(_currency, { shouldUseDecimals: true })
}

export function truncateNumber(
	_numberValue?: number,
	options?: { shouldUseDecimals: boolean }
): string {
	const shouldUseDecimals = options?.shouldUseDecimals ?? false
	if (!_numberValue) {
		return ""
	}
	const currency = Math.round(_numberValue * 100) / 100

	switch (true) {
		case String(currency.toFixed(2)).replace(/\./g, "").length < 6 ||
			_numberValue < 1:
			return numeral(currency).format(shouldUseDecimals ? "0,0.00" : "0,0")
		case String(currency.toFixed(2)).replace(/\./g, "").length < 9: // thousands
			const currencyThousands = currency / 1e3
			return `${numeral(currencyThousands).format("0,0.00")}K`
		case String(currency.toFixed(2)).replace(/\./g, "").length >= 9: // millions
			const currencyMillions = currency / 1e6
			return `${numeral(currencyMillions).format("0,0.00")}M`
		default:
			return ""
	}
}

export function getFMVDisplayLabelFromSource(source: string): string {
	switch (source.toUpperCase()) {
		case "OTM":
			return "OTM's True Value model"
		default:
			return source
	}
}

export function getFMVHrefFromSource(source: string): string {
	switch (source.toUpperCase()) {
		case "OTM":
			return "https://www.otmnft.com/nbatopshot/articles/introducing-otm-true-value-account-valuation"
		default:
			return "404"
	}
}

export function getSourceFromSourceList(sources: string): string {
	const sourceList = sources.split(",")

	if (sourceList.length > 1) return "blended"

	return sourceList[0]
}

export function getProviderImage(walletServiceUid: string): string {
	const uid = walletServiceUid.toLowerCase()
	if (uid.includes("dapper")) return Dapper
	if (uid.includes("blocto")) return Blocto
	if (uid.includes("lilico")) return FlowReferenceWallet
	// Note- the wallet uuid might change at some point, making some guesses here
	// that support Flow Reference FlowReference  Flow-Reference(case insensitive)
	if (/flow.*reference/i.test(uid)) return FlowReferenceWallet

	return ""
}

export const getListingOrderData = (
	listingType: "loan" | "rental" | "storefront" | "transfer" | "acceptOffer",
	values: FlowtyListingFormValues,
	lenderFunds: number,
	royaltyFee: number
):
	| LoanListingOrderType
	| RentListingOrderType
	| StorefrontAcceptOfferOrderType => {
	switch (listingType) {
		case "loan":
			return {
				derivations: {
					calculatedValues: {
						borrowerRate:
							Number(values?.amountToRepay) / Number(values?.amountToBorrow) -
							1,
						marketplaceAmount: lenderFunds,
						periodicInterest:
							(Number(values?.amountToRepay) + royaltyFee) / lenderFunds - 1,
						repayment: Number(values?.amountToRepay),
					},
				},
				listingDuration: values?.listingDuration,
				listingKind: "loan",
				paymentTokenName: values?.tokenType,
				term: values?.loanDuration,
				valuations: {
					aggregate: {
						loanToValueRatio: "",
					},
				},
			}
		case "rental":
			return {
				amount: values?.rentalFee,
				deposit: values?.refundableDeposit,
				listingKind: "rental",
				paymentTokenName: values?.tokenType,
				rentalListingDuration: values?.rentalListingDuration,
				term: values?.rentalDuration,
			}
		case "storefront" || "acceptOffer":
			return {
				listingKind: "storefront",
				paymentTokenName: values?.tokenType,
				salePrice: values?.saleListingValue,
				storefrontListingDuration: values?.storefrontListingDuration,
			}
		default:
			return {
				listingKind: "storefront",
				paymentTokenName: values?.tokenType,
				salePrice: values?.saleListingValue,
				storefrontListingDuration: values?.storefrontListingDuration,
			}
	}
}

export const getBestFtPrivatePath = (
	paths: Path[],
	ftContractAddress?: string,
	ftContractName?: string
): string => {
	if (!paths || !paths.length) return ""

	if (ftContractAddress && ftContractName) {
		const flowtyPrivatePath = `${ftContractName}${ftContractAddress}FtProviderForFlowty`

		const privatePath = paths.find(path =>
			path.identifier.includes(flowtyPrivatePath)
		)
		if (privatePath) return privatePath.identifier
	}

	const defaultPath = paths.find(path => path.identifier.includes("ForFlowty"))
	if (defaultPath) return defaultPath.identifier

	return paths?.[0]?.identifier || ""
}

export const fetchJSON = async <T>(
	url: string,
	mixPanelFn: (event: string, data: unknown) => void
): Promise<T | null> => {
	try {
		const resp = await fetch(url)
		if (!resp.ok) {
			const errorMessage = await resp.text()
			mixPanelFn("FETCH_ERROR", {
				message: errorMessage,
				status: resp.status,
				url: url,
			})
			return null
		}

		const text = await resp.text()
		try {
			return JSON.parse(text)
		} catch (jsonError) {
			mixPanelFn("JSON_PARSE_ERROR", {
				jsonError: jsonError,
				message: text,
				url: url,
			})
			return null
		}
	} catch (err) {
		mixPanelFn("FETCH_JSON_ERROR", { err: err, url: url })
		return null
	}
}
