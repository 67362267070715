import {
	OpensearchRentalAvailableData,
	RentalListingAvailableData,
} from "flowty-common"
import React from "react"
import { useNftDisplayViewContext } from "../../../../contexts/NftDisplayViewContext"
import { getDurationPeriod } from "../../../../util/nftDataUtil"
import { TokenAmount } from "../../../Tokens/tokenDisplays"
import { NftOrderLoadingDisplay } from "./NftOrderLoadingDisplay"

interface RentalOrderDisplayProps {
	order: OpensearchRentalAvailableData | RentalListingAvailableData
	isLoading?: boolean
}

export const RentalOrderDisplay: React.FC<RentalOrderDisplayProps> = ({
	isLoading,
	order,
}) => {
	const { view } = useNftDisplayViewContext()
	return (
		<div>
			{isLoading ? (
				<NftOrderLoadingDisplay />
			) : (
				<>
					<div className='flex flex-row justify-between mt-1 items-center'>
						<div>
							<span className='text-[#AAAAAA] text-base font-montserrat'>
								Rental Fee
							</span>
						</div>
						<div className='self-center text-end'>
							<span className='self-center text-bold'>
								<TokenAmount
									amount={order?.amount || 0}
									isSmall
									token={order?.paymentTokenName}
								/>
							</span>
						</div>
					</div>
					<div className='flex flex-row justify-between mt-1 items-center'>
						<div>
							{view === "large" ? (
								<span className=' text-[#AAAAAA] text-base font-montserrat'>
									Refundable Deposit
								</span>
							) : (
								<span className='text-[#AAAAAA] text-base font-montserrat'>
									Deposit
								</span>
							)}
						</div>
						<div className='self-center text-end'>
							<span className='self-center text-bold'>
								<TokenAmount
									amount={order?.deposit || 0}
									isSmall
									token={order?.paymentTokenName}
								/>
							</span>
						</div>
					</div>
					<div className='my-1 border-t border-[#474747]'></div>
					<div className='flex flex-row justify-between mt-1 items-center'>
						<div>
							<span className='text-[#AAAAAA] text-base font-montserrat'>
								Total Upfront
							</span>
						</div>
						<div className='self-center text-end'>
							<span className='self-center text-bold'>
								{order?.amount && order?.deposit ? (
									<TokenAmount
										amount={Number(order.deposit) + Number(order.amount)}
										isSmall
										token={order?.paymentTokenName}
									/>
								) : (
									<span className='text-white text-end text-sm'>-</span>
								)}
							</span>
						</div>
					</div>
					<div className='flex flex-row justify-between mt-1 items-center'>
						<div className='text-[#AAAAAA] text-base font-montserrat'>
							Duration
						</div>
						<div className='self-center text-end'>
							<span className='text-bold text-base font-montserrat'>
								{order?.term && getDurationPeriod(Number(order.term))}
							</span>
						</div>
					</div>
				</>
			)}
		</div>
	)
}
