import React from "react"
import { UserProfileTabButtons } from "../components/UserProfile/UserProfileData"
import { UserStorefrontProfileData } from "../components/UserProfile/UserStorefrontProfileData"
import { PageHeader } from "../components/PageHeader"
import Layout from "./Layout"

interface UserStorefrontScreenProps {
	activeTabBtn?: UserProfileTabButtons
}

const metaTitle = `Flowty: Your NFT Storefront Activity`
const metaDescription =
	"Flowty is an open, full-featured NFT marketplace for collectors, traders, investors and creators on the Flow blockchain. Flowty's transaction capabilities range from buying and selling to NFT-backed loans and NFT rentals."
const metaImage = "https://www.flowty.io/icons/icon192.png"

export const UserStorefrontScreen: React.FC<UserStorefrontScreenProps> = ({
	activeTabBtn = UserProfileTabButtons.History,
}) => (
	<Layout>
		<PageHeader
			metaTitle={metaTitle}
			metaDescription={metaDescription}
			metaImage={metaImage}
		/>
		<div className='container'>
			<UserStorefrontProfileData activeTabBtn={activeTabBtn} />
		</div>
	</Layout>
)
