import { PropsWithChildren } from "react"

import { ReactComponent as FLOWIcon } from "../../src/assets/tokens/flow.svg"
import { ReactComponent as FUSDIcon } from "../../src/assets/tokens/fusd.svg"
import { ReactComponent as SloppyIcon } from "../../src/assets/tokens/sloppy.svg"
import { ReactComponent as USDCIcon } from "../../src/assets/tokens/usdc.svg"
import { ReactComponent as USDTIcon } from "../../src/assets/tokens/usdt.svg"

type FlowtyTokenIconProps = PropsWithChildren<{
	token: string
}>

export const FlowtyTokenIcon: React.FC<FlowtyTokenIconProps> = ({
	token = "fusd",
}) => {
	let icon
	switch (token.toLowerCase()) {
		case "fusd":
			icon = <FUSDIcon className='flex-shrink-0 w-[0.875rem] h-[0.875rem]' />
			break
		case "flow":
			icon = <FLOWIcon className='flex-shrink-0 w-[0.875rem] h-[0.875rem]' />
			break
		case "fut":
			icon = (
				<div>
					<svg
						width='14'
						height='14'
						viewBox='0 0 14 14'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<g id='Layer_1-2'>
							<g id='g43'>
								<path
									id='circle20'
									d='M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z'
									fill='#762FBE'
								/>
								<path
									id='rect22'
									d='M10.066 5.90802H8.09204V7.88202H10.066V5.90802Z'
									fill='white'
								/>
								<path
									id='path24'
									d='M6.11791 8.62402C6.11791 9.03002 5.78191 9.36602 5.37591 9.36602C4.96991 9.36602 4.63391 9.03002 4.63391 8.62402C4.63391 8.21802 4.96991 7.88202 5.37591 7.88202H6.11791V5.90802H5.37591C3.87791 5.90802 2.65991 7.12602 2.65991 8.62402C2.65991 10.122 3.87791 11.34 5.37591 11.34C6.87391 11.34 8.09191 10.122 8.09191 8.62402V7.88202H6.11791V8.62402Z'
									fill='white'
								/>
								<path
									id='path26'
									d='M8.83392 4.914H11.0599V2.94H8.83392C7.33592 2.94 6.11792 4.158 6.11792 5.656V5.908H8.09192V5.656C8.09192 5.25 8.42792 4.914 8.83392 4.914Z'
									fill='white'
								/>
								<path
									id='polygon28'
									d='M8.09192 5.90802H6.11792V7.88202H8.09192V5.90802Z'
									fill='#762FBE'
								/>
							</g>
						</g>
					</svg>
				</div>
			)
			break
		case "usdc":
			icon = <USDCIcon className='flex-shrink-0 w-[0.875rem] h-[0.875rem]' />
			break
		case "duc":
			icon = (
				<div>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='14'
						height='15'
						viewBox='0 0 14 15'
						fill='none'
					>
						<rect y='0.5' width='14' height='14' rx='7' fill='#762FBE' />
						<path
							d='M7.1945 5.42609C6.33539 5.42609 5.63894 5.89039 5.63894 6.46313C5.63894 7.03587 6.33539 7.50016 7.1945 7.50016C8.05361 7.50016 8.75007 7.96446 8.75007 8.5372C8.75007 9.10994 8.05362 9.57424 7.19451 9.57424M7.1945 5.42609C7.77027 5.42609 8.27299 5.63464 8.54195 5.94461M7.1945 5.42609V4.90757M7.1945 5.42609L7.19451 9.57424M7.19451 9.57424L7.1945 10.0928M7.19451 9.57424C6.61874 9.57424 6.11602 9.36569 5.84706 9.05572M11.8612 7.50016C11.8612 10.0775 9.77183 12.1668 7.1945 12.1668C4.61717 12.1668 2.52783 10.0775 2.52783 7.50016C2.52783 4.92283 4.61717 2.8335 7.1945 2.8335C9.77183 2.8335 11.8612 4.92283 11.8612 7.50016Z'
							stroke='white'
							strokeLinecap='round'
						/>
					</svg>
				</div>
			)
			break
		case "sloppy":
		case "sloppystakes":
			icon = <SloppyIcon className='flex-shrink-0 w-[0.875rem] h-[0.875rem]' />
			break
		case "usdt":
			icon = <USDTIcon className='flex-shrink-0 w-[0.875rem] h-[0.875rem]' />
			break
		default:
			return null
	}

	return icon
}
