import { OpensearchFlowNFT, truncateString } from "flowty-common"
import { AccountSummaries, AccountSummary, verifyAddress } from "flowty-sdk"
import { useField } from "formik"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { FlowtyButton } from "../../../../FlowtyButton"
import { FlowtyFieldInput } from "../../../../FlowtyFieldInput"
import { walletDisabledMessage } from "../../../../FlowtyWalletSelector/FlowtyWalletSelector"
import { FlowtyAccountTag } from "../../../../FlowtyWalletSelector/components/FlowtyAccountTag"
import { useFlowtyListingModalContext } from "../../../contexts/FlowtyListingModalContext/FlowtyListingModalContext"

const contractTypeToDapperID: Record<string, string> = {
	"A.0b2a3299cc857e29.TopShot": "ad3260ba-a87c-4359-a8b0-def2cc36310b",
	"A.329feb3ab062d289.UFC_NFT": "24ed5274-68f4-42bc-8600-4a6f5fa17359",
	"A.427ceada271aa0b1.SturdyItems": "fb3cebea-cb28-4231-9d56-ea4ecd2f8ff7",
	"A.427ceada271aa0b1.SturdyTokens": "35c53829-12f1-4f0d-ac7f-9a9d2b7bc172",
	"A.87ca73a41bb50ad5.Golazos": "44924327-dea6-4915-a18d-85a827c5eb51",
	"A.8b148183c28ff88f.Gaia": "75dc054b-e405-4fbd-91c3-6dbfc0e691ca",
	"A.e4cf4bdc1751c65d.AllDay": "567226a0-f1fe-4c9a-ba85-423d020d646a",
}

export const getDapperInventoryURL = (
	nft: OpensearchFlowNFT | null
): string | null => {
	if (!nft) {
		return null
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, addr, name] = nft.type.split(".")
	const contractID = `A.${addr}.${name}`

	const appID = contractTypeToDapperID[contractID] ?? null
	if (!appID) {
		return null
	}

	const url = `https://accounts.meetdapper.com/inventory?page=1&sort=DESC&token=${nft.id}&contract=${contractID}&dapp=${appID}`
	return url
}

interface TransferProps {
	isDapper?: boolean
	childAccounts?: AccountSummary[]
	openSearchFlowNFT: OpensearchFlowNFT | null
	transferFormError: boolean
	transferReceiver: string
}

export const Transfer: React.FunctionComponent<TransferProps> = ({
	isDapper,
	childAccounts,
	openSearchFlowNFT,
	transferFormError,
	transferReceiver,
}) => {
	const {
		transferWalletSelected,
		setTransferWalletSelected,
		transferInvalidAddress,
		setTransferInvalidAddress,
		transferSuggestedAccounts,
		hasNftReceiver,
	} = useFlowtyListingModalContext()

	const inputErrorRef = useRef<HTMLDivElement>(null)
	const serviceTermRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (transferFormError && (transferFormError || transferInvalidAddress)) {
			if (inputErrorRef?.current?.scrollIntoView) {
				inputErrorRef?.current?.scrollIntoView({ behavior: "smooth" })
				return
			}
		}
		if (transferFormError) {
			if (inputErrorRef?.current?.scrollIntoView) {
				serviceTermRef?.current?.scrollIntoView({ behavior: "smooth" })
			}
		}
	}, [transferFormError])

	const [field, meta, helpers] = useField({ name: `transferReceiver` })
	const { setValue } = helpers
	const { value } = meta

	const dapperURL = getDapperInventoryURL(openSearchFlowNFT)

	const checkIsInvalid = useCallback(async () => {
		if (process.env.IS_STORYBOOK) return
		const is = await verifyAddress(transferReceiver)
		setTransferInvalidAddress(!is)
	}, [transferReceiver])

	useEffect(() => {
		if (transferWalletSelected != "" && value !== transferWalletSelected) {
			setTransferWalletSelected("")
		} else {
			setTransferInvalidAddress(false)
		}
	}, [transferWalletSelected, value])

	useEffect(() => {
		if (transferReceiver != "") {
			checkIsInvalid()
		}
	}, [checkIsInvalid, value, transferReceiver])

	const accounts = useMemo(() => {
		return Object.values(transferSuggestedAccounts || {}).reduce(
			(result, account: AccountSummary) => {
				//if nft owner equals to account address, this account should not be shown
				if (openSearchFlowNFT?.owner === account.address) {
					return result
				}
				const isAccountInvalid = !hasNftReceiver?.apply(account)

				return isAccountInvalid
					? { invalid: [account, ...result.invalid], valid: result.valid }
					: { invalid: result.invalid, valid: [account, ...result.valid] }
			},
			{
				invalid: [] as AccountSummary[],
				valid: [] as AccountSummary[],
			}
		)
	}, [transferSuggestedAccounts, hasNftReceiver])

	return (
		<>
			{isDapper ? (
				<div className='flex flex-col gap-4'>
					<p className='text-base font-normal text-white'>
						NFTs in Dapper Wallet can only be transferred via Dapper&apos;s
						Inventory.
					</p>
					<FlowtyButton
						text='VIEW IN DAPPER INVENTORY'
						variant='secondary'
						btnSize='small'
						onClick={() => {
							window.open(
								dapperURL || "https://accounts.meetdapper.com/inventory",
								"_blank"
							)
						}}
					/>
				</div>
			) : (
				<div
					className='w-full h-full flex flex-col justify-between gap-4'
					ref={inputErrorRef}
				>
					<div className='flex flex-col gap-4'>
						<p className='text-base font-semibold text-white'>Recipient</p>
						<FlowtyFieldInput
							name={`transferReceiver`}
							valueError={transferFormError && transferInvalidAddress}
							inputError={transferFormError && transferInvalidAddress}
							placeholder='Enter Wallet Address'
							textLeft
							errorMessage={
								transferFormError && transferInvalidAddress
									? "Address Not Found"
									: null
							}
						/>
						{childAccounts &&
							childAccounts?.length > 0 &&
							transferSuggestedAccounts && (
								<div className='flex flex-col gap-3 p-3 bg-[#FFFFFF14] rounded-md'>
									<p className='text-white font-normal text-xs'>
										Linked Accounts
									</p>
									{accounts.valid?.map(
										(account: AccountSummary, index: number) => {
											return (
												<button
													key={`valid-${account?.address}-${index}`}
													onClick={() => {
														if (transferWalletSelected === account?.address) {
															setTransferWalletSelected("")
															setValue("")
															return
														}
														setTransferWalletSelected(account?.address)
														setValue(account?.address)
													}}
													data-testid='transfer-wallet-checkbox'
													className='border border-[#FFFFFF1F] bg-[#606E7D33] py-3 w-full flex rounded-md group px-2 cursor-pointer'
												>
													<div className='flex h-full justify-center items-center px-2'>
														<div
															className={`w-5 h-5 rounded-full border-2  border-neutral-700 hover:border-white  ${
																transferWalletSelected === account?.address
																	? "bg-primary border-none"
																	: "bg-transparent!"
															} flex items-center justify-center`}
														>
															<div
																className={`p-[0.25rem] rounded-full absolute ${
																	transferWalletSelected === account?.address
																		? "bg-[#031021]"
																		: "bg-transparent"
																}`}
															></div>
														</div>
													</div>
													<div className='flex flex-col px-3 py-1 items-start'>
														<div className='flex items-center'>
															<div className='leading-none text-sm font-bold text-white'>
																{truncateString(account?.display?.name, 20) ||
																	(account.isMain
																		? "Main Account"
																		: "Child Account")}
															</div>
															<img
																src={account?.display?.thumbnail}
																alt='Wallet'
																className='h-6 w-6 rounded-lg mx-2'
															/>
															<FlowtyAccountTag isMainWallet={account.isMain} />
														</div>
														<div className='text-xs mt-1 text-white'>
															{account?.address}
														</div>
													</div>
												</button>
											)
										}
									)}
									{accounts.invalid?.map((account: AccountSummary, index) => {
										return (
											<div key={`invalid-${account?.address}-${index}`}>
												<div
													aria-disabled
													className='cursor-not-allowed items-center opacity-50 border border-[#FFFFFF1F] bg-[#606E7D33] py-3 w-full flex rounded-md group px-2'
												>
													<div className='flex h-full justify-center items-center px-2'>
														<div
															className={`w-5 h-5 rounded-full border-2  border-neutral-700 hover:border-white  ${
																transferWalletSelected === account?.address
																	? "bg-primary border-none"
																	: "bg-transparent!"
															} flex items-center justify-center`}
														>
															<div
																className={`p-[0.25rem] rounded-full absolute ${
																	transferWalletSelected === account?.address
																		? "bg-[#031021]"
																		: "bg-transparent"
																}`}
															></div>
														</div>
													</div>
													<div className='flex flex-col px-3 py-1'>
														<div className='flex items-center'>
															<div className='leading-none text-sm font-bold text-white'>
																{truncateString(account?.display?.name, 20) ||
																	(account.isMain
																		? "Main Account"
																		: "Child Account")}
															</div>
															<img
																src={account?.display?.thumbnail}
																alt='Wallet'
																className='h-6 w-6 rounded-lg mx-2'
															/>
															<FlowtyAccountTag isMainWallet={account.isMain} />
														</div>
														<div className='text-xs mt-1 text-white'>
															{account?.address}
														</div>
													</div>
												</div>

												{index === accounts.invalid.length - 1 &&
												walletDisabledMessage.sendTo ? (
													<div className='w-full border-gray-500 h-8 text-danger text-xs grid place-items-center'>
														{walletDisabledMessage.sendTo}
													</div>
												) : null}
											</div>
										)
									})}
								</div>
							)}
					</div>

					<div
						className='flex flex-col gap-4 text-white font-normal'
						ref={serviceTermRef}
					>
						<p className='text-xs text-white'>
							Note: Flowty is not responsible for any loss of assets or
							unexpected outcomes resulting from NFT transfers
						</p>
					</div>
				</div>
			)}
		</>
	)
}
