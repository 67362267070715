import React, { useEffect, useMemo, useRef, useState } from "react"
import FlowtyLogo from "../../FlowtyLogo"
import { FlowtyUserNotifications } from "../../FlowtyUserNotifications"
import { ReactComponent as MenuIcon } from "../../assets/mobile-menu.svg"
import { cn } from "../../utils/cn"
import { FlowtyNavbarProps } from "../FlowtyNavbar"
import { navItems } from "../types/NavbarTypes"
import SidebarNavigation from "./SidebarNavigation"
import { SignInButton } from "./SignInButton"
import { NotificationData, NotificationType } from "flowty-common"

const NavBarLogoAndHamburgerMenu = ({
	setSidebarNavigation,
}: {
	setSidebarNavigation: (value: number) => void
}): JSX.Element => {
	return (
		<div className={cn("flex items-center gap-[12px] flex-shrink-0")}>
			<button onClick={() => setSidebarNavigation(300)} role='menu'>
				<MenuIcon className='w-[32px] h-[32px]' />
			</button>
			<a href='/' className='flex items-center'>
				<FlowtyLogo size='small' theme='dark' />
			</a>
		</div>
	)
}

const NavBarSignInAndNotifications = ({
	isLogged,
	loggedPopoverComponent,
	isLoading,
	logUser,
	hasMore,
	notifications,
	queueNotification,
	loadingNotifications,
	fetchMoreNotifications,
}: {
	isLogged: boolean
	isLoading: boolean
	loggedPopoverComponent: React.ReactNode
	hasMore: boolean
	logUser: () => void
	notifications: NotificationData[]
	queueNotification?: ({
		type,
		id,
	}: {
		type: NotificationType
		id: string
	}) => void
	loadingNotifications: boolean
	fetchMoreNotifications: () => void
}): JSX.Element => {
	const authComponent = useMemo(() => {
		if (!isLogged) {
			return <SignInButton isLoading={isLoading} logUser={logUser} />
		}
		if (isLogged && !isLoading) {
			return loggedPopoverComponent
		}
	}, [isLogged, isLoading, loggedPopoverComponent])
	return (
		<div
			className={cn(
				"flex items-center justify-items-end gap-[12px] flex-shrink-0 relative z-[999]"
			)}
		>
			{isLogged && !isLoading && (
				<FlowtyUserNotifications
					fetchMoreNotifications={fetchMoreNotifications}
					loading={loadingNotifications}
					hasMore={hasMore}
					notifications={notifications}
					queueMessage={queueNotification}
				/>
			)}
			{authComponent}
		</div>
	)
}

export const NavbarMobile: React.FunctionComponent<FlowtyNavbarProps> = ({
	bgColor,
	isLogged = false,
	loggedPopoverComponent,
	fetchMoreNotifications,
	mixpanelFn,
	authLoading,
	logUser,
	registerHeight,
	selectedItem,
	loadingNotifications,
	queueNotification,
	hasMore,
	notifications,
}) => {
	const [sidebarNavigation, setSidebarNavigation] = useState<number>(0)

	const navbarRef = useRef<HTMLDivElement | null>(null)

	const getNavbarHeight = () => {
		if (navbarRef.current && registerHeight) {
			registerHeight(navbarRef.current.offsetHeight)
		}
	}

	useEffect(() => {
		getNavbarHeight()

		const handleResize = () => {
			getNavbarHeight()
		}

		window.addEventListener("resize", handleResize)

		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [navbarRef, registerHeight])

	const baseStyles = `${bgColor} py-[18px] flex flex-col w-full items-center fixed top-0 left-1/2 -translate-x-1/2 z-50 font-black text-white`

	return (
		<div>
			<div
				ref={navbarRef}
				className={baseStyles}
				data-testid='navbar-component'
			>
				<nav className={`container flex w-full justify-between h-[40px]`}>
					<NavBarLogoAndHamburgerMenu
						setSidebarNavigation={setSidebarNavigation}
					/>
					<NavBarSignInAndNotifications
						fetchMoreNotifications={fetchMoreNotifications}
						loadingNotifications={loadingNotifications}
						hasMore={hasMore}
						notifications={notifications}
						queueNotification={queueNotification}
						isLoading={authLoading}
						isLogged={isLogged}
						loggedPopoverComponent={loggedPopoverComponent}
						logUser={logUser}
					/>
				</nav>
				{sidebarNavigation ? (
					<SidebarNavigation
						isLogged={isLogged}
						width={sidebarNavigation}
						onClose={() => setSidebarNavigation(0)}
						navItems={navItems}
						selectedItem={selectedItem!}
					/>
				) : null}
			</div>
		</div>
	)
}
