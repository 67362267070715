import { observer } from "mobx-react"
import React, { useEffect } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { UserProfileTabButtons } from "../components/UserProfile/UserProfileData"
import ActivityScreen from "../screens/ActivityScreen"
import { AssetScreen } from "../screens/AssetScreen/AssetScreen"
import { CollectionListPage } from "../screens/CollectionListPage/CollectionListPage"
import { CollectionPage } from "../screens/CollectionPage/CollectionPage"
import { DiscordOauthScreen } from "../screens/DiscordOauthScreen"
import HomeScreen from "../screens/HomeScreen"
import { ListingRedirect } from "../screens/ListingRedirect"
import LoanListingInfoScreen from "../screens/LoanListingInfoScreen"
import NotFound from "../screens/NotFound"
import PrivacyScreen from "../screens/PrivacyPolicy"
import { ProfileScreen } from "../screens/ProfileScreen"
import { RentalListingInfoScreen } from "../screens/RentalListingInfoScreen"
import { RentalsActivityScreen } from "../screens/RentalsActivityScreen"
import SettingsScreen from "../screens/SettingsScreen"
import { SignInScreen } from "../screens/SignInScreen"
import { StorefrontActivityScreen } from "../screens/StorefrontActivityScreen"
import { TermsScreen } from "../screens/TermsofService"
import { TwitterOauthScreen } from "../screens/TwitterOauth"
import { UserFlowScreen } from "../screens/UserFlowScreen/UserFlowScreen"
import ScreenUserProfile from "../screens/UserProfileScreen"
import { UserRentalScreen } from "../screens/UserRentalScreen"
import { UserStorefrontScreen } from "../screens/UserStorefrontScreen"
import { COLLECTION_PAGE_ENABLED, STOREFRONT_ENABLED } from "../util/settings"

export enum RoutePaths {
	Home = "/",
	Marketplace = "/marketplace",
	Activity = "/activity",
	AssetResource = "/asset/:collectionAddress/:collectionName/:resource/:nftId",
	Asset = "/asset/:collectionAddress/:collectionName/:nftId",
	UserFlow = "/user/:walletAddress",
	UserSignIn = "/signIn",
	UserProfileScreen = "/user-profile",
	UserProfileScreenP2P = "/user-profile/loans",
	UserProfileScreenRentals = "/user-profile/rentals",
	UserProfileScreenStorefront = "/user-profile/storefront",
	Settings = "/settings",
	SettingsNotifications = "/notification-settings",
	Listed = "/listed",
	Loans = "/loans",
	Funded = "/funded",
	LoanListing = "/listing/:listingResourceId",
	Terms = "/tos",
	Privacy = "/privacy",
	RentalListing = "/rentals/:listingResourceId",
	RentalMarketplace = "/rentals",
	ProfileFlowScreen = "/user/profile",
	SwapListing = "/swaps/:listingResourceId",
	Collection = "/collection/:collectionAddress/:collectionName",
	Storefront = "/storefront",
	StorefrontListing = "/storefront/:listingResourceId",
	Collections = "/collections",
	TwitterOauth = "/_twitter-oauth",
	DiscordOauth = "/_discord-oauth",
}

export const composeUserProfilePath = (userWalletAddress: string): string =>
	RoutePaths.UserFlow.replace(":walletAddress", userWalletAddress)

export const composeListingResourcePath = (
	basePath: `${string}/:listingResourceId`,
	listingResourceId: string | number
): string => basePath.replace(":listingResourceId", String(listingResourceId))

export const composeLoanListingPath = (listingID: string | number): string =>
	composeListingResourcePath(RoutePaths.LoanListing, listingID)

export const composeRentalListingPath = (listingID: string | number): string =>
	composeListingResourcePath(RoutePaths.RentalListing, listingID)

// TODO could this be removed and have the path be used as the route key?
enum RouteComponentKeys {
	Home = "Home",
	Marketplace = "Marketplace",
	Activity = "/activity",
	Asset = "Asset",
	AssetResource = "AssetResource",
	UserProfile = "/user-profile",
	UserSignIn = "/signIn",
	UserFlow = "UserFlow",
	UserProfileScreen = "UserProfileScreen",
	UserProfileScreenP2P = "UserProfileScreenP2P",
	UserProfileScreenRentals = "UserProfileScreenRentals",
	UserProfileScreenStorefront = "UserProfileScreenStorefront",
	Settings = "Settings",
	SettingsNotifications = "SettingsNotifications",
	Listed = "Listed",
	Loans = "Loans",
	RentalListing = "RentalListing",
	RentalMarketplace = "RentalMarketplace",
	Funded = "Funded",
	LoanListing = "LoanListing",
	Terms = "Terms",
	Privacy = "Privacy",
	ProfileFlowScreen = "ProfileFlowScreen",
	Collection = "Collection",
	Collections = "Collections",
	TwitterOauth = "TwitterOauth",
	DiscordOauth = "DiscordOauth",
	StorefrontListing = "StorefrontListing",
	Storefront = "Storefront",
	StorefrontMarketplace = "StorefrontMarketplace",
	UserPreference = "UserPreference",
}

const ACTIVITY_PREF = "ACTIVITY_PREF"

// allows us to trust that we can join all path strings
type PathWithLeadingSlash = `/${string}`

interface RouteParams {
	element: React.ReactNode
	key: RouteComponentKeys | RouteComponentKeys[]
	path:
		| (PathWithLeadingSlash & RoutePaths)
		| Array<PathWithLeadingSlash & RoutePaths>
}

interface ComponentWithActivityProps {
	children: React.ReactElement
	type: "loans" | "rentals" | "swaps" | "storefront"
}

const ComponentWithActivity = ({
	children,
	type,
}: ComponentWithActivityProps): React.ReactElement => {
	useEffect(() => localStorage.setItem(ACTIVITY_PREF, type))
	return children
}

const ROUTES_HOME: RouteParams[] = [
	{
		element: <HomeScreen />,
		key: RouteComponentKeys.Home,
		path: RoutePaths.Home,
	},
	{
		element: <SignInScreen />,
		key: RouteComponentKeys.UserSignIn,
		path: RoutePaths.UserSignIn,
	},
	{
		element: <CollectionListPage />,
		key: [RouteComponentKeys.Marketplace],
		path: [RoutePaths.Marketplace],
	},
	{
		element: <CollectionListPage />,
		key: RouteComponentKeys.Collections,
		path: RoutePaths.Collections,
	},

	{
		element: <TwitterOauthScreen />,

		key: RouteComponentKeys.TwitterOauth,
		path: RoutePaths.TwitterOauth,
	},

	{
		element: <DiscordOauthScreen />,

		key: RouteComponentKeys.DiscordOauth,
		path: RoutePaths.DiscordOauth,
	},

	{
		element: <Navigate replace to={`${RoutePaths.Activity}/storefront`} />,
		key: [
			RouteComponentKeys.Activity,
			RouteComponentKeys.Loans,
			RouteComponentKeys.UserPreference,
		],
		path: RoutePaths.Activity,
	},
	{
		element: (
			<ComponentWithActivity type={"loans"}>
				<ActivityScreen />
			</ComponentWithActivity>
		),

		key: [RouteComponentKeys.Activity, RouteComponentKeys.Loans],
		path: [RoutePaths.Activity, RoutePaths.Loans],
	},

	{
		element: (
			<ComponentWithActivity type={"rentals"}>
				<RentalsActivityScreen />
			</ComponentWithActivity>
		),
		key: [RouteComponentKeys.Activity, RouteComponentKeys.RentalMarketplace],
		path: [RoutePaths.Activity, RoutePaths.RentalMarketplace],
	},
	{
		element: (
			<Navigate
				replace
				to={`${RoutePaths.UserProfileScreen + RoutePaths.Loans}`}
			/>
		),
		key: [RouteComponentKeys.Activity, RouteComponentKeys.RentalListing],
		path: [RoutePaths.Activity, RoutePaths.RentalListing],
	},
	{
		element: (
			<ComponentWithActivity type={"loans"}>
				<ScreenUserProfile />
			</ComponentWithActivity>
		),
		key: [RouteComponentKeys.UserProfile, RouteComponentKeys.Loans],
		path: [RoutePaths.UserProfileScreen, RoutePaths.Loans],
	},
	{
		element: (
			<ComponentWithActivity type={"rentals"}>
				<UserRentalScreen />
			</ComponentWithActivity>
		),
		key: [RouteComponentKeys.UserProfile, RouteComponentKeys.RentalMarketplace],
		path: [RoutePaths.UserProfileScreen, RoutePaths.RentalMarketplace],
	},
	{
		element: (
			<ComponentWithActivity type={"storefront"}>
				<UserStorefrontScreen />
			</ComponentWithActivity>
		),
		key: [RouteComponentKeys.UserProfile, RouteComponentKeys.Storefront],
		path: [RoutePaths.UserProfileScreen, RoutePaths.Storefront],
	},
	{
		element: (
			<Navigate
				replace
				to={`${RoutePaths.UserProfileScreen}/${
					localStorage.getItem(ACTIVITY_PREF) ?? "storefront"
				}`}
			/>
		),
		key: [RouteComponentKeys.UserProfileScreen, RouteComponentKeys.Loans],
		path: RoutePaths.UserProfileScreen,
	},
	{
		element: <SettingsScreen />,
		key: RouteComponentKeys.Settings,
		path: RoutePaths.Settings,
	},
	{
		element: <TermsScreen />,
		key: RouteComponentKeys.Terms,
		path: RoutePaths.Terms,
	},
	{
		element: <PrivacyScreen />,
		key: RouteComponentKeys.Privacy,
		path: RoutePaths.Privacy,
	},
	{
		// element: <SettingsScreen activeTabBtn={SettingsButtons.Notifications} />,
		element: <SettingsScreen />,
		key: RouteComponentKeys.SettingsNotifications,
		path: RoutePaths.SettingsNotifications,
	},
	{
		element: <ScreenUserProfile activeTabBtn={UserProfileTabButtons.Listed} />,
		key: RouteComponentKeys.Listed,
		path: RoutePaths.Listed,
	},
	{
		element: <ScreenUserProfile activeTabBtn={UserProfileTabButtons.Funded} />,
		key: RouteComponentKeys.Funded,
		path: RoutePaths.Funded,
	},
	{
		element: <LoanListingInfoScreen />,
		key: RouteComponentKeys.LoanListing,
		path: RoutePaths.LoanListing,
	},
	{
		element: <RentalListingInfoScreen />,
		key: RouteComponentKeys.RentalListing,
		path: RoutePaths.RentalListing,
	},
	{
		element: <ListingRedirect />,
		key: RouteComponentKeys.StorefrontListing,
		path: RoutePaths.StorefrontListing,
	},
	{
		element: <UserFlowScreen />,
		key: RouteComponentKeys.UserFlow,
		path: RoutePaths.UserFlow,
	},
	{
		element: <ProfileScreen />,
		key: RouteComponentKeys.ProfileFlowScreen,
		path: RoutePaths.ProfileFlowScreen,
	},
	{
		element: <AssetScreen />,
		key: RouteComponentKeys.AssetResource,
		path: RoutePaths.AssetResource,
	},
	{
		element: <AssetScreen />,
		key: RouteComponentKeys.Asset,
		path: RoutePaths.Asset,
	},
]
if (COLLECTION_PAGE_ENABLED) {
	ROUTES_HOME.push({
		element: <CollectionPage />,
		key: [RouteComponentKeys.Collection],
		path: [RoutePaths.Collection],
	})
	if (STOREFRONT_ENABLED) {
		ROUTES_HOME.push({
			element: (
				<ComponentWithActivity type={"storefront"}>
					<StorefrontActivityScreen />
				</ComponentWithActivity>
			),
			key: [
				RouteComponentKeys.Activity,
				RouteComponentKeys.StorefrontMarketplace,
			],
			path: [RoutePaths.Activity, RoutePaths.Storefront],
		})
	}
}

const RenderRoutesComponent: React.FunctionComponent = () => {
	const routes: RouteParams[] = ROUTES_HOME

	return (
		<>
			<Routes>
				{routes.map(({ key: _key, path: _path, ...otherRouteParams }) => {
					const key = Array.isArray(_key) ? _key.join("-") : _key
					const path = Array.isArray(_path) ? _path.join("") : _path
					return <Route {...otherRouteParams} key={key} path={path} />
				})}
				<Route path='*' element={<NotFound />} />
			</Routes>
		</>
	)
}

export const RenderRoutes = observer(RenderRoutesComponent)
