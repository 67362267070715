import {
	OfferCreated,
	OpensearchFlowNFT,
	OpensearchStorefrontAvailableData,
	Order,
} from "flowty-common"
import { ListingState } from "../types/ListingModalContextTypes"
import { FlowtyListingFormValues } from "../../../FlowtyListingModal"
import {
	MAX_AMOUNT_TO_BORROW,
	MAX_AMOUNT_TO_REPAY,
	MAX_LOAN_DURATION,
	MAX_RENT_DURATION,
	MIN_AMOUNT_TO_BORROW,
	MIN_AMOUNT_TO_REPAY,
	getMaxLoanDuration,
	getMinLoanDuration,
	getMinRentDuration,
} from "./constants"
import {
	getMinListingDuration,
	MAX_LISTING_DURATION,
} from "../../../utils/constants"

type ButtonTextProps = {
	activeTab: "1" | "2" | "3" | "4"
	loanStep: 1 | 2
	rentStep: 1 | 2
	isDelist: ListingState
	isLoading: ListingState
	error: ListingState
	sealed: ListingState
	existingLoanOrder: boolean
	existingRentalOrder: boolean
	isAcceptOffer: boolean
	isSelectedOffer: boolean
	existingPurchaseOrder?: OpensearchStorefrontAvailableData[] | null
	isUpdateListing: boolean
}

export const getButtonText = ({
	activeTab,
	loanStep,
	rentStep,
	isDelist,
	isLoading,
	error,
	sealed,
	existingLoanOrder,
	existingRentalOrder,
	isAcceptOffer,
	isSelectedOffer,
	existingPurchaseOrder,
	isUpdateListing,
}: ButtonTextProps): string => {
	switch (activeTab) {
		case "1":
			if (
				isDelist.sale &&
				!isLoading.delist &&
				!error.delist &&
				!sealed.delist
			) {
				return "CONFIRM DELIST"
			} else if (isLoading.acceptOffer) {
				return "ACCEPTING OFFER"
			} else if (isLoading.sale) {
				return "SUBMITTING LISTING"
			} else if (isLoading.delist) {
				return "DELISTING ITEM"
			} else if (error.sale || error.delist || error.acceptOffer) {
				return "TRY AGAIN"
			} else if (sealed.sale || sealed.acceptOffer || sealed.delist) {
				return "VIEW ITEM"
			} else if (isAcceptOffer || isSelectedOffer) {
				return "ACCEPT OFFER"
			} else if (existingPurchaseOrder && !isUpdateListing) {
				return "EDIT LISTING"
			} else {
				return "SUBMIT LISTING"
			}
		case "2":
			if (loanStep === 1) {
				if (isDelist.loan && !error.delist && !sealed.delist) {
					return "CONFIRM DELIST"
				} else if (isLoading.delist) {
					return "DELISTING ITEM"
				} else if (error.delist) {
					return "TRY AGAIN"
				} else if (existingLoanOrder && !isUpdateListing && !sealed.delist) {
					return "ADD LISTING"
				} else if (sealed.delist) {
					return "VIEW ITEM"
				} else {
					return "NEXT"
				}
			} else {
				if (isLoading.loan) {
					return "SUBMITTING LISTING"
				} else if (error.loan || error.delist) {
					return "TRY AGAIN"
				} else if (sealed.loan) {
					return "VIEW ITEM"
				} else {
					return "SUBMIT LISTING"
				}
			}
		case "3":
			if (rentStep === 1) {
				if (isDelist.rent && !error.delist && !sealed.delist) {
					return "CONFIRM DELIST"
				} else if (isLoading.delist) {
					return "DELISTING ITEM"
				} else if (error.delist) {
					return "TRY AGAIN"
				} else if (existingRentalOrder && !isUpdateListing && !sealed.delist) {
					return "ADD LISTING"
				} else if (sealed.delist) {
					return "VIEW ITEM"
				} else {
					return "NEXT"
				}
			} else {
				if (isLoading.rent) {
					return "SUBMITTING LISTING"
				} else if (isLoading.delist) {
					return "DELISTING ITEM"
				} else if (error.rent || error.delist) {
					return "TRY AGAIN"
				} else if (sealed.rent) {
					return "VIEW ITEM"
				} else {
					return "SUBMIT LISTING"
				}
			}
		case "4":
			if (isLoading.transfer) {
				return "TRANSFERRING"
			} else if (error.transfer) {
				return "TRY AGAIN"
			} else if (sealed.transfer) {
				return "VIEW ITEM"
			} else {
				return "SUBMIT TRANSFER"
			}
		default:
			return "ERROR"
	}
}

type FunctionCallProps = {
	activeTab: "1" | "2" | "3" | "4"
	loanStep: 1 | 2
	rentStep: 1 | 2
	isDelist: ListingState
	error: ListingState
	sealed: ListingState
	existingLoanOrder: boolean
	existingRentalOrder: boolean
	isAcceptOffer: boolean
	selectedOffer?: OfferCreated
	existingPurchaseOrder?: OpensearchStorefrontAvailableData[] | null
	isUpdateListing: boolean
	isMainnet: boolean
	values: FlowtyListingFormValues
	selectedOrder: {
		purchase?: Order | null | undefined
		fundLoan?: Order | null | undefined
		fundRental?: Order | null | undefined
	}
	transferWalletSelected: string
	setFormError: () => void
	submitOfferTransaction: () => Promise<void>
	submitDelistTransaction: (
		listingResourceID: string,
		order: Order | null
	) => Promise<void>
	setIsUpdateListing: (isUpdateListing: boolean) => void
	redirectToAssetPage: () => void
	submitTransaction: () => Promise<void>
	changeLoanStep: () => void
	changeRentStep: () => void
}

export const getFuncionCall = ({
	activeTab,
	loanStep,
	rentStep,
	isDelist,
	error,
	sealed,
	existingLoanOrder,
	existingRentalOrder,
	isAcceptOffer,
	existingPurchaseOrder,
	isUpdateListing,
	selectedOffer,
	values,
	selectedOrder,
	transferWalletSelected,
	isMainnet,
	setFormError,
	submitOfferTransaction,
	submitDelistTransaction,
	setIsUpdateListing,
	redirectToAssetPage,
	submitTransaction,
	changeLoanStep,
	changeRentStep,
}: FunctionCallProps) => {
	const MIN_RENT_DURATION = getMinRentDuration(isMainnet)
	const MIN_LOAN_DURATION = getMinLoanDuration(isMainnet)
	const MIN_LISTING_DURATION = getMinListingDuration(isMainnet)

	switch (activeTab) {
		case "1":
			if (
				(isAcceptOffer || error.acceptOffer || Boolean(selectedOffer)) &&
				!sealed.acceptOffer
			) {
				return submitOfferTransaction
			} else if ((error.delist || isDelist.sale) && !sealed.delist) {
				return () =>
					submitDelistTransaction(
						selectedOrder.purchase?.listingResourceID || "",
						selectedOrder?.purchase || null
					)
			} else if (
				existingPurchaseOrder &&
				!isUpdateListing &&
				!sealed.acceptOffer &&
				!sealed.delist
			) {
				return () => setIsUpdateListing(true)
			} else if (
				(!values?.storefrontListingDuration ||
					+values?.storefrontListingDuration < MIN_LISTING_DURATION ||
					+values?.storefrontListingDuration > MAX_LISTING_DURATION ||
					!values?.saleListingValue ||
					+values?.saleListingValue < 0.01 ||
					+values?.saleListingValue > 10000000) &&
				!sealed.acceptOffer &&
				!sealed.delist
			) {
				return setFormError
			} else if (sealed.sale || sealed.acceptOffer || sealed.delist) {
				return redirectToAssetPage
			} else {
				return submitTransaction
			}
		case "2":
			if (loanStep === 1) {
				if ((error.delist || isDelist.loan) && !sealed.delist) {
					return () =>
						submitDelistTransaction(
							selectedOrder.fundLoan?.listingResourceID || "",
							selectedOrder?.fundLoan || null
						)
				} else if (existingLoanOrder && !isUpdateListing && !sealed.delist) {
					return () => setIsUpdateListing(true)
				} else if (
					(!values?.amountToBorrow ||
						+values?.amountToBorrow < MIN_AMOUNT_TO_BORROW ||
						+values?.amountToBorrow > MAX_AMOUNT_TO_BORROW ||
						!values?.amountToRepay ||
						+values?.amountToRepay < MIN_AMOUNT_TO_REPAY ||
						+values?.amountToRepay > MAX_AMOUNT_TO_REPAY ||
						values?.loanDuration === "" ||
						+values?.loanDuration < MIN_LOAN_DURATION ||
						+values?.loanDuration >
							getMaxLoanDuration(values.tokenType === "USDC") ||
						+values?.listingDuration < MIN_LISTING_DURATION ||
						+values?.listingDuration > MAX_LISTING_DURATION ||
						values?.amountToRepay <= values?.amountToBorrow) &&
					!sealed.delist
				) {
					return setFormError
				} else if (sealed.delist) {
					return redirectToAssetPage
				} else {
					return changeLoanStep
				}
			} else {
				return sealed.loan ? redirectToAssetPage : submitTransaction
			}
		case "3":
			if (rentStep === 1) {
				if ((error.delist || isDelist.rent) && !sealed.delist) {
					return () =>
						submitDelistTransaction(
							selectedOrder.fundRental?.listingResourceID || "",
							selectedOrder?.fundRental || null
						)
				} else if (existingRentalOrder && !isUpdateListing && !sealed.delist) {
					return () => setIsUpdateListing(true)
				} else if (
					(values?.rentalDuration === "" ||
						+values?.rentalDuration < MIN_RENT_DURATION ||
						+values?.rentalDuration > MAX_RENT_DURATION ||
						!values?.rentalFee ||
						+values?.rentalFee < 0.01 ||
						+values?.rentalFee > 50000 ||
						!values?.rentalListingDuration ||
						+values?.rentalListingDuration < MIN_LISTING_DURATION ||
						+values?.rentalListingDuration > MAX_LISTING_DURATION ||
						!values?.refundableDeposit ||
						+values?.refundableDeposit < 0.01 ||
						+values?.refundableDeposit > 1000000) &&
					!sealed.delist
				) {
					return setFormError
				} else if (sealed.delist) {
					return redirectToAssetPage
				} else {
					return changeRentStep
				}
			} else {
				return sealed.rent ? redirectToAssetPage : submitTransaction
			}
		case "4":
			return !values?.transferReceiver && !transferWalletSelected
				? setFormError
				: sealed.transfer
				? redirectToAssetPage
				: submitTransaction
		default:
			return () => {}
	}
}

type DisabledButtonProps = {
	activeTab: "1" | "2" | "3" | "4"
	loanStep: 1 | 2
	rentStep: 1 | 2
	isLoading: ListingState
	isAcceptOffer: boolean
	selectedOffer?: OfferCreated
	isDapper: boolean
	openSearchFlowNFT: OpensearchFlowNFT
	existingPurchaseOrder?: OpensearchStorefrontAvailableData[] | null
	orderFromChildAccount: boolean
	isCatalog: boolean
	isDelist: ListingState
	privateRentInvalidAddress: boolean
}

export const getIsDisabledButton = ({
	activeTab,
	loanStep,
	rentStep,
	isLoading,
	isAcceptOffer,
	selectedOffer,
	isDapper,
	openSearchFlowNFT,
	existingPurchaseOrder,
	orderFromChildAccount,
	isCatalog,
	isDelist,
	privateRentInvalidAddress,
}: DisabledButtonProps): boolean => {
	switch (activeTab) {
		case "1":
			const { sale, delist, acceptOffer } = isLoading
			const { bestNonCustodialOffer, bestDapperOffer } = openSearchFlowNFT || {}

			const hasBestOffer = isDapper ? bestDapperOffer : bestNonCustodialOffer

			return Boolean(
				sale ||
					delist ||
					acceptOffer ||
					((isAcceptOffer || Boolean(selectedOffer)) && !hasBestOffer) ||
					(existingPurchaseOrder && orderFromChildAccount && !isAcceptOffer) ||
					(isDapper &&
						selectedOffer &&
						!selectedOffer?.paymentTokenName.includes("DUC") &&
						!selectedOffer?.paymentTokenName.includes("FUT"))
			)
		case "2":
			if (loanStep === 1) {
				return Boolean(
					isLoading.delist ||
						!isCatalog ||
						isDapper ||
						(isDelist.loan && orderFromChildAccount)
				)
			} else {
				return isLoading.loan
			}
		case "3":
			if (rentStep === 1) {
				return Boolean(
					isLoading.delist ||
						!isCatalog ||
						isDapper ||
						privateRentInvalidAddress ||
						(isDelist.rent && orderFromChildAccount)
				)
			} else {
				return isLoading.rent
			}
		case "4":
			return isLoading.transfer
		default:
			return false
	}
}

type DisplayProps = {
	activeTab: "1" | "2" | "3" | "4"
	loanStep: 1 | 2
	rentStep: 1 | 2
	isLocked: boolean
	isDelist: ListingState
	sealed: ListingState
	isAssetDetailPage: boolean
	isDapper: boolean
	hasProvider: boolean
}

export const getIsNotDisplay = ({
	activeTab,
	loanStep,
	rentStep,
	isLocked,
	isDelist,
	sealed,
	isAssetDetailPage,
	isDapper,
	hasProvider,
}: DisplayProps) => {
	switch (activeTab) {
		case "1":
			return (
				!hasProvider ||
				(isLocked && !isDelist.sale) ||
				((sealed.sale || sealed.delist || sealed.acceptOffer) &&
					isAssetDetailPage)
			)
		case "2":
			if (loanStep === 1) {
				return (
					!hasProvider || ((sealed.loan || sealed.delist) && isAssetDetailPage)
				)
			} else {
				return sealed.loan && isAssetDetailPage
			}
		case "3":
			if (rentStep === 1) {
				return (
					!hasProvider || ((sealed.rent || sealed.delist) && isAssetDetailPage)
				)
			} else {
				return sealed.rent && isAssetDetailPage
			}
		case "4":
			return !hasProvider || isDapper || (sealed.transfer && isAssetDetailPage)
		default:
			return true
	}
}
