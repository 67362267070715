import React from "react"
import defaultProfileImage from "../../../assets/media/landingPage/defaultProfileImage.svg"
import { User } from "../../../models/user"
import { CopyPublicProfileLinkButton } from "../../UserProfile/CopyPublicProfileLinkButton"
import { CopyWalletAddressButton } from "../../UserProfile/CopyWalletAddressButton"
import { AccountLinkingButton } from "./AccountLinkingButton"
import { DiscordIntegration } from "./DiscordIntegration/DiscordIntegration"
import { TwitterIntegration } from "./TwitterIntegration/TwitterIntegration"

interface UserHeaderProps {
	profile: User
}

export const UserHeader: React.FC<UserHeaderProps> = ({ profile }) => {
	if (!profile) {
		return null
	}

	return (
		<div className='mb-8 pt-[3rem] md:pt-[6rem] lg:pt-20 lg:mb-4'>
			<div className='max-w-5xl'>
				<div className='sm:-mt-16 inline-flex items-center gap-[0.75rem]'>
					<img
						className='h-[4rem] w-[4rem] rounded-[0.5rem]'
						src={profile.avatar || defaultProfileImage}
						alt='User profile'
					/>
					<div className='flex flex-col gap-[0.25rem]'>
						<div className='truncate flex items-center space-x-[0.75rem]'>
							<h1 className='text-[1.5rem] font-bold text-white truncate leading-none'>
								{profile.userName && profile.userName !== ""
									? profile.userName
									: profile.addr}
							</h1>
							<div className='flex flex-row items-center'>
								<TwitterIntegration walletAddress={profile.addr || ""} />
								<DiscordIntegration walletAddress={profile.addr || ""} />
								<CopyPublicProfileLinkButton address={profile?.addr || ""} />
							</div>
						</div>
						<div className='mt-[0.25rem] flex'>
							<CopyWalletAddressButton address={profile.addr || ""} />
						</div>
					</div>
				</div>
				<AccountLinkingButton profile={profile} />
			</div>
		</div>
	)
}
