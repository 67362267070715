import { Flowty } from "flowty-sdk"
import { Dispatch, SetStateAction } from "react"
import Swal from "sweetalert2"
import { DropStateType } from "../types/FlowtyDropModalTypes"

const IS_STORYBOOK = process.env.IS_STORYBOOK
interface DropMintFnBuilderProps {
	setIsLoading: Dispatch<SetStateAction<DropStateType>>
	setError: Dispatch<SetStateAction<DropStateType>>
	setIsFormError: Dispatch<SetStateAction<DropStateType>>
	setIsSealed: Dispatch<SetStateAction<DropStateType>>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mixPanelFn: (name: string, data: any) => void
	txAvailableCallback: (txID: string) => void
	contractAddress: string
	contractName: string
	numToMint: number
	totalCost: number
	paymentIdentifier: string
	paymentStoragePath: string
	paymentReceiverPath: string
	dropID: string
	dropPhaseIndex: number
	nftIdentifier: string
	commissionAddress: string
	flowty: Flowty
}

export const DropMintFnBuilder = async ({
	setIsLoading,
	setError,
	setIsFormError,
	setIsSealed,
	mixPanelFn,
	txAvailableCallback,
	contractAddress,
	contractName,
	numToMint,
	totalCost,
	paymentIdentifier,
	paymentStoragePath,
	paymentReceiverPath,
	dropID,
	dropPhaseIndex,
	nftIdentifier,
	commissionAddress,
	flowty,
}: DropMintFnBuilderProps): Promise<undefined> => {
	setIsLoading((prevState: DropStateType) => ({
		...prevState,
		drop: true,
	}))
	setError((prevState: DropStateType) => ({
		...prevState,
		drop: false,
	}))
	setIsFormError((prevState: DropStateType) => ({
		...prevState,
		drop: false,
	}))
	setIsSealed((prevState: DropStateType) => ({
		...prevState,
		drop: false,
	}))

	if (IS_STORYBOOK) {
		if (dropID === "ERROR_TYPE_MOCK") {
			setTimeout(() => {
				setIsLoading((prevState: DropStateType) => ({
					...prevState,
					drop: false,
				}))
				setError((prevState: DropStateType) => ({
					...prevState,
					drop: true,
				}))
			}, 3000)
		} else {
			setTimeout(() => {
				setIsLoading((prevState: DropStateType) => ({
					...prevState,
					drop: false,
				}))
				setIsSealed((prevState: DropStateType) => ({
					...prevState,
					drop: true,
				}))
			}, 3000)
		}
		return
	}

	try {
		const response = await flowty.dropMint({
			commissionAddress,
			contractAddress,
			contractName,
			dropID,
			dropPhaseIndex,
			nftIdentifier,
			numToMint,
			paymentIdentifier,
			paymentReceiverPath,
			paymentStoragePath,
			totalCost,
			txAvailableCallback,
		})

		if (response?.status === 4) {
			setIsSealed((prevState: DropStateType) => ({
				...prevState,
				drop: true,
			}))
			setIsLoading((prevState: DropStateType) => ({
				...prevState,
				drop: false,
			}))
			mixPanelFn(`Drop mint successful transaction`, {
				dropID: dropID,
				nftIdentifier: nftIdentifier,
			})
			return
		}
		if (
			(response?.status !== 4 && response?.toString()?.includes("Error")) ||
			response?.toString()?.includes("Declined")
		) {
			throw new Error(response)
		}
	} catch (e) {
		setError((prevState: DropStateType) => ({
			...prevState,
			drop: true,
		}))
		setIsLoading((prevState: DropStateType) => ({
			...prevState,
			drop: false,
		}))
		if (e?.toString()?.includes("Popup failed to open")) {
			await Swal.fire({
				background: "#031021",
				color: "#FFFFFF",
				confirmButtonColor: "#FF6969",
				icon: "error",
				showConfirmButton: true,
				text: "Pop-up blocker is enabled. Please allow pop-ups and try again.",
				timer: 5000,
			})
		}
		mixPanelFn(`Drop mint failed transaction`, {
			dropID: dropID,
			err: e,
			nftIdentifier: nftIdentifier,
		})
	}
	return
}
