import { OfferCreated, SupportedTokens } from "flowty-common"
import React, { useMemo } from "react"
import { MakeOfferOrder, ValuationData } from "../../../../../Types/GlobalTypes"
import {
	getSourceFromSourceList,
	truncateNumber,
} from "../../../../../utils/GlobalUtils"
import { NftCardFMVTooltip } from "../../NftCardFMVTooltip/NftCardFMVTooltip"
import { NftPrice } from "../../NftPrice"
import {
	BestOfferDisplay,
	MakeOfferType,
} from "../BestOfferDisplay/BestOfferDisplay"
import { LoadingOrderDisplay } from "../LoadingOrderDisplay/LoadingOrderDisplay"
import { useFlowtyPurchaseModalContext } from "../../../../contexts/FlowtyPurchaseModalContext/FlowtyPurchaseModalContext"

interface MakeOfferOrderDisplayProps {
	dapperOffer?: OfferCreated | null
	nonCustodialOffer?: OfferCreated | null
	order: MakeOfferOrder
	isLoading?: boolean
	valuationData: ValuationData
	isDapper?: boolean
	isLoadingValuation?: boolean
}

export const MakeOfferOrderDisplay: React.FC<MakeOfferOrderDisplayProps> = ({
	dapperOffer,
	isLoading,
	nonCustodialOffer,
	order,
	valuationData,
	isDapper,
	isLoadingValuation,
}) => {
	const { spotPrice } = useFlowtyPurchaseModalContext()
	const valuationSource = getSourceFromSourceList(valuationData.source)

	const currentOffer: MakeOfferType = useMemo(() => {
		return {
			amount: order.offerAmount,
			paymentTokenName: order.paymentTokenName as SupportedTokens,
		}
	}, [order])

	const higherNonCustodialOffer = useMemo(() => {
		const nonCustondialOfferValue =
			nonCustodialOffer?.paymentTokenName === "FLOW"
				? Number(nonCustodialOffer?.amount) * Number(spotPrice?.value)
				: Number(nonCustodialOffer?.amount)

		const currentOfferAmount =
			order.paymentTokenName === "FLOW"
				? order.offerAmount * Number(spotPrice?.value)
				: order.offerAmount

		return nonCustondialOfferValue > currentOfferAmount
	}, [spotPrice, order, nonCustodialOffer])

	return (
		<div className='h-full flex flex-col justify-between'>
			{isLoading ? (
				<LoadingOrderDisplay />
			) : (
				<>
					<div className='flex flex-row justify-between mt-4 items-center border-gradient mb-3'>
						<p className='text-white text-[14px] leading-6 mb-2 font-normal'>
							Purchase Price
						</p>
						<span className='text-neutral-600 leading-6 text-bold mb-2'>
							{order?.currentOrderSalePrice ? (
								<NftPrice
									price={order?.currentOrderSalePrice}
									token={order?.currentOrderTokenName || "FLOW"}
									toolTipCustomId='make-offer-price-tooltip'
								/>
							) : (
								"-"
							)}
						</span>
					</div>
					<div className='w-full flex justify-between'>
						{isLoadingValuation ? (
							<div className='w-[87px] h-[56px] animate-pulse bg-[#edf3f60a] rounded-lg'></div>
						) : (
							<>
								{valuationData?.usdValue && valuationData.usdValue > 0 ? (
									<div className='flex flex-col justify-start items-start h-24 relative group'>
										<div className='text-neutral-900 text-[12px] font-montserrat font-normal'>
											3rd Party FMV
										</div>
										<div className='self-center text-end py-1 w-full'>
											<span className='text-neutral-600 font-medium text-base font-montserrat w-full flex justify-start'>
												$
												{truncateNumber(valuationData.usdValue, {
													shouldUseDecimals: true,
												})}
											</span>
										</div>
										<NftCardFMVTooltip valuationSource={valuationSource} />
									</div>
								) : (
									<div />
								)}
							</>
						)}

						<BestOfferDisplay
							dapperOffer={
								Boolean(isDapper) &&
								currentOffer.amount > (dapperOffer?.amount || 0)
									? currentOffer
									: dapperOffer
							}
							nonCustodialOffer={
								!Boolean(isDapper) && !higherNonCustodialOffer
									? currentOffer
									: nonCustodialOffer
							}
						/>
					</div>
				</>
			)}
		</div>
	)
}
