import {
	ListingAvailableData,
	nftTypeAndIdToLocationData,
	Order,
} from "flowty-common"
import React, { useCallback } from "react"
import { useFlowtyModalsContext } from "../../contexts/FlowtyModalsContext"
import { useOpensearchNFT } from "../../hooks/data/nfts/useOpensearchNFT"

interface Props {
	className?: string
	listing: ListingAvailableData
	isOwner: boolean
	isOrderListedByLoggedAccount: boolean
}

export const DelistLoan: React.FunctionComponent<Props> = ({
	className,
	listing,
	isOwner,
	isOrderListedByLoggedAccount,
}) => {
	const locationData = nftTypeAndIdToLocationData(
		listing.nftType,
		listing.nftID
	)

	const { openSearchNFT } = useOpensearchNFT({
		collectionAddress: locationData.contract.address,
		collectionName: locationData.contract.name,
		nftId: locationData.nftID,
		resourceName: locationData.resourceName,
	})

	const { selectCard } = useFlowtyModalsContext()

	const selectNft = useCallback(
		({ singleListing }: { singleListing: Order }) => {
			selectCard({
				selected: {
					contractAddress: openSearchNFT?.contractAddress ?? "",
					contractName: openSearchNFT?.contractName ?? "",
					nftID: openSearchNFT?.id ?? "",
					nftType: openSearchNFT?.type ?? "",
				},
				singleDelistAction: "delistLoan",
				singleDelistOrder: singleListing,
			})
		},
		[selectCard, openSearchNFT, listing]
	)

	return (
		<div className={`${className}`}>
			<button
				disabled={!isOrderListedByLoggedAccount}
				className={`${
					!isOwner ? "bg-primary hover-button" : "bg-danger hover-button-red"
				}  ${
					!isOrderListedByLoggedAccount && isOwner
						? "opacity-50 cursor-not-allowed"
						: "hover-glow  hover-button-red"
				} shadow text-black text-bold text-lg py-2 px-10 w-full rounded-md hover-button cursor-pointer`}
				onClick={() => selectNft({ singleListing: listing as Order })}
			>
				DELIST
			</button>
		</div>
	)
}
