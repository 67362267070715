import { CheckIcon, UploadIcon, XIcon } from "@heroicons/react/solid"
import React, { useRef, useState } from "react"
import { LoadingSpinner } from "../../Shared/LoadingSpinner"
import { onChangeHandler } from "../../Shared/fileUpload"

interface EditWalletDetailsProps {
	onSave: (name: string, image: string) => void
	onCancel: () => void
	defaultName: string
	defaultImage: string
	childWalletAddress: string
	parentWalletAddress: string
}

export const EditWalletDetails: React.FunctionComponent<
	EditWalletDetailsProps
> = ({
	onSave,
	onCancel,
	defaultName,
	defaultImage,
	childWalletAddress,
	parentWalletAddress,
}) => {
	const [name, setName] = useState(defaultName)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [image, setImage] = useState(defaultImage)
	const fileInput = useRef<HTMLInputElement>(null)

	const fileName = `${parentWalletAddress}_${childWalletAddress}`
	const onUploadBtnClick = (): void => {
		if (fileInput && fileInput.current) {
			fileInput.current?.click()
		}
	}

	return (
		<div className='flex gap-2 items-center' onClick={e => e.stopPropagation()}>
			<input
				type='file'
				ref={fileInput}
				style={{ display: "none" }}
				onChange={event =>
					onChangeHandler(event, setImage, setIsLoading, fileName)
				}
			/>
			<button
				className='flex flex-col focus:border-none focus:ring-0 rounded-[0.25rem] h-8 w-8 bg-cover bg-center text-white cursor-pointer items-center justify-center'
				onClick={e => {
					e.stopPropagation()
					onUploadBtnClick()
				}}
				style={{
					backgroundColor: image ? "transparent" : "",
					backgroundImage: image ? `url('${image}')` : "",
					backgroundRepeat: "no-repeat",
					backgroundSize: "contain",
				}}
			>
				<div className='bg-black/60 border border-primary rounded-[0.25rem] w-full h-full flex items-center justify-center p-1'>
					{isLoading ? (
						<LoadingSpinner size={3} />
					) : (
						<UploadIcon className='text-white' />
					)}
				</div>
			</button>
			<input
				onChange={e => setName(e.target.value)}
				className='bg-transparent font-bold border py-0 border-primary rounded-[0.25rem] px-2 max-w-[10rem] focus:outline-none focus:ring-0 active:ring-0 text-[1rem]'
				value={name}
				maxLength={20}
				onClick={e => e.stopPropagation()}
			/>
			<div className='absolute z-2 flex flex-row gap-1 bottom-[1.125rem] right-[1.125rem]'>
				<button
					disabled={!name}
					className='bg-white rounded-[0.5rem] flex p-[0.625rem] border-[1px] white-box-shadow'
					onClick={e => {
						e.stopPropagation()
						if (name.length > 0) {
							onSave(name, image)
						}
					}}
				>
					<CheckIcon className='h-[12px] w-[12px] text-black' />
				</button>
				<button
					className='bg-white rounded-[0.5rem] flex p-[0.625rem] border-[1px] white-box-shadow'
					onClick={e => {
						e.stopPropagation()
						onCancel()
					}}
				>
					<XIcon className='h-[12px] w-[12px] text-black' />
				</button>
			</div>
		</div>
	)
}
