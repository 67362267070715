import { FlowNFTContract, getSquareImage } from "flowty-common"
import numeral from "numeral"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Tooltip } from "react-tooltip"
import CheckMark from "../../../assets/media/CatalogCheckMark.png"
import { addFallbackImage } from "../../../util/images"
import FallbackImg from "../../../assets/new_fallback_image.svg"

interface CollectionCardProps {
	collection?: FlowNFTContract
	isLoading?: boolean
}

export const CollectionCard: React.FunctionComponent<CollectionCardProps> = ({
	collection,
	isLoading,
}) => {
	const [imageLoaded, setImageLoaded] = useState(false)
	const handleImageLoad = () => {
		setImageLoaded(true)
	}

	const imageCardSize = "max-w-full object-fill aspect-square"

	return (
		<div>
			{isLoading ? (
				<div className='collectionCard h-auto p-[18px] z-10 max-sm:m-auto'>
					<div className='h-full flex flex-col gap-[14px] overflow-hidden'>
						<div
							className={`flex animate-pulse bg-[#43ff640a] ${imageCardSize}`}
						></div>
						<div className='flex flex-col gap-[18px]'>
							<div className='w-[211px] h-[16px] animate-pulse bg-[#43ff640a] rounded-lg'></div>
							<div className='w-full flex justify-between'>
								<div className='flex flex-col gap-1'>
									<div className='w-[50px] h-[15px] animate-pulse bg-[#43ff640a] rounded-lg'></div>
									<div className='w-[88px] h-[15px] animate-pulse bg-[#43ff640a] rounded-lg'></div>
								</div>
								<div className='flex flex-col gap-1'>
									<div className='w-[50px] h-[15px] animate-pulse bg-[#43ff640a] rounded-lg'></div>
									<div className='w-[88px] h-[15px] animate-pulse bg-[#43ff640a] rounded-lg'></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<>
					<Link to={`/collection/${collection?.address}/${collection?.name}`}>
						<div className='collectionCard h-full p-[18px] z-10 max-sm:m-auto'>
							<div className='flex flex-col h-full gap-[14px] overflow-hidden'>
								{!imageLoaded && (
									<div
										className={`flex max-w-full ${imageCardSize} animate-pulse bg-[#43ff640a] ${
											imageLoaded ? "hidden" : "object-fill aspect-square"
										}`}
									></div>
								)}

								<div
									className={`flex max-w-full h-full ${
										!imageLoaded ? "hidden" : "object-fill aspect-square"
									}`}
								>
									<img
										src={
											collection?.collectionDisplay?.name
												? getSquareImage(
														collection?.collectionDisplay?.name || "",
														collection || null
												  )
												: FallbackImg
										}
										alt={
											collection?.collectionDisplay?.name
												? collection?.collectionDisplay?.name
												: ""
										}
										onLoad={handleImageLoad}
										onError={addFallbackImage}
										className={`${
											imageLoaded
												? "object-fill aspect-square w-full"
												: "hidden"
										}`}
									/>
								</div>

								<div className='flex flex-col gap-[18px] text-[#FFFFFF]'>
									<div className='w-full flex gap-2 items-center text-[#F8F9FA]'>
										<p className='text-sm sm:text-lg uppercase font-black break-words max-w-[300px] text-ellipsis whitespace-nowrap overflow-hidden'>
											{collection?.collectionDisplay?.name?.replaceAll(
												"-",
												" "
											)}
										</p>
										{collection?.isNFTCatalog && (
											<button id='anchor-select'>
												<img
													src={CheckMark}
													alt='Catalog check mark'
													data-tooltip-place='top'
													data-tooltip-id={`${collection?.name}-verified-tooltip`}
												/>
											</button>
										)}
									</div>
									<div className='w-full flex justify-between'>
										<div className='flex flex-col'>
											<p className='text-xs font-normal text-[#CED4DA]'>
												Total Items
											</p>
											<p className='text-base uppercase font-bold'>
												{collection?.totalItems &&
													(collection.totalItems < 1000
														? collection.totalItems
														: numeral(collection.totalItems || 0)
																.format("0.0a")
																.toLocaleUpperCase())}{" "}
											</p>
										</div>
										<div className='flex flex-col'>
											<p className='text-xs font-normal text-[#CED4DA]'>
												Floor Price
											</p>
											<p className='text-base uppercase font-bold text-right'>
												{collection?.floorPrice?.valueInUSD
													? `$${collection?.floorPrice?.valueInUSD.toFixed(2)}`
													: "-"}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Link>
					<Tooltip
						id={`${collection?.name}-verified-tooltip`}
						style={{
							backdropFilter: "blur(100px)",
							backgroundColor: "#33333380",
							zIndex: 999,
						}}
						anchorSelect='#anchor-select'
						clickable
					>
						<div className='flex flex-col gap-2 z-50'>
							<div className='flex gap-1'>
								<img
									src={CheckMark}
									alt='Catalog check mark'
									className='w-[16px] h-[16px]'
								/>
								<p className='text-sm font-medium text-[#F8F9FA]'>
									Verified Collection
								</p>
							</div>
							<div className='flex flex-col space-y-1.5'>
								<p className='text-sm font-normal text-[#ADB5BD] leading-none'>
									Verified by the
								</p>
								<a
									href='https://www.flow-nft-catalog.com/'
									target='_blank'
									rel='noreferrer'
									className=' text-sm font-normal text-[#04E5A3] leading-none'
								>
									Flow NFT Catalog
								</a>
							</div>
						</div>
					</Tooltip>
				</>
			)}
		</div>
	)
}
