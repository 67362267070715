import { OpensearchFlowNFT } from "flowty-common"
import React from "react"
import { useFlowtyPurchaseModalContext } from "../../contexts/FlowtyPurchaseModalContext/FlowtyPurchaseModalContext"
import { ModalWrapper } from "./ModalWrapper"

interface ModalWrapperProps {
	isOpen: boolean
	openSearchFlowNFT: OpensearchFlowNFT
	hasProvider: boolean
	orderContent: React.ReactNode
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mixPanelFn: (event: string, data: any) => void
}

export const ModalWrapperPurchase: React.FunctionComponent<
	ModalWrapperProps
> = ({ isOpen, openSearchFlowNFT, hasProvider, orderContent, mixPanelFn }) => {
	const {
		valuationData,
		onCloseModal,
		listingType,
		collectionImage,
		resetModal,
		closeBtnProps,
		confirmBtnProps,
		orderData,
		isLoading,
		sealed,
		singleOffer,
		isDapper,
		isLoadingValuation,
		isLoadingOrders,
	} = useFlowtyPurchaseModalContext()

	return (
		<ModalWrapper
			collectionImage={collectionImage}
			isOpen={isOpen}
			openSearchFlowNFT={openSearchFlowNFT}
			hasProvider={hasProvider}
			valuationData={valuationData}
			onCloseModal={onCloseModal}
			listingType={listingType}
			resetModal={resetModal}
			closeBtnProps={closeBtnProps}
			confirmBtnProps={confirmBtnProps}
			orderData={orderData}
			isLoadingMakeOffer={isLoading.makeOffer}
			sealedMakeOffer={sealed.makeOffer}
			orderContent={orderContent}
			isPurchase={true}
			isSingleOffer={Boolean(singleOffer)}
			isDapper={isDapper}
			isLoadingValuation={isLoadingValuation}
			isLoadingOrders={isLoadingOrders}
			mixPanelFn={mixPanelFn}
		/>
	)
}
