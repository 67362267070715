import { useCallback, useEffect, useMemo, useState } from "react"
import { db } from "../firebase"
import { waitForSeal } from "../services/flow/FlowUtil"
import {
	BaseNotification,
	InitiatedTransactionNotification,
	NotificationData,
	NotificationType,
} from "flowty-common"

interface UseNotificationsProps {
	loggedUserAddress: string
	autoResolve?: boolean
	delay?: number
}

export const useNotifications = ({
	loggedUserAddress,
	autoResolve = true,
	delay = 1000,
}: UseNotificationsProps): {
	notifications: NotificationData[]
	queueNotification: ({
		id,
		type,
	}: {
		id: string
		type: BaseNotification["type"]
	}) => void
	fetchMoreNotifications: () => void
	hasMore: boolean
	loading: boolean
} => {
	const [loading, setLoading] = useState(false)

	const [notifications, setNotifications] = useState<NotificationData[]>([])
	const [queuedNotifications, setQueuedNotifications] = useState<
		{ id: string; type: BaseNotification["type"] }[]
	>([])

	const [lastDoc, setLastDoc] = useState<NotificationData | null>(null)
	const [hasMore, setHasMore] = useState(true)
	const limit = 25

	const pendingNotifications = useMemo(
		() =>
			notifications.filter(
				notification =>
					notification.type === NotificationType.Transaction &&
					"status" in notification &&
					notification.status === "PROCESSING"
			),
		[notifications]
	)

	const queueNotification = useCallback(
		({ id, type }: { id: string; type: BaseNotification["type"] }) => {
			setQueuedNotifications(prevQueue => [...prevQueue, { id, type }])
		},
		[]
	)

	const resolveTransactionNotification = useCallback(
		async (id: string) => {
			const notification = (
				notifications as InitiatedTransactionNotification[]
			).find(n => n.id === id)

			if (!notification || notification.type !== NotificationType.Transaction)
				return

			try {
				const processingMessage = await db
					.collection(`/accounts/${loggedUserAddress}/messages`)
					.doc(id)
					.get()

				if (!processingMessage.exists) {
					return
				}

				let notificationTransactionId: string = (
					processingMessage.data() as InitiatedTransactionNotification
				).transactionID

				if (
					notification.type === NotificationType.Transaction &&
					(notification.listingType === "bulkListing" ||
						notification.listingType === "bulkDelisting")
				) {
					notificationTransactionId = notificationTransactionId.split("-")[0]
				}

				const { statusString } = await waitForSeal(notificationTransactionId)

				const newStatus = statusString === "SEALED" ? "SUCCESSFUL" : "FAILED"

				await db
					.doc(`/accounts/${loggedUserAddress}/messages/${id}`)
					.update({ status: newStatus })

				setNotifications(prevNotifications => {
					const index = prevNotifications.findIndex(
						cbNotification => cbNotification.id === id
					)
					if (index === -1) {
						return prevNotifications
					}
					const updatedNotifications = [...prevNotifications]
					updatedNotifications[index] = {
						...notification,
						...(notification.type === NotificationType.Transaction && {
							status: newStatus,
						}),
					}

					return updatedNotifications
				})
			} catch (error) {
				await db
					.collection(`/accounts/${loggedUserAddress}/messages`)
					.doc(id)
					.update({ status: "FAILED" })
				setNotifications(prevNotifications => {
					const index = prevNotifications.findIndex(
						cbNotification => cbNotification.id === id
					)
					if (index === -1) {
						return prevNotifications
					}
					const updatedNotifications = [...prevNotifications]
					updatedNotifications[index] = {
						...notification,
						status: "FAILED",
					}

					return updatedNotifications
				})
			}
		},
		[loggedUserAddress, notifications]
	)

	const determineResolutionStrategy = useCallback(
		async ({ id, type }: { id: string; type: BaseNotification["type"] }) => {
			switch (type) {
				case "TRANSACTION": {
					await resolveTransactionNotification(id)
					break
				}
				// Add more cases for other notification types as needed
				default:
					console.error("Unknown notification type")
			}
		},
		[resolveTransactionNotification]
	)

	const resolvePendingNotifications = useCallback(
		async (queue: NotificationData[]) => {
			for await (const { id, type } of queue) {
				await determineResolutionStrategy({ id: id!, type })
			}
		},
		[determineResolutionStrategy]
	)

	useEffect(() => {
		if (!loggedUserAddress) {
			return
		}
		if (autoResolve && pendingNotifications.length) {
			resolvePendingNotifications(pendingNotifications)
		}
	}, [
		autoResolve,
		pendingNotifications,
		resolvePendingNotifications,
		loggedUserAddress,
	])

	useEffect(() => {
		if (!loggedUserAddress) return

		const unsubscribe = db
			.collection(`/accounts/${loggedUserAddress}/messages`)
			.orderBy("timestamp", "desc")
			.limit(1)
			.onSnapshot(snapshot => {
				const newNotifications: NotificationData[] = []

				snapshot.forEach(doc => {
					newNotifications.push({
						...doc.data(),
						id: doc.id,
					} as NotificationData)
				})

				setNotifications(prev => {
					const allNotifications = [...newNotifications, ...prev]
					const uniqueNotificationsMap = new Map(
						allNotifications.map(n => [n.id, n])
					)
					const uniqueNotifications = Array.from(
						uniqueNotificationsMap.values()
					)

					return uniqueNotifications
				})
			})

		return () => unsubscribe()
	}, [loggedUserAddress])

	const fetchNotifications = useCallback(
		async (initial = false) => {
			if (!loggedUserAddress) return

			if (initial) {
				setLoading(true)
			}

			let query = db
				.collection(`/accounts/${loggedUserAddress}/messages`)
				.orderBy("timestamp", "desc")
				.limit(limit)

			if (lastDoc && !initial) {
				query = query.startAfter(lastDoc)
			}

			const snapshot = await query.get()
			const fetchedNotifications: NotificationData[] = []

			snapshot.forEach(doc => {
				fetchedNotifications.push({
					...doc.data(),
					id: doc.id,
				} as NotificationData)
			})

			if (snapshot.docs.length < limit) {
				setHasMore(false)
			}

			setLastDoc(
				snapshot.docs[snapshot.docs.length - 1] as unknown as NotificationData
			)
			setNotifications(prev =>
				initial ? fetchedNotifications : [...prev, ...fetchedNotifications]
			)
			setLoading(false)
		},
		[lastDoc, loggedUserAddress]
	)

	const fetchMoreNotifications = useCallback(() => {
		if (hasMore) {
			fetchNotifications()
		}
	}, [fetchNotifications, hasMore])

	useEffect(() => {
		fetchNotifications(true)
	}, [loggedUserAddress])

	useEffect(() => {
		if (!autoResolve && queuedNotifications.length) {
			const interval = setInterval(() => {
				const queue = [...queuedNotifications]
				setQueuedNotifications([])
				queue.forEach(({ id, type }) => {
					determineResolutionStrategy({ id, type })
				})
			}, delay)

			return () => {
				clearInterval(interval)
			}
		}
	}, [autoResolve, queuedNotifications, determineResolutionStrategy])

	return {
		fetchMoreNotifications,
		hasMore,
		loading,
		notifications,
		queueNotification,
	}
}
