import { Config } from "../types"

export const getValidateOwnershipScript = (
	config: Config,
	nftContractName: string,
	nftContractAddress: string,
	resourceName: string,
	storagePath: string
): string => {
	return !config.crescendo
		? // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  _validateOwnershipScript(
				config,
				nftContractName,
				nftContractAddress,
				resourceName,
				storagePath
		  )
		: // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  _validateOwnershipScriptCrescendo(
				config,
				nftContractName,
				nftContractAddress,
				resourceName,
				storagePath
		  )
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const _validateOwnershipScript = (
	config: Config,
	nftContractName: string,
	nftContractAddress: string,
	resourceName: string,
	storagePath: string
): string => `import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import ${nftContractName} from ${nftContractAddress}

pub fun main(address: Address, nftId: UInt64, typeIdentifier: String): Bool {
    // see note in AccountService.ts about using NFT resource name
    let expectedType = Type<@${nftContractName}.${resourceName}>()
    let statedType = CompositeType(typeIdentifier) ?? panic("Invalid type identifier")

    let collection = getAuthAccount(address)
        .borrow<&{NonFungibleToken.CollectionPublic}>(from: /storage/${storagePath})
    if collection == nil {
      return false
    }

    return statedType == expectedType && collection!.getIDs().contains(nftId)
}`

// eslint-disable-next-line @typescript-eslint/naming-convention
const _validateOwnershipScriptCrescendo = (
	config: Config,
	nftContractName: string,
	nftContractAddress: string,
	resourceName: string,
	storagePath: string
): string => `import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import ${nftContractName} from ${nftContractAddress}

access(all) fun main(address: Address, nftId: UInt64, typeIdentifier: String): Bool {
    // see note in AccountService.ts about using NFT resource name
    let expectedType = Type<@${nftContractName}.${resourceName}>()
    let statedType = CompositeType(typeIdentifier) ?? panic("Invalid type identifier")

    let collection = getAuthAccount<auth(Storage) &Account>(address).storage
        .borrow<&{NonFungibleToken.CollectionPublic}>(from: /storage/${storagePath})
    if collection == nil {
    	log("Collection not found")
      return false
    }

    return statedType == expectedType && collection!.getIDs().contains(nftId)
}`
