import React, { useEffect, useState } from "react"
import Fireworks from "react-canvas-confetti/dist/presets/fireworks"
export const ConfettiAnimation = ({ duration = 5000 }) => {
	// default duration of 5000 milliseconds
	const [isActive, setIsActive] = useState(true)

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsActive(false)
		}, duration)

		return () => clearTimeout(timer)
	}, [duration])

	return (
		<>
			{isActive && (
				<Fireworks
					width={1000}
					style={{ height: "100vh", maxWidth: "1000px", position: "fixed" }}
					autorun={{ speed: 1 }}
				/>
			)}
		</>
	)
}
