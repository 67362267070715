import numeral from "numeral"
import { InfiniteLogos } from "./InfiniteLogos/InfiniteLogos"
import { cn } from "../../utils/cn"

export interface FlipSideDataResponse {
	"Total Transactions": number
	"Total Sales Volume": number
}
interface WhyFlowtyProps {
	topSalesData: FlipSideDataResponse | null
	isLoadingTopSalesData: boolean
}

export const WhyFlowty: React.FC<WhyFlowtyProps> = ({
	topSalesData,
	isLoadingTopSalesData,
}: WhyFlowtyProps) => {
	const Flowtyfeatures = [
		{
			description: "Save money by selling on Flowty",
			icon: "2%",
			style: "text-[28px] md:text-[52px] lg:text-[58px]",
			title: "Seller Fees",
		},
		{
			description: "Find liquidity by listing your NFTs for any price",
			icon: "<$1",
			style: "text-[26px] md:text-[46px] lg:text-[52px]",
			title: "List NFTs for <$1",
		},
		{
			description: "Save time by listing multiple NFTs in one transaction",
			icon: "75",
			style: "text-[28px] md:text-[52px] lg:text-[58px]",
			title: "Bulk List Up To 75 NFTs",
		},
		{
			description:
				"List with confidence using FMV for NBA Top Shot and NFL All Day",
			icon: "FMV",
			style: "text-[18px] md:text-[34px] lg:text-[38px]",
			title: "Fair Market Value",
		},
	]

	return (
		<div className='container py-[40px] md:py-[80px] lg:py-[128px]'>
			<div className='flex flex-col justify-start gap-[20px] md:gap-[60px] lg:gap-[80px]'>
				<div className='text-center'>
					<h4
						className='font-extrabold text-[32px] md:text-[40px] lg:text-[48px] leading-normal text-montserrat'
						style={{
							WebkitBackgroundClip: "text",
							WebkitTextFillColor: "transparent",
							background: "linear-gradient(90deg, #FFF 0%, #AEAEAE 100%)",
							backgroundClip: "text",
						}}
					>
						Why Flowty?
					</h4>
					<p className='text-white font-normal text-[11px] md:text-[18px] lg:text-[24px] leading-normal text-montserrat'>
						Best-in-class marketplace with low fees and useful features
					</p>
				</div>
				<div className='grid lg:grid-cols-2 my-auto mx-auto md:px-[88px] gap-[20px] md:gap-[88px]'>
					<div className='flex flex-col gap-[24px] my-auto'>
						{Flowtyfeatures.map(feature => (
							<div
								key={feature.title}
								className='flex flex-row items-center gap-[32px]'
							>
								<div
									className='flex items-center justify-center p-[12px] md:p-[21px] lg:p-[24px] rounded-[9px] h-[59px] w-[69px] md:h-[108px] md:w-[126px] lg:h-[120px] lg:w-[140px] flex-shrink-0'
									style={{
										backdropFilter: "blur(21.80327796936035px)",
										border: "1.038px solid #343A40",
									}}
								>
									<span
										className={`font-montserrat leading-normal font-bold text-[#F8F9FA] custom-shadow-feature ${feature.style}`}
									>
										{feature.icon}
									</span>
								</div>
								<div className='text-container'>
									<h3 className='title text-[18px] font-semibold'>
										{feature.title}
									</h3>
									<p className='description text-[14px] text-[#a0a0a0]'>
										{feature.description}
									</p>
								</div>
							</div>
						))}
					</div>
					<div className='flex flex-col items-center justify-around xs:w-full lg:w-auto my-[26px] md:my-[0px]'>
						<div className='flex flex-col items-center gap-[26px] md:gap-[32px] xs:w-full lg:w-auto'>
							<div className='text-container'>
								<h3 className='title text-[18px] font-semibold'>
									Total Sales Volume
								</h3>
							</div>
							<div
								className={cn(
									"flex items-center justify-center p-[12px] md:p-[21px] lg:p-[24px] rounded-[9px] flex-shrink-0 w-full",
									{
										"bg-gray-400 animate-pulse": isLoadingTopSalesData,
									}
								)}
								style={{
									backdropFilter: "blur(21.80327796936035px)",
									border: "1.038px solid #343A40",
								}}
							>
								<span
									className={`font-montserrat leading-normal font-bold text-[#F8F9FA] text-[26px] md:text-[46px] lg:text-[52px] custom-shadow-feature`}
									style={{
										opacity: isLoadingTopSalesData ? 0 : 1,
									}}
								>
									{isLoadingTopSalesData
										? numeral(1000000.0).format("0,0")
										: `$${numeral(topSalesData?.["Total Sales Volume"]).format(
												"0,0"
										  )}`}
								</span>
							</div>
							<div className='text-container'>
								<h3 className='title text-[18px] font-semibold'>
									Total Transactions
								</h3>
							</div>
							<div
								className={cn(
									"flex items-center justify-center p-[12px] md:p-[21px] lg:p-[24px] rounded-[9px] flex-shrink-0 w-full",
									{
										"bg-gray-400 animate-pulse": isLoadingTopSalesData,
									}
								)}
								style={{
									backdropFilter: "blur(21.80327796936035px)",
									border: "1.038px solid #343A40",
								}}
							>
								<span
									className={`font-montserrat leading-normal font-bold text-[#F8F9FA] text-[26px] md:text-[46px] lg:text-[52px] custom-shadow-feature`}
									style={{
										opacity: isLoadingTopSalesData ? 0 : 1,
									}}
								>
									{isLoadingTopSalesData
										? numeral(1000000.0).format("0,0")
										: numeral(topSalesData?.["Total Transactions"]).format(
												"0,0"
										  )}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className='2xl:max-w-[1920px] mx-auto'>
					<InfiniteLogos />
				</div>
			</div>
		</div>
	)
}
