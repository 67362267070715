import { TokenMetadata } from "flowty-common"
import { FlowNFTData } from "../../common/CommonTypes"
import { Config } from "../../types"

export const getReturnRentalTxn = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData
): string =>
	config.crescendo
		? // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  returnRentalTxnCrescendo(config, token, nftData)
		: // eslint-disable-next-line @typescript-eslint/no-use-before-define
		  returnRentalTxn(config, token, nftData)

const returnRentalTxn = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import ${token.contractName} from ${token.contractAddress}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import FlowtyRentals from ${config.contractAddresses.Flowty}

import ${nftData.contractName} from ${nftData.contractAddress}

import HybridCustody from ${config.contractAddresses.HybridCustody}

transaction(rentalResourceID: UInt64, marketplaceAddr: Address, nftProviderAddress: Address, nftProviderPathIdentifier: String) {
  let nftProvider: Capability<&{NonFungibleToken.Provider}>
  let marketplace: &FlowtyRentals.FlowtyRentalsMarketplace{FlowtyRentals.FlowtyRentalsMarketplacePublic}
  let rental: &FlowtyRentals.Rental{FlowtyRentals.RentalPublic}

  prepare(acct: AuthAccount) {

    self.marketplace = getAccount(marketplaceAddr)
      .getCapability<&FlowtyRentals.FlowtyRentalsMarketplace{FlowtyRentals.FlowtyRentalsMarketplacePublic}>(
        FlowtyRentals.FlowtyRentalsMarketplacePublicPath
      )!.borrow() ?? panic("Could not find FlowtyRentals marketplace")

    self.rental = self.marketplace.borrowRental(rentalResourceID: rentalResourceID) ?? panic("failed to find rental resource")
    
    let rentalDetails = self.rental.getDetails()
    let nftType = rentalDetails.nftType

    let catalogIdentifiers = NFTCatalog.getCollectionsForType(nftTypeIdentifier: nftType.identifier) ?? panic("not found in catalog")
    var catalogIdentifier = ""
    for k in catalogIdentifiers.keys {
        if catalogIdentifiers[k] == true {
            catalogIdentifier = k
            break
        }
    }

    assert(catalogIdentifier != "", message: "no valid catalog identifier found")

    let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: catalogIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")
    let storageCollectionPath = catalogEntry.collectionData.storagePath

    if nftProviderAddress == acct.address {
      let flowtyNftCollectionProviderPath = /private/${nftData.contractName}${nftData.contractAddress}CollectionProviderForFlowty

      if !acct.getCapability<&{NonFungibleToken.Provider}>(flowtyNftCollectionProviderPath).check() {
        acct.unlink(flowtyNftCollectionProviderPath)
        acct.link<&{NonFungibleToken.Provider}>(flowtyNftCollectionProviderPath, target: storageCollectionPath)
      }
      self.nftProvider = acct.getCapability<&{NonFungibleToken.Provider}>(flowtyNftCollectionProviderPath)!
    } else {
      // signer is the parent account and nftProvider is child Account
      // get the manager resource and borrow proxyAccount
      let manager = acct.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
        ?? panic("manager does not exist")
      let childAcct = manager.borrowAccount(addr: nftProviderAddress) ?? panic("nftProvider account not found")
      let collectionProviderPrivatePath = PrivatePath(identifier: nftProviderPathIdentifier) ?? panic("invalid provider path identifier")
      let receiverCap = childAcct.getCapability(path: collectionProviderPrivatePath, type: Type<&{NonFungibleToken.Provider}>()!) ?? panic("no cap found")
      self.nftProvider = receiverCap as! Capability<&{NonFungibleToken.Provider}>
      assert(self.nftProvider.check(), message: "invalid provider capability")
    }
  }

  execute {
  	let rentalDetails = self.rental.getDetails()
    let ref = self.nftProvider.borrow()!
    let nft <- ref!.withdraw(withdrawID: rentalDetails.nftID)
    self.rental.returnNFT(nft: <-nft)
  }
}`

const returnRentalTxnCrescendo = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import ${token.contractName} from ${token.contractAddress}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import FlowtyRentals from ${config.contractAddresses.Flowty}

import ${nftData.contractName} from ${nftData.contractAddress}

import HybridCustody from ${config.contractAddresses.HybridCustody}

transaction(rentalResourceID: UInt64, marketplaceAddr: Address, nftProviderAddress: Address, nftProviderControllerID: UInt64) {
  let nftProvider: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}>
  let marketplace: &{FlowtyRentals.FlowtyRentalsMarketplacePublic}
  let rental: &{FlowtyRentals.RentalPublic}

  self.marketplace = getAccount(marketplaceAddr)
    .capabilities.get<&{FlowtyRentals.FlowtyRentalsMarketplacePublic}>(
      FlowtyRentals.FlowtyRentalsMarketplacePublicPath
    )!.borrow() ?? panic("Could not find FlowtyRentals marketplace")

  self.rental = self.marketplace.borrowRental(rentalResourceID: rentalResourceID) ?? panic("failed to find rental resource")

  let rentalDetails = self.rental.getDetails()
  let nftType = rentalDetails.nftType

  let catalogIdentifiers = NFTCatalog.getCollectionsForType(nftTypeIdentifier: nftType.identifier) ?? panic("not found in catalog")
  var catalogIdentifier = ""
  for k in catalogIdentifiers.keys {
      if catalogIdentifiers[k] == true {
          catalogIdentifier = k
          break
      }
  }

  assert(catalogIdentifier != "", message: "no valid catalog identifier found")

  let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")
  let storageCollectionPath = catalogEntry.collectionData.storagePath

  prepare(acct: auth(Storage, Capabilities) &Account) {

    if nftProviderAddress == acct.address {
      self.nftProvider = acct.capabilities.storage.issue<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}>(storageCollectionPath)
    } else {
      // signer is the parent account and nftProvider is child Account
      // get the manager resource and borrow proxyAccount
      let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
        ?? panic("manager does not exist")
      let childAcct = manager.borrowAccount(addr: nftProviderAddress) ?? panic("nftProvider account not found")

      let receiverCap = childAcct.getCapability(controllerID: nftProviderControllerID, type: Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}>()) ?? panic("no cap found")
      self.nftProvider = receiverCap as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider}>
      assert(self.nftProvider.check(), message: "invalid provider capability")
    }
  }

  execute {
    let ref = self.nftProvider.borrow()!
    let nft <- ref.withdraw(withdrawID: rentalDetails.nftID)
    self.rental.returnNFT(nft: <-nft)
  }
}`
