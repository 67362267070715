/* eslint-disable @typescript-eslint/no-explicit-any */
import { format } from "date-fns"
import noImage from "../../../../assets/media/image_not_available.svg"
import { formatImageSource } from "../../../../util/nftDataUtil"
import { ActivityTableImage } from "../../../Shared/ActivityTableFallbackImage/ActivityTableImage"

export const userRentalActivityTableFields = [
	{
		customRender: ({ item }: any) => {
			const eventType = item.type
				? item.type.split("_").pop().toLowerCase()
				: ""
			return (
				<div className='flex flex-row justify-start items-center capitalize'>
					{eventType === "destroyed" ? "delisted" : eventType}
				</div>
			)
		},
		name: "type",
		sortable: false,
		title: "Event",
	},

	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				{item.activityType}
			</div>
		),
		name: "data.flowtyStorefrontAddress",
		sortable: false,
		title: "Role",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				{item.blockTimestamp
					? format(item.blockTimestamp.toDate(), "yyyy-MM-dd HH:mm")
					: ""}
			</div>
		),
		name: "blockTimestamp",
		sortable: true,
		title: "Date",
	},
	{
		customRender: ({ item }: any) => (
			<div>
				{!!item.nftInfo?.nft && (
					<div className='flex flex-row justify-start items-center'>
						<div className='w-[40px] mr-2'>
							<ActivityTableImage
								collectionAddress={item?.nftInfo?.card?.collectionAddress || ""}
								collectionName={item?.nftInfo?.card?.collectionName || ""}
								srcImg={formatImageSource(
									item?.nftInfo?.card?.images?.[0]?.url ?? noImage
								)}
							/>
						</div>
						<div className='flex flex-col'>
							{item.nftInfo?.card?.additionalDetails ? (
								<span className='text-[12px] text-primary font-montserrat font-extrabold truncate'>
									{item.nftInfo?.card?.title}
								</span>
							) : (
								<>
									<span className='text-[12px] text-primary font-montserrat font-extrabold truncate'>
										{item.nftInfo?.card?.title}
									</span>
									{item.nftInfo?.card?.num &&
										!item.nftInfo?.card?.title.includes("#") && (
											<span className='text-[12px] text-primary font-montserrat font-extrabold ml-2'>
												#{item.nftInfo?.card?.num}
											</span>
										)}
								</>
							)}
							{item?.nftInfo?.card?.additionalDetails ? (
								<>
									{item?.nftInfo?.card?.additionalDetails ? (
										item?.nftInfo?.card?.additionalDetails?.map(
											(detail: any) => (
												<span
													key={detail}
													className='font-montserrat text-primary text-[12px] font-light'
												>
													{detail}
												</span>
											)
										)
									) : (
										<div className='flex flex-row items-center justify-start mt-2 h-4'></div>
									)}
								</>
							) : (
								<>
									{item?.nftInfo?.card?.headerTraits &&
										item?.nftInfo?.card?.headerTraits?.map((trait: any) => (
											<span
												key={trait.displayType}
												className='font-montserrat text-primary text-[12px] font-light'
											>
												{trait.value}
											</span>
										))}
								</>
							)}
						</div>
					</div>
				)}
			</div>
		),
		name: "detail",
		sortable: false,
		title: "Details",
	},
]
