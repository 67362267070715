import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/solid"
import { Fragment } from "react"

interface WrappedHtmlModalProps {
	isOpen: boolean
	onClose: () => void
	ipfsLink: string
}

export const WrappedHtmlModal: React.FunctionComponent<
	WrappedHtmlModalProps
> = ({ isOpen, onClose, ipfsLink }) => {
	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[60]' onClose={onClose}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className='fixed inset-0 bg-black bg-opacity-75' />
				</Transition.Child>
				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-0'
							leaveFrom='opacity-0 scale-100'
							leaveTo='opacity-0 scale-95'
						>
							<Dialog.Panel className=' transform rounded-xl bg-[#212b36]   text-left align-middle shadow-xl transition-all w-[26rem] md:w-[45rem] lg:w-[65rem]'>
								<Dialog.Title
									as='div'
									className='p-4 border-b border-[#495057] px-10 w-full flex items-center justify-between'
								>
									<div></div>
									<XIcon
										onClick={onClose}
										className='w-8 h-8 cursor-pointer fill-white'
									/>
								</Dialog.Title>
								<div className={`flex w-full h-[85vh]`}>
									<iframe
										title='FlowtyWrapped'
										src={`https://flowty.quicknode-ipfs.com/ipfs/${ipfsLink.slice(
											7
										)}`}
										className='w-full h-full'
									/>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}
