import { Dispatch, SetStateAction } from "react"
import { Order } from "flowty-common"
import Swal from "sweetalert2"
import { Flowty } from "flowty-sdk"
import { ListingState } from "../contexts/FlowtyListingModalContext/types/ListingModalContextTypes"

const IS_STORYBOOK = process.env.IS_STORYBOOK

interface DelistingFnBuilderProps {
	listingType: string
	listingResourceID: string
	order: Order | null
	txAvailableCallback: (txId: string | null) => void
	setIsLoading: Dispatch<SetStateAction<ListingState>>
	setError: Dispatch<SetStateAction<ListingState>>
	setIsSealed: Dispatch<SetStateAction<ListingState>>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mixPanelFn: (event: string, data: any) => void
	flowty: Flowty
}

export const delistingFnBuilder = async ({
	listingType,
	order,
	listingResourceID,
	txAvailableCallback,
	setIsLoading,
	setError,
	setIsSealed,
	mixPanelFn,
	flowty,
}: DelistingFnBuilderProps): Promise<undefined> => {
	setIsLoading((prevState: ListingState) => ({
		...prevState,
		delist: true,
	}))
	setError((prevState: ListingState) => ({
		...prevState,
		delist: false,
	}))
	setIsSealed((prevState: ListingState) => ({
		...prevState,
		delist: false,
	}))
	if (IS_STORYBOOK) {
		setTimeout(() => {
			setIsLoading((prevState: ListingState) => ({
				...prevState,
				delist: false,
			}))
			if (listingResourceID === "ERROR_TYPE_MOCK") {
				setError((prevState: ListingState) => ({
					...prevState,
					transfer: true,
				}))
				return
			} else {
				setIsSealed((prevState: ListingState) => ({
					...prevState,
					delist: true,
				}))
			}
		}, 3000)
		return
	}

	switch (listingType) {
		case "storefront":
			try {
				const response = await flowty.removeStorefrontListing({
					listingResourceID: listingResourceID,
					listingType: order?.type || "",
					txAvailableCallback: txAvailableCallback,
				})
				if (response?.status === 4) {
					setIsSealed((prevState: ListingState) => ({
						...prevState,
						delist: true,
					}))
					setIsLoading((prevState: ListingState) => ({
						...prevState,
						delist: false,
					}))
					mixPanelFn("Successful delist storefront transaction", {
						listingData: order,
						token: order?.paymentTokenName,
					})
					return
				}
				if (
					(response?.status !== 4 && response?.toString()?.includes("Error")) ||
					response?.toString()?.includes("Declined")
				) {
					throw new Error(response)
				}
			} catch (e) {
				setError((prevState: ListingState) => ({
					...prevState,
					delist: true,
				}))
				setIsLoading((prevState: ListingState) => ({
					...prevState,
					delist: false,
				}))
				if (e?.toString()?.includes("Popup failed to open")) {
					await Swal.fire({
						background: "#031021",
						color: "#FFFFFF",
						confirmButtonColor: "#FF6969",
						icon: "error",
						showConfirmButton: true,
						text: "Pop-up blocker is enabled. Please allow pop-ups and try again.",
						timer: 5000,
					})
				}
				mixPanelFn("Error delist storefront transaction", {
					err: e,
					listingData: order,
					token: order?.paymentTokenName,
				})
			}
			break
		case "loan":
			try {
				const response = await flowty.removeLoanListing({
					listingResourceID: listingResourceID,
					txAvailableCallback: txAvailableCallback,
				})
				if (response?.status === 4) {
					setIsSealed((prevState: ListingState) => ({
						...prevState,
						delist: true,
					}))
					setIsLoading((prevState: ListingState) => ({
						...prevState,
						delist: false,
					}))
					mixPanelFn(`Successful delist loan transaction`, {
						listingData: order,
						token: order?.paymentTokenName,
					})
					return
				}
				if (
					(response?.status !== 4 && response?.toString()?.includes("Error")) ||
					response?.toString()?.includes("Declined")
				) {
					throw new Error(response)
				}
			} catch (e) {
				setError((prevState: ListingState) => ({
					...prevState,
					delist: true,
				}))
				setIsLoading((prevState: ListingState) => ({
					...prevState,
					delist: false,
				}))
				if (e?.toString()?.includes("Popup failed to open")) {
					await Swal.fire({
						background: "#031021",
						color: "#FFFFFF",
						confirmButtonColor: "#FF6969",
						icon: "error",
						showConfirmButton: true,
						text: "Pop-up blocker is enabled. Please allow pop-ups and try again.",
						timer: 5000,
					})
				}
				mixPanelFn(`Error delist loan transaction`, {
					err: e,
					listingData: order,
					token: order?.paymentTokenName,
				})
			}
			break
		case "rental":
			try {
				const response = await flowty.removeRentListing({
					listingResourceID: listingResourceID,
					txAvailableCallback: txAvailableCallback,
				})
				if (response?.status === 4) {
					setIsSealed((prevState: ListingState) => ({
						...prevState,
						delist: true,
					}))
					setIsLoading((prevState: ListingState) => ({
						...prevState,
						delist: false,
					}))
					mixPanelFn("Successful delist rental transaction", {
						listingData: order,
						token: order?.paymentTokenName,
					})
					return
				}
				if (
					(response?.status !== 4 && response?.toString()?.includes("Error")) ||
					response?.toString()?.includes("Declined")
				) {
					throw new Error(response)
				}
			} catch (e) {
				setError((prevState: ListingState) => ({
					...prevState,
					delist: true,
				}))
				setIsLoading((prevState: ListingState) => ({
					...prevState,
					delist: false,
				}))
				if (e?.toString()?.includes("Popup failed to open")) {
					await Swal.fire({
						background: "#031021",
						color: "#FFFFFF",
						confirmButtonColor: "#FF6969",
						icon: "error",
						showConfirmButton: true,
						text: "Pop-up blocker is enabled. Please allow pop-ups and try again.",
						timer: 5000,
					})
				}
				mixPanelFn("Error delist rental transaction", {
					err: e,
					listingData: order,
					token: order?.paymentTokenName,
				})
			}
			break
		default:
			return
	}
}
