import React from "react"
import { UserActionsControllers } from "./UserActionsNftListControllers/UserActionsNftListControllers"

export const UserActions: React.FunctionComponent = () => {
	return (
		<div className='flex flex-col w-full'>
			<UserActionsControllers />
		</div>
	)
}
