import { OpensearchFlowNFT } from "flowty-common"
import { formatImageSource } from "../../../../util/nftDataUtil"
import { Tooltip } from "react-tooltip"
import { AccountSummaries } from "flowty-sdk"
import React, { useMemo } from "react"

interface WalletImageComponentProps {
	nft: OpensearchFlowNFT
	accountSummaries: AccountSummaries | undefined
	publicAccountView: {
		addr: string | undefined | null
		avatar: string | undefined | null
		userName: string | undefined | null
	}
}

export const WalletImageComponent: React.FunctionComponent<
	WalletImageComponentProps
> = ({ accountSummaries, nft, publicAccountView }) => {
	const walletName = useMemo(() => {
		if (location.pathname.includes("/profile")) {
			return (
				accountSummaries?.[nft.owner]?.display?.name ||
				accountSummaries?.[nft.owner]?.address
			)
		} else {
			const nftDisplayExists = accountSummaries?.[nft.owner]
			if (nftDisplayExists) {
				return (
					accountSummaries?.[nft.owner]?.display?.name ||
					accountSummaries?.[nft.owner]?.address
				)
			} else {
				return publicAccountView?.userName || publicAccountView?.addr
			}
		}
	}, [accountSummaries, nft.owner])

	const walletImg = useMemo(() => {
		if (location.pathname.includes("/profile")) {
			return accountSummaries?.[nft.owner]?.display?.thumbnail
		} else {
			const nftDisplayExists = accountSummaries?.[nft.owner]
			if (nftDisplayExists) {
				return accountSummaries?.[nft.owner]?.display?.thumbnail
			} else {
				return publicAccountView?.avatar
			}
		}
	}, [accountSummaries, nft.owner])

	return (
		<>
			<Tooltip
				id={`${nft.uuid}-nft-origin-tooltip`}
				style={{
					backgroundColor: "white",
					color: "#031021",
					fontSize: "0.75rem",
				}}
			/>
			<img
				data-tooltip-id={`${nft.uuid}-nft-origin-tooltip`}
				data-tooltip-content={`From ${walletName} account`}
				data-tooltip-place='right'
				className='rounded-full h-5 w-5 aspect-square'
				src={formatImageSource(walletImg || "")}
				alt='Account owner icon'
			/>
		</>
	)
}
