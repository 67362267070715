import {
	nftTypeAndIdToLocationData,
	NotificationType,
	InitiatedTransactionNotification,
} from "flowty-common"
import { useEffect } from "react"
import { FlowtyBadge } from "../../../FlowtyBadge"
import fallback from "../../../assets/fallBack.png"
import { ReactComponent as Pulse } from "../assets/pulse.svg"
import { getDescriptiveText } from "../util/getDescriptiveText"
import { calculateTimeAgo } from "../util/getTimeAgo"

export interface TransactionNotificationItemProps
	extends InitiatedTransactionNotification {
	queueMessage?: ({
		type,
		id,
	}: {
		type: NotificationType.Transaction
		id: string
	}) => void
}

export const TransactionNotificationItem: React.FunctionComponent<
	TransactionNotificationItemProps
> = ({
	amount,
	transactionID,
	listingType,
	imageUrl,
	status,
	timestamp,
	title,
	transferAddress,
	id,
	token,
	collectionAddress,
	collectionName,
	nftId,
	nftType,
	queueMessage,
}) => {
	const message = getDescriptiveText({
		amount: amount ?? 0,
		listingType,
		title,
		token: token ?? "",
		transferAddress,
	})

	const timeAgo = calculateTimeAgo(timestamp)

	const {
		contract: { address, name },
		resourceName,
		nftID,
	} = nftTypeAndIdToLocationData(nftType ?? "", nftId ?? "")

	const isLinkAvailable = address && name && resourceName && nftID

	useEffect(() => {
		if (queueMessage) {
			if (status === "PROCESSING") {
				queueMessage({
					id: id?.toString() ?? "",
					type: NotificationType.Transaction,
				})
			}
		}
	}, [id, queueMessage, status])

	return (
		<a
			href={
				isLinkAvailable
					? `/asset/${address}/${name}/${resourceName}/${nftID}`
					: undefined
			}
			className={`inline-flex items-start gap-[16px] ${
				!isLinkAvailable ? "pointer-events-none" : ""
			}`}
			data-testid={`notification-item-${transactionID}`}
		>
			<div className='hidden md:block md:relative'>
				<img
					src={imageUrl ?? fallback}
					className='w-[48px] h-[48px] rounded-[8px]'
					alt='Notification Icon'
				/>
				<div className='absolute -top-1 -right-1'>
					{status === "PROCESSING" ? <Pulse className='animate-pulse' /> : null}
				</div>
			</div>
			<div className='flex flex-col gap-[8px] items-start flex-1'>
				<div className='flex flex-row items-center flex-wrap'>{message}</div>
				<div className='flex gap-[8px]'>
					<FlowtyBadge text={status ?? ""} status={status} />
					<span className='text-neutral-500 text-[12px] font-[400]'>
						{timeAgo}
					</span>
				</div>
			</div>
		</a>
	)
}
