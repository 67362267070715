import Swal from "sweetalert2"
import { DiscordIcon } from "./DiscordIcon"
import { ShareIcon } from "./ShareIcon"
import { TwitterIcon } from "./TwitterIcon"
import { WebsiteIcon } from "./WebsiteIcon"

interface SocialLinksProps {
	websiteLink: string | undefined
	twitter: string | undefined
	discord: string | undefined
	flexEnd?: boolean
}

export const SocialLinks: React.FunctionComponent<SocialLinksProps> = ({
	websiteLink,
	twitter,
	discord,
	flexEnd,
}) => {
	const handleCopyClick = (): void => {
		;(navigator as Navigator).clipboard.writeText(window.location.href)
		Swal.fire({
			icon: "success",
			showConfirmButton: false,
			timer: 1500,
			title: "Link Copied",
		})
	}

	return (
		<div className={`flex gap-2 ${flexEnd && "justify-end"}`}>
			{websiteLink && <WebsiteIcon link={websiteLink} />}
			{twitter && <TwitterIcon link={twitter} />}
			{discord && <DiscordIcon link={discord} />}
			<ShareIcon
				shareFn={() => {
					handleCopyClick()
				}}
			/>
		</div>
	)
}
