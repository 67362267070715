import {
	OfferCreated,
	OpensearchStorefrontAvailableData,
	StorefrontV2ListingAvailable,
} from "flowty-common"
import React from "react"
import { ReactComponent as Triangle } from "../../../../assets/media/Triangle.svg"
import {
	getFMVDisplayLabelFromSource,
	getFMVHrefFromSource,
	getSourceFromSourceList,
} from "../../../../util/fmv"
import { truncateCurrencyAmount } from "../../../../util/truncateValueUtil"
import { StorefrontFormValues } from "../../../Modals/Listings/Storefront/form/StorefrontListingForm"
import { TokenAmount } from "../../../Tokens/tokenDisplays"
import { BestStorefrontOffers } from "./BestStorefrontOffers"
import { NftOrderLoadingDisplay } from "./NftOrderLoadingDisplay"
import { FlowtyButton } from "ds-flowty"

interface StorefrontOrderDisplayProps {
	dapperOffer?: OfferCreated | StorefrontFormValues | null
	nonCustodialOffer?: OfferCreated | StorefrontFormValues | null
	order: OpensearchStorefrontAvailableData | StorefrontV2ListingAvailable
	isLoading?: boolean
}

export const StorefrontOrderDisplay: React.FC<StorefrontOrderDisplayProps> = ({
	dapperOffer,
	isLoading,
	nonCustodialOffer,
	order,
}) => {
	const hasValuation = !!order?.valuations?.blended?.usdValue

	const valuationSource = getSourceFromSourceList(
		order?.valuations?.blended?.source || ""
	)

	return (
		<div>
			{isLoading ? (
				<NftOrderLoadingDisplay />
			) : (
				<>
					<div className='flex flex-row justify-between mt-1 items-center border-gradient mb-3'>
						<p className='text-[#AAAAAA] text-base font-montserrat mb-2'>
							Purchase Price
						</p>
						<span className='text-bold mb-2'>
							{order?.salePrice ? (
								<TokenAmount
									amount={order?.salePrice || 0}
									isSmall
									token={order?.paymentTokenName}
								/>
							) : (
								"-"
							)}
						</span>
					</div>
					<div className='w-full flex justify-between'>
						{hasValuation ? (
							<div className='flex flex-col justify-start items-start h-24 relative group'>
								{order.valuations?.blended?.usdValue && (
									<div className='text-[#AAAAAA] text-base font-montserrat'>
										3rd Party FMV
									</div>
								)}
								<div className='self-center text-end py-1 w-full'>
									<span className='text-bold text-base font-montserrat w-full flex justify-start'>
										{order.valuations?.blended?.usdValue
											? `$${truncateCurrencyAmount(
													order.valuations?.blended?.usdValue
											  )}`
											: "-"}
									</span>
								</div>
								<div
									onClick={e => {
										e.stopPropagation()
									}}
									className='absolute hidden group-hover:block'
								>
									<div className='relative right-0 -top-[9rem] bg-[#373D3F] rounded-md min-w-[18rem] z-10 cursor-default flex flex-col'>
										<div className='p-3'>
											<p className='font-bold text-base mb-2'>
												Fair Market Value (FMV)
											</p>
											<p className='text-sm'>
												An estimate of an NFT&apos;s value based on{" "}
												<FlowtyButton
													variant='tertiary'
													onClick={() => {
														window.open(
															getFMVHrefFromSource(valuationSource),
															"_blank"
														)
													}}
													text={getFMVDisplayLabelFromSource(valuationSource)}
												/>
												. FMV is an estimate and is in no way meant to construe
												financial advice.
											</p>
										</div>
										<Triangle className='fill-[#373D3F]  absolute h-6 w-6 -bottom-4 left-[13%] rotate-180' />
									</div>
								</div>
							</div>
						) : (
							<div className='h-24' />
						)}

						<BestStorefrontOffers
							dapperOffer={dapperOffer}
							nonCustodialOffer={nonCustodialOffer}
						/>
					</div>
				</>
			)}
		</div>
	)
}
