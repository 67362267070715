/* eslint-disable @typescript-eslint/no-explicit-any */
import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"
import axios from "axios"
import { sendTx } from "./FlowUtil"

import { Log } from "../../util/Log"
import {
	apiURL,
	HYBRID_CUSTODY_ADDRESS,
	NFT_CONTRACT_ADDRESS,
} from "../../util/settings"

export interface WalletBalance {
	address: string
	balances?: { [key: string]: number }
}

export const setChildAccountDisplay = async (
	childAddress: string,
	name: string,
	desc: string,
	thumbnail: string
): Promise<any> => {
	const txArguments = [
		fcl.arg(childAddress, t.Address),
		fcl.arg(name, t.String),
		fcl.arg(desc, t.String),
		fcl.arg(thumbnail, t.String),
	]
	return sendTx({
		args: txArguments,
		transactionCdcScript: setChildAccountDisplayTx,
	})
}

const setChildAccountDisplayTx = `import HybridCustody from ${HYBRID_CUSTODY_ADDRESS}
import MetadataViews from ${NFT_CONTRACT_ADDRESS}

transaction(childAddress: Address, name: String, description: String, thumbnail: String) {
	prepare(acct: AuthAccount) {
			let m = acct.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
					?? panic("manager not found")
			
			let d = MetadataViews.Display(
					name: name,
					description: description,
					thumbnail: MetadataViews.HTTPFile(url: thumbnail)
			)

			m.setChildAccountDisplay(address: childAddress, d)
	}
}`

export const ingestAccount = async (address: string): Promise<any> =>
	axios.get(`${apiURL}/ingestAccount`, {
		params: { address },
	})

export const verifyAddress = async (address: string): Promise<boolean> => {
	let isVerified = false
	try {
		const account = await fcl.account(address)
		if (account.address) isVerified = true
	} catch (error) {
		Log(`Couldn't find account [${address}] for private listing`)
	}
	return isVerified
}
