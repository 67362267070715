import { Config } from "../types"

export const getIsDapperCollectionScript = (config: Config): string => {
	if (config.crescendo) {
		return ""
	}

	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	return _isDapperCollectionScript(config)
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const _isDapperCollectionScript = (config: Config): string => `
import DapperWalletCollections from ${config.contractAddresses.DapperWalletCollections}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}

pub fun main(contractAddress: Address, contractName: String): Bool {
	let contractAcct = getAccount(contractAddress)
	let tmp = contractAcct.contracts.borrow<&NonFungibleToken>(name: contractName)
	if tmp == nil {
		return false
  }

  let c = tmp!
  return DapperWalletCollections.containsType(c.getType())
}
`
