import { FlowtyFooter, FlowtySearch } from "ds-flowty"
import React, { useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { ScrollToLoadMsg } from "../../components/Loaders/ScrollToLoadMsg"
import { PageHeader } from "../../components/PageHeader"
import { useSearchCollections } from "../../hooks/useSearchCollections"
import { subscribeEmailToNotification } from "../../services/EmailService"
import Layout from "../Layout"
import { CollectionCard } from "./components/CollectionCard"
import { useCollectionListPage } from "./hooks/useCollectionListPage"
import { Divider } from "ds-flowty/src/Shared/Atoms/Divider"

export const CollectionListPage: React.FunctionComponent = () => {
	const { collections, endOfData, isLoading, onScrollRef } =
		useCollectionListPage({
			count: 21,
		})

	const {
		search,
		setSearch,
		collections: collectionsResults,
	} = useSearchCollections("")
	const metaTitle =
		"Flowty NFT Collections: From NBA TopShot, UFC Moments, NFL All Day to Jolly Jokers, Flovatar and more!"
	const metaDescription =
		"Flowty is an open, full-featured NFT marketplace for collectors, traders, investors and creators on the Flow blockchain. Flowty's transaction capabilities range from buying and selling to NFT-backed loans and NFT rentals."
	const metaImage = "https://www.flowty.io/icons/icon192.png"

	const loadingCards = useMemo(() => {
		return window.innerWidth >= 1280
			? [0, 1, 2, 3, 4, 5, 6, 7]
			: window.innerWidth >= 768
			? [0, 1, 2]
			: [0, 1]
	}, [window.innerWidth])

	const navigate = useNavigate()

	useEffect(() => {
		const clearQueries = () => {
			/// we can remove this in the future but it's just to handle redirects from the marketplace that contain query params
			navigate(window.location.pathname, { replace: true })
		}

		clearQueries()
	}, [navigate])

	const popularSearches = collections.slice(0, 5)

	const hits = collectionsResults?.contracts ?? []

	return (
		<Layout>
			<PageHeader
				metaTitle={metaTitle}
				metaDescription={metaDescription}
				metaImage={metaImage}
			/>
			<div className='mt-4 container'>
				<div className='w-full flex flex-col items-center justify-center gap-[16px] lg:gap-0 lg:flex-row md:justify-between'>
					<p
						className='text-2xl uppercase font-black max-sm:text-center self-start'
						data-testid='collection-list-header'
					>
						Collections
					</p>
					<FlowtySearch
						onBlur={() => setSearch("")}
						popularSearches={popularSearches}
						onChange={setSearch}
						hits={hits}
						value={search}
						placeholder={"Search NFT Collections"}
						debounceTime={300}
						labelText={"Search NFT Collections"}
					/>
				</div>

				{collections.length > 0 && (
					<>
						<div className='mt-5 grid grid-cols-2  smMobile:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-auto 2xl:grid-cols-5 3xl:grid-cols-7 gap-[12px] max-sm:justify-center'>
							{collections?.map((collection, index) => {
								return (
									<CollectionCard
										collection={collection}
										key={`${collection.address}-${collection.name}-${index}`}
									/>
								)
							})}
							{isLoading && (
								<>
									{loadingCards.map((_, index) => {
										return <CollectionCard key={index} isLoading={true} />
									})}
								</>
							)}
						</div>
						{!isLoading && !endOfData && (
							<div className='w-full flex justify-center mt-2'>
								<ScrollToLoadMsg />
							</div>
						)}
					</>
				)}

				{!endOfData ? (
					<>
						{isLoading ? (
							collections?.length === 0 && (
								<>
									<div className='w-full h-auto mt-12 hidden xl:grid xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-7 gap-[12px] auto-rows-1fr'>
										{Array.from({ length: 21 }).map((_, index) => {
											return <CollectionCard key={index} isLoading={true} />
										})}
									</div>
									<div className='w-full h-auto mt-12 grid max-md:hidden md:grid-cols-3 lg:grid-cols-4 xl:hidden gap-[10px]'>
										{[0, 1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => {
											return <CollectionCard key={index} isLoading={true} />
										})}
									</div>
									<div className='w-full h-auto mt-12 grid max-smMobile:grid-cols-1 max-md:grid-cols-2 md:hidden gap-[10px] max-sm:auto-cols-min justify-center'>
										{[0, 1, 2, 4, 5, 6].map((_, index) => {
											return <CollectionCard key={index} isLoading={true} />
										})}
									</div>
								</>
							)
						) : (
							<>
								{collections?.length > 0 && (
									<div ref={onScrollRef} className='mt-[8rem]' />
								)}
								<p className='justify-center items-center flex flex-row animate-pulse color-[#D9D9D9] text-sm font-semibold'>
									Loading More
								</p>
							</>
						)}
					</>
				) : (
					<p className='justify-center items-center flex flex-row mt-16 animate-pulse color-[#D9D9D9] text-sm font-semibold'>
						Nothing else to see here!
					</p>
				)}
			</div>
			<>
				{endOfData && (
					<>
						<Divider />
						<FlowtyFooter
							subscribeEmailToNotification={subscribeEmailToNotification}
						/>
					</>
				)}
			</>
		</Layout>
	)
}
