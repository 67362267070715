import { Flowty } from "flowty-sdk"
import {
	PropsWithChildren,
	createContext,
	useContext,
	useMemo,
	useState,
} from "react"
import { DropMintFnBuilder } from "../utils/DropMintModalFnBuilder"
import {
	DropStateType,
	FlowtyDropModalFormType,
} from "../types/FlowtyDropModalTypes"

interface NavPropsType {
	text: string
	onClick: () => void
	disabled?: boolean
	bgColor: "primary" | "danger" | "white"
}

type NavProps = NavPropsType | NavPropsType[] | undefined

interface FlowtyDropModalContextValues {
	error: {
		drop: boolean
	}
	isFormError: {
		drop: boolean
	}
	isLoading: {
		drop: boolean
	}
	onCloseModal: () => void
	resetModal: () => void
	transactionExplorerLink: string | null
	sealed: {
		drop: boolean
	}
	submitTransaction: () => void
	modalNavbarProps: NavProps
	contractName: string
	showNoFundsMessage?: boolean
	setShowNoFundsMessage?: (value: boolean) => void
}

const defaultContext: FlowtyDropModalContextValues = {
	contractName: "",
	error: {
		drop: false,
	},
	isFormError: {
		drop: false,
	},
	isLoading: {
		drop: false,
	},
	modalNavbarProps: undefined,
	onCloseModal: () => {},
	resetModal: () => {},
	sealed: {
		drop: false,
	},
	submitTransaction: () => {},
	transactionExplorerLink: "",
}

export const FlowtyDropModalContext =
	createContext<FlowtyDropModalContextValues>(defaultContext)

interface FlowtyDropModalContextProps extends PropsWithChildren {
	onClose: () => void
	resetForm: () => void
	values: FlowtyDropModalFormType
	contractAddress: string
	contractName: string
	dropID: string
	phasePrice: number
	mintCount: number
	nftIdentifier: string
	flowty: Flowty
	updateMintCount?: (value: number) => void
	showNoFundsMessage?: boolean
	setShowNoFundsMessage?: (value: boolean) => void
}

export const FlowtyDropModalContextProvider: React.FC<
	FlowtyDropModalContextProps
> = ({
	children,
	onClose,
	resetForm,
	updateMintCount,
	showNoFundsMessage,
	setShowNoFundsMessage,
	values,
	contractAddress,
	contractName,
	dropID,
	phasePrice,
	mintCount,
	nftIdentifier,
	flowty,
}) => {
	const [transactionID, setTransactionID] = useState<string | null>(null)
	const [error, setError] = useState<DropStateType>({
		drop: false,
	})

	const [isLoading, setIsLoading] = useState<DropStateType>({
		drop: false,
	})

	const [sealed, setSealed] = useState<DropStateType>({
		drop: false,
	})

	const [isFormError, setIsFormError] = useState<DropStateType>({
		drop: false,
	})

	const transactionExplorerLink = useMemo(() => {
		if (!transactionID) return null
		const flowdiverBaseURL =
			flowty.config.network === "mainnet"
				? "https://flowdiver.io"
				: "https://testnet.flowdiver.io"
		return `${flowdiverBaseURL}/${transactionID}`
	}, [transactionID])

	const setFormError = (): void => {
		setIsFormError((prev: DropStateType) => ({ ...prev, ["drop"]: true }))
	}

	const submitTransaction = async (): Promise<void> => {
		if (mintCount < 1) {
			setFormError()
			return
		}
		DropMintFnBuilder({
			commissionAddress: "0xb051bdaddb672a33",
			contractAddress,
			contractName,
			dropID,
			dropPhaseIndex: 0,
			flowty,
			mixPanelFn: () => {},
			nftIdentifier,
			numToMint: mintCount,
			paymentIdentifier: "A.7e60df042a9c0868.FlowToken.Vault",
			paymentReceiverPath: "flowTokenReceiver",
			paymentStoragePath: "flowTokenVault",
			setError,
			setIsFormError,
			setIsLoading,
			setIsSealed: setSealed,
			totalCost: mintCount * phasePrice,
			txAvailableCallback: (txID: string) => {
				setTransactionID(txID)
			},
		})
	}

	const FilteredProfilePageUrl = useMemo(() => {
		const pageOrigin = window.location.origin
		const baseUrl = `${pageOrigin}/user/profile?collectionFilters=`
		const key = `${contractAddress}.${contractName}`
		const filterObject = { [key]: {} }

		const encodedFilters = encodeURIComponent(JSON.stringify(filterObject))
		const completeUrl = baseUrl + encodedFilters
		return completeUrl
	}, [contractAddress])

	const redirectToFilteredProfilePage = (): void => {
		window.open(FilteredProfilePageUrl, "_blank")
	}

	const onCloseModal = (): void => {
		if (!isLoading.drop) {
			setError({
				drop: false,
			})

			setIsFormError({
				drop: false,
			})
			setSealed({
				drop: false,
			})
		}
		resetForm()
		onClose()
	}

	const resetModal = (): void => {
		setError({
			drop: false,
		})

		setIsFormError({
			drop: false,
		})

		setIsLoading({
			drop: false,
		})

		setSealed({
			drop: false,
		})
		setTransactionID(null)
	}

	const resetFormAndModal = (): void => {
		if (updateMintCount) {
			updateMintCount(1)
		}
		resetModal()
		resetForm()
	}

	const modalNavbarProps: NavProps = useMemo(() => {
		if (!error.drop && !isLoading.drop && !sealed.drop) {
			return {
				bgColor: "primary",
				disabled: showNoFundsMessage ?? false,
				onClick: submitTransaction,
				text: "Confirm Purchase",
			}
		}
		if (isLoading.drop) {
			return {
				bgColor: "primary",
				disabled: true,
				onClick: () => {},
				text: "Purchasing",
			}
		}
		if (sealed.drop) {
			return [
				{
					bgColor: "white",
					className: "w-[14rem] text-base",
					disabled: false,
					onClick: resetFormAndModal,
					text: "Buy More",
				},
				{
					bgColor: "primary",
					className: "w-[14rem] text-base",
					disabled: false,
					onClick: redirectToFilteredProfilePage,
					text: "View NFT",
				},
			]
		}
		if (error.drop) {
			return [
				{
					bgColor: "white",
					className: "w-[14rem] text-lg",
					disabled: false,
					onClick: resetModal,
					text: "Back",
				},
				{
					bgColor: "primary",
					className: "w-[14rem] text-lg",
					disabled: false,
					onClick: submitTransaction,
					text: "Try Again",
				},
			]
		}
	}, [isLoading, error, sealed, submitTransaction])

	return (
		<FlowtyDropModalContext.Provider
			value={{
				contractName,
				error,
				isFormError,
				isLoading,
				modalNavbarProps,
				onCloseModal,
				resetModal,
				sealed,
				showNoFundsMessage,
				submitTransaction,
				transactionExplorerLink,
			}}
		>
			{children}
		</FlowtyDropModalContext.Provider>
	)
}

export const useFlowtyDropModalContext = () =>
	useContext(FlowtyDropModalContext) as FlowtyDropModalContextValues
