import React, { PropsWithChildren } from "react"
import { PageHeader } from "../components/PageHeader"
import { FlowtyModeSelector } from "../components/Shared/FlowtyModeSelector"
import Layout from "./Layout"

const metaTitle =
	"Flowty NFT Activity: Loans, Rentals and Storefront. View the latest activity from our users"
const metaDescription =
	"Flowty is an open, full-featured NFT marketplace for collectors, traders, investors and creators on the Flow blockchain. Flowty's transaction capabilities range from buying and selling to NFT-backed loans and NFT rentals."
const metaImage = "https://www.flowty.io/icons/icon192.png"

// we want a plain object so it can merge; Record<string, never> doesn't work
// eslint-disable-next-line @typescript-eslint/ban-types
export const ActivityLayout: React.FC<PropsWithChildren<{}>> = ({
	children,
}) => {
	return (
		<Layout>
			<PageHeader
				metaTitle={metaTitle}
				metaDescription={metaDescription}
				metaImage={metaImage}
			/>
			<div className='container bg-darkBg'>
				<FlowtyModeSelector path='activity' />
				{children}
			</div>
		</Layout>
	)
}
