import { FCLTransactionResult } from "flowty-common"
import {
	Err,
	getCatalogEntryForType,
	sendMutation,
	validateListing,
} from "../../utils/Utils"
import { PurchaseStorefrontListing } from "./StorefrontPurchaseTypes"
import { Config } from "../../types"
import { getStorefrontPurchaseTxn } from "./transactions"

const fcl = require("@onflow/fcl")
const t = require("@onflow/types")

export const InvalidListingMessage =
	"Uh oh! This listing is no longer valid. Our system is working on removing it from the marketplace."

export class StorefrontPurchase {
	config: Config

	constructor(config: Config) {
		this.config = config
	}

	purchaseStorefrontListing = async ({
		listing,
		token,
		nftReceiverAddress,
		ftProviderAddress,
		privateFTPath,
		txAvailableCallback,
	}: PurchaseStorefrontListing): Promise<FCLTransactionResult> => {
		const {
			commissionReceivers,
			listingResourceID,
			storefrontAddress,
			type,
			nftType,
			paymentTokenName,
		} = listing

		// Is flowty a valid commission receiver?
		let commissionReceiver = this.config.contractAddresses.NFTStorefrontV2

		if (
			commissionReceivers &&
			!commissionReceivers.includes(commissionReceiver)
		) {
			;[commissionReceiver] = commissionReceivers
		}

		// Validate the listing before we let the user buy it. If the listing isn't valid,
		// bubble up an exception to show them flagging the listing as no longer valid
		const isValid = await validateListing(listing, this.config.apiURL)

		if (!isValid) {
			throw new Error(InvalidListingMessage)
		}

		const catalogIdentifier = await getCatalogEntryForType(nftType, this.config)

		const txContent = getStorefrontPurchaseTxn(
			this.config,
			!!catalogIdentifier,
			type,
			token
		)

		const ftProviderArg = this.config.crescendo
			? fcl.arg(privateFTPath || "0", t.UInt64)
			: fcl.arg(privateFTPath, t.String)

		const txArgs = [
			fcl.arg(storefrontAddress, t.Address),
			fcl.arg(listingResourceID.toString(), t.UInt64),
			fcl.arg(commissionReceiver, t.Address),
		]

		if (catalogIdentifier) {
			txArgs.push(fcl.arg(catalogIdentifier, t.String))
			txArgs.push(fcl.arg(nftReceiverAddress, t.Address))

			if (!["DUC", "FUT"].includes(paymentTokenName)) {
				// Only append Hybrid Custody args if the payment token is not DUC or FUT
				txArgs.push(fcl.arg(ftProviderAddress, t.Address))
				txArgs.push(ftProviderArg)
			}
		} else {
			txArgs.push(fcl.arg(nftReceiverAddress, t.Address))
			txArgs.push(fcl.arg(ftProviderAddress, t.Address))
			txArgs.push(ftProviderArg)
		}

		console.debug("storefront purchase txn", { txArgs, txContent })

		try {
			const res = await sendMutation(txContent, txArgs, txAvailableCallback)
			return res
		} catch (e) {
			console.log("TX ERROR", e)
			Err("createStorefrontListing", e)
			throw e
		}
	}
}
