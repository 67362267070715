import { NotificationData } from "flowty-common"

interface ReturnPastAndTodaysNotifications {
	notificationsFromBeforeToday: NotificationData[]
	notificationsFromToday: NotificationData[]
}

export const returnPastAndTodaysNotifications = (
	notifications: NotificationData[]
): ReturnPastAndTodaysNotifications => {
	const oneDayAgo = Date.now() - 24 * 60 * 60 * 1000
	const notificationsFromToday: NotificationData[] = []
	const notificationsFromBeforeToday: NotificationData[] = []

	notifications.forEach(notification => {
		if (notification.timestamp > oneDayAgo) {
			notificationsFromToday.push(notification)
		} else {
			notificationsFromBeforeToday.push(notification)
		}
	})
	notificationsFromToday.sort((a, b) => b.timestamp - a.timestamp)
	notificationsFromBeforeToday.sort((a, b) => b.timestamp - a.timestamp)

	return { notificationsFromBeforeToday, notificationsFromToday }
}
