import { useFlowtyNumericInput } from "../../../FlowtyNumericInput/useFlowtyNumericInput"

interface NumberStepperProps {
	mintCount: number
	increaseMintCount: () => void
	decreaseMintCount: () => void
	updateMintCount: (value: number) => void
}

export const NumberStepper: React.FunctionComponent<NumberStepperProps> = ({
	mintCount,
	increaseMintCount,
	decreaseMintCount,
	updateMintCount,
}) => {
	useFlowtyNumericInput()
	return (
		<div className='flex flex-col'>
			<div className='flex text-white h-[2.75rem]'>
				<button
					onClick={decreaseMintCount}
					className='rounded-l-md bg-[#606E7D33] border border-[#495057] px-4 py-3 click-effect'
					data-testid='decrease-mint-count'
				>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='21'
						height='20'
						viewBox='0 0 21 20'
						fill='none'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M5.5 10C5.5 9.44772 5.94772 9 6.5 9L14.5 9C15.0523 9 15.5 9.44772 15.5 10C15.5 10.5523 15.0523 11 14.5 11L6.5 11C5.94772 11 5.5 10.5523 5.5 10Z'
							fill='#F8F9FA'
						/>
					</svg>
				</button>
				<div className='bg-[#606E7D33] border-y border-[#495057] w-[90px] text-center'>
					<input
						className='bg-transparent text-white w-full h-full p-0 text-center font-montserrat py-3'
						type='number'
						value={mintCount}
						onChange={e => updateMintCount(+e.target.value)}
						data-testid='mint-count'
						min={1}
					/>
				</div>
				<button
					onClick={increaseMintCount}
					className='rounded-r-md bg-[#606E7D33] border border-[#495057] px-4 py-3 click-effect'
					data-testid='increase-mint-count'
				>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='21'
						height='20'
						viewBox='0 0 21 20'
						fill='none'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M10.5 5C11.0523 5 11.5 5.44772 11.5 6V9L14.5 9C15.0523 9 15.5 9.44772 15.5 10C15.5 10.5523 15.0523 11 14.5 11H11.5V14C11.5 14.5523 11.0523 15 10.5 15C9.94771 15 9.5 14.5523 9.5 14V11H6.5C5.94772 11 5.5 10.5523 5.5 10C5.5 9.44771 5.94772 9 6.5 9L9.5 9V6C9.5 5.44772 9.94771 5 10.5 5Z'
							fill='#F8F9FA'
						/>
					</svg>
				</button>
			</div>
			<div className='text-[#FF6E25] text-[10px] text-end h-[15px]'>
				{mintCount < 1 && "Mint count should be greater than 0"}
			</div>
		</div>
	)
}
