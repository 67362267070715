import React, { useState } from "react"
import { BulkSelectButton } from "../BulkActions/BulkSelectButton"
import { SortMenu } from "./sorting/SortMenu"
import { Tooltip } from "react-tooltip"
import { ingestAccount } from "../../services/flow/AccountService"
import { actions as Mixpanel } from "../../util/Mixpanel"
import { ReactComponent as RefreshArrow } from "../../assets/media/loadingArrow.svg"
import { cn } from "../../util/cn"
import { ReactComponent as MobileFilter } from "./assets/mobile-filter.svg"

interface OpenSearchBarMobileProps {
	onClick: () => void
	address?: string
	isActionsTab?: boolean
}

export const OpenSearchBarMobile: React.FC<OpenSearchBarMobileProps> = ({
	onClick,
	address,
	isActionsTab = false,
}) => {
	const [isRefreshToolTipOpen, setIsRefreshToolTipOpen] = useState(false)
	const [loadingInventory, setLoadingInventory] = useState<boolean>(false)

	const ingestAccountClick = async (): Promise<void> => {
		if (loadingInventory) return

		setLoadingInventory(true)
		Mixpanel.track("Refresh Inventory Click")
		setLoadingInventory(true)
		ingestAccount(address ?? "")
		setTimeout(() => {
			setLoadingInventory(false)
		}, 4000)
	}

	return (
		<div className='w-full sticky top-[74px] z-20 bg-darkBg'>
			<div
				className={`flex flex-col sm:flex-row flex-wrap ${
					!isActionsTab && "justify-between"
				} gap-x-3`}
			>
				<div className='flex flex-wrap items-center md:flex-none pb-[.69rem]'>
					<button
						onClick={onClick}
						className={`flex justify-center h-[2.5rem] w-[5.75rem] gap-2 items-center py-2 px-5 text-base border border-[#ffffff1f] lg:border-none rounded-l-md lg:rounded-md hover:bg-opacity-[15%] bg-[#606e7d33] hover:bg-glassFillGrayHover`}
					>
						<MobileFilter />
					</button>

					{!isActionsTab && (
						<div className='max-w-sm flex-1 md:flex-none'>
							<SortMenu />
						</div>
					)}
				</div>

				<div className='order-4 hidden max-lg:flex gap-3'>
					{!isActionsTab && <BulkSelectButton />}
					{address && (
						<>
							<Tooltip
								id={`profile-refresh-tooltip`}
								style={{
									backdropFilter: "blur(100px)",
									backgroundColor: "#33333380",
									zIndex: 999,
								}}
								isOpen={isRefreshToolTipOpen}
							>
								<p className='text-sm font-normal leading-none text-white'>
									Something look off? Refresh NFT
								</p>
							</Tooltip>
							<div
								data-tooltip-place='top'
								onMouseEnter={() => setIsRefreshToolTipOpen(true)}
								onMouseLeave={() => setIsRefreshToolTipOpen(false)}
								data-tooltip-id={`profile-refresh-tooltip`}
								onClick={ingestAccountClick}
								className='group cursor-pointer bg-[#FFFFFF] backdrop-blur-sm p-2 rounded-md hover:bg-opacity-[15%] bg-opacity-[8%] self-start'
							>
								<RefreshArrow
									className={cn("w-6 h-6", {
										"animate-spin": loadingInventory,
									})}
								/>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	)
}
