import { safeParseJSON } from "flowty-common"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { firestore } from "../../../../firebase"
import { Err } from "../../../../util/Log"
import { actions as Mixpanel } from "../../../../util/Mixpanel"
import {
	OpenSearchContext,
	OpenSearchContextValues,
} from "../../../OpenSearch/OpenSearchConnector"
import { Filter, SortColumn } from "../../../OpenSearch/types"
import { SnackbarMessage } from "../../../Shared/Snackbar/SnackbarAlert"

export interface FilterView {
	id?: string
	config?: string
	name: string
	filterCategory: string
	createdAt: number
}

export interface AddFilterViewProps {
	config: string
	name: string
}

function checkFilterViewIsNew(
	views: FilterView[],
	newView: string
): FilterView | null {
	const existingView = views.filter(view => view.config === newView)

	return existingView[0] || null
}

interface SaveViewProps {
	address?: string | null
	onShowSnackbar?: (args: SnackbarMessage) => void
}

interface SaveViewHookValues {
	addFilterView: (filterView: AddFilterViewProps) => void
	applyPreset: (item: FilterView) => void
	defaultPreset: AddFilterViewProps
	defaultPresetLabel: string
	deleteFilterView: (filterView: FilterView) => void
	filterViews: FilterView[]
	isMenuOpen: boolean
	isSaveModalOpen: boolean
	newPresetTitle: string
	onSaveView: () => void
	query: URLSearchParams
	selectedForDelete: FilterView | null
	selectedValue: FilterView | null
	setNewPresetTitle: (title: string) => void
	setSelectedForDelete: (filterView: FilterView | null) => void
	toggleIsSaveModalOpen: () => void
	toggleMenuOpen: () => void
}

export const useSaveView = ({
	address,
	onShowSnackbar,
}: SaveViewProps): SaveViewHookValues => {
	const { isLoading, setFilters, setOrderFilters, setSerialFilter, setSort } =
		useContext<OpenSearchContextValues>(OpenSearchContext)
	const [filterViews, setFilterViews] = useState<FilterView[]>([])

	const [selectedValue, setSelectedValue] = useState<FilterView | null>(null)
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const [selectedForDelete, setSelectedForDelete] = useState<FilterView | null>(
		null
	)
	const [newPresetTitle, setNewPresetTitle] = useState("")
	const location = useLocation()
	const query = new URLSearchParams(location.search)
	const defaultPresetLabel = "Default View"
	const defaultPreset = {
		config: `orderFilters=%7B%7D&collectionFilters=%7B%7D&sort=%7B%22direction%22%3A%22desc%22%2C%22listingKind%22%3Anull%2C%22path%22%3A%22blockTimestamp%22%7D`,
		name: defaultPresetLabel,
	}

	const hasSelectedPreset = useRef(false)
	const [isSaveModalOpen, setIsSaveModalOpen] = useState(false)

	const toggleMenuOpen = (): void => setIsMenuOpen(prev => !prev)

	const toggleIsSaveModalOpen = (): void => setIsSaveModalOpen(!isSaveModalOpen)

	const applyPreset = (item: FilterView): void => {
		hasSelectedPreset.current = true
		setSelectedValue(item)
		const params = new URLSearchParams(item.config)

		const orderFilters = safeParseJSON(params.get("orderFilters")) || {}
		const sort = safeParseJSON(params.get("sort")) || null
		const traitFilters = safeParseJSON(params.get("collectionFilters")) || {}
		const serialFilter = safeParseJSON(params.get("serialFilter")) || {}
		setOrderFilters(orderFilters)
		setFilters(traitFilters as Filter)
		setSerialFilter(serialFilter)
		setSort(sort as SortColumn | null)
		setIsMenuOpen(false)
		setTimeout(() => (hasSelectedPreset.current = false), 500)
	}

	const onSaveView = useCallback(() => {
		const existingView = checkFilterViewIsNew(filterViews, query.toString())
		if (
			(query.toString() === defaultPreset.config || existingView) &&
			onShowSnackbar
		) {
			onShowSnackbar({
				message: existingView
					? `View previously saved as ${existingView.name}.`
					: "This is the default view!",
				type: "WARNING",
			})
		} else {
			setIsSaveModalOpen(true)
		}
	}, [filterViews, query.toString()])

	const addFilterView = useCallback(
		async (filterView: AddFilterViewProps) => {
			try {
				const response = await firestore
					.collection(`/accounts/${address}/filterViews`)
					.add({ ...filterView, createdAt: new Date().getTime() })

				setSelectedValue({ ...filterView, id: response.id } as FilterView)
				setIsSaveModalOpen(false)
				Mixpanel.track("Filter preset added", {
					filter: query.toString(),
				})
				onShowSnackbar &&
					onShowSnackbar({
						message: "Successfully saved filter preset!",
						type: "SUCCESS",
					})
			} catch (e) {
				Err("Failed to add preset", e)
				onShowSnackbar &&
					onShowSnackbar({
						message: "Failed to saved filter preset!",
						type: "ERROR",
					})
				Mixpanel.track("Failed to add filter preset", {
					error: e,
					filter: query.toString(),
				})
			}

			setNewPresetTitle("")
		},
		[address]
	)

	const deleteFilterView = useCallback(
		async (filterView: FilterView) => {
			if (filterView.id) {
				try {
					await firestore
						.collection(`/accounts/${address}/filterViews`)
						.doc(filterView.id)
						.delete()

					Mixpanel.track("Filter preset deleted", {
						filter: query.toString(),
					})
					setSelectedForDelete(null)
					onShowSnackbar &&
						onShowSnackbar({
							message: "Successfully deleted filter preset!",
							type: "SUCCESS",
						})
					if (selectedValue?.id === filterView.id) setSelectedValue(null)
				} catch (e) {
					if (onShowSnackbar) {
						onShowSnackbar({
							message: "Failed to delete filter preset!",
							type: "ERROR",
						})
						Mixpanel.track("Failed to delete filter preset", {
							error: e,
							filter: query.toString(),
						})
					}
				}
			}
		},
		[address, selectedValue]
	)

	const getUserFilterViews = useCallback(async () => {
		try {
			await firestore
				.collection(`/accounts/${address}/filterViews`)
				.orderBy("createdAt", "asc")
				.onSnapshot(({ docs }) => {
					const userFilterViews = docs.map(
						doc =>
							({
								...doc.data(),
								id: doc.id,
							} as FilterView)
					)

					setFilterViews(userFilterViews)
				})
		} catch (err) {
			Err("Failed to get user filter views", err)
		}
	}, [address])

	useEffect(() => {
		if (address) {
			getUserFilterViews()
		}
	}, [address])

	useEffect(() => {
		if (!hasSelectedPreset) setSelectedValue(null)
	}, [isLoading])

	useEffect(() => {
		if (query.toString() === defaultPreset.config)
			applyPreset(defaultPreset as FilterView)
	}, [query.toString()])

	return {
		addFilterView,
		applyPreset,
		defaultPreset,
		defaultPresetLabel,
		deleteFilterView,
		filterViews,
		isMenuOpen,
		isSaveModalOpen,
		newPresetTitle,
		onSaveView,
		query,
		selectedForDelete,
		selectedValue,
		setNewPresetTitle,
		setSelectedForDelete,
		toggleIsSaveModalOpen,
		toggleMenuOpen,
	}
}
