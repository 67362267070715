import { format } from "date-fns"
import { useMemo } from "react"
import { flowty } from "../../../../../config/config"
import { RentEvent } from "../types/RentEventTypes"
import { StorefrontEvent } from "../types/StorefrontEventTypes"
import { LoanRow } from "./LoanRow/LoanRow"
import { RentRow } from "./RentRow/RentRow"
import { StorefrontRow } from "./StorefrontRow/StorefrontRow"
import { LoanEvent } from "../types/LoanEventTypes"

interface Props {
	historyRow: StorefrontEvent | RentEvent | LoanEvent
}

export const HistoryRow: React.FC<Props> = ({ historyRow }) => {
	const listingType = useMemo(() => {
		if (historyRow?.type.includes("STOREFRONT")) {
			return "STOREFRONT"
		} else if (historyRow?.type.includes("RENT")) {
			return "RENT"
		} else {
			return "LOAN"
		}
	}, [historyRow])

	const flowdiverBaseURL =
		flowty.config.network === "mainnet"
			? "https://flowdiver.io"
			: "https://testnet.flowdiver.io"
	const transactionExplorerLink = `${flowdiverBaseURL}/${historyRow.transactionId}`

	const date = format(historyRow.blockTimestamp.toDate(), "yyyy-MM-dd HH:mm")

	return (
		<button
			onClick={() => {
				window.open(transactionExplorerLink, "_blank")
			}}
			className='w-full flex flex-col md:flex-row gap-2 md:gap-6 justify-between collectionCard p-6'
		>
			{listingType === "STOREFRONT" && (
				<StorefrontRow storefront={historyRow as StorefrontEvent} />
			)}
			{listingType === "RENT" && <RentRow rent={historyRow as RentEvent} />}
			{listingType === "LOAN" && <LoanRow loan={historyRow as LoanEvent} />}
			<p className='font-semibold text-[12px] md:text-[16px] md:font-bold text-[#898989] md:text-white whitespace-nowrap'>
				{date}
			</p>
		</button>
	)
}
