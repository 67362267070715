/* eslint-disable xss/no-mixed-html */
import { OpensearchFlowNFT, getImageURL } from "flowty-common"
import React, { PropsWithChildren, useMemo, useState } from "react"
import { Tooltip } from "react-tooltip"
import { FlowtyDiscountIndicator } from "../Shared/Molecules/FlowtyDiscountIndicator"
import { AccountSummaries, ValuationData } from "../Types/GlobalTypes"
import CatalogVerifier from "../assets/CatalogVerifier-2.png"
import { ReactComponent as ViewOnly } from "../assets/ViewOnly.svg"
import FallbackImage from "../assets/new_fallback_image.svg"
import { ListingTypeBanner } from "./components/ListingTypeBanner"
import { WalletImageComponent } from "./components/WalletImageComponent"
import { FlowtyModalListingType } from "../FlowtyModal/types/FlowtyModalTypes"
import { returnTsSpecialTraits, TsSpecialIcons } from "../TsSpecialIcons"

export interface FlowtyNftCardProps extends PropsWithChildren {
	nft: OpensearchFlowNFT
	listingType?: FlowtyModalListingType
	valuationData: ValuationData
	accountSummaries?: AccountSummaries | undefined
	hasProvider: boolean
	collectionImage: string | null
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mixPanelFn: (event: string, data: any) => void
}

export const FlowtyNftCard: React.FunctionComponent<FlowtyNftCardProps> = ({
	children,
	nft,
	listingType,
	valuationData,
	accountSummaries,
	hasProvider,
	collectionImage,
	mixPanelFn,
}) => {
	const [isMp4, setIsMp4] = useState<boolean | null>(null)
	const [onVideoError, setOnVideoError] = useState<boolean>(false)
	const assetUrl = getImageURL(nft?.card?.images?.[0]?.url || "")
	const isChild = Object.keys(accountSummaries || {}).length > 1 || false
	const [collectionImageFallbackImageFail, setCollectionFallbackImageFail] =
		useState<boolean>(false)

	const specialTsTraits = useMemo(
		() => returnTsSpecialTraits({ traits: nft.nftView?.traits?.traits ?? [] }),
		[nft.nftView?.traits?.traits]
	)

	const renderAsset = useMemo(() => {
		if (collectionImageFallbackImageFail) {
			return (
				<img
					src={FallbackImage}
					onError={() => setIsMp4(true)}
					alt={`${nft?.card?.title} asset`}
					className='object-cover aspect-square w-[152px] sm:w-full'
				/>
			)
		}

		if (!isMp4) {
			return (
				<img
					src={getImageURL(assetUrl)}
					onError={() => setIsMp4(true)}
					alt={`${nft?.card?.title} asset`}
					className='object-cover aspect-square w-[152px] sm:w-full'
				/>
			)
		}

		if (onVideoError) {
			return (
				<img
					src={collectionImage ?? ""}
					onError={() => {
						setCollectionFallbackImageFail(true)
						setIsMp4(true)
					}}
					alt={`${nft?.card?.title} asset`}
					className='object-cover aspect-square w-[152px] sm:w-full'
				/>
			)
		}

		// Assume assetUrl is for a video if all above checks fail
		return (
			<video
				muted
				loop
				playsInline
				preload='auto'
				autoPlay={false}
				className='object-cover aspect-square w-[152px] sm:w-full'
				onMouseEnter={event => {
					const videoElement = event.target as HTMLMediaElement

					const playPromise = videoElement.play()

					if (playPromise !== undefined) {
						playPromise
							.then(_ => {})
							.catch(error => mixPanelFn("ERROR_PLAYING_NFT_VIDEO", { error }))
					}
				}}
				onMouseLeave={event => {
					const videoElement = event.target as HTMLMediaElement
					videoElement.pause()
					videoElement.currentTime = 0
				}}
				onError={() => {
					setOnVideoError(true)
				}}
			>
				<source type='video/mp4' src={assetUrl} />
			</video>
		)
	}, [
		collectionImageFallbackImageFail,
		isMp4,
		onVideoError,
		assetUrl,
		nft,
		collectionImage,
	])

	return (
		<div className='p-[16px] md:p-[19px] w-full relative flowty-nft-card rounded-lg border border-neutral-300'>
			{valuationData?.percentage &&
			(listingType === "storefront" || listingType === "purchase") ? (
				<div className='absolute -top-1 right-[7%] z-40'>
					<FlowtyDiscountIndicator
						percent={valuationData?.percentage || 0}
						tooltip
						source={valuationData?.source || ""}
					/>
				</div>
			) : null}
			<div className='flex flex-row-reverse gap-6 sm:gap-3 md:flex-col justify-between sm:justify-start'>
				<div className='flex flex-col flex-grow sm:flex-none sm:pl-0 col-span-2 sm:order-first min-w-[0px]'>
					<div className='w-full flex flex-row justify-between'>
						<div className='flex flex-row items-center justify-start gap-2 max-w-[307px]'>
							{nft?.card?.additionalDetails ? (
								<p className='max-w-[110px] sm:max-w-[286px] block text-sm sm:text-lg text-white font-montserrat font-extrabold truncate pointer-events-none uppercase'>
									{nft?.card?.title}
								</p>
							) : (
								<div className='max-w-[110px] sm:max-w-[286px] flex truncate'>
									<p className='block text-sm sm:text-lg text-white font-montserrat font-extrabold truncate pointer-events-none uppercase'>
										{nft?.card?.title}
									</p>
									{nft?.card?.num && !nft?.card?.title.includes("#") && (
										<p className='block text-sm sm:text-lg text-white font-montserrat font-extrabold truncate pointer-events-none uppercase ml-2'>
											#{nft?.card?.num}
										</p>
									)}
								</div>
							)}
							<div>
								{nft?.isNFTCatalog && (
									<>
										<Tooltip
											id={`${nft.uuid}-verified-tooltip`}
											style={{
												backgroundColor: "white",
												color: "#031021",
												fontSize: "0.75rem",
												zIndex: 50,
											}}
										/>
										<img
											data-tooltip-id={`${nft.uuid}-verified-tooltip`}
											data-tooltip-content={`Verified Collection from the Flow NFT catalog`}
											data-tooltip-place='top'
											alt='Verified Collection from the Flow NFT catalog'
											src={CatalogVerifier}
											className='w-[20px] h-[20px] min-w-[20px]'
										/>
									</>
								)}
							</div>
						</div>
						<div className='flex flex-col items-center justify-center'>
							{isChild && (
								<WalletImageComponent
									accountSummaries={accountSummaries}
									nft={nft}
								/>
							)}
							{hasProvider === false && (
								<>
									<Tooltip
										id={`${nft.uuid}-view-only-tooltip`}
										style={{
											backgroundColor: "white",
											color: "#031021",
											fontSize: "0.75rem",
											zIndex: 10,
										}}
									/>
									<ViewOnly
										data-tooltip-id={`${nft.uuid}-view-only-tooltip`}
										data-tooltip-content='This profile has view-only access to this item'
										data-tooltip-place='right'
									/>
								</>
							)}
						</div>
					</div>
					{nft?.card?.additionalDetails ? (
						<>
							{nft?.card?.additionalDetails ? (
								nft?.card?.additionalDetails?.map(detail => (
									<div
										key={detail}
										className='flex flex-row items-center justify-start mt-2 h-4 truncate'
									>
										<p className='font-montserrat text-[#8B8B8B] text-sm font-semibold last:border-none border-r-2 border-[#8B8B8B] pointer-events-none first:pl-0 pr-2 pl-2'>
											{detail}
										</p>
									</div>
								))
							) : (
								<div className='flex flex-row items-center justify-start mt-2 h-4'></div>
							)}
							{nft?.card?.additionalDetails &&
								nft?.card?.additionalDetails.length === 1 && (
									<div className='flex flex-row items-center justify-start mt-2 h-4'></div>
								)}
						</>
					) : (
						<>
							<div className='flex flex-row items-center justify-start mt-2 h-4 truncate'>
								{nft?.card?.headerTraits &&
									nft?.card?.headerTraits?.map(trait => (
										<p
											key={trait.displayType}
											className='font-montserrat text-[#8B8B8B] text-sm font-semibold last:border-none border-r-2 border-[#8B8B8B] pointer-events-none first:pl-0 pr-2 pl-2'
										>
											{trait.value}
										</p>
									))}
							</div>
							<div className='flex flex-row items-center justify-start mt-2 h-4'></div>
						</>
					)}
				</div>
				<div className='relative sm:w-full'>
					<ListingTypeBanner listingType={listingType} />
					<div className='flex flex-row absolute top-7 md:top-16 left-1 md:left-2 z-20'>
						<TsSpecialIcons specialTsTraits={specialTsTraits} />
					</div>
					{renderAsset}
				</div>
			</div>
			<div className='min-h-[10rem]'>{children}</div>
		</div>
	)
}
