import { OpensearchFlowNFT, getImageURL } from "flowty-common"
import React, { useEffect, useMemo, useState } from "react"
import noImage from "../../../assets/media/image_not_available.svg"
import { guessAssetExtension } from "../../../util/guessAssetExtension"
import { addFallbackImage } from "../../../util/images"
import FallbackImage from "../FallbackImage"
import { WrappedHtmlModal } from "../../FlowtyWrapped/WrappedHtmlModal/WrappedHtmlModal"
import { flowty } from "../../../config/config"
import { IS_MAINNET } from "../../../util/settings"
import { returnTsSpecialTraits, TsSpecialIcons } from "ds-flowty"

const FLOWTY_WRAPPED_TYPE = IS_MAINNET
	? "A.592eb32b47d8b85f.FlowtyWrapped.NFT"
	: "A.d1712bc9e162f9e3.FlowtyWrapped.NFT"

interface AssetDisplayProps {
	asset: OpensearchFlowNFT
	classNames?: string
}

export const AssetDisplay: React.FC<AssetDisplayProps> = ({
	asset,
	classNames,
}) => {
	const [wrappedModalOpen, setWrappedModalOpen] = useState<boolean>(false)
	const [ipfsLink, setIpfsLink] = useState<string | boolean>(false)

	const isWrappedNFT = useMemo(() => {
		return asset.type === FLOWTY_WRAPPED_TYPE
	}, [asset])

	const getIpfsFn = async (address: string): Promise<void> => {
		const result = await flowty.scripts.getFlowtyWrappedIpfs(address)
		if (result) {
			setIpfsLink(result)
		}
	}

	useEffect(() => {
		if (isWrappedNFT) {
			getIpfsFn(asset?.owner)
		}
	}, [isWrappedNFT, asset])

	const [isMp4, setIsMp4] = useState<boolean | null>(null)
	const [didImageLoadFail, setDidImageLoadFail] = useState(false)

	const assetUrl = useMemo(
		() => getImageURL(asset.card?.images?.[0]?.url ?? noImage),
		[asset.card?.images?.[0]?.url]
	)

	useEffect(() => {
		if (assetUrl && didImageLoadFail) {
			guessAssetExtension(assetUrl, "mp4", { shouldUseContentType: true }).then(
				setIsMp4
			)
		}
	}, [assetUrl, didImageLoadFail])

	const specialTsTraits = useMemo(
		() =>
			returnTsSpecialTraits({ traits: asset.nftView?.traits?.traits ?? [] }),
		[asset.nftView?.traits?.traits]
	)

	const renderImage = useMemo(() => {
		if (!isMp4 && didImageLoadFail) {
			return (
				<div className='relative mx-auto w-fit'>
					<div className='flex flex-row absolute top-4 left-4'>
						<TsSpecialIcons specialTsTraits={specialTsTraits} />
					</div>
					<FallbackImage
						collectionAddress={
							asset?.card?.collectionAddress || asset?.contractAddress || ""
						}
						collectionName={
							asset?.card?.collectionName || asset?.contractName || ""
						}
						onError={e => {
							addFallbackImage(e)
						}}
						className={`bg-no-repeat bg-cover bg-center ${classNames}`}
					/>
				</div>
			)
		}

		if (!isMp4) {
			const title =
				asset.card?.title || `${asset?.contractName} #${asset?.nftView?.id}`

			return isWrappedNFT ? (
				<div className='relative mx-auto w-fit'>
					<img
						className={`bg-no-repeat bg-cover bg-center ${classNames}`}
						src={assetUrl}
						alt={title}
						onError={() => {
							setDidImageLoadFail(true)
						}}
					/>
					<button
						onClick={() => {
							setWrappedModalOpen(true)
						}}
						className='absolute top-1/2  left-1/2 transform translate-x-[-50%] translate-y-[-50%] w-[10rem] h-[2.3rem] md:w-[15rem] md:h-[3.3rem] flex justify-center items-center gap-[1rem] flex-shrink-0 open-rich-text-button text-[14px] md:text-[1.5rem] font-[500] text-white font-montserrat'
					>
						{"Click To Open"}
					</button>
				</div>
			) : (
				<div className='relative mx-auto w-fit'>
					<div className='flex flex-row absolute top-4 left-4'>
						<TsSpecialIcons specialTsTraits={specialTsTraits} />
					</div>
					<img
						className={`bg-no-repeat bg-cover bg-center ${classNames}`}
						src={assetUrl}
						alt={title}
						onError={() => {
							setDidImageLoadFail(true)
						}}
					/>
				</div>
			)
		}

		if (isMp4) {
			return (
				<div className={`${classNames}`}>
					<video
						autoPlay
						muted
						loop
						controls={false}
						playsInline
						preload='auto'
					>
						<source type='video/mp4' src={assetUrl}></source>
					</video>
				</div>
			)
		}
	}, [
		classNames,
		assetUrl,
		addFallbackImage,
		isMp4,
		didImageLoadFail,
		setDidImageLoadFail,
	])

	return (
		<>
			{renderImage}
			{wrappedModalOpen && (
				<WrappedHtmlModal
					ipfsLink={ipfsLink.toString()}
					isOpen={wrappedModalOpen}
					onClose={() => setWrappedModalOpen(false)}
				/>
			)}
		</>
	)
}
