import { ReactNode } from "react"

interface FilterTagProps {
	label: string
	onRemove: (evt: React.MouseEvent<HTMLButtonElement>) => void
	children: ReactNode
}

const FilterTagPlain: React.FC<FilterTagProps> = ({
	label,
	onRemove,
	children,
}) => {
	return (
		<button
			onClick={onRemove}
			className='py-[0.75em] lg:py-[1.125rem] tracking-[0.1px] font-montserrat text-[0.9375rem] font-[500] px-[1rem] rounded-[0.375rem] text-white bg-glassFillGray hover:bg-glassFillGrayHover lg:h-[2.5rem] flex items-center justify-center gap-[0.75rem] shadow-[0_2px_4px_0px_rgba(0,0,0,0.1)]'
		>
			<span className='text-base capitalize text-slate-100 font-montserrat whitespace-nowrap group-hover:text-opacity-80'>
				{label}
			</span>

			{children}
		</button>
	)
}

export const FilterTag: React.FC<Omit<FilterTagProps, "children">> = props => {
	return (
		<FilterTagPlain {...props}>
			<span className='ml-2 h-4 w-4 flex-shrink-0 p-1 text-gray-400 group-hover:text-opacity-[50%'>
				<svg
					className='w-2 h-2'
					stroke='currentColor'
					fill='none'
					viewBox='0 0 8 8'
				>
					<path strokeLinecap='round' strokeWidth='1.5' d='M1 1l6 6m0-6L1 7' />
				</svg>
			</span>
		</FilterTagPlain>
	)
}

export const ClearAllFilterTag: React.FC<
	Omit<Omit<FilterTagProps, "label">, "children"> & {
		label?: string
		shouldHideBorder?: boolean
	}
> = ({ shouldHideBorder, ...props }) => {
	return (
		<div
			className={`${
				!shouldHideBorder && "pl-2 ml-1 border-l-2"
			} border-[#7c7c7c] border-opacity-70  fade-in inline-flex`}
		>
			<FilterTagPlain {...{ ...props, label: props.label ?? "Clear All" }}>
				<></>
			</FilterTagPlain>
		</div>
	)
}
