import { useState } from "react"
import { ReactComponent as Twitter } from "../../../../assets/media/twitterOutlined.svg"
import { ReactComponent as TwitterFilled } from "../../../../assets/media/twitterFilled.svg"

interface TwitterIconProps {
	link: string
}

export const TwitterIcon: React.FunctionComponent<TwitterIconProps> = ({
	link,
}) => {
	const [onHover, setOnHover] = useState(false)
	return (
		<>
			{onHover ? (
				<TwitterFilled
					className='cursor-pointer h-5'
					onClick={() => {
						window.open(link)
					}}
					onMouseEnter={() => setOnHover(true)}
					onMouseLeave={() => setOnHover(false)}
				/>
			) : (
				<Twitter
					className='cursor-pointer h-5'
					onClick={() => {
						window.open(link)
					}}
					onMouseEnter={() => setOnHover(true)}
					onMouseLeave={() => setOnHover(false)}
				/>
			)}
		</>
	)
}
