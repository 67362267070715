/* eslint-disable @typescript-eslint/no-use-before-define */

import {
	NFTLocationData,
	nftTypeAndIdToLocationData,
	TokenMetadata,
} from "flowty-common"
import { Config } from "../../types"
import { FlowNFTData } from "../../common/CommonTypes"

export const getCreateRentalListingTxn = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData
): string => {
	const locationData = nftTypeAndIdToLocationData(nftData.type, nftData.id)

	return config.crescendo
		? rentalListingTxnCrescendo(config, token, nftData, locationData)
		: rentalListingTxn(config, token, nftData)
}

export const getDelistRentalListingTxn = (config: Config): string =>
	config.crescendo ? rentalDelistTxnCrescendo(config) : rentalDelistTxn(config)

const rentalListingTxn = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import ${token.contractName} from ${token.contractAddress}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import ${nftData.contractName} from ${nftData.contractAddress}

import FlowtyRentals from ${config.contractAddresses.Flowty}
import Flowty from ${config.contractAddresses.Flowty}

import HybridCustody from ${config.contractAddresses.HybridCustody}

transaction(listItemID: UInt64, amount: UFix64, deposit: UFix64, term: UFix64, expiresAfter: UFix64, renter: Address?, nftProviderAddress: Address, nftProviderPathIdentifier: String, collectionIdentifier: String, ftReceiverAddress: Address) {
    let receiver: Capability<&{FungibleToken.Receiver}>
    let nftProvider: Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
    let storefront: &FlowtyRentals.FlowtyRentalsStorefront
    let nftReceiver: Capability<&{NonFungibleToken.CollectionPublic}>

    prepare(acct: AuthAccount) {
        let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

        let publicCollectionPath = catalogEntry.collectionData.publicPath
        let storageCollectionPath = catalogEntry.collectionData.storagePath

        if(acct.borrow<&FlowtyRentals.FlowtyRentalsStorefront>(from: FlowtyRentals.FlowtyRentalsStorefrontStoragePath) == nil) {
            // Create a new empty .Storefront
            let storefront <- FlowtyRentals.createStorefront() as! @FlowtyRentals.FlowtyRentalsStorefront

            // save it to the account
            acct.save(<-storefront, to: FlowtyRentals.FlowtyRentalsStorefrontStoragePath)

            // create a public capability for the .Storefront
            acct.link<&FlowtyRentals.FlowtyRentalsStorefront{FlowtyRentals.FlowtyRentalsStorefrontPublic}>(FlowtyRentals.FlowtyRentalsStorefrontPublicPath, target: FlowtyRentals.FlowtyRentalsStorefrontStoragePath)
        }

        if ftReceiverAddress == acct.address {
            if acct.borrow<&${token.contractName}.Vault>(from: ${token.storagePath}) == nil {
                acct.save(<-${token.contractName}.createEmptyVault(), to: ${token.storagePath})
                acct.link<&${token.contractName}.Vault{FungibleToken.Receiver}>(
                    ${token.receiverPath},
                    target: ${token.storagePath}
                )
                acct.link<&${token.contractName}.Vault{FungibleToken.Balance}>(
                    ${token.balancePath},
                    target: ${token.storagePath}
                )
            }

            self.receiver = acct.getCapability<&{FungibleToken.Receiver}>(${token.receiverPath})
        } else {
            let manager = acct.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: ftReceiverAddress) ?? panic("no child account with that address")
            self.receiver = getAccount(ftReceiverAddress).getCapability<&{FungibleToken.Receiver}>(${token.receiverPath})
        }

        assert(self.receiver.check(), message: "Missing or mis-typed receiver")

        if nftProviderAddress == acct.address {
            let flowtyNftCollectionProviderPath = /private/${nftData.contractName}${nftData.contractAddress}CollectionProviderForFlowty

            if !acct.getCapability<&${nftData.contractName}.Collection{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(flowtyNftCollectionProviderPath).check() {
                acct.unlink(flowtyNftCollectionProviderPath)
                acct.link<&${nftData.contractName}.Collection{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(flowtyNftCollectionProviderPath, target: storageCollectionPath)
            }

            if !acct.getCapability<&{NonFungibleToken.CollectionPublic}>(publicCollectionPath).check() {
                acct.unlink(publicCollectionPath)
                acct.link<&{NonFungibleToken.CollectionPublic, MetadataViews.ResolverCollection, NonFungibleToken.Receiver}>(publicCollectionPath, target: storageCollectionPath)
            }

            self.nftProvider = acct.getCapability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(flowtyNftCollectionProviderPath)
            self.nftReceiver = acct.getCapability<&{NonFungibleToken.CollectionPublic}>(publicCollectionPath)
        } else {
            let collectionProviderPrivatePath = PrivatePath(identifier: nftProviderPathIdentifier) ?? panic("invalid provider path identifier")
            
            let manager = acct.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: nftProviderAddress) ?? panic("no child account with that address")
            let providerCap = child.getCapability(path: collectionProviderPrivatePath, type: Type<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()) ?? panic("no nft provider found")
            
            self.nftProvider = providerCap as! Capability<&{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
            let item = self.nftProvider.borrow() ?? panic("nft provider could not be borrowed")

            let receiverCap = child.getCapability(path: publicCollectionPath, type: Type<&{NonFungibleToken.CollectionPublic}>()) ?? panic("no nft collection public found")
            self.nftReceiver = receiverCap as! Capability<&{NonFungibleToken.CollectionPublic}>
        }

        assert(self.nftProvider.check(), message: "Missing or mis-typed NFT Provider")
        assert(self.nftReceiver.check(), message: "Missing or mis-typed NFT Receiver")

        self.storefront = acct.borrow<&FlowtyRentals.FlowtyRentalsStorefront>(from: FlowtyRentals.FlowtyRentalsStorefrontStoragePath)
            ?? panic("Missing or mis-typed FlowtyRentals.FlowtyRentalsStorefront")
    }

    execute {
        let paymentCut = Flowty.PaymentCut(
            receiver: self.receiver,
            amount: amount
        )

        self.storefront.createListing(
            nftProviderCapability: self.nftProvider,
            nftPublicCollectionCapability: self.nftReceiver,
            ownerFungibleTokenReceiver: self.receiver,
            nftType: Type<@${nftData.contractName}.NFT>(),
            nftID: listItemID,
            amount: amount,
            deposit: deposit,
            term: term,
            paymentVaultType: Type<@${token.contractName}.Vault>(),
            paymentCut: paymentCut,
            expiresAfter: expiresAfter,
            renter: renter
        )
    }
}`

const rentalListingTxnCrescendo = (
	config: Config,
	token: TokenMetadata,
	nftData: FlowNFTData,
	locationData: NFTLocationData
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import ${token.contractName} from ${token.contractAddress}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import ${nftData.contractName} from ${nftData.contractAddress}

import FlowtyRentals from ${config.contractAddresses.Flowty}
import Flowty from ${config.contractAddresses.Flowty}

import HybridCustody from ${config.contractAddresses.HybridCustody}

transaction(listItemID: UInt64, amount: UFix64, deposit: UFix64, term: UFix64, expiresAfter: UFix64, renter: Address?, nftProviderAddress: Address, nftProviderControllerID: UInt64, collectionIdentifier: String, ftReceiverAddress: Address) {
    let receiver: Capability<&{FungibleToken.Receiver}>
    let nftProvider: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
    let storefront: auth(FlowtyRentals.List) &FlowtyRentals.FlowtyRentalsStorefront
    let nftReceiver: Capability<&{NonFungibleToken.CollectionPublic}>

    prepare(acct: auth(Capabilities, Storage) &Account) {
        let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

        let publicCollectionPath = catalogEntry.collectionData.publicPath
        let storageCollectionPath = catalogEntry.collectionData.storagePath

        if(acct.storage.borrow<&FlowtyRentals.FlowtyRentalsStorefront>(from: FlowtyRentals.FlowtyRentalsStorefrontStoragePath) == nil) {
            let storefront <- FlowtyRentals.createStorefront()

            acct.storage.save(<-storefront, to: FlowtyRentals.FlowtyRentalsStorefrontStoragePath)
            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{FlowtyRentals.FlowtyRentalsStorefrontPublic}>(FlowtyRentals.FlowtyRentalsStorefrontStoragePath),
                at: FlowtyRentals.FlowtyRentalsStorefrontPublicPath
            )
        }

        if ftReceiverAddress == acct.address {
            if acct.storage.borrow<&${token.contractName}.Vault>(from: ${token.storagePath}) == nil {
                acct.storage.save(<-${token.contractName}.createEmptyVault(vaultType: Type<@${token.contractName}.Vault>()), to: ${token.storagePath})

                acct.capabilities.publish(
                    acct.capabilities.storage.issue<&{FungibleToken.Receiver}>(${token.storagePath}),
                    at: ${token.receiverPath}
                )
                acct.capabilities.publish(
                    acct.capabilities.storage.issue<&{FungibleToken.Balance}>(${token.storagePath}),
                    at: ${token.balancePath}
                )
            }

            self.receiver = acct.capabilities.get<&{FungibleToken.Receiver}>(${token.receiverPath})!
        } else {
            let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: ftReceiverAddress) ?? panic("no child account with that address")
            self.receiver = getAccount(ftReceiverAddress).capabilities.get<&{FungibleToken.Receiver}>(${token.receiverPath})!
        }

        assert(self.receiver.check(), message: "Missing or mis-typed receiver")

        if nftProviderAddress == acct.address {
            self.nftProvider = acct.capabilities.storage.issue<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(storageCollectionPath)

            if !acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(publicCollectionPath).check() {
                acct.capabilities.unpublish(publicCollectionPath)
                acct.capabilities.publish(
                    acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(storageCollectionPath),
                    at: publicCollectionPath
                )
            }

            self.nftReceiver = acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(publicCollectionPath)!
        } else {           
            let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("Missing or mis-typed HybridCustody Manager")

            let child = manager.borrowAccount(addr: nftProviderAddress) ?? panic("no child account with that address")
            let providerCap = child.getCapability(controllerID: nftProviderControllerID, type: Type<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>()) ?? panic("no nft provider found")
            
            self.nftProvider = providerCap as! Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
            let item = self.nftProvider.borrow() ?? panic("nft provider could not be borrowed")

            let receiverCap = child.getPublicCapability(path: publicCollectionPath, type: Type<&{NonFungibleToken.CollectionPublic}>()) ?? panic("no nft collection public found")
            self.nftReceiver = receiverCap as! Capability<&{NonFungibleToken.CollectionPublic}>
        }

        assert(self.nftProvider.check(), message: "Missing or mis-typed NFT Provider")
        assert(self.nftReceiver.check(), message: "Missing or mis-typed NFT Receiver")

        self.storefront = acct.storage.borrow<auth(FlowtyRentals.List) &FlowtyRentals.FlowtyRentalsStorefront>(from: FlowtyRentals.FlowtyRentalsStorefrontStoragePath)
            ?? panic("Missing or mis-typed FlowtyRentals.FlowtyRentalsStorefront")
    }

    execute {
        let paymentCut = Flowty.PaymentCut(
            receiver: self.receiver,
            amount: amount
        )

        self.storefront.createListing(
            nftProviderCapability: self.nftProvider,
            nftPublicCollectionCapability: self.nftReceiver,
            ownerFungibleTokenReceiver: self.receiver,
            nftType: Type<@${nftData.contractName}.${locationData.resourceName}>(),
            nftID: listItemID,
            amount: amount,
            deposit: deposit,
            term: term,
            paymentVaultType: Type<@${token.contractName}.Vault>(),
            paymentCut: paymentCut,
            expiresAfter: expiresAfter,
            renter: renter
        )
    }
}`

const rentalDelistTxn = (
	config: Config
): string => `import FlowtyRentals from ${config.contractAddresses.Flowty}

transaction(listingResourceID: UInt64) {
	let storefront: &FlowtyRentals.FlowtyRentalsStorefront{FlowtyRentals.FlowtyRentalsStorefrontManager}

	prepare(acct: AuthAccount) {
		self.storefront = acct.borrow<&FlowtyRentals.FlowtyRentalsStorefront{FlowtyRentals.FlowtyRentalsStorefrontManager}>(from: FlowtyRentals.FlowtyRentalsStorefrontStoragePath)
			?? panic("Missing or mis-typed FlowtyRentals.FlowtyRentalsStorefront")
	}

	execute {
		self.storefront.removeListing(listingResourceID: listingResourceID)
	}
}`
const rentalDelistTxnCrescendo = (
	config: Config
): string => `import FlowtyRentals from ${config.contractAddresses.Flowty}

transaction(listingResourceID: UInt64) {
	let storefront: auth(FlowtyRentals.Cancel) &{FlowtyRentals.FlowtyRentalsStorefrontManager}

	prepare(acct: auth(Storage) &Account) {
		self.storefront = acct.storage.borrow<auth(FlowtyRentals.Cancel) &{FlowtyRentals.FlowtyRentalsStorefrontManager}>(from: FlowtyRentals.FlowtyRentalsStorefrontStoragePath)
			?? panic("Missing or mis-typed FlowtyRentals.FlowtyRentalsStorefront")
	}

	execute {
		self.storefront.removeListing(listingResourceID: listingResourceID)
	}
}`
