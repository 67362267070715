/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from "axios"
import { FlowtyListingModal, FlowtyPurchaseModal } from "ds-flowty"
import {
	FlowNFT,
	NotificationType,
	OfferCreated,
	OpenSearchListingAvailableData,
	OpensearchFlowNFT,
	OpensearchRentalAvailableData,
	OpensearchStorefrontAvailableData,
	Order,
	OrdersType,
	SpotPrice,
	Valuation,
	checkIsLocked,
	nftTypeAndIdToLocationData,
	nftTypeToContractID,
	InitiatedTransactionNotification,
} from "flowty-common"
import { LoanRentalFilteredData, AccountSummaries } from "flowty-sdk"
import { inject, observer } from "mobx-react"
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react"
import { useNavigate, useParams } from "react-router-dom"
import { flowty } from "../config/config"
import { useTokenSpotPrice } from "../hooks/data/collections/useTokenSpotPrice"
import { useFallbackImage } from "../hooks/useFallbackImage"
import { User } from "../models/user"
import { getPublicAccount } from "../services/AuthService"
import { fetchSpecificFlowNFT } from "../services/firestore/nftIInfo"
import { AuthStoreProp } from "../stores/AuthStore"
import { Err, Log } from "../util/Log"
import { actions as Mixpanel } from "../util/Mixpanel"
import { getBestCollectionPrivatePath } from "../util/getBestPrivatePath"
import { TOPSHOT_TYPE, apiURL, strapiUrl } from "../util/settings"
import { useHybridCustodyContext } from "./HybridCustodyContext"
import { useMarketplaceAppContext } from "./MarketplaceAppContext/MarketplaceAppContext"
import { getLoanFundingId } from "../screens/UserFlowScreen/firebase/LoanRentalQueries"
import { db } from "../firebase"

const defaultValues: FlowtyModalsContextValues = {
	isViewOnly: false,
	selectCard: async () => {},
}

interface SelectCardProps {
	selected: NftDetails | null
	initListingType?: "loan" | "rent" | "transfer"
	selectedSinglePurchase?: Order
	selectedSingleOffer?: OfferCreated
	singleOfferType?: "make-offer" | "cancel-offer"
	singleDelistAction?: "delistLoan" | "delistRental" | "delistSale"
	singleDelistOrder?: Order
	loanRentalActions?: LoanRentalFilteredData
}

interface FlowtyModalsContextValues {
	isViewOnly: boolean
	selectCard: ({
		selected,
		initListingType,
		selectedSinglePurchase,
	}: SelectCardProps) => Promise<void>
}

export interface NftDetails {
	contractAddress: string
	contractName: string
	nftType: string
	nftID: string
}

export const FlowtyModalsContext =
	createContext<FlowtyModalsContextValues>(defaultValues)

interface FlowtyModalsProviderProps extends AuthStoreProp {
	children: React.ReactNode
}

const FlowtyModalsProvider: React.FC<FlowtyModalsProviderProps> = ({
	authStore,
	children,
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [selectedNftDetails, setSelectedNftDetails] =
		useState<NftDetails | null>(null)
	const [selectedNft, setSelectedNft] = useState<OpensearchFlowNFT | null>(null)
	const [selectedOffer, setSelectedOffer] = useState<OfferCreated | null>(null)
	const [offerType, setOfferType] = useState<
		"make-offer" | "cancel-offer" | null
	>(null)
	const [singleAction, setSingleAction] = useState<
		"delistLoan" | "delistRental" | "delistSale" | undefined
	>(undefined)
	const [singleOrder, setSingleOrder] = useState<Order | null | undefined>(null)
	const [ownsNFTSelected, setOwnsNFTSelected] = useState<boolean>(false)
	const [initialListingType, setInitialListingType] = useState<
		"loan" | "rent" | "transfer" | undefined
	>(undefined)
	const [singlePurchase, setSinglePurchase] = useState<
		Order | null | undefined
	>(null)
	const [publicAccount, setProfile] = useState<User | null>(null)
	const [orders, setOrders] = useState<OrdersType>({
		loan: [],
		rental: [],
		storefront: [],
	})
	const [isOrderLoading, setIsOrderLoading] = useState(false)
	const [valuation, setValuation] = useState<Valuation | null>(null)
	const [isLoadingValuation, setIsLoadingValuation] = useState<boolean>(false)
	const [flowNFT, setFlowNFT] = useState<FlowNFT | null>(null)
	const [addressesWithCollectionPublic, setAddressesWithCollectionPublic] =
		useState<string[]>([])
	const [loanRentalActionsData, setLoanRentalActionsData] =
		useState<LoanRentalFilteredData | null>(null)
	const [collectionDisplayName, setCollectionDisplayName] = useState<
		string | null
	>(null)

	const navigate = useNavigate()
	const { walletAddress } = useParams()

	const { collectionAddress, collectionName } = selectedNft?.card || {
		collectionAddress: "",
		collectionName: "",
	}
	const collectionImages = useFallbackImage({
		collectionAddress,
		collectionName,
	})

	const collectionImage = collectionImages[collectionName]

	useEffect(() => {
		setOrders({
			loan: [],
			rental: [],
			storefront: [],
		})
		setValuation(null)
		if (
			!selectedNftDetails?.contractAddress ||
			!selectedNftDetails?.contractName ||
			!selectedNftDetails?.nftID
		)
			return

		setIsOrderLoading(true)
		setIsLoadingValuation(true)

		const ld = nftTypeAndIdToLocationData(
			selectedNftDetails?.nftType ?? "",
			selectedNftDetails?.nftID ?? ""
		)

		flowty.api
			.getItem({
				contractAddress: ld.contract.address,
				contractName: ld.contract.name,
				nftID: ld.nftID,
				resourceName: ld.resourceName,
			})
			.then((data: OpensearchFlowNFT) => {
				setSelectedNft(data)
				const allOrders = data.orders?.reduce(
					(result: OrdersType, order: Order): OrdersType => {
						const copyResult = { ...result }
						const listingKindOrders = copyResult[order.listingKind]?.length
							? [...copyResult[order.listingKind]]
							: []
						copyResult[order.listingKind] = [
							...(listingKindOrders as any),
							order.listingKind === "storefront"
								? (order as OpensearchStorefrontAvailableData)
								: order.listingKind === "loan"
								? (order as OpenSearchListingAvailableData)
								: (order as OpensearchRentalAvailableData),
						]
						return copyResult
					},
					orders
				)
				setOrders(
					allOrders || {
						loan: [],
						rental: [],
						storefront: [],
					}
				)
				setIsOrderLoading(false)
			})
			.catch(err => {
				Err("Failed to fetch asset orders.", err)
				setIsOrderLoading(false)
			})

		axios
			.post(`${apiURL}/nft/valuation`, selectedNftDetails)
			.then((res: AxiosResponse<Valuation>) => {
				setIsLoadingValuation(false)
				setValuation(res.data)
			})
			.catch((err: Error) => {
				setIsLoadingValuation(false)
				setValuation(null)
				Log({ err })
			})

		fetchSpecificFlowNFT({
			nftId: selectedNftDetails.nftID,
			nftType: selectedNftDetails?.nftType ?? "",
		}).then(res => setFlowNFT(res))
	}, [selectedNftDetails])

	useEffect(() => {
		getPublicAccount(walletAddress || "")
			.then(userProfile =>
				setProfile({
					...userProfile,
					accountSummaries: Object.keys(
						userProfile?.childAccounts || {}
					).reduce((res, cur) => {
						return {
							...res,
							[cur]: userProfile?.childAccounts?.[cur],
						}
					}, {}),
				} as User)
			)
			.catch(err => {
				Err(err)
				navigate("/wallet-not-found")
			})
	}, [walletAddress])

	const spotPrice = useTokenSpotPrice("FLOW")

	const listingType = useMemo(() => {
		if (singlePurchase) {
			return singlePurchase.listingKind === "storefront"
				? "purchase"
				: singlePurchase.listingKind
		}
		if (
			selectedNft?.orders?.[0]?.listingKind === "storefront" ||
			offerType === "make-offer" ||
			loanRentalActionsData
		)
			return "purchase"

		return selectedNft?.orders?.[0]?.listingKind
	}, [selectedNft, offerType, loanRentalActionsData])

	const {
		hybridCustodyNFTStatus,
		iterateAndRunScript,
		publicAccountNftProviders,
	} = useHybridCustodyContext()

	const { loggedUserAddress } = useMarketplaceAppContext()

	const collectionIdentifier = `${selectedNftDetails?.contractAddress}.${selectedNftDetails?.contractName}`
	useEffect(() => {
		if (selectedNftDetails) {
			iterateAndRunScript(
				[collectionIdentifier],
				authStore?.loggedUser?.childAccounts || {},
				authStore?.loggedUser?.addr || ""
			)
			flowty.scripts
				.getAddressesWithCollectionPublic(
					Object.keys(authStore?.loggedUser?.accountSummaries || {}) || [],
					selectedNftDetails?.contractAddress || "",
					selectedNftDetails?.contractName || ""
				)
				.then(addresses => {
					setAddressesWithCollectionPublic(addresses)
				})
				.catch(err => {
					Log(err)
				})
		}
	}, [selectedNftDetails])

	const hasProviderFn = useCallback(
		(nft: OpensearchFlowNFT | null): boolean | null => {
			if (!nft) return null
			if (
				nft?.owner === publicAccount?.addr ||
				nft?.owner === authStore?.loggedUser?.addr
			) {
				return true
			}

			const paths = hybridCustodyNFTStatus[nft?.owner]
			if (paths === undefined) {
				return null
			}

			return (
				paths?.[nft?.contractAddress.concat(".").concat(nft?.contractName)]
					?.length > 0
			)
		},
		[hybridCustodyNFTStatus, selectedNft]
	)

	const hasProvinerPublicFn = useCallback(
		(nft: OpensearchFlowNFT | null): boolean | null => {
			if (!nft) return null
			if (
				nft?.owner === publicAccount?.addr ||
				nft?.owner === authStore?.loggedUser?.addr
			) {
				return true
			}

			const paths = publicAccountNftProviders[nft?.owner]
			if (paths === undefined) {
				return null
			}

			return (
				paths?.[nft?.contractAddress.concat(".").concat(nft?.contractName)]
					?.length > 0
			)
		},
		[publicAccountNftProviders, selectedNft]
	)

	const userAccounts = Object.values(
		authStore?.loggedUser?.accountSummaries || {}
	).map(account => account.address)

	const isProfile = location.pathname.includes("/profile")
	const isLoggedUserProfile = loggedUserAddress === publicAccount?.addr

	const hasProvider = useMemo(() => {
		return isProfile ||
			isLoggedUserProfile ||
			userAccounts?.includes(selectedNft?.owner || "")
			? hasProviderFn(selectedNft)
			: hasProvinerPublicFn(selectedNft)
	}, [
		selectedNft,
		hybridCustodyNFTStatus,
		userAccounts,
		isLoggedUserProfile,
		isProfile,
	])

	const nftType = selectedNft?.type?.endsWith(".NFT")
		? selectedNft?.type
		: `${selectedNft?.type}.NFT`

	let isLocked = false
	if (nftType === TOPSHOT_TYPE) {
		isLocked = checkIsLocked(selectedNft)
	}

	const userHasProvider = useMemo(() => {
		return hasProvider || isLocked
	}, [hasProvider, selectedNft, isLocked, hybridCustodyNFTStatus])

	const nftProviderPathIdentifier = useMemo(() => {
		return authStore?.loggedUser?.addr !== selectedNft?.owner
			? getBestCollectionPrivatePath(
					hybridCustodyNFTStatus?.[selectedNft?.owner || ""]?.[
						collectionIdentifier
					] || [],
					selectedNft?.contractAddress,
					selectedNft?.contractName
			  )
			: ""
	}, [selectedNft, authStore, hybridCustodyNFTStatus])

	const selectCard = useCallback(
		async ({
			selected,
			initListingType,
			selectedSinglePurchase,
			selectedSingleOffer,
			singleOfferType,
			singleDelistAction,
			singleDelistOrder,
			loanRentalActions,
		}: SelectCardProps) => {
			setIsModalOpen(true)
			setSelectedNftDetails(selected)
			if (loanRentalActions) {
				if (loanRentalActions.type === "loan") {
					setIsOrderLoading(true)
					const fundingId = await getLoanFundingId(
						loanRentalActions?.listingResourceID ?? ""
					)
					loanRentalActions = {
						...loanRentalActions,
						fundingResourceID: fundingId,
					}
					setIsOrderLoading(false)
				}
				setLoanRentalActionsData(loanRentalActions)
				return
			}
			setInitialListingType(
				initListingType as "loan" | "rent" | "transfer" | undefined
			)
			setSinglePurchase(selectedSinglePurchase)
			selectedSingleOffer
				? setSelectedOffer(selectedSingleOffer)
				: setSelectedOffer(null)
			singleOfferType ? setOfferType(singleOfferType) : setOfferType(null)
			singleDelistAction
				? setSingleAction(singleDelistAction)
				: setSingleAction(undefined)
			singleDelistOrder
				? setSingleOrder(singleDelistOrder)
				: setSingleOrder(null)
		},
		[]
	)

	const getCollectionDisplayName = async () => {
		const contractID = nftTypeToContractID(nftType)
		try {
			const doc = await db.collection("flowNFTContract").doc(contractID).get()
			if (doc.exists) {
				const data = doc.data()
				if (data?.collectionDisplay?.name) {
					const removeDashesName = data?.collectionDisplay?.name?.replaceAll(
						"-",
						" "
					)
					setCollectionDisplayName(removeDashesName)
				}
			}
		} catch (error) {
			Log(error, nftType, contractID)
		}
	}

	useEffect(() => {
		getCollectionDisplayName()

		const ownerNFTSelected = selectedNft?.owner

		if (!ownerNFTSelected || !authStore?.loggedUser?.addr) {
			setOwnsNFTSelected(false)
			return
		}

		if (authStore.loggedUser.addr === ownerNFTSelected) {
			setOwnsNFTSelected(true)
			return
		}

		if (!authStore?.loggedUser?.childAccounts) {
			setOwnsNFTSelected(false)
			return
		}

		if (ownerNFTSelected in authStore?.loggedUser?.childAccounts) {
			setOwnsNFTSelected(true)
			return
		}

		// The signed in user owns this nft is they are the owner or if one of their child accounts is the owner
	}, [selectedNft, authStore])

	const values = {
		isViewOnly: false,
		selectCard,
	}

	const mixPanelFn = useCallback((action: string, properties: any) => {
		Mixpanel.track(action, properties)
	}, [])

	const onCloseModal = (): void => {
		setIsModalOpen(false)
		setSelectedNft(null)
		setSelectedNftDetails(null)
		setOrders({
			loan: [],
			rental: [],
			storefront: [],
		})
		setSelectedOffer(null)
		setSinglePurchase(null)
		setValuation(null)
		setFlowNFT(null)
		setLoanRentalActionsData(null)
	}

	const createTransactionNotification = async (
		data: InitiatedTransactionNotification
	): Promise<void> => {
		if (!authStore?.loggedUser?.addr) {
			return
		}
		await db
			.collection(`/accounts/${authStore.loggedUser.addr}/messages`)
			.add({ ...data, type: NotificationType.Transaction })
	}

	return (
		<FlowtyModalsContext.Provider value={values}>
			{children}
			{isModalOpen && selectedNft && (
				<>
					{ownsNFTSelected &&
					!loanRentalActionsData &&
					offerType !== "cancel-offer" ? (
						<FlowtyListingModal
							createTransactionNotification={createTransactionNotification}
							collectionDisplayName={collectionDisplayName}
							collectionImage={collectionImage}
							initialListingType={initialListingType}
							valuation={valuation}
							isLoadingValuation={isLoadingValuation}
							isOpen={isModalOpen}
							onClose={onCloseModal}
							openSearchFlowNFT={selectedNft as OpensearchFlowNFT}
							accountSummaries={
								authStore?.loggedUser?.accountSummaries as AccountSummaries
							}
							hasProvider={userHasProvider}
							spotPrice={spotPrice as SpotPrice}
							nftProviderPathIdentifier={nftProviderPathIdentifier}
							flowNFT={flowNFT ? flowNFT : undefined}
							selectedOffer={selectedOffer as OfferCreated}
							singleAction={singleAction}
							singleOrder={singleOrder as Order | undefined}
							strapiUrl={strapiUrl}
							mixPanelFn={mixPanelFn}
							addressesWithCollectionPublic={addressesWithCollectionPublic}
							flowty={flowty}
						/>
					) : (
						<FlowtyPurchaseModal
							createTransactionNotification={createTransactionNotification}
							collectionImage={collectionImage}
							singleOffer={offerType ? offerType : undefined}
							offer={selectedOffer as OfferCreated}
							isLoggedUser={Boolean(authStore?.loggedUser?.loggedIn)}
							purchaseType={listingType}
							nftOrders={!singlePurchase ? orders : undefined}
							isLoadingOrders={isOrderLoading}
							singleListing={singlePurchase ? singlePurchase : undefined}
							openSearchFlowNFT={selectedNft as OpensearchFlowNFT}
							accountSummaries={
								authStore?.loggedUser?.accountSummaries as AccountSummaries
							}
							nftProviderPathIdentifier={nftProviderPathIdentifier}
							isOpen={isModalOpen}
							onClose={onCloseModal}
							valuation={valuation as Valuation}
							isLoadingValuation={isLoadingValuation}
							spotPrice={spotPrice as SpotPrice}
							strapiUrl={strapiUrl}
							hasProvider
							mixPanelFn={mixPanelFn}
							addressesWithCollectionPublic={addressesWithCollectionPublic}
							loanRentalActionsData={loanRentalActionsData || undefined}
							flowty={flowty}
						/>
					)}
				</>
			)}
		</FlowtyModalsContext.Provider>
	)
}

const FlowtyModalsProviderAuthStore = inject("authStore")(
	observer(FlowtyModalsProvider)
)

const useFlowtyModalsContext = (): FlowtyModalsContextValues =>
	useContext(FlowtyModalsContext) as FlowtyModalsContextValues

export {
	FlowtyModalsProviderAuthStore as FlowtyModalsProvider,
	useFlowtyModalsContext,
}
